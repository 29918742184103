import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spacer } from '@chakra-ui/react'

import BaseCard from 'components/cards/BaseCard'

const Wrapper = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const EntityIcon = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  font-size: 40px;
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.4));
`

const Content = styled.div`
  height: 100%;
  width: 80%;
  margin: 2px 0 auto 0;

  > h4 {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 4px;
  }

  > p {
    font-size: 12px;
    font-weight: normal;
    padding-right: 4px;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
`

const EntityCard = ({ onClick, color, icon, title, subTitle, children }) => {
  return (
    <BaseCard onClick={onClick} color={color}>
      <Wrapper>
        {icon && (
          <EntityIcon>
            <FontAwesomeIcon color={color} icon={['fad', icon]} />
          </EntityIcon>
        )}
        <Content>
          <h4>{title}</h4>
          {subTitle && <p>{subTitle}</p>}
          {children}
        </Content>
      </Wrapper>
    </BaseCard>
  )
}

export default EntityCard
