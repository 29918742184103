import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Heading } from '@chakra-ui/react'

const SectionTitle = ({ color, children, ...rest }) => {
  return (
    <Heading
      textTransform="uppercase"
      py="1"
      px="2"
      bg={transparentize(0.9, color)}
      rounded="lg"
      size="md"
      textAlign="center"
      mx="auto"
      mb="5"
      color={color}
      {...rest}>
      {children}
    </Heading>
  )
}

export default SectionTitle
