import React, { useState, useRef, useCallback } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import ReactMarkdown from 'react-markdown'
import {
  Box,
  Flex,
  Center,
  Tabs,
  TabList,
  Tab,
  Spinner,
  Button,
  HStack,
  Skeleton,
  ButtonGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Spacer,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image
} from '@chakra-ui/react'

import bankIdLogo from 'style/images/BankID_logo_white.svg'
import { useToaster } from 'utils/toaster'

const DO_POLL = gql`
  query doPollBankID($token: String) {
    doPollBankID(token: $token) {
      complete
      success
      message
    }
  }
`

const BankIDPoll = ({ isOpen, onClose, onSuccess }) => {
  const toast = useToaster()
  const [state, setState] = useState({})

  const { loading, error, data } = useQuery(DO_POLL, {
    skip: !isOpen,
    fetchPolicy: 'network-only',
    pollInterval: 2000,
    onCompleted: ({ doPollBankID }) => {
      if (doPollBankID.complete) {
        if (doPollBankID.success) {
          onSuccess()
        } else {
          onClose()
        }
      }
    }
  })

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="0">Legitimera med BankID</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box my="1">
            <Center flexDir="column" my="8">
              <Center
                h="160px"
                w="160px"
                background="rgba(0, 0, 0, 0.2)"
                rounded="full"
                boxShadow="inner"
                mx="auto"
                mb="4">
                <Image src={bankIdLogo} w="140px" />
              </Center>

              <Text fontWeight="bold">
                {data && !error
                  ? data?.doPollBankID?.message
                  : 'Väntar på BankID...'}
              </Text>
              <Spinner mt="4" size="lg" speed="1s" />
            </Center>
          </Box>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BankIDPoll
