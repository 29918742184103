import React, { useEffect, useState } from 'react'
import { gql, useQuery, useMutation, fromPromise } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Center,
  Button,
  Textarea,
  Input,
  Spinner
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import SubtleCard from 'components/cards/SubtleCard'
import TextInput from 'components/form/TextInput'
import TextareaInput from 'components/form/TextareaInput'

const GET_NOTE = gql`
  query note($memberNumber: ID!, $id: ID!) {
    note(memberNumber: $memberNumber, id: $id) {
      id
      createdAt

      title
      content
      done
    }
  }
`

const SET_NOTE = gql`
  mutation setNote($id: String!, $memberNumber: ID!, $input: NoteInput!) {
    setNote(id: $id, memberNumber: $memberNumber, input: $input) {
      success
    }
  }
`

const DELETE_NOTE = gql`
  mutation deleteNote($id: ID!, $memberNumber: ID!) {
    deleteNote(id: $id, memberNumber: $memberNumber) {
      success
    }
  }
`

const Note = ({ memberNumber, id, onDelete = () => null }) => {
  const toast = useToaster()
  const [isDone, setIsDone] = useState(false)

  const form = useForm({
    mode: 'onChange'
  })

  const { loading, error, refetch, data } = useQuery(GET_NOTE, {
    skip: !id,
    variables: { id: id, memberNumber: memberNumber },
    fetchPolicy: 'network-only',
    onCompleted: ({ note }) => form.reset(note)
  })

  const [
    setNote,
    { loading: setNoteLoading, data: setNoteData, error: setNoteError }
  ] = useMutation(SET_NOTE)

  useEffect(() => {
    setIsDone(data?.note.done)
  }, [data])

  useEffect(() => {
    if (setNoteData?.setNote.success) {
      toast({
        title: 'Anteckning sparad!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }
  }, [setNoteData])

  const [
    deleteNote,
    { loading: deleteNoteLoading, data: deleteNoteData, error: deleteNoteError }
  ] = useMutation(DELETE_NOTE)

  useEffect(() => {
    if (deleteNoteData?.deleteNote.success === true) {
      toast({
        title: 'Anteckning raderad.',
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
      onDelete()
    }
  }, [deleteNoteData])

  return (
    <SubtleCard>
      <Box pos="relative" p="4" pt="1" minH="160px" transition="0.3s">
        {!loading && (
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(({ title, content }) =>
                setNote({
                  variables: {
                    id: id,
                    memberNumber: memberNumber,
                    input: { title, content }
                  }
                })
              )}>
              <TextInput
                id="title"
                variant="flushed"
                opacity={isDone ? 0.2 : 1}
                placeholder="Anteckningens titel"
                fontWeight="bold"
                px="2"
                borderBottom="0"
                bg="none"
              />

              <TextareaInput
                id="content"
                opacity={isDone ? 0.2 : 1}
                placeholder="En anteckning..."
                border="0"
                px="2"
                bg="none"
              />
            </form>
          </FormProvider>
        )}

        {loading && (
          <Center
            pos="absolute"
            top="0"
            right="0"
            bottom="0"
            left=""
            h="100%"
            w="100%">
            <Spinner size="xl" />
          </Center>
        )}

        {!loading && isDone && (
          <Center
            pos="absolute"
            top="0"
            right="0"
            bottom="0"
            left=""
            h="100%"
            w="100%">
            <FontAwesomeIcon
              size="3x"
              color="lightgreen"
              icon={['fad', 'check-circle']}
            />
          </Center>
        )}
      </Box>

      <Flex justifyContent="flex-end">
        <Button
          size="sm"
          variant="ghost"
          leftIcon={
            isDone ? <></> : <FontAwesomeIcon icon={['fal', 'check']} />
          }
          onClick={() => {
            setNote({
              variables: {
                id: id,
                memberNumber: memberNumber,
                input: { done: !isDone }
              }
            })
            setIsDone(!isDone)
          }}>
          {isDone ? 'Avmarkera som klar' : 'Markera som klar'}
        </Button>
        <Button
          color="#ff7675"
          size="sm"
          variant="ghost"
          leftIcon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
          onClick={() => {
            deleteNote({ variables: { id: id, memberNumber: memberNumber } })
          }}>
          Radera
        </Button>
      </Flex>
    </SubtleCard>
  )
}

export default Note
