import React, { useMemo, useCallback } from 'react'

import FreshTable from 'containers/FreshTable'
import MemberSelectedRender from 'containers/tables/MemberSelectedRender'

const MemberPicker = ({ data, getData, loading, onDone, onCancel, max }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'M.nr',
        accessor: 'memberNumber',
        maxWidth: 50
      },
      {
        Header: 'Namn',
        accessor: 'primaryAddress.fullname'
      },
      {
        Header: 'Personnr',
        accessor: 'primaryAddress.nationalId'
      },
      {
        Header: 'Epost',
        accessor: 'primaryAddress.email'
      }
    ],
    []
  )

  const onChange = useCallback((val) => {
    getData({ variables: val })
  }, [])

  return (
    <FreshTable
      title="Alla Medlemmar"
      color="#1abc9c"
      icon="users"
      loading={loading}
      columns={columns}
      data={data?.members ? data.members.members : []}
      pageCount={data?.members.pageCount}
      filteredCount={data?.members.filteredCount}
      totalCount={data?.members.totalCount}
      limitedMode={true}
      SelectedRender={MemberSelectedRender}
      selectionMode={true}
      requiredAmountSelected={max || 20}
      doneOnRequiredAmountSelected
      fullScreenOnly={true}
      onChange={onChange}
      onDone={onDone}
      onCancel={onCancel}
    />
  )
}

export default MemberPicker
