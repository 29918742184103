import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import EventTable from 'containers/tables/EventTable'
import PageHeader from 'components/PageHeader'
import ErrorBoundary from 'components/ErrorBoundary'

const Course = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Arrangemang | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <PageHeader image="https://images.pexels.com/photos/2062708/pexels-photo-2062708.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="flag-checkered"
              title="Arrangemang"
              subTitle="Hantera alla arrangemang från kalendern i listformat"
            />

            <EventTable />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Course
