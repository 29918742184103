import { setAppReady } from './app'

export const setShouldReauthenticate = (bool) => {
  return { type: 'SET_SHOULD_REAUTHENTICATE', payload: bool }
}

export const setIsAuthenticated = (bool) => {
  return { type: 'SET_IS_AUTHENTICATED', payload: bool }
}

export const requestLogin = (data) => {
  return { type: 'REQUEST_LOGIN', payload: data }
}

export const logout = () => {
  return (dispatch) => {
    localStorage.setItem('auth-token', null)
    localStorage.setItem('is-refreshing-token', false)
    dispatch(setShouldReauthenticate(false))
    dispatch(setIsAuthenticated(false))
    dispatch(setAppReady(false))
    dispatch({ type: 'LOGOUT' })
  }
}

export const setUser = (user) => {
  return { type: 'SET_USER', payload: user }
}

export const setMainFacility = (id) => {
  return { type: 'SET_MAIN_FACILITY', payload: id }
}

export const setSelectedFacilities = (items) => {
  return { type: 'SET_SELECTED_FACILITIES', payload: items }
}

export const setHasCookieConsented = (user) => {
  return { type: 'HAS_COOKIE_CONSENTED' }
}
