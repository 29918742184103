import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Box,
  useDisclosure,
  Collapse,
  Switch,
  LightMode,
  Flex
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'

const SwitchInputWithDisclosure = ({
  id,
  label,
  helper,
  error,
  required,
  disabled,
  defaultChecked,
  noSpacer = false,
  children,
  ...other
}) => {
  const { control, errors } = useFormContext()

  return (
    <InputSpacer ignore={noSpacer}>
      <FormControl>
        <Controller
          control={control}
          name={id}
          render={({ field }) => {
            return (
              <Box
                border="2px"
                borderStyle={field.value ? 'solid' : 'dashed'}
                transition="0.5s"
                boxShadow={field.value ? 'xl' : 'sm'}
                borderColor={
                  field.value
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(255, 255, 255, 0.1)'
                }
                p="2"
                rounded="md">
                <Flex w="100%" justify="space-between">
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    mb="0"
                    htmlFor={id}>
                    {label}
                  </FormLabel>

                  <LightMode>
                    <Switch
                      h="20px"
                      name={id}
                      size="md"
                      colorScheme="green"
                      onBlur={field.onBlur}
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      {...other}
                    />
                  </LightMode>
                </Flex>

                <Collapse in={field.value} animateOpacity>
                  <Box mt="2">{children}</Box>
                </Collapse>
              </Box>
            )
          }}
        />
      </FormControl>
    </InputSpacer>
  )
}

export default SwitchInputWithDisclosure
