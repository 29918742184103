import React, { useEffect } from 'react'
import styled from 'styled-components'

import FullCalendar from '@fullcalendar/react'
import { Draggable } from '@fullcalendar/interaction'
import { Box, Flex, Text } from '@chakra-ui/react'

const List = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ color }) => color};
  border-radius: 6px;
  margin-bottom: 4px;
  cursor: pointer;
  color: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.3);
`

const EventList = ({ events = [], category, onDragStart }) => {
  useEffect(() => {
    if (events.length) {
      const draggableEl = document.getElementById(`external-events-${category}`)
      new Draggable(draggableEl, {
        // eslint-disable-line
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const title = eventEl.getAttribute('title')
          const id = eventEl.getAttribute('data')
          const color = eventEl.getAttribute('color')
          return {
            title: title,
            id: id,
            color: color
          }
        },
        eventBorderColor: 'red'
      })
    }
  }, [events.length])

  return (
    <List
      id={`external-events-${category}`}
      style={{ maxHeight: '-webkit-fill-available' }}>
      {events.map((event) => (
        <Item
          className="fc-event"
          title={event.name}
          data={event.id}
          key={event.id}
          color={event.color}
          onMouseDown={onDragStart}>
          <Flex flexDir="column" px="4">
            <Text fontSize="md">{event.title}</Text>
            <Text mt="-1" fontSize="xs" fontWeight="normal">
              {event.duration}min
            </Text>
          </Flex>
        </Item>
      ))}
    </List>
  )
}

export default EventList
