import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormContext, useFormState } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectInput = ({
  id,
  type,
  label,
  helper,
  placeholder,
  options = [],
  required = false,
  noSpacer = false,
  ...other
}) => {
  const [hasErrored, setHasErrored] = useState(false)
  const { register } = useFormContext()
  const { errors } = useFormState()
  const [ref, setRef] = useState(null)
  const [isActive, setIsActive] = useState(false)

  const error = errors[id]?.message

  useEffect(() => {
    if (error) {
      setHasErrored(true)
    }
  }, [error])

  return (
    <InputSpacer ignore={noSpacer}>
      <FormControl id={id} isRequired={required}>
        <InputWrapper>
          <FormLabel fontSize="sm" fontWeight="bold" mb="0">
            {label}
          </FormLabel>

          <Select
            {...register(id)}
            {...other}
            placeholder={placeholder || 'Välj...'}>
            {options.map(({ label, value, selected }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </Select>

          {(error || (!hasErrored && helper)) && (
            <FormHelperText fontSize="xs" mt="1" ml="1">
              {error || helper}
            </FormHelperText>
          )}
        </InputWrapper>
      </FormControl>
    </InputSpacer>
  )
}

export default SelectInput
