export const setPanels = (panels) => {
  return (dispatch) => {
    dispatch({ type: 'SET_PANELS', payload: panels })
  }
}

export const setPanelTitle = (payload) => {
  return { type: 'SET_PANEL_TITLE', payload: payload }
}

export const addEventToEventStream = (payload) => {
  return { type: 'ADD_EVENT_TO_EVENTSTREAM', payload: payload }
}

export const consumeEventFromEventStream = (key) => {
  return { type: 'REMOVE_EVENT_FROM_EVENTSTREAM', payload: { key } }
}
