import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten, darken, radialGradient } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge,
  useDisclosure,
  Flex,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  InputLeftAddon,
  Kbd
} from '@chakra-ui/react'

const RegisterCart = () => {
  const [productEditing, setProductEditing] = useState(null)

  return (
    <Box>
      {productEditing ? (
        <Flex
          flexDir="column"
          align="center"
          h="100%"
          p="2"
          border="1px"
          borderColor="whiteAlpha.300"
          rounded="lg">
          <Heading size="sm" color="gray.400" mt="2">
            Ändra produkt
          </Heading>
          <Heading size="md" color="white" mt="1" mb="1">
            Nocco tropical extra big sun
          </Heading>
          <Heading size="xs" color="gray.500" mb="2">
            #N0cc0
          </Heading>

          <FormControl mb="3">
            <FormLabel fontSize="sm" m="0">
              Antal
            </FormLabel>
            <NumberInput size="lg" placeholder="Antal" defaultValue={1} min={1}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl mb="3">
            <FormLabel fontSize="sm" m="0">
              Rabatt i procent (%)
            </FormLabel>
            <NumberInput
              size="lg"
              placeholder="Antal"
              defaultValue={0}
              min={0}
              max={100}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" m="0">
              Rabatt i kronor (Sek)
            </FormLabel>
            <NumberInput size="lg" placeholder="Antal" defaultValue={1} min={1}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <Spacer />

          <Box w="30%" mb="2" py="4" ml="auto">
            <Flex>
              <Text fontSize="md" fontWeight="bold">
                Antal
              </Text>
              <Spacer />
              <Text fontSize="md">2 st</Text>
            </Flex>

            <Flex>
              <Text fontSize="md" fontWeight="bold">
                Styckpris
              </Text>
              <Spacer />
              <Text fontSize="md">23.00 kr</Text>
            </Flex>

            <Flex>
              <Text color="red.300" fontSize="md" fontWeight="bold">
                Rabatt
              </Text>
              <Spacer />
              <Text color="red.300" fontSize="md">
                -23.00 kr
              </Text>
            </Flex>

            <Flex>
              <Text fontSize="md" fontWeight="bold">
                totalt
              </Text>
              <Spacer />
              <Text fontSize="md" fontWeight="bold">
                23.00 kr
              </Text>
            </Flex>
          </Box>

          <Button
            h="100px"
            w="100%"
            mb="2"
            size="lg"
            variant="outline"
            colorScheme="red"
            leftIcon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}>
            Radera rad
          </Button>

          <Flex w="100%">
            <Button
              h="120px"
              flex="1"
              mr="2"
              size="lg"
              colorScheme="red"
              onClick={() => setProductEditing(null)}>
              Avbryt
            </Button>
            <Button h="120px" flex="3" size="lg" colorScheme="green">
              Tillämpa ändringar
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Box
          w="100%"
          border="1px"
          borderColor="whiteAlpha.300"
          rounded="lg"
          boxShadow={productEditing && 'xl'}>
          <Table>
            <Thead>
              <Tr>
                <Th borderColor="rgba(255, 255, 255, 0.1)">Produkt</Th>
                <Th borderColor="rgba(255, 255, 255, 0.1)">Art.nr</Th>
                <Th borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                  Antal
                </Th>
                <Th borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                  Styckpris
                </Th>
                <Th borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                  Totalt
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {new Array(4).fill(0).map(() => (
                <Tr
                  onClick={() => setProductEditing('n0cc0')}
                  h="65px"
                  fontSize="sm"
                  cursor="pointer"
                  _hover={{
                    bg: 'rgba(255, 255, 255, 0.05)',
                    boxShadow: 'sm'
                  }}>
                  <Td borderColor="rgba(255, 255, 255, 0.1)" fontWeight="bold">
                    Nocco tropical sun extra big sun
                  </Td>
                  <Td borderColor="rgba(255, 255, 255, 0.1)">n0cc0</Td>
                  <Td borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                    2
                  </Td>
                  <Td borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                    23,00
                  </Td>
                  <Td
                    borderColor="rgba(255, 255, 255, 0.1)"
                    fontWeight="bold"
                    isNumeric>
                    46,00
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  )
}

export default RegisterCart
