import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-scroll'

const MenuWrap = styled.div`
  margin: 0 6px auto 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: inset 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
`

const MenuLink = styled(Link)`
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0;

  > svg {
    font-size: 26px;
  }

  ${({ theme }) => theme.mixin.scaleFix}

  cursor: pointer;
  font-size: 24px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  background-color: ${({ theme }) =>
    theme.type === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.35)'};

  &:hover {
    background-color: ${({ theme }) =>
      theme.type === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.2)'};
  }

  &.active {
    color: #fff;
    background-color: ${({ color }) => color};
  }
`

const PanelSideNav = ({ options }) => {
  return (
    <MenuWrap>
      {options.map(({ to, icon, color }) => (
        <MenuLink
          to={to}
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
          key={icon}
          color={color}
          containerId="slideout-inner0"
          activeClass="active">
          <FontAwesomeIcon icon={['fad', icon]} />
        </MenuLink>
      ))}
    </MenuWrap>
  )
}

export default PanelSideNav
