import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import { useDispatch } from 'react-redux'
import {
  Box,
  Flex,
  Center,
  Spinner,
  Heading,
  Text,
  Button,
  VStack,
  useColorModeValue
} from '@chakra-ui/react'

import PanelSideNav from 'components/slideouts/PanelSideNav'
import ActionMenu from 'components/slideouts/ActionMenu'

import { setPanelTitle } from 'actions/panels'

const PanelBox = styled(
  ({ isTopmost, stackHeight, color, active, contextMenuItems, ...props }) => (
    <Box {...props} />
  )
)`
  margin-right: ${({ index, stackHeight }) => (stackHeight - index) * 10}px;
  margin-right: -28px;

  max-height: 100vh;
  padding: 5px 38px 5px 10px;

  display: flex;
  flex-direction: column;

  height: ${({ type }) => (type === 'modal' ? '60%' : '100%')};
  /* ${({ isTopmost }) => !isTopmost && 'filter: blur(10px);'} */
  transition: 0.6s ease;

  ${({ type }) =>
    type === 'modal' &&
    `
    margin-top: auto;
    margin-bottom: auto;
  `}

  width: 1400px;
  z-index: 1300;
  overflow-y: ${({ offset, stackHeight, positionInStack }) =>
    positionInStack === stackHeight ? 'auto' : 'hidden'};

  background: ${({ color }) =>
    `linear-gradient(to left, ${darken(0.05, color)}, ${color}, ${darken(
      0.05,
      color
    )})`};
  border-radius: 25px;
  box-shadow: -8px 0px 15px 0px rgba(0, 0, 0, 0.2);
`

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

const LeftAside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const Header = styled.div`
  height: 70px;
  padding: 0px 10px 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 26px;
  font-weight: bolder;
  color: ${({ theme }) => theme.primary_text};

  > svg {
    font-size: 36px;
    margin-left: 5px;
    margin-bottom: 2px;
    margin-top: auto;
    margin-bottom: 8px;
  }
`

const HorizontalTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bolder;
  color: ${({ theme }) => theme.primary_text};
  margin-left: 8px;
  margin-top: auto;

  span {
    font-size: 14px;
    font-weight: bolder;
    //text-transform: uppercase;
    opacity: 0.85;
  }

  p {
    font-size: 24px;
    font-weight: bolder;
    margin: 0;
    margin-top: -7px;
  }
`

const CloseButton = styled.a`
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.type === 'light'
      ? 'rgba(0, 0, 0, 0.05)'
      : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 20px;

  padding-top: 3px;
  margin: auto 10px 0 0;
  font-size: 24px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }

  > span {
    font-size: 10px;
    margin-top: 3px;
  }
`

const ContentWrap = styled(Box)`
  position: relative;
  height: calc(100vh - 80px);
  width: 100%;
  background-color: ${({ bg }) => bg};
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px 25px;
  border-radius: 12px 12px 12px 22px;
  transition: 0.3s ease;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

// You MUST pass key and ...rest to slideoutpanel!
const SlideOutPanel = ({
  type,
  title,
  subtitle,
  tabTitle,
  color,
  icon,
  offset,
  index,
  stackHeight,
  style,
  closeAction,
  loading,
  error,
  actions,
  navigation,
  children: content,
  panelDecoration,
  panelKey,
  actionLeftAddon,
  actionRightAddon,
  onBack = () => null
}) => {
  const bg = useColorModeValue('white', 'gray.700')
  const dispatch = useDispatch()
  const [contextMenuOpen, setContextMenuOpen] = useState(false)
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    if (!loading && !error) {
      setShowContent(true)
    } else {
      setShowContent(false)
    }
  }, [loading, error])

  // useEffect(() => {
  //   console.log('mounting panel')
  //   return () => console.log('unmounting panel')
  // }, [])

  useEffect(() => {
    dispatch(setPanelTitle({ panel: panelKey, title: tabTitle || title }))
  }, [title])

  return (
    <PanelBox
      id={`slideout${stackHeight - index}`}
      type={type}
      style={style}
      index={index}
      stackHeight={stackHeight}
      isTopmost={index === stackHeight}
      color={color}>
      {/* <CloseButton onClick={closeAction}>
        <FontAwesomeIcon
          icon={['fal', positionInStack === 1 ? 'times' : 'arrow-left']}
        />
        <span>{positionInStack === 1 ? 'stäng' : 'tillbaka'}</span>
      </CloseButton>
      {icon && (
        <TitleIcon color={color} style={{ opacity: loading ? 0 : 1 }}>
          <FontAwesomeIcon icon={['fal', icon]} />
        </TitleIcon>
      )}
      {positionInStack !== stackHeight && (
        <Title color={color}>{title}</Title>
      )} */}

      <Header color={color}>
        <CloseButton
          onClick={() => {
            closeAction()
            onBack()
          }}>
          <FontAwesomeIcon
            icon={['fal', index === 1 ? 'times' : 'arrow-left']}
          />
          <span>{index === 1 ? 'stäng' : 'tillbaka'}</span>
        </CloseButton>

        <FontAwesomeIcon icon={['fad', icon]} />

        <HorizontalTitle>
          <span>{title && subtitle}</span>
          <p>{title}</p>
        </HorizontalTitle>
      </Header>

      <Flex h="100%" w="100%">
        {panelDecoration}

        {showContent && navigation && index === stackHeight && (
          <LeftAside>
            <PanelSideNav options={navigation} />
          </LeftAside>
        )}

        {showContent && (
          <ContentWrap bg={bg} id={`slideout-inner${stackHeight - index}`}>
            {content}
          </ContentWrap>
        )}

        {!!loading && !error && (
          <Center h="100%" w="100%">
            <VStack>
              <Spinner size="xl" speed="1.2s" thickness="3px" />
              <Heading pt="2" size="sm">
                Laddar...
              </Heading>
            </VStack>
          </Center>
        )}

        {!!error && (
          <Center h="100%" w="100%">
            <VStack mb="4">
              <FontAwesomeIcon
                size="8x"
                icon={['fad', 'exclamation-triangle']}
              />
              <Heading pt="2" size="lg">
                Något gick fel...
              </Heading>
              <Text>Problemet har rapporterats.</Text>
              {typeof error === 'object' && error !== null
                ? error.graphQLErrors.map(({ message }, i) => (
                    <Text pb="4" key={i}>
                      {message}
                    </Text>
                  ))
                : error}
              <Button onClick={closeAction}>Stäng panel</Button>
            </VStack>
          </Center>
        )}
      </Flex>

      {showContent && actions && index === stackHeight && (
        <ActionMenu
          onToggle={setContextMenuOpen}
          color={color}
          options={actions}
          leftAddon={actionLeftAddon}
          rightAddon={actionRightAddon}
        />
      )}
    </PanelBox>
  )
}

export default SlideOutPanel
