import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Boxed, Content, TopPadder } from 'style/wrappers'
import {
  Box,
  Flex,
  Fade,
  Center,
  SimpleGrid,
  Heading,
  Text,
  Container,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import bkgimg from 'style/images/login.jpg'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import MemberTable from 'containers/tables/MemberTable'
import PageHeader from 'components/PageHeader'
import PageHelp from 'components/PageHelp'
import ErrorBoundary from 'components/ErrorBoundary'

const Members = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Medlemmar | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content pos="relative">
          <PageHeader image="https://images.pexels.com/photos/4164512/pexels-photo-4164512.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="users"
              title="Medlemmar"
              subTitle="Hantera alla medlemmar inom din organisation. Medlemmar inkluderar även företag."
            />

            <MemberTable />
            <PageHelp tags={['member']} />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Members
