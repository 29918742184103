import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Calendar } from 'react-date-range'
import { format, isValid, formatDistance, isToday } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Flex,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'

const NumberRangeInput = ({
  id,
  onChange,
  disabled,
  bothMin = 0,
  bothMax = 500,
  ...other
}) => {
  const { control } = useFormContext()
  const [low, setLow] = useState(0)
  const [high, setHigh] = useState(0)

  return (
    <Controller
      control={control}
      name={id}
      defaultValue={'0, 0'}
      render={({ field }) => (
        <>
          <InputSpacer>
            <Flex align="center">
              <NumberInput
                onChange={(val) => {
                  setLow(val)
                  field.onChange(`${val}, ${high}`)
                  field.onBlur()
                }}
                value={String(field.value).split(',')[0]}
                size="md"
                min={bothMin}
                max={bothMax}
                disabled={disabled}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <Text mx="2" fontWeight="bold" opacity={disabled ? 0.4 : 1}>
                Till
              </Text>

              <NumberInput
                onChange={(val) => {
                  setHigh(val)
                  field.onChange(`${low}, ${val}`)
                  field.onBlur()
                }}
                value={String(field.value).split(',')[1]}
                min={bothMin}
                max={bothMax}
                disabled={disabled}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          </InputSpacer>
        </>
      )}
    />
  )
}

export default NumberRangeInput
