import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Calendar } from 'react-date-range'
import { format, isValid, formatDistance, isToday } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text
} from '@chakra-ui/react'
import parser from 'any-date-parser'

import InputSpacer from 'components/form/InputSpacer'
import TextInput from './TextInput'

const DateInput = ({ id, onChange, ...other }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={id}
      defaultValue={format(new Date(), 'y-MM-dd')}
      render={({ field, fieldState }) => (
        <>
          <Popover placement="bottom" gutter="-10" onClose={field.onBlur}>
            <PopoverTrigger>
              <Box w="100%">
                <InputSpacer>
                  <TextInput
                    type="text"
                    value={field.value}
                    onChange={(e) =>
                      field.onChange(
                        format(parser.fromString(e.target.value), 'y-MM-dd')
                      )
                    }
                    rules={{
                      pattern:
                        /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
                      message: 'Felaktig datum'
                    }}
                    noSpacer={true}
                    leftAddon={
                      <FontAwesomeIcon
                        size="lg"
                        icon={['fad', 'calendar-day']}
                      />
                    }
                  />

                  <Box
                    p="4px"
                    pt="3px"
                    mx="2"
                    mt="-1px"
                    roundedBottom="lg"
                    border="1px"
                    bg="rgba(255, 255, 255, 0.04)"
                    border="0">
                    <Flex wrap="wrap" justify="center">
                      {isValid(new Date(field.value)) ? (
                        <Text fontSize="xs">
                          {field.value === null
                            ? 'Ange ett datum'
                            : isToday(new Date(field.value))
                            ? 'idag'
                            : formatDistance(
                                new Date(field.value),
                                new Date(),
                                {
                                  addSuffix: true,
                                  locale: sv
                                }
                              )}
                        </Text>
                      ) : (
                        <Text fontSize="xs">{fieldState.error?.message}</Text>
                      )}
                    </Flex>
                  </Box>
                </InputSpacer>
              </Box>
            </PopoverTrigger>
            <PopoverContent
              w="332px"
              rounded="lg"
              overflow="hidden"
              bg="none"
              border="0">
              <Calendar
                date={
                  field.value === null || !isValid(new Date(field.value))
                    ? new Date()
                    : new Date(field.value)
                }
                onChange={(date) => {
                  field.onChange(format(new Date(date), 'y-MM-dd'))
                }}
              />
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  )
}

export default DateInput
