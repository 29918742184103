import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import SelectInput from 'components/form/SelectInput'
import NumberRangeInput from 'components/form/NumberRangeInput'
import RadioInput from 'components/form/RadioInput'

const DOWNLOAD_TABLE = gql`
  mutation downloadTable(
    $type: String!
    $pagesToInclude: String
    $selectedPages: String
  ) {
    downloadTable(
      type: $type
      pagesToInclude: $pagesToInclude
      selectedPages: $selectedPages
    ) {
      success
      link
    }
  }
`

const FreshTableDownload = ({
  isOpen,
  onClose,
  downloadTypes = [],
  nrOfPages
}) => {
  const [isDone, setIsDone] = useState(false)
  const [config, setConfig] = useState({})

  const [doDownload, { loading, data, error }] = useMutation(DOWNLOAD_TABLE)

  useEffect(() => {
    if (data?.downloadTable.success === true) {
      setIsDone(true)
    }
  }, [data])

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>Ladda ner data från tabell</Center>
        </ModalHeader>

        <ModalBody>
          {loading ? (
            <Center my="5">
              <Spinner size="xl" />
            </Center>
          ) : isDone ? (
            <Flex my="5" flexDir="column" align="center">
              <FontAwesomeIcon
                size="4x"
                color="lightgreen"
                icon={['fad', 'check-circle']}
              />
              <Text mt="2" fontWeight="bold">
                Fil redo för nerladdning
              </Text>
            </Flex>
          ) : (
            <form
              defaultValues={{ pagesToInclude: 'current_page' }}
              onSubmit={(val) => setConfig(val)}
              autoSubmit
              autoSubmitDebounceTime={0}>
              {error && (
                <Alert status="error" rounded="lg" mb="2">
                  <AlertIcon />
                  {error && error.message}
                </Alert>
              )}

              <SelectInput
                id="type"
                options={downloadTypes.map(({ key, label }) => ({
                  label: label,
                  value: key
                }))}
              />

              <RadioInput
                id="pagesToInclude"
                label="Sidor att inkludera"
                defaultValue="current_page"
                options={[
                  { label: 'Aktuell sida', value: 'current_page' },
                  { label: 'Alla sidor', value: 'all_pages' },
                  { label: 'Valda sidor', value: 'selected_pages' }
                ]}
              />

              <Box pt="2">
                <NumberRangeInput
                  disabled={config?.pagesToInclude !== 'selected_pages'}
                  label="Valda sidor"
                  id="selectedPages"
                  bothMax={nrOfPages}
                />
              </Box>
            </form>
          )}
        </ModalBody>

        <ModalFooter>
          <Flex w="100%" flexDir="column">
            {!loading && !isDone && (
              <Button
                size="md"
                colorScheme="blue"
                w="100%"
                mb="1"
                onClick={() => doDownload({ variables: config })}
                disabled={!config?.type}>
                Generera fil
              </Button>
            )}

            {isDone && (
              <Button
                as="a"
                target="_blank"
                href={data?.downloadTable.link}
                cursor="pointer"
                size="md"
                colorScheme="blue"
                w="100%"
                mb="1"
                download
                onClick={() => {
                  onClose()
                  setIsDone(false)
                }}>
                Ladda ner
              </Button>
            )}

            <Button
              size="sm"
              w="100%"
              onClick={() => {
                onClose()
                setIsDone(false)
              }}>
              Avbryt
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FreshTableDownload
