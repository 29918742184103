import React, { useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { ButtonGroup, Button as Button, Switch } from '@chakra-ui/react'

import FreshTable from 'containers/FreshTable'
import WithHelpi from 'components/utils/WithHelpi'
import TagRender from 'components/TagRender'

import { pushPanel } from 'containers/PanelNavigation'
import { membership } from 'constants/entities'
import MemberTagRender from 'components/MemberTagRender'

const GET_MEMBERSHIPS = gql`
  query memberships($pageIndex: Int!, $pageSize: Int!, $search: String!) {
    memberships(
      table: { pageIndex: $pageIndex, pageSize: $pageSize, search: $search }
    ) {
      pageCount
      filteredCount
      totalCount
      memberships {
        id
        name

        facility {
          name
        }

        otherPayer {
          memberNumber
          fullname
        }
        payerSigner {
          memberNumber
          fullname
        }

        users {
          memberNumber
          fullname
        }

        tableChargeStr
        tableAdmissionStr
        tablePaymentStr

        tags {
          name
          value
          color
        }
      }
    }
  }
`

const MembershipTable = () => {
  const [getMemberships, { loading, error, data }] = useLazyQuery(
    GET_MEMBERSHIPS,
    {
      fetchPolicy: 'network-only'
    }
  )

  const onChange = useCallback((val) => {
    getMemberships({ variables: val })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        maxWidth: 50
      },
      {
        Header: 'Namn',
        accessor: 'name'
      },
      {
        Header: 'Anläggning',
        accessor: 'facility.name'
      },
      {
        Header: 'Användare',
        accessor: 'users',
        Cell: (props) =>
          props.value
            .slice(0, 5)
            .map((member) => <MemberTagRender {...member} />)
      },
      {
        Header: 'Betalare',
        accessor: 'payerSigner',
        Cell: (props) => <MemberTagRender {...props.value} />
      },
      {
        Header: 'Debitering',
        accessor: 'tableChargeStr',
        maxWidth: 60
      },
      {
        Header: 'Insläppt',
        accessor: 'tableAdmissionStr',
        maxWidth: 60
      },
      {
        Header: 'Betalt',
        accessor: 'tablePaymentStr',
        maxWidth: 60
      },
      {
        Header: '',
        accessor: 'tags',
        Cell: (props) => <TagRender size="sm" tags={props.value} />
      },
      {
        Header: '',
        accessor: 'toggle',
        Cell: (props) => <FontAwesomeIcon icon={['fad', 'eye-slash']} />,
        maxWidth: 20
      }
    ],
    []
  )

  return (
    <>
      <ButtonGroup mb="2" ml="2">
        <Button
          onClick={() => pushPanel('create-membership')}
          leftIcon={<FontAwesomeIcon icon={['fad', membership.icon]} />}
          color={membership.color}
          helpi="skapa en ny medlem">
          Skapa ett nytt abonnemang
        </Button>

        <WithHelpi>
          <Button leftIcon={<FontAwesomeIcon icon={['fad', 'layer-group']} />}>
            Massåtgärder
          </Button>
        </WithHelpi>
      </ButtonGroup>

      <FreshTable
        title="Alla Abonnemang"
        color={membership.color}
        icon={membership.icon_multi}
        loading={loading}
        columns={columns}
        data={data?.memberships ? data.memberships.memberships : []}
        pageCount={data?.memberships.pageCount}
        filteredCount={data?.memberships.filteredCount}
        totalCount={data?.memberships.totalCount}
        SelectedRender={() => null}
        onChange={onChange}
        onRowClick={(values) =>
          pushPanel('membership', {
            id: values.id
          })
        }
      />
    </>
  )
}

export default MembershipTable
