import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Flex,
  Input,
  Spinner
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { closeCardCollector } from 'actions/services'

import TextInput from 'components/form/TextInput'

const START_MEDIAID_POLL = gql`
  mutation startMediaIdPoll {
    startMediaIdPoll {
      success
    }
  }
`

const POLL_MEDIAID = gql`
  query pollMediaId {
    pollMediaId {
      hasResult
      result
    }
  }
`

const CardCollector = ({ isOpen, onClose, onSave }) => {
  const toast = useToaster()
  const inputRef = useRef()

  const [pollInterval, setPollInterval] = useState(null)
  const [isPolling, setIsPolling] = useState(false)
  const [value, setValue] = useState('')

  const form = useForm()

  const [
    startMediaIdPoll,
    { loading: startPollLoading, data: startPollData, error: startPollError }
  ] = useMutation(START_MEDIAID_POLL)

  const [
    doPollMedia,
    {
      loading: pollMediaIdLoading,
      data: pollMediaIdData,
      error: pollMediaIdError
    }
  ] = useLazyQuery(POLL_MEDIAID, {
    onCompleted: (data) => {
      if (data.pollMediaId.hasResult) {
        form.setValue('tagId', data.pollMediaId.result)
        toast({
          title: 'Kort/tagg hittad!',
          description:
            'Klicka på "spara kort/tagg" för att lägga till på medlemmen',
          status: 'success',
          position: 'top-right',
          isClosable: true
        })

        // Result found, stop polling
        stopPolling()
      }
    }
  })

  let pollCounter = 0
  useEffect(() => {
    if (startPollData?.startMediaIdPoll.success) {
      setPollInterval(
        setInterval(() => {
          if (pollCounter <= 40) {
            pollCounter += 1
            doPollMedia()
          } else {
            stopPolling()
            toast({
              title: 'Kunde inte hitta kort/tagg.',
              description: 'timeout',
              status: 'warning',
              position: 'top-right',
              isClosable: true
            })
          }
        }, 1500)
      )
      setIsPolling(true)
    }
    return () => {
      stopPolling()
    }
  }, [startPollData])

  const stopPolling = () => {
    clearInterval(pollInterval)
    setIsPolling(false)
    pollCounter = 0
  }

  return (
    <>
      <Modal
        size="lg"
        initialFocusRef={inputRef}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          stopPolling()
          setValue('')
        }}>
        <ModalOverlay />
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((value) => {
              onSave(value.tagId)
              onClose()
              stopPolling()
            })}>
            <ModalContent>
              <ModalHeader>Hämta kort/tagg</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  ref={inputRef}
                  {...form.register('tagId')}
                  placeholder="Ange Kort/tagg nummer"
                />

                <Box
                  my="2"
                  p="2"
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.4)"
                  rounded="md">
                  {isPolling ? (
                    <Flex
                      py="4"
                      flexDir="column"
                      justify="center"
                      align="center">
                      <Spinner size="lg" speed="2s" />
                      <Text fontSize="xs" fontWeight="bold" mt="3">
                        Hämtar från läsare...
                      </Text>
                      <Button
                        size="xs"
                        variant="ghost"
                        mt="1"
                        onClick={stopPolling}>
                        avbryt
                      </Button>
                    </Flex>
                  ) : (
                    <Flex flexDir="column">
                      <Button onClick={startMediaIdPoll}>
                        Hämta från kopplade läsare
                      </Button>
                      <Text
                        as="small"
                        fontSize="xs"
                        textAlign="center"
                        opacity="0.4"
                        px="5"
                        py="2">
                        Hämtar kort/tagg nummer från kopplade läsare i till
                        exempel anläggningen själv-service stationer. Notera att
                        läsaren inte kommer funka som vanligt medans denna
                        funktion är igång.
                      </Text>
                    </Flex>
                  )}
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button
                  size="sm"
                  w="100%"
                  mr="2"
                  onClick={() => {
                    onClose()
                    stopPolling()
                    setValue('')
                  }}>
                  Avbryt
                </Button>
                <Button size="sm" colorScheme="blue" w="100%" type="submit">
                  Spara kort/tagg
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default CardCollector
