import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Heading,
  Text,
  Box,
  Flex,
  Input,
  Spinner,
  Image
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { closeEntryRegistration } from 'actions/services'
import undrawLost from 'style/images/undraw_lost.svg'
import TextInput from 'components/form/TextInput'

const START_MEDIAID_POLL = gql`
  mutation startMediaIdPoll {
    startMediaIdPoll {
      success
    }
  }
`

const POLL_MEDIAID = gql`
  query pollMediaId {
    pollMediaId {
      hasResult
      result
    }
  }
`

const EntryRegistration = () => {
  const toast = useToaster()
  const inputRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const entryRegistrationOpen = useSelector(
    (state) => state.services.entryRegistration.open
  )

  const [searchValue, setSearchValue] = useState('')

  return (
    <Modal
      isOpen={entryRegistrationOpen}
      onClose={() => {
        setSearchValue('')
        dispatch(closeEntryRegistration())
      }}
      size="xl">
      <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
      <ModalContent>
        <ModalHeader pb="0">
          <FontAwesomeIcon
            size="lg"
            style={{ marginRight: '15px' }}
            icon={['fad', 'walking']}
          />
          Gör en manuell inpassering
        </ModalHeader>

        <ModalCloseButton size="lg" />

        <ModalBody>
          <Box w="100%" py="2" pb="1">
            <Box>
              <Input
                autoFocus
                placeholder="Sök med namn eller medlemsnummer"
                variant="filled"
                size="lg"
                rounded="full"
              />

              <Flex>{}</Flex>
            </Box>

            <Flex flexDir="column" justify="center" align="center" my="10">
              <Image w="40%" src={undrawLost} />
              <Heading size="sm" mt="4">
                Sök för att hitta medlemmen
              </Heading>
              <Text fontSize="xs">
                Använd medlemmens namn eller medlemsnummer
              </Text>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EntryRegistration
