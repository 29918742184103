import React, { useState, createContext } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import styled, { keyframes } from 'styled-components'
import { darken } from 'polished'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Flex,
  Center,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Heading,
  Text,
  Spinner,
  Tag
} from '@chakra-ui/react'

import TextInput from 'components/form/TextInput'
import EventList from 'components/EventList'
import { FormProvider, useForm } from 'react-hook-form'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'
import EntityCard from 'components/cards/EntityCard'

const SEARCH_EVENTS = gql`
  query searchEvents($searchString: String) {
    searchEvents(searchString: $searchString) {
      id
      type
      name
      color
      duration
    }
  }
`

const CalendarOrders = ({ children, isOpen, onClose, onBookingSelected }) => {
  const { loading, error, data } = useQuery(SEARCH_EVENTS, {
    skip: !isOpen
  })

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
        blockScrollOnMount={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Hantera beställningar</DrawerHeader>

          <DrawerBody>
            <Flex flexDir="column">
              <Button size="sm" w="100%" mb="2" mx="auto">
                Skapa en ny bokning
              </Button>

              <EntityCard
                color="#FAF089"
                title="En bokning"
                icon="flag-pennant"
                subTitle="Medlem: Stefan Stefansson"
                onClick={() => {
                  onBookingSelected('323')
                  onClose()
                }}>
                <Flex mt="1">
                  <Tag size="sm">Betalsätt: Faktura</Tag>
                </Flex>
              </EntityCard>
            </Flex>

            {!loading ? (
              <></>
            ) : (
              <Center my="10">
                <Spinner size="xl" />
              </Center>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CalendarOrders
