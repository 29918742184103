import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Heading, Spacer } from '@chakra-ui/react'

const EntityRow = ({ onClick, color, icon, title, subTitle, children }) => {
  return (
    <Flex
      bg="whiteAlpha.50"
      align="center"
      onClick={onClick}
      p="2"
      pl="4"
      mb="2px"
      rounded="md"
      cursor="pointer"
      _hover={{ bg: 'whiteAlpha.400' }}
      border="1px solid"
      borderColor="whiteAlpha.200">
      <FontAwesomeIcon size="md" color={color} icon={['fad', icon]} />

      <Flex w="100%" justify="space-between" ml="4">
        <Heading fontSize="sm" mt="3px">
          {title}
        </Heading>
        <Flex>{children}</Flex>
      </Flex>
    </Flex>
  )
}

export default EntityRow
