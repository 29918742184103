export const setAppReady = (bool) => {
  return { type: 'SET_APP_READY', payload: bool }
}

export const setIsOnline = (bool) => {
  return { type: 'SET_IS_ONLINE', payload: bool }
}

export const setTheme = (data) => {
  localStorage.setItem('theme', data)
  return { type: 'SET_THEME', payload: data }
}

export const setScreenSize = (data) => {
  return { type: 'SET_SCREEN_SIZE', payload: data }
}

export const setLastRequestTimestamp = (data) => {
  localStorage.setItem('lastRequestTimestamp', data)
  return { type: 'SET_LAST_REQUEST_TIMESTAMP', payload: data }
}

export const setHelpMode = (bool) => {
  return { type: 'SET_HELP_MODE', payload: bool }
}
