import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { getUnixTime } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  Divider,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Skeleton,
  Progress,
  SkeletonText,
  Checkbox
} from '@chakra-ui/react'

import {
  member,
  membership,
  invoice as invoiceEntity,
  receipt as receiptEntity,
  externalPayment as externalPaymentEntity
} from 'constants/entities'
import { ListItem as CardListItem } from 'components/cards/ListCard'

import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'

import TimeTraveler from 'components/TimeTraveler'

import { useToaster } from 'utils/toaster'
import TagRender from 'components/TagRender'
import ProductInput from 'components/form/ProductInput'
import NumberInput from 'components/form/NumberInput'
import SubtleCard from 'components/cards/SubtleCard'
import DefaultErrorHandler from 'utils/DefaultErrorHandler'
import PaySelector from 'containers/services/PaySelector'
import MembershipCancel from 'containers/membership/MembershipCancel'
import MembershipDeviation from './MembershipDeviation'

const GET_MEMBERSHIP_EVENTRANGE = gql`
  query getMembershipEventsRange(
    $id: String!
    $startDate: String
    $endDate: String
    $rangeIndex: Int
  ) {
    membershipEventsRange(
      id: $id
      startDate: $startDate
      endDate: $endDate
      rangeIndex: $rangeIndex
    ) {
      startDate
      endDate

      canReverse
      canForward

      display {
        name
        isPrimary

        items {
          name
          color
          startDate
          endDate
        }
      }

      periods {
        id
        periodNumber
        name
        status

        startDate
        endDate

        canPay
        amount
        balance

        invoice {
          id
          name
          tags {
            name
            value
            color
          }
        }

        receipt {
          id
          name
          tags {
            name
            value
            color
          }
        }

        externalPayment {
          id
        }
      }

      deviations {
        id
        name
        type
        comment

        product {
          id
        }
        sameProductAsMembership

        tags {
          name
          value
          color
        }
      }
    }
  }
`

const DISASSOCIATE_PAYMENT = gql`
  mutation disassociatePaymentOnPeriod($id: String!) {
    disassociatePaymentOnPeriod(id: $id) {
      success
    }
  }
`

const MembershipTimeline = ({ id }) => {
  const toast = useToaster()
  const payDisclosure = useDisclosure()
  const createDeviationDisclosure = useDisclosure()
  const [selectedPeriods, setSelectedPeriods] = useState([])
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    start: null,
    end: null
  })
  const [createDeviationUsePeriods, setCreateDeviationUsePeriods] =
    useState(false)

  const { loading, error, data, refetch } = useQuery(
    GET_MEMBERSHIP_EVENTRANGE,
    {
      variables: { id },
      fetchPolicy: 'network-only',
      onCompleted: () => null
    }
  )

  const TimeTravelerMemo = useMemo(
    () =>
      loading ? null : (
        <TimeTraveler
          showToday
          startDate={new Date(data.membershipEventsRange.startDate).getTime()}
          endDate={new Date(data.membershipEventsRange.endDate).getTime()}
          rows={data.membershipEventsRange.display.map(
            ({ isPrimary, items }) => ({
              size: isPrimary ? 'large' : 'normal',
              entries: items
            })
          )}
          onChange={({ start, end }) => {
            setSelectedTimeRange({ start, end })
            refetch({
              id,
              startDate: new Date(start).toISOString(),
              endDate: new Date(end).toISOString(),
              rangeIndex: data.membershipEventsRange.rangeIndex
            })
          }}
          canReverse={data.membershipEventsRange.canReverse}
          onReverse={({ start, end }) =>
            refetch({
              id,
              startDate: new Date(start).toISOString(),
              endDate: new Date(end).toISOString(),
              rangeIndex: data.membershipEventsRange.rangeIndex - 1
            })
          }
          canForward={data.membershipEventsRange.canForward}
          onForward={({ start, end }) =>
            refetch({
              id,
              startDate: new Date(start).toISOString(),
              endDate: new Date(end).toISOString(),
              rangeIndex: data.membershipEventsRange.rangeIndex + 1
            })
          }
        />
      ),
    [data, loading]
  )

  if (error) return error.message
  if (loading) return <Skeleton h="80px" w="80%" />

  return (
    <>
      <Box w="100%" mb="0" overflow="hidden">
        {TimeTravelerMemo}
      </Box>
      <Flex w="100%" flexDir="column" align="center">
        <Flex w="100%">
          <Flex w="60%" flexDir="column">
            <Heading size="md" textAlign="center" my="2">
              Betalperioder
            </Heading>

            <Flex w="100%" flexDir="column">
              <Flex
                align="flex-start"
                w="100%"
                px="3.5"
                py="2"
                border="1px"
                borderColor="whiteAlpha.300"
                rounded="lg"
                mr="auto"
                mb="2">
                <Flex flexDir="column">
                  {data.membershipEventsRange.periods.length > 0 && (
                    <>
                      <Checkbox
                        size="md"
                        fontWeight="bold"
                        color="white"
                        colorScheme="green"
                        isIndeterminate={
                          !(
                            selectedPeriods.length ===
                            data.membershipEventsRange.periods.length
                          ) && !!selectedPeriods.length
                        }
                        isChecked={
                          !!(
                            selectedPeriods.length ===
                            data.membershipEventsRange.periods.length
                          )
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPeriods(
                              data.membershipEventsRange.periods
                            )
                          } else {
                            setSelectedPeriods([])
                          }
                        }}>
                        {!!(
                          selectedPeriods.length ===
                          data.membershipEventsRange.periods.length
                        )
                          ? 'Välj bort alla perioder'
                          : 'Välj alla perioder'}
                      </Checkbox>

                      {selectedPeriods.length === 0 ? (
                        <Text as="small" color="whiteAlpha.700">
                          Gör ett urval av perioderna nedan för att se
                          återgärder.
                        </Text>
                      ) : (
                        <Text w="80%" as="small" color="whiteAlpha.700">
                          Det går endast att betala av betala av perioder som
                          inte redan har en registrerad betalning. Flera
                          avvikelser skapas om markerade perioder inte är i
                          följd.
                        </Text>
                      )}
                    </>
                  )}
                </Flex>

                <Flex flexDir="column" ml="auto">
                  {selectedPeriods.filter(({ canPay }) => canPay).length >=
                    2 && (
                    <Button
                      size="sm"
                      h="45px"
                      color="green.300"
                      leftIcon={
                        <FontAwesomeIcon icon={['fad', 'money-bill-wave']} />
                      }
                      mb="1"
                      onClick={payDisclosure.onOpen}>
                      Betala valda perioder:{' '}
                      {selectedPeriods.reduce((prev, { canPay, amount }) => {
                        if (canPay) {
                          return prev + amount
                        } else {
                          return prev
                        }
                      }, 0)}
                      kr
                    </Button>
                  )}

                  {selectedPeriods.length > 0 && (
                    <Button
                      size="sm"
                      h="35px"
                      leftIcon={
                        <FontAwesomeIcon icon={['fal', 'plus-circle']} />
                      }
                      mb="1"
                      onClick={() => {
                        setCreateDeviationUsePeriods(true)
                        createDeviationDisclosure.onOpen()
                      }}>
                      Skapa avvikelse på valda perioder
                    </Button>
                  )}
                </Flex>
              </Flex>
              {data.membershipEventsRange.periods.map((period) => (
                <Period
                  {...period}
                  selectedPeriods={selectedPeriods}
                  setSelectedPeriods={setSelectedPeriods}
                  membershipId={id}
                />
              ))}
            </Flex>
          </Flex>

          <Flex w="40%" flexDir="column" py="1" px="2" rounded="lg" mb="auto">
            <Heading size="md" textAlign="center" my="2">
              Avvikelser
            </Heading>
            <Flex flexDir="column">
              {data.membershipEventsRange.deviations.map(
                ({ id, name, type, tags, comment }) => (
                  <EntityCard
                    key={id}
                    color="lightblue"
                    icon="snowflakes"
                    title={name}>
                    <Text>{comment}</Text>
                    <TagRender tags={tags} />
                  </EntityCard>
                )
              )}
            </Flex>
            <Button
              size="sm"
              mx="auto"
              mt="1"
              mb="2"
              leftIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              isDisabled={!selectedTimeRange.start}
              onClick={() => {
                setCreateDeviationUsePeriods(false)
                createDeviationDisclosure.onOpen()
              }}>
              Skapa ny avvikelse för vald tidspann
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <PaySelector
        {...payDisclosure}
        payment={{ membershipPeriods: selectedPeriods.map(({ id }) => id) }}
      />

      <MembershipDeviation
        {...createDeviationDisclosure}
        periods={selectedPeriods}
        timeRange={selectedTimeRange}
        usePeriods={createDeviationUsePeriods}
      />
    </>
  )
}

export default MembershipTimeline

const Period = ({
  id,
  name,
  status,
  canPay,
  amount,
  balance,
  startDate,
  endDate,
  invoice,
  receipt,
  externalPayment,
  selectedPeriods,
  setSelectedPeriods,
  membershipId
}) => {
  const toast = useToaster()
  const cancelDisclosure = useDisclosure()
  const payDisclosure = useDisclosure()

  const [doDisassociatePayment] = useMutation(DISASSOCIATE_PAYMENT, {
    onCompleted: () => {
      toast({
        title: `Betalning bortkopplad`,
        status: 'warning',
        position: 'top-right',
        isClosable: false
      })
    },
    onError: DefaultErrorHandler
  })

  return (
    <>
      <SubtleCard mb="2">
        <Flex>
          <Flex flexDir="column" flexGrow="4" w="100%">
            <Checkbox
              size="md"
              fontWeight="bold"
              color="white"
              colorScheme="green"
              mb="2"
              onChange={(e) => {
                console.log(e)
                if (e.target.checked) {
                  if (!selectedPeriods.find((period) => period.id === id)) {
                    setSelectedPeriods([
                      ...selectedPeriods,
                      {
                        id,
                        status,
                        canPay,
                        amount,
                        balance
                      }
                    ])
                  }
                } else {
                  setSelectedPeriods(
                    selectedPeriods.filter((period) => period.id !== id)
                  )
                }
              }}
              isChecked={!!selectedPeriods.find((period) => period.id === id)}>
              {name}
            </Checkbox>

            <Flex align="center" mb="2">
              <Tag colorScheme="blue" mr="2">
                {new Date(startDate).toLocaleDateString()}
              </Tag>
              <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
              <Tag colorScheme="blue" ml="2">
                {new Date(endDate).toLocaleDateString()}
              </Tag>
            </Flex>

            <Flex w="90%" flexDir="column" mr="auto">
              <LabelRow
                label="Status"
                value={
                  status === 'PAID'
                    ? 'betalad'
                    : status === 'CHARGED'
                    ? 'debiterad'
                    : 'odebiterad'
                }
                color="green"
              />

              <LabelRow label="Belopp" value={`${amount}kr`} color="yellow" />
              <LabelRow label="Saldo" value={`${balance}kr`} color="yellow" />
            </Flex>
          </Flex>

          <Flex w="50%" flexGrow="2" flexDir="column" ml="2">
            {canPay && (
              <Button
                size="sm"
                h="45px"
                color="green.300"
                leftIcon={<FontAwesomeIcon icon={['fad', 'money-bill-wave']} />}
                mb="1"
                onClick={payDisclosure.onOpen}>
                Betala period
              </Button>
            )}

            <Button
              size="sm"
              leftIcon={<FontAwesomeIcon icon={['fad', 'arrows-cross']} />}
              mb="1">
              Ändra avtal
            </Button>

            <Button
              size="sm"
              color="red.300"
              leftIcon={<FontAwesomeIcon icon={['fad', 'ban']} />}
              onClick={cancelDisclosure.onOpen}>
              Säg upp
            </Button>
          </Flex>
        </Flex>

        <Wrap mt="2">
          {invoice && (
            <CardListItem
              name={invoice.name}
              icon={invoiceEntity.icon}
              color={invoiceEntity.color}
              rightContent={
                <Center
                  ml="4"
                  cursor="pointer"
                  opacity={0.65}
                  _hover={{ opacity: 1 }}
                  onClick={() => doDisassociatePayment({ variables: { id } })}>
                  <FontAwesomeIcon size="lg" icon={['fal', 'xmark-circle']} />
                </Center>
              }
            />
          )}

          {receipt && (
            <CardListItem
              name={receipt.name}
              icon={receiptEntity.icon}
              color={receiptEntity.color}
              rightContent={
                <Center
                  ml="4"
                  cursor="pointer"
                  opacity={0.65}
                  _hover={{ opacity: 1 }}
                  onClick={() => doDisassociatePayment({ variables: { id } })}>
                  <FontAwesomeIcon size="lg" icon={['fal', 'xmark-circle']} />
                </Center>
              }
            />
          )}

          {externalPayment && (
            <CardListItem
              name="Externt betald"
              icon={externalPaymentEntity.icon}
              color={externalPaymentEntity.color}
              rightContent={
                <Center
                  ml="4"
                  cursor="pointer"
                  opacity={0.65}
                  _hover={{ opacity: 1 }}
                  onClick={() => doDisassociatePayment({ variables: { id } })}>
                  <FontAwesomeIcon size="lg" icon={['fal', 'xmark-circle']} />
                </Center>
              }
            />
          )}
        </Wrap>
      </SubtleCard>

      <MembershipCancel
        {...cancelDisclosure}
        id={membershipId}
        paymentPeriodId={id}
      />

      <PaySelector {...payDisclosure} payment={{ membershipPeriods: [id] }} />
    </>
  )
}
