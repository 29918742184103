import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Center,
  Flex,
  Stack,
  StackDivider,
  Heading,
  Text,
  useColorModeValue,
  Button,
  useToast,
  CloseButton
} from '@chakra-ui/react'

export const useToaster = () => {
  const chakraToast = useToast()
  const toastFn = (options) =>
    chakraToast({
      position: 'top-right',
      ...options,
      render: (props) => (
        <Notification
          status={options.status}
          title={options.title}
          description={options.description}
          isClosable={options.isClosable}
          {...props}
        />
      )
    })

  toastFn.close = chakraToast.close
  toastFn.closeAll = chakraToast.closeAll
  toastFn.update = chakraToast.update
  toastFn.isActive = chakraToast.isActive

  return toastFn
}

export const Notification = (props) => {
  const {
    status,
    title,
    description,
    isClosable,
    onClose,
    primaryAction,
    secondaryAction,
    children,
    ...flexProps
  } = props

  const bg = useColorModeValue('white', 'gray.700')
  const blue = useColorModeValue('blue.500', 'blue.300')
  const green = useColorModeValue('green.500', 'green.300')
  const red = useColorModeValue('red.500', 'red.300')
  const yellow = useColorModeValue('yellow.500', 'yellow.300')

  return (
    <Flex
      w="sm"
      boxShadow="xl"
      borderRadius="base"
      overflow="hidden"
      mb="-2"
      //   border="1px"
      //   borderColor="whiteAlpha.300"
      bg={bg}
      {...flexProps}>
      {isClosable && (
        <CloseButton
          pos="absolute"
          top="12px"
          right="12px"
          onClick={onClose}
          size="sm"
        />
      )}

      <Center
        bg={
          status === 'info'
            ? blue
            : status === 'success'
            ? green
            : status === 'error'
            ? red
            : yellow
        }
        px="4"
        display={{
          base: 'none',
          sm: 'flex'
        }}
        w="75px">
        <FontAwesomeIcon
          color="rgba(0, 0, 0, 0.75)"
          size={description ? '2x' : 'lg'}
          icon={[
            'fad',
            status === 'info'
              ? 'info-circle'
              : status === 'success'
              ? 'check-circle'
              : status === 'error'
              ? 'times-circle'
              : 'exclamation-circle'
          ]}
        />
      </Center>

      <Stack
        alignItems="center"
        direction={{
          base: 'column',
          sm: 'row'
        }}
        divider={primaryAction ? <StackDivider /> : null}
        spacing="0"
        pr="4">
        <Box px="4" py="3">
          <Stack spacing="1">
            <Heading as="h3" fontSize="sm">
              {title}
            </Heading>
            {description && <Text fontSize="xs">{description}</Text>}
          </Stack>
        </Box>
        <Stack
          direction={{
            base: 'row',
            sm: 'column'
          }}
          height="full"
          divider={<StackDivider />}
          spacing="0">
          {primaryAction}
          {secondaryAction}
        </Stack>
      </Stack>
    </Flex>
  )
}

export const NotificationButton = (props) => (
  <Button
    variant="ghost"
    size="sm"
    px="8"
    minH="10"
    borderRadius="none"
    flex="1"
    {...props}
  />
)
