import React, { forwardRef } from 'react'
import styled from 'styled-components'
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from '@chakra-ui/react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import svLocale from '@fullcalendar/core/locales/sv'

import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import { NonceProvider } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BoxOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 4px 4px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.3s;
  background: linear-gradient(transparent, transparent, #ffbd59);

  .fc-scrollgrid {
    border-radius: 5px;
    border-right: 1px;
    overflow: hidden;
  }

  .fc-toolbar-title {
    font-size: 20px;
    font-weight: bold;
  }

  .fc-button {
    background: none;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.2);
  }

  .fc .fc-button-primary:hover {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2);
  }

  tbody .fc-scrollgrid-section td {
    border-bottom-right-radius: 5px;
  }

  .fc-day-today {
    border-bottom-right-radius: 0px !important;
  }

  .fc-theme-standard
    *:not(.fc-timegrid-now-indicator-arrow):not(.fc-timegrid-now-indicator-line) {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .fc .fc-button-primary:disabled {
    background: rgba(255, 255, 255, 0.1);
    border: 0;
  }

  .fc-event-title {
    font-weight: bold;
  }

  .fc-event-time {
    margin-bottom: -3px;
  }
`

const Calendar = forwardRef(
  ({ onEventChange = () => null, eventContent, doFetchEvents }, ref) => {
    return (
      <Box pos="relative">
        <BoxOuter>
          <Box bg="gray.700" rounded="lg" p="2">
            <FullCalendar
              // stickyHeaderDates={'true'}
              ref={ref}
              schedulerLicenseKey="0222119018-fcs-1633431710" //0237714683-fcs-1576018655
              initialView="timeGridWeek"
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'resourceTimeGridDay,timeGridWeek,dayGridMonth'
              }}
              contentHeight={'auto'}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeGridPlugin
              ]}
              weekNumbers={true}
              allDaySlot={false}
              slotMinTime="06:00:00"
              slotMaxTime="22:00:00"
              locale={svLocale}
              nowIndicator={true}
              editable={true}
              droppable={true}
              // drop={this.drop}
              eventChange={onEventChange}
              events={doFetchEvents}
              eventContent={eventContent}
              slotLaneContent={({ date }) => {
                return (
                  <Box display="flex" w="100%">
                    <Text fontSize="xs" color="whiteAlpha.300" ml="auto" mr="1">
                      {date.toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Text>
                  </Box>
                )
              }}
              weekNumberContent={({ text }) => {
                return (
                  <Flex w="100%" justify="space-between" align="center">
                    <Text fontSize="sm">{text}</Text>
                    <IconButton
                      ml="1"
                      size="xs"
                      variant="ghost"
                      icon={
                        <FontAwesomeIcon size="sm" icon={['fal', 'copy']} />
                      }
                    />
                    {/* <Box ml="2" mr="1">
                      <FontAwesomeIcon size="xs" icon={['fal', 'copy']} />
                    </Box> */}
                  </Flex>
                )
              }}
              dayHeaderContent={({ text }) => {
                return (
                  <Flex w="100%" justify="space-between" align="center">
                    <Text fontSize="sm">{text}</Text>
                    <IconButton
                      ml="1"
                      size="xs"
                      variant="ghost"
                      icon={
                        <FontAwesomeIcon size="sm" icon={['fal', 'copy']} />
                      }
                    />
                    {/* <Box ml="2" mr="1">
                      <FontAwesomeIcon size="xs" icon={['fal', 'copy']} />
                    </Box> */}
                  </Flex>
                )
              }}
            />
          </Box>
        </BoxOuter>
      </Box>
    )
  }
)

export default Calendar
