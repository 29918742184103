import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  BarChart,
  Bar
} from 'recharts'
import SwiperCore, { Autoplay, Navigation, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import ErrorBoundary from 'components/ErrorBoundary'

SwiperCore.use([Autoplay, Navigation, Lazy])

const bardata = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]

const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 }
]
const data02 = [
  { name: 'A1', value: 100 },
  { name: 'A2', value: 300 },
  { name: 'B1', value: 100 },
  { name: 'B2', value: 80 },
  { name: 'B3', value: 40 },
  { name: 'B4', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'C1', value: 100 },
  { name: 'C2', value: 200 },
  { name: 'D1', value: 150 },
  { name: 'D2', value: 50 }
]

const Reports = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Rapporter | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <Boxed>
            <Flex w="100%" flexDir="column">
              <Box mb="4">
                <Swiper
                  navigation={true}
                  // autoplay={{
                  //   delay: 20000,
                  //   disableOnInteraction: false
                  // }}
                  lazy={true}
                  loop={true}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}>
                  <SwiperSlide>
                    <Box
                      h="400px"
                      rounded="lg"
                      bg="white"
                      boxShadow="2xl"
                      color="#333">
                      <Flex
                        h="100%"
                        w="100%"
                        justify="space-around"
                        align="center">
                        <Flex flex="1" flexDir="column" align="center">
                          <Box>
                            <Heading fontWeight="normal">
                              Välkommen till <br /> Nova<b>Insikt</b>
                            </Heading>
                            <Text mt="2">
                              Novainsikt hjälper dig förstå <br /> datan som
                              betyder mest.
                            </Text>
                          </Box>
                        </Flex>

                        <Flex flex="1" h="100%" w="400px" align="center">
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                              <Pie
                                data={data01}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                outerRadius={60}
                                fill="#8884d8"
                              />
                              <Pie
                                data={data02}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                innerRadius={70}
                                outerRadius={90}
                                fill="#82ca9d"
                                label
                              />
                            </PieChart>
                          </ResponsiveContainer>
                        </Flex>
                      </Flex>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Box h="400px" rounded="lg" bg="white" boxShadow="2xl">
                      <Box
                        h="400px"
                        rounded="lg"
                        bg="white"
                        boxShadow="2xl"
                        color="#333">
                        <Flex
                          h="100%"
                          w="100%"
                          justify="space-around"
                          align="center">
                          <Flex flex="1" flexDir="column" align="center">
                            <Box>
                              <Heading fontWeight="normal">
                                Inpasseringar i <br /> <b>December</b>
                              </Heading>
                              <Text mt="2">
                                Inpasseringar{' '}
                                <Text as="b" color="red.400">
                                  Minskade
                                </Text>{' '}
                                med 23.4% <br /> jämfört med November.
                              </Text>
                            </Box>
                          </Flex>

                          <Flex
                            flex="1"
                            h="200px"
                            w="200px"
                            pr="10"
                            justify="center"
                            align="center">
                            <ResponsiveContainer width="80%" height="100%">
                              <AreaChart
                                width={200}
                                height={200}
                                data={data}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 0
                                }}>
                                <Tooltip />
                                <Area
                                  type="monotone"
                                  dataKey="uv"
                                  stackId="1"
                                  stroke="#8884d8"
                                  fill="#8884d8"
                                />
                                <Area
                                  type="monotone"
                                  dataKey="pv"
                                  stackId="1"
                                  stroke="#82ca9d"
                                  fill="#82ca9d"
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Box h="400px" rounded="lg" bg="white" boxShadow="2xl">
                      <Box
                        h="400px"
                        rounded="lg"
                        bg="white"
                        boxShadow="2xl"
                        color="#333">
                        <Flex
                          h="100%"
                          w="100%"
                          justify="space-around"
                          align="center">
                          <Flex flex="1" flexDir="column" align="center">
                            <Box>
                              <Heading fontWeight="normal">
                                Försäljning i <br /> <b>December</b>
                              </Heading>
                              <Text mt="2">
                                Inpasseringar{' '}
                                <Text as="b" color="green.400">
                                  ökade
                                </Text>{' '}
                                med 4234kr, <br /> eller 4.3% jämfört med
                                November.
                              </Text>
                            </Box>
                          </Flex>

                          <Flex
                            flex="1"
                            h="200px"
                            w="200px"
                            pr="10"
                            justify="center"
                            align="center">
                            <ResponsiveContainer width="100%" height="100%">
                              <BarChart
                                width={500}
                                height={400}
                                data={bardata}
                                margin={{
                                  top: 20,
                                  right: 30,
                                  left: 20,
                                  bottom: 5
                                }}>
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                                <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
                              </BarChart>
                            </ResponsiveContainer>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Box
                      h="400px"
                      rounded="lg"
                      bg="white"
                      boxShadow="2xl"></Box>
                  </SwiperSlide>
                </Swiper>
              </Box>

              <Flex
                w="100%"
                minH="450px"
                bg="gray.700"
                rounded="lg"
                px="10"
                py="8"
                mb="4">
                <Flex flex="1" flexDir="column">
                  <Heading size="lg" fontWeight="normal">
                    Besök under <br /> <b>månaden</b>
                  </Heading>
                  <Text>
                    En rapport över månades besök <br /> på alla anläggningar.
                  </Text>
                </Flex>
                <Flex flex="1">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={800}
                      height={300}
                      data={data}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                      <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>

              <Flex
                w="100%"
                minH="450px"
                bg="gray.700"
                rounded="lg"
                px="10"
                py="8">
                <Flex flex="1" flexDir="column">
                  <Heading size="lg" fontWeight="normal">
                    Månadens <br /> <b>Fakturaprognos</b>
                  </Heading>
                  <Text>
                    En rapport över fakturor <br /> på alla anläggningar.
                  </Text>
                </Flex>
                <Flex flex="1">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#8884d8"
                        fill="#8884d8"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Flex>
              </Flex>
            </Flex>
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Reports
