import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import {
  isValid,
  format,
  fromUnixTime,
  differenceInCalendarMonths
} from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@react-pdf-viewer/core/lib/styles/index.css'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Stack,
  Heading,
  Button,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Text,
  Grid,
  GridItem,
  Tag,
  TagLeftIcon,
  TagLabel,
  Divider,
  RadioGroup,
  Radio,
  ButtonGroup
} from '@chakra-ui/react'

import { member, booking, invoice } from 'constants/entities'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import SlideOutSection from 'components/slideouts/SlideOutSection'
import SlideOutColumns, { Column } from 'components/slideouts/SlideOutColumns'
import SectionTitle from 'components/slideouts/SectionTitle'
import BaseCard from 'components/cards/BaseCard'
import SwitchDisclosureCard from 'components/cards/SwitchDisclosureCard'
import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'
import MemberPicker from 'containers/MemberPicker'

import Timeline, { TimelineEntry, TimelineConnector } from 'components/Timeline'
import TimeTraveler from 'components/TimeTraveler'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import SelectorInput from 'components/form/SelectorInput'
import DateRangeInput from 'components/form/DateRangeInput'
import DateInput from 'components/form/DateInput'
import RadioInput from 'components/form/RadioInput'
import NumberInput from 'components/form/NumberInput'
import YesNoInput from 'components/form/YesNoInput'

import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import InvoiceRow from 'components/InvoiceRow'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'

const segments = [
  {
    startDate: 1501538400000,
    endDate: 1509490800000,
    type: 'freeze'
  },
  {
    startDate: 1509490800000,
    endDate: 1527804000000,
    type: 'normal'
  },
  {
    startDate: 1527804000000,
    endDate: 1604185200000,
    type: 'discounted'
  },
  {
    startDate: 1604185200000,
    endDate: 1651356000000,
    type: 'normal'
  }
]

const GET_INVOICE = gql`
  query getInvoice {
    facilities {
      id
      shortName
      name
    }
  }
`

const actions = [
  {
    title: 'Radera',
    color: '#e17055',
    icon: 'trash-alt',
    description: 'Radera bokning'
  }
]

const EventPanel = ({ uid, data: { id }, ...rest }) => {
  const form = useForm({
    mode: 'onChange'
  })

  useFormAutoSubmit({
    form,
    onSubmit: (val) => {}
  })

  return (
    <>
      <SlideOutPanel
        actions={actions}
        {...rest}
        title={'test'}
        error={null}
        loading={false}>
        <SlideOutSection name="inv-home">
          <FormProvider {...form}>
            <form style={{ width: '100%' }}>
              <Flex px="6" py="2" rounded="lg">
                <Center pos="relative" h="100px" w="100px">
                  <FontAwesomeIcon
                    color={booking.color}
                    size="5x"
                    icon={['fad', booking.icon]}
                  />
                  <Center
                    pos="absolute"
                    bottom="0px"
                    right="5px"
                    h="40px"
                    w="40px"
                    rounded="full"
                    bg="#00b894">
                    <FontAwesomeIcon size="lg" icon={['fad', 'check']} />
                  </Center>
                </Center>

                <Flex height="100%" direction="column" justify="end" ml="6">
                  <Heading size="xl">Bokning</Heading>
                  <Flex my="1">
                    <FontAwesomeIcon icon={['fad', 'long-arrow-right']} />
                    <Text ml="2">På medlem</Text>
                    <Tag ml="2" variant="solid" bg={member.color}>
                      <TagLeftIcon
                        boxSize="20px"
                        as={() => (
                          <FontAwesomeIcon icon={['fad', member.icon]} />
                        )}
                      />
                      <TagLabel ml="2" fontWeight="bold">
                        Stefan Stefansson
                      </TagLabel>
                    </Tag>
                  </Flex>

                  <Box mt="1">
                    <Tag mr="1">
                      <TagLabel ml="1">Tjänst</TagLabel>
                    </Tag>
                    <Tag mr="1">
                      <TagLabel ml="1">650kr</TagLabel>
                    </Tag>
                    <Tag mr="1">
                      <TagLabel ml="1">25 minuter</TagLabel>
                    </Tag>
                  </Box>

                  <Wrap mt="5"></Wrap>
                </Flex>
              </Flex>

              <SlideOutColumns columns={3}>
                <Column>
                  <BaseCard
                    title="Allmänt"
                    icon="abacus"
                    color="#8e44ad"></BaseCard>
                </Column>

                <Column>
                  <BaseCard
                    title="Kund"
                    icon="id-card"
                    color={member.color}></BaseCard>
                </Column>

                <Column>
                  <BaseCard
                    title="Utövare och anläggning"
                    icon="id-card"
                    color="#2e86de"></BaseCard>
                </Column>
              </SlideOutColumns>
            </form>
          </FormProvider>
        </SlideOutSection>
      </SlideOutPanel>
    </>
  )
}

export default EventPanel
