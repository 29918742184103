import React, { useState, useEffect } from 'react'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Box,
  Center,
  Flex,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  PinInput,
  PinInputField,
  VisuallyHidden
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const SelectorInput = ({
  id,
  label,
  helper,
  required,
  disabled,
  defaultValue,
  ...other
}) => {
  const [hasErrored, setHasErrored] = useState(false)
  const { control } = useFormContext()
  const { errors } = useFormState()
  const [value, setValue] = useState('0000')

  const error = errors[id]?.message

  useEffect(() => {
    if (error) {
      setHasErrored(true)
    }
  }, [error])

  return (
    <InputSpacer>
      <FormControl id={id}>
        <Controller
          control={control}
          name={id}
          render={({ field: { ref, ...field } }) => (
            <Flex w="100%" justify="space-between" align="center">
              <Box>
                <FormLabel
                  textAlign="left"
                  fontSize="sm"
                  fontWeight="bold"
                  mb="0">
                  {label}
                </FormLabel>
                {(error || (!hasErrored && helper)) && (
                  <FormHelperText mt="0">{error || helper}</FormHelperText>
                )}
              </Box>

              <Center>
                <HStack>
                  <PinInput
                    defaultValue={defaultValue}
                    {...field}
                    onComplete={field.onBlur}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Center>
            </Flex>
          )}
        />
      </FormControl>
    </InputSpacer>
  )
}

export default SelectorInput
