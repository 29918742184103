import * as yup from 'yup'
import personnummer from 'personnummer'

export default yup.object().shape({
  nationalId: yup
    .string()
    .required()
    .test('personnr-valid', 'Inte ett giltigt personnummer', (value) => {
      return personnummer.valid(value)
    })
})
