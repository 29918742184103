import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from 'react-redux'
import { Center, Tooltip, useTimeout } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Avatar,
  useDisclosure,
  useColorModeValue,
  Text,
  Button
} from '@chakra-ui/react'

import WithHelpi from 'components/utils/WithHelpi'
import ControlPanel from './ControlPanel'
import CashRegisterStarter from 'containers/CashRegisterStarter'

import { setHelpMode } from 'actions/app'
import { openSearchModal, openEntryRegistration } from 'actions/services'

const Quicky = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0 2px 0;
  color: ${({ theme }) => theme.secondary_text};
`

const QuickOptions = (props) => {
  const color = useColorModeValue('gray.700', 'white')
  const borderColor = useColorModeValue('gray.300', 'gray.700')
  const dispatch = useDispatch()

  const {
    isOpen: controlPanelIsOpen,
    onOpen: controlPanelOnOpen,
    onClose: controlPanelOnClose
  } = useDisclosure()

  const panels = useSelector((state) => state.panels.panels)

  const [cashRegisterStarterOpen, setCashRegisterStarterOpen] = useState(false)
  const helpMode = useSelector((state) => state.app.helpMode)
  const user = useSelector((state) => state.user.user)

  const [notify, setNotify] = useState(0)

  window?.fcWidget?.on('unreadCount:notify', function (resp) {
    setNotify(resp.count)
  })

  return (
    <Quicky>
      <Tooltip label="Sök" placement="right">
        <Button
          h="50px"
          w="100%"
          bg="0"
          rounded="none"
          borderTop="2px"
          color={color}
          borderColor={borderColor}
          onClick={() => dispatch(openSearchModal())}>
          <FontAwesomeIcon size="lg" icon={['fal', 'search']} />
        </Button>
      </Tooltip>

      <WithHelpi>
        <Tooltip label="Kassaregister" placement="right">
          <Button
            h="50px"
            w="75px"
            bg="0"
            rounded="none"
            borderTop="2px"
            color={color}
            borderColor={borderColor}
            onClick={() => setCashRegisterStarterOpen(true)}>
            <FontAwesomeIcon size="lg" icon={['fal', 'cash-register']} />
          </Button>
        </Tooltip>
      </WithHelpi>

      <Tooltip label="Manuell Inpassering" placement="right">
        <Button
          h="50px"
          w="75px"
          bg="0"
          rounded="none"
          borderTop="2px"
          color={color}
          borderColor={borderColor}
          onClick={() => dispatch(openEntryRegistration())}>
          <FontAwesomeIcon size="lg" icon={['fal', 'walking']} />
        </Button>
      </Tooltip>

      <Tooltip label="Hjälp och Support" placement="right">
        <Button
          h="50px"
          w="75px"
          bg="0"
          rounded="none"
          borderTop="2px"
          color={color}
          borderColor={borderColor}
          onClick={() => dispatch(setHelpMode(true))}>
          <FontAwesomeIcon size="lg" icon={['fal', 'life-ring']} />
          {notify > 0 && (
            <Center
              fontSize="xs"
              fontWeight="bold"
              ml="2"
              px="2"
              bg="red.400"
              rounded="full">
              {notify}
            </Center>
          )}
        </Button>
      </Tooltip>

      <Popover
        placement="right-start"
        closeOnBlur={!panels.length}
        onOpen={controlPanelOnOpen}
        onClose={controlPanelOnClose}
        isOpen={controlPanelIsOpen}>
        <PopoverTrigger>
          <Button
            h="75px"
            w="75px"
            bg="0"
            rounded="none"
            borderTop="2px"
            borderColor={borderColor}>
            <Avatar
              cursor="pointer"
              boxShadow="inset -2px -2px 5px 2px rgba(0,0,0,0.2)"
              size="md"
              name={`${user.firstname} ${user.lastname}`}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent width="400px" pt="4">
          <PopoverCloseButton mr="-1" size="md" />
          <ControlPanel doClose={controlPanelOnClose} />
        </PopoverContent>
      </Popover>

      <CashRegisterStarter
        isOpen={cashRegisterStarterOpen}
        onClose={() => setCashRegisterStarterOpen(false)}
      />
    </Quicky>
  )
}

export default QuickOptions
