import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  SimpleGrid,
  Flex,
  useBoolean,
  Button,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import BigOption from 'components/BigOption'

import { pushPanel } from 'containers/PanelNavigation'
import { member } from 'constants/entities'

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 0 6% 0;
  > small {
    margin-top: -10px;
  }
  > svg {
    font-size: 60px;
    margin-bottom: 10px;
  }
`

const MemberOptionsPanel = ({ data: { name, id }, ...rest }) => {
  const [deleteFlag, setDeleteFlag] = useBoolean(false)
  const cancelRef = useRef()
  const dispatch = useDispatch()

  return (
    <SlideOutPanel
      {...rest}
      title={decodeURI(name)}
      tabTitle={`Medlemsval: ${decodeURI(name)}`}>
      <Wrapper>
        <BigOption
          onClick={() => pushPanel('member', { id: id })}
          icon="box-open"
          title="Öppna medlem"
          description="Visa kundens profil"
          color="#33d9b2"
        />

        <Heading size="sm" pb="1" pt="5" pl="1">
          Andra val
        </Heading>
        <SimpleGrid columns={2} spacing={0}>
          <Flex flexDir="column" mr="3">
            <BigOption
              icon="file-invoice"
              title="Visa Fakturor"
              description="Visa alla fakturor kopplade till kunden."
              color="#fd7272"
            />

            <BigOption
              icon="clipboard-list-check"
              title="Händelselogg"
              description="Visa kundens händelselogg"
              color="#0abde3"
            />
          </Flex>

          <Flex flexDir="column">
            <BigOption
              icon="repeat-alt"
              title="Hantera Abonnemang"
              description="Inspektera och redigera kundens abonnemang. Detta öppnar en ny ruta."
              color="#ffbd59"
            />
            <BigOption
              icon="ban"
              title="Radera medlem"
              description="ta bort medlemmen permanent. Detta kan inte ångras."
              color="#e17055"
              onClick={setDeleteFlag.on}
            />
          </Flex>
        </SimpleGrid>
      </Wrapper>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={deleteFlag}
        onClose={setDeleteFlag.off}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Radera medlem
            </AlertDialogHeader>

            <AlertDialogBody>
              Är du säker? Medlemmen kommer försvinna för evigt.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={setDeleteFlag.off}>
                Avbryt
              </Button>
              <Button colorScheme="red" onClick={() => null} ml={3}>
                Radera
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </SlideOutPanel>
  )
}

export default MemberOptionsPanel
