import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Box,
  Flex,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Heading,
  Text,
  Image,
  Spinner
} from '@chakra-ui/react'

import undrawSpace from 'style/images/undraw_space.svg'

import TextInput from 'components/form/TextInput'

import { closeSearchModal } from 'actions/services'
import { ListItem } from 'components/cards/ListCard'

const SEARCH = gql`
  query globalSearch($searchString: String) {
    globalSearch(searchString: $searchString) {
      hasResult
      members {
        id
        primaryAddress {
          fullname
        }
      }
      memberships {
        id
      }
      invoices {
        id
      }
    }
  }
`

const SearchModal = () => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const shouldShowSearchModal = useSelector(
    (state) => state.services.searchModal.open
  )

  const form = useForm({
    mode: 'onChange'
  })

  const [doSearch, { loading, error, data, called }] = useLazyQuery(SEARCH, {
    variables: { searchString: searchString }
  })

  return (
    <Modal
      isOpen={shouldShowSearchModal}
      onClose={() => dispatch(closeSearchModal())}
      size="xl">
      <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
      <ModalContent>
        <ModalHeader pb="0">
          <FontAwesomeIcon
            size="lg"
            style={{ marginRight: '15px' }}
            icon={['fad', 'search']}
          />
          Sök i hela Novasecur
        </ModalHeader>

        <ModalCloseButton size="lg" />

        <ModalBody>
          <Box w="100%" py="2" pb="1">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((vals) => {
                  setSearchString(vals.searchString)
                  doSearch({ variables: vals })
                })}>
                <TextInput
                  id="searchString"
                  autoFocus
                  placeholder="Sök här"
                  variant="filled"
                  size="lg"
                  rounded="full"
                  rightElement={
                    loading && (
                      <Center mr="3">
                        <Spinner />
                      </Center>
                    )
                  }
                />
              </form>
            </FormProvider>

            {(searchString === '' ||
              (!loading && !data?.globalSearch.hasResult)) && (
              <Flex flexDir="column" justify="center" align="center" my="10">
                <Image w="40%" src={undrawSpace} />
                <Heading size="sm" mt="4">
                  {!called || searchString === ''
                    ? 'Redo att söka?'
                    : 'Inga resultat'}
                </Heading>
                <Text fontSize="xs">
                  {!called || searchString === ''
                    ? 'Använd fältet längst upp i rutan'
                    : 'Försök igen'}
                </Text>
              </Flex>
            )}

            {searchString && (loading || data?.globalSearch.hasResult) && (
              <Box mb="4">
                <Box>
                  <Heading size="xs" pt="4" mb="2" ml="1">
                    ABONNEMANG
                  </Heading>
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                </Box>

                <Box>
                  <Heading size="xs" mt="4" mb="2" ml="1">
                    MEDLEMMAR
                  </Heading>
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                </Box>

                <Box>
                  <Heading size="xs" mt="4" mb="2" ml="1">
                    ABONNEMANG
                  </Heading>
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                  <ListItem
                    name="DEAL - 12 månader AG"
                    icon="repeat-alt"
                    color="#ffbd59"
                    meta="ID: 268 | SKAPAD: 11/12 20 9:55"
                  />
                </Box>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SearchModal
