import { createGlobalStyle } from 'styled-components'
import { lighten, saturate } from 'polished'

import 'react-datetime/css/react-datetime.css'
import 'react-html5-camera-photo/build/css/index.css'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export default createGlobalStyle`

  tr.selected{
    background-color: ${({ theme }) => theme.divider} !important;
  }

  tr.selected .sorting_1 {
    background-color: ${({ theme }) => saturate(0.1, theme.divider)} !important;
  }

  html{
    height: 100%;
  }

  body {
    font-family: 'Nunito', sans-serif;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  body *{
    box-sizing: border-box;
  }

  body a:hover{
    text-decoration: none;
  }

  #root{
    height: 100%;
  }

  .swiper-button-prev::after, .swiper-button-next::after {
    color: #333;
    font-size: 30px;
  }

  .blockbuilder-branding {
    display: none !important;
  }

`
