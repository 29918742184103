import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import SettingsBoxes from 'containers/SettingsBoxes'
import PageHeader from 'components/PageHeader'
import ErrorBoundary from 'components/ErrorBoundary'

const Settings = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Inställningar | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <PageHeader image="https://images.pexels.com/photos/4857499/pexels-photo-4857499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="cogs"
              title="Inställningar"
              subTitle="Anpassa Novasecur och hantera inställningar för dig och din organization."
            />

            <SettingsBoxes />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Settings
