import { v4 as uuidv4 } from 'uuid'
import { history } from 'Router'
import isEqual from 'lodash/isEqual'

const init = {
  panels: [],
  titles: {}
}

const panelsReducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_PANELS':
      return { ...state, panels: action.payload }

    case 'SET_PANEL_TITLE':
      return {
        ...state,
        titles: {
          ...state.titles,
          [action.payload.panel]: action.payload.title
        }
      }

    default:
      return state
  }
}

export default panelsReducer
