import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'

const Crm = () => {
  return (
    <Page>
      <Helmet>
        <title>Kundrelationer | Novasecur</title>
      </Helmet>

      <PrimaryNavigation />

      <Content></Content>
    </Page>
  )
}

export default Crm
