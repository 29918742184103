import React from 'react'

import TextInput from 'components/form/TextInput'
import InlineTitle from 'components/InlineTitle'
import { Button } from '@chakra-ui/react'

const AddressForm = ({
  defaultValues = {},
  noNationalId = false,
  nationalIdButton,
  onKeyDownNationalId
}) => {
  return (
    <>
      {!noNationalId && (
        <>
          <TextInput
            id="nationalId"
            defaultValue={defaultValues['nationalId']}
            label="Personnummer eller Organisationsnummer"
            onKeyDown={onKeyDownNationalId}
          />
          {nationalIdButton}
        </>
      )}
      <TextInput
        id="firstname"
        defaultValue={defaultValues['firstname']}
        label="Förnamn"
        required
      />

      <TextInput
        id="lastname"
        defaultValue={defaultValues['lastname']}
        label="Efternamn"
        required
      />

      <InlineTitle>Adress</InlineTitle>

      <TextInput id="co" defaultValue={defaultValues['co']} label="C/O" />

      <TextInput
        id="street"
        defaultValue={defaultValues['address']}
        label="Adress"
        required
      />

      <TextInput
        id="street2"
        defaultValue={defaultValues['address2']}
        label="Adress 2"
      />

      <TextInput
        id="postcode"
        defaultValue={defaultValues['postcode']}
        label="Postnr"
        required
      />

      <TextInput
        id="city"
        defaultValue={defaultValues['city']}
        label="Ort"
        required
      />

      <InlineTitle>Kontaktuppgifter</InlineTitle>

      <TextInput
        id="email"
        type="email"
        defaultValue={defaultValues['mail']}
        label="Mailadress"
        required
      />

      <TextInput
        id="mobileNumber"
        defaultValue={defaultValues['phone']}
        label="Mobil"
      />
    </>
  )
}

export default AddressForm
