import React, { useContext, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react'

import { HelpContext } from 'containers/HelpOverlay'

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const Info = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoCircle = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #333;
  z-index: 1;

  cursor: pointer;
  font-size: 18px;
  animation: ${pulse} 1s infinite;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
`

const WithHelpi = ({ title, description, children, ...other }) => {
  const helpContext = useContext(HelpContext)

  return (
    <Box pos="relative" {...other}>
      {helpContext && (
        <Info>
          <Popover Index="popover">
            <PopoverTrigger zIndex="popover">
              <InfoCircle>
                <FontAwesomeIcon icon={['fal', 'info-circle']} swapOpacity />
              </InfoCircle>
            </PopoverTrigger>
            <PopoverContent px="1" py="2">
              <PopoverArrow />
              {title && (
                <PopoverHeader borderBottom="0" fontWeight="bold">
                  {title}
                </PopoverHeader>
              )}
              {description && (
                <PopoverBody fontSize="sm" pt="0" mt="-6px">
                  {description}
                </PopoverBody>
              )}
            </PopoverContent>
          </Popover>
        </Info>
      )}

      {React.cloneElement(children, {
        style: helpContext ? { opacity: 0.45 } : {}
      })}
    </Box>
  )
}

export default WithHelpi
