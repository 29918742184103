import React from 'react'
import { Router } from './containers/PanelNavigation'
import { member, membership, invoice, booking } from './constants/entities'

import MemberOptionsPanel from './containers/panels/MemberOptionsPanel'
import MemberPanel from './containers/panels/MemberPanel'
import CreateMemberPanel from './containers/panels/CreateMemberPanel'
import MembershipPanel from './containers/panels/MembershipPanel'
import InvoicePanel from './containers/panels/InvoicePanel'
import EventPanel from './containers/panels/EventPanel'
import CreateMembershipPanel from 'containers/panels/CreateMembershipPanel'

const PanelRouter = () => (
  <Router
    routes={[
      {
        route: 'member-menu',
        panel: {
          subtitle: 'Medlemsval för',
          title: 'Medlemsval',
          color: member.color,
          icon: member.icon,
          loading: false,
          content: MemberOptionsPanel,
          options: { autoSize: true }
        }
      },

      {
        route: 'member',
        panel: {
          subtitle: 'Medlem',
          title: 'En Medlem',
          color: member.color,
          icon: member.icon,
          loading: false,
          content: MemberPanel
        }
      },

      {
        route: 'create-member',
        panel: {
          subtitle: 'Guide för att',
          title: 'Skapa medlem',
          color: member.color,
          icon: member.icon,
          loading: false,
          content: CreateMemberPanel
        }
      },

      {
        route: 'create-membership',
        panel: {
          subtitle: 'Sälj ett',
          title: 'Nytt abonnemang',
          color: membership.color,
          icon: membership.icon,
          loading: false,
          content: CreateMembershipPanel
        }
      },

      {
        route: 'membership',
        panel: {
          subtitle: 'Abonnemang',
          title: 'Ett Abonnemang',
          color: membership.color,
          icon: membership.icon,
          loading: false,
          content: MembershipPanel
        }
      },

      {
        route: 'invoice',
        panel: {
          subtitle: 'Faktura',
          title: 'Fakura',
          color: invoice.color,
          icon: invoice.icon,
          loading: false,
          content: InvoicePanel
        }
      },

      {
        route: 'event',
        panel: {
          subtitle: 'En bokning',
          title: 'Bokning',
          color: booking.color,
          icon: booking.icon,
          loading: false,
          content: EventPanel
        }
      }
    ]}
  />
)

export default PanelRouter
