import { Box, Center, Flex, Grid, Text } from '@chakra-ui/react'
import { darken, radialGradient, transparentize } from 'polished'
import React from 'react'

import clickSound from 'style/sounds/ogg/Click/click_18.ogg'

const RegisterButtonSet = ({
  onScreenKeypadWrite,
  openProductPanel,
  openPayPanel
}) => {
  return (
    <Flex flexDir="column" pos="relative" h="100%" w="100%">
      <Flex flexDir="column" h="100%" mr="2">
        <Grid
          flexGrow={2}
          w="100%"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap="2px"
          border="1px solid"
          borderColor="whiteAlpha.300"
          rounded="lg"
          overflow="hidden"
          mb="1">
          <RegisterButton color="#27ae60">Drycker</RegisterButton>
          <RegisterButton color="#27ae60">Bars</RegisterButton>
          <RegisterButton color="#27ae60">Proteinpulver</RegisterButton>
          <RegisterButton color="#27ae60">Snabbrätter</RegisterButton>
          <RegisterButton color="#27ae60">Kläder</RegisterButton>
          <RegisterButton color="#27ae60">Andra produkter</RegisterButton>

          <RegisterButton color="#16a085">Klippkort</RegisterButton>
          <RegisterButton color="#16a085">Abonnemang</RegisterButton>
          <RegisterButton color="#16a085">Massage</RegisterButton>
          <RegisterButton color="#16a085">Spinning</RegisterButton>
        </Grid>

        <Grid
          flexGrow={2}
          w="100%"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap="2px"
          border="1px solid"
          borderColor="whiteAlpha.300"
          rounded="lg"
          overflow="hidden"
          mb="1">
          <RegisterButton color="#00cec9">
            Sälj <br /> presentkort
          </RegisterButton>

          <RegisterButton color="#00cec9">
            Betala med <br /> presentkort
          </RegisterButton>

          <RegisterButton color="#3498db">Skriv ut fribiljett</RegisterButton>
          <RegisterButton color="#3498db">
            Skriv ut parkeringsbiljett
          </RegisterButton>
          <RegisterButton color="#3498db">
            Andra <br /> återgärder
          </RegisterButton>

          <RegisterButton color="#9b59b6">
            Betala <br /> Abonnemang
          </RegisterButton>
          <RegisterButton color="#9b59b6">
            Förläng <br /> Abonnemang
          </RegisterButton>
          <RegisterButton color="#9b59b6">
            Nytt <br /> Abonnemang
          </RegisterButton>
          <RegisterButton color="#0984e3">
            Betala <br /> faktura
          </RegisterButton>
          <RegisterButton color="#0984e3">
            Överför till <br /> faktura
          </RegisterButton>
        </Grid>

        <Grid
          flexGrow={1}
          w="100%"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap="2px"
          border="1px solid"
          borderColor="whiteAlpha.300"
          rounded="lg"
          overflow="hidden"
          mb="1">
          <RegisterButton color="#fdcb6e">Kontant</RegisterButton>
          <RegisterButton color="#fdcb6e">Kort</RegisterButton>
          <RegisterButton color="#fdcb6e">Presentkort</RegisterButton>
          <RegisterButton color="#fdcb6e">Swish</RegisterButton>
          <RegisterButton color="#f39c12">
            Övriga <br /> betalsätt
          </RegisterButton>
        </Grid>

        <Flex h="360px" w="100%" mb="2">
          <Flex flex={3}>
            <Grid
              w="120px"
              flexGrow={1}
              templateRows="repeat(4, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap="2px"
              border="1px solid"
              borderColor="whiteAlpha.300"
              rounded="lg"
              overflow="hidden"
              mr="1">
              <DigitButton onClick={() => onScreenKeypadWrite(7)}>
                7
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(8)}>
                8
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(9)}>
                9
              </DigitButton>

              <DigitButton onClick={() => onScreenKeypadWrite(4)}>
                4
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(5)}>
                5
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(6)}>
                6
              </DigitButton>

              <DigitButton onClick={() => onScreenKeypadWrite(1)}>
                1
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(2)}>
                2
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(3)}>
                3
              </DigitButton>

              <DigitButton onClick={() => onScreenKeypadWrite(',')}>
                ,
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(0)}>
                0
              </DigitButton>
              <DigitButton onClick={() => onScreenKeypadWrite(',00')}>
                ,00
              </DigitButton>
            </Grid>
          </Flex>

          <Flex flex={4} flexDir="column">
            <Grid
              h="100%"
              flex="1"
              templateRows="repeat(4, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap="2px"
              border="1px solid"
              borderColor="whiteAlpha.300"
              rounded="lg"
              overflow="hidden">
              <RegisterButton color="#00b894" bold>
                Öppna låda
              </RegisterButton>
              <RegisterButton color="#bdc3c7">Rensa</RegisterButton>
              <RegisterButton color="#e17055">Töm</RegisterButton>

              <RegisterButton color="#e58e26">
                Parkera <br /> Kvitto
              </RegisterButton>
              <RegisterButton color="#fa983a">
                Hämta <br /> Kvitto
              </RegisterButton>
              <RegisterButton color="#b2bec3">
                Återköp <br /> kvitto
              </RegisterButton>

              <RegisterButton color="#e84393">X-rapport</RegisterButton>
              <RegisterButton color="#e84393">Dagsavslut</RegisterButton>
              <Box />

              <RegisterButton color="#20bf6b" gridColumn="span 2">
                Kvitto
              </RegisterButton>
              <RegisterButton color="#eb3b5a">Kopia</RegisterButton>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RegisterButtonSet

export const RegisterButton = ({
  children,
  color = 'rgba(0, 0, 0, 0.2)',
  bold = false,
  addon,
  onClick,
  ...other
}) => {
  return (
    <Center
      display="flex"
      flexDir="column"
      w="100%"
      h="100%"
      // css={{
      //   ...radialGradient({
      //     colorStops: [`${color} 20%`, `${darken(0.1, color)} 100%`],
      //     shape: 'circle'
      //   })
      // }}
      bg={color}
      cursor="pointer"
      boxShadow="xl"
      // _hover={{ boxShadow: 'xl', bg: darken(0.1, color) }}
      _active={{
        bg: transparentize(0.6, color),
        transform: 'scale(0.92)',
        rounded: 'lg'
      }}
      transition="0.1s"
      {...other}
      onClick={() => {
        const audio = new Audio(clickSound)
        audio.volume = 0.3
        audio.play()
        onClick()
      }}>
      <Text
        fontSize="xl"
        fontWeight={bold ? 'bold' : 'normal'}
        textAlign="center"
        lineHeight="1.2"
        p="4">
        {children}
      </Text>
      {addon}
    </Center>
  )
}

export const CategoryButton = ({
  children,
  color = 'rgba(0, 0, 0, 0.2)',
  bold = false,
  addon,
  ...other
}) => (
  <Center
    display="flex"
    flexDir="column"
    rounded="sm"
    h="100%"
    w="220px"
    mr="1"
    css={{
      ...radialGradient({
        colorStops: [`${color} 20%`, `${darken(0.1, color)} 100%`],
        shape: 'circle'
      })
    }}
    cursor="pointer"
    boxShadow="xl"
    transition="0.1s"
    _hover={{ boxShadow: 'xl', bg: darken(0.1, color) }}
    _active={{ transform: 'scale(0.9)' }}
    {...other}>
    <Text
      fontSize="xl"
      fontWeight={bold ? 'bold' : 'normal'}
      textAlign="center"
      lineHeight="1.2">
      {children}
    </Text>
    {addon}
  </Center>
)

export const DigitButton = ({
  children,
  color = '#b2bec3',
  bold = false,
  onClick
}) => (
  <Center
    w="100%"
    h="100%"
    css={{
      ...radialGradient({
        colorStops: [`${color} 20%`, `${darken(0.1, color)} 100%`],
        shape: 'circle'
      })
    }}
    cursor="pointer"
    boxShadow="xl"
    transition="0.1s"
    _hover={{ boxShadow: 'xl', bg: darken(0.1, color) }}
    _active={{ transform: 'scale(0.9)' }}
    onClick={onClick}>
    <Text
      fontSize="xl"
      fontWeight={bold ? 'bold' : 'normal'}
      textAlign="center"
      lineHeight="1.2">
      {children}
    </Text>
  </Center>
)
