import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  BarChart,
  Bar
} from 'recharts'
import SwiperCore, { Autoplay, Navigation, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import ErrorBoundary from 'components/ErrorBoundary'
import RadioInput from 'components/form/RadioInput'
import { FormProvider, useForm } from 'react-hook-form'
import DateInput from 'components/form/DateInput'
import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import SelectInput from 'components/form/SelectInput'
import SelectorInput from 'components/form/SelectorInput'

const ReportGenerator = () => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      type: 'FREEZE'
    }
  })

  const isCustomPeriod = form.watch('period') === 'CUSTOM'

  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Rapportgenerator | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((data) => {})}>
              <Boxed>
                <Flex w="100%" flexDir="column">
                  <PageTitle title="Rapport generator" />

                  <CardBox>
                    <Flex flex="1" flexDir="column">
                      <Flex flexDir="column">
                        <Heading size="lg" fontWeight="normal">
                          Datakälla och datatyp
                        </Heading>
                        <Text>
                          Välj datatypen du vill använda för rapporten
                        </Text>
                      </Flex>

                      <Box mt="4">
                        <RadioInput
                          id="data_source"
                          label="Datakälla"
                          defaultValue="newPrice"
                          options={[
                            {
                              icon: 'user-plus',
                              color: 'blue.200',
                              value: 'NEW_MEMBERS',
                              label: 'Nya medlemmar'
                            },
                            {
                              icon: 'octagon-xmark',
                              color: 'red.200',
                              value: 'CANCELED_MEMBERSHIPS',
                              label: 'Uppsagda abonnemang'
                            },
                            {
                              icon: 'users-line',
                              color: 'green.200',
                              value: 'ACTIVE_MEMBERS',
                              label: 'Aktiva medlemmar'
                            },
                            {
                              icon: 'money-check-pen',
                              color: 'purple.200',
                              value: 'ONGOING_AUTOGIRO_PAYMENTS',
                              label: 'Pågående Autogirobetalningar'
                            },
                            {
                              icon: 'face-icicles',
                              color: 'blue.200',
                              value: 'FROZEN_MEMBERSHIP',
                              label: 'Frysta abonnemang'
                            },
                            {
                              icon: 'ban',
                              color: 'yellow.200',
                              value: 'BLOCKED_MEMBERSHIP',
                              label: 'Spärrade abonnemang'
                            },
                            {
                              icon: 'cart-shopping-fast',
                              color: 'pink.200',
                              value: 'ONLINE_PURCHASE',
                              label: 'Webbköp'
                            }
                          ]}
                        />
                      </Box>

                      <Box mt="4">
                        <RadioInput
                          id="data_type"
                          label="Datatyp"
                          defaultValue="newPrice"
                          options={[
                            {
                              icon: 'money-bills',
                              color: 'blue.200',
                              value: 'CURRENCY',
                              label: 'Kronor exclusive moms'
                            },
                            {
                              icon: 'tally',
                              color: 'yellow.200',
                              value: 'AMOUNT',
                              label: 'antal'
                            },
                            {
                              icon: 'door-open',
                              color: 'purple.200',
                              value: 'ENTRY',
                              label: 'Inpasseringar'
                            }
                          ]}
                        />
                      </Box>
                    </Flex>
                  </CardBox>

                  <CardBox>
                    <Flex flex="1" flexDir="column">
                      <Flex flexDir="column">
                        <Heading size="lg" fontWeight="normal">
                          Period och periodjämförelse
                        </Heading>
                        <Text>
                          Välj vilken period du vill att rapporten ska täcka.
                        </Text>
                      </Flex>

                      <Flex flexDir="column">
                        <Box mt="4">
                          <RadioInput
                            id="period"
                            label="Nuvarande Perioder"
                            defaultValue="newPrice"
                            options={[
                              {
                                icon: 'calendar-week',
                                color: 'blue.200',
                                value: 'THIS_WEEK',
                                label: 'Denna vecka'
                              },
                              {
                                icon: 'calendar',
                                color: 'green.200',
                                value: 'THIS_MONTH',
                                label: 'Denna månad'
                              },

                              {
                                icon: 'champagne-glasses',
                                color: 'red.200',
                                value: 'THIS_YEAR',
                                label: 'Nuvarande år'
                              }
                            ]}
                          />
                        </Box>
                        <Box mt="0">
                          <RadioInput
                            id="period"
                            label="Förgående Perioder"
                            defaultValue="newPrice"
                            options={[
                              {
                                icon: 'calendar-week',
                                icon2: 'rotate-right',
                                color: 'blue.200',
                                value: 'LAST_WEEK',
                                label: 'Förgående vecka'
                              },

                              {
                                icon: 'calendar',
                                icon2: 'rotate-right',
                                color: 'green.200',
                                value: 'LAST_MONTH',
                                label: 'Förgående månad'
                              },
                              {
                                icon: 'champagne-glasses',
                                icon2: 'rotate-right',
                                color: 'red.200',
                                value: 'LAST_YEAR',
                                label: 'Förgående år'
                              }
                            ]}
                          />
                        </Box>
                        <Box mt="0">
                          <RadioInput
                            id="period"
                            label="Jämför Perioder"
                            defaultValue="newPrice"
                            options={[
                              {
                                icon: 'calendar-week',
                                icon2: 'code-compare',
                                color: 'blue.200',
                                value: 'THIS_WEEK_COMPARE_LAST_WEEK',
                                label: 'Jämför denna vecka med förgående vecka'
                              },

                              {
                                icon: 'calendar',
                                icon2: 'code-compare',
                                color: 'green.200',
                                value: 'THIS_MONTH_COMPARE_LAST_MONTH',
                                label: 'Jämför denna vecka med förgående  månad'
                              },
                              {
                                icon: 'champagne-glasses',
                                icon2: 'code-compare',
                                color: 'red.200',
                                value: 'THIS_YEAR_COMPARE_LAST_YEAR',
                                label: 'Jämför denna vecka med förgående  år'
                              }
                            ]}
                          />
                        </Box>
                        <Box mt="0">
                          <RadioInput
                            id="period"
                            label="Andra Perioder"
                            defaultValue="newPrice"
                            options={[
                              {
                                icon: 'wand-magic-sparkles',
                                color: 'yellow.200',
                                value: 'CUSTOM',
                                label: 'Välj en egen period'
                              }
                            ]}
                          />
                        </Box>
                      </Flex>

                      {isCustomPeriod && (
                        <>
                          <Flex flex={1}>
                            <Box flex={1} mt="4" mx="4">
                              <Text>Startdatum för period</Text>
                              <DateInput
                                id="start"
                                disabled={!isCustomPeriod}
                              />
                            </Box>

                            <Box flex={1} mt="4" mx="4">
                              <Text>slutdatum för period</Text>
                              <DateInput id="start" />
                            </Box>
                          </Flex>

                          <Box mt="4">
                            <SwitchInputWithDisclosure
                              id="compare_dates_switch"
                              label="Jämför med en annan period">
                              <Flex flex={1}>
                                <Box flex={1} mt="4" mx="4">
                                  <Text>
                                    Startdatum för period att jämföra med
                                  </Text>
                                  <DateInput id="start" />
                                </Box>

                                <Box flex={1} mt="4" mx="4">
                                  <Text>slutdatum för jämföra med</Text>
                                  <DateInput id="start" />
                                </Box>
                              </Flex>
                            </SwitchInputWithDisclosure>
                          </Box>
                        </>
                      )}
                    </Flex>
                  </CardBox>

                  <CardBox>
                    <Flex flex="1" flexDir="column">
                      <Flex flexDir="column">
                        <Heading size="lg" fontWeight="normal">
                          Urvalskriterier
                        </Heading>
                        <Text></Text>
                      </Flex>

                      <Flex flex={1} mt="2">
                        <Flex flex={1} flexDir="column" mx="4" mb="auto">
                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="fac"
                              label="Orter"
                              options={[
                                { value: 'stockholm', label: 'Stockholm' }
                              ]}
                            />
                          </Box>

                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="facility"
                              label="Anläggningar"
                              options={[
                                { value: 'stockholm', label: 'Stockholm' }
                              ]}
                            />
                          </Box>

                          <Divider my="4" />

                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="productgroup"
                              label="Varugrupper"
                              options={[{ value: 'man', label: '0-15 år' }]}
                            />

                            <SelectorInput
                              multi
                              id="services"
                              label="Tjänster"
                              options={[{ value: 'man', label: '0-15 år' }]}
                            />
                          </Box>
                        </Flex>

                        <Flex flex={1} flexDir="column" mx="4" mb="auto">
                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="gender"
                              label="Abonnemang"
                              options={[{ value: 'ag', label: 'AG 12-23 mån' }]}
                            />
                          </Box>
                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="gender"
                              label="Kön"
                              options={[{ value: 'man', label: 'Man' }]}
                            />
                          </Box>
                          <Box flex={1} mt="2">
                            <SelectorInput
                              multi
                              id="age"
                              label="Ålder"
                              options={[{ value: 'man', label: '0-15 år' }]}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </CardBox>
                </Flex>
              </Boxed>
            </form>
          </FormProvider>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default ReportGenerator

const CardBox = ({ children }) => {
  return (
    <Flex w="100%" bg="gray.700" rounded="lg" px="8" py="6" mb="4">
      {children}
    </Flex>
  )
}
