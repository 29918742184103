import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten, darken, radialGradient } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge,
  useDisclosure,
  Flex,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  InputLeftAddon,
  Kbd
} from '@chakra-ui/react'

const RegisterPayments = () => {
  return (
    <Box mt="2">
      <Box
        w="100%"
        border="1px"
        borderColor="whiteAlpha.300"
        rounded="lg"
        boxShadow={'xl'}>
        <Table>
          <Thead>
            <Tr>
              <Th borderColor="rgba(255, 255, 255, 0.1)">Betalsätt</Th>
              <Th borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                saldo
              </Th>
              <Th borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                Ångra
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {new Array(1).fill(0).map(() => (
              <Tr
                h="65px"
                fontSize="sm"
                cursor="pointer"
                _hover={{
                  bg: 'rgba(255, 255, 255, 0.05)',
                  boxShadow: 'sm'
                }}>
                <Td borderColor="rgba(255, 255, 255, 0.1)" fontWeight="bold">
                  Swish
                </Td>
                <Td borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                  200,00
                </Td>
                <Td borderColor="rgba(255, 255, 255, 0.1)" isNumeric>
                  <Button
                    size="sm"
                    colorScheme="red"
                    variant="outline"
                    ml="auto">
                    Ångra betalning
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

export default RegisterPayments
