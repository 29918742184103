import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { useIdleTimer } from 'react-idle-timer'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Heading,
  Text,
  Divider,
  ButtonGroup,
  Button,
  Image
} from '@chakra-ui/react'
import jwt_decode from 'jwt-decode'

import { useToaster } from 'utils/toaster'
import { setShouldReauthenticate, logout } from 'actions/user'
import undrawBreak from 'style/images/undraw_break.svg'

import TextInput from 'components/form/TextInput'

const DO_REFRESH_TOKEN = gql`
  mutation doRefreshToken($refreshToken: String) {
    doRefreshToken(input: { refreshToken: $refreshToken }) {
      success
      token
    }
  }
`

const DO_REAUTHENTICATE = gql`
  mutation doReauthenticate($input: ReauthenticateInput) {
    doReauthenticate(input: $input) {
      success
      token
      refreshToken
    }
  }
`

const LoginOverlay = () => {
  const toast = useToaster()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user.user)
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
  const shouldReauthenticate = useSelector(
    (state) => state.user.shouldReauthenticate
  )
  const isRehydrated = useSelector((state) => state._persist.rehydrated)

  const form = useForm({
    mode: 'onChange'
  })

  const [doReauthenticate, { loading }] = useMutation(DO_REAUTHENTICATE, {
    onCompleted: (data) => {
      if (data === null) return
      if (data.doReauthenticate.success) {
        localStorage.setItem('auth-token', data.doReauthenticate.token)
        localStorage.setItem(
          'refresh-token',
          data.doReauthenticate.refreshToken
        )

        toast({
          title: 'Välkommen tillbaka!',
          description: 'Du kan nu fortsätta använda Novasecur',
          status: 'success',
          position: 'top-right',
          isClosable: true
        })

        dispatch(setShouldReauthenticate(false))
      } else {
        toast({
          title: 'fel lösenord',
          description: 'försök igen!',
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    }
    // onError: () => {
    //   dispatch(logout())
    //   history.push('/login')
    // }
  })

  const [doRefreshToken] = useMutation(DO_REFRESH_TOKEN, {
    onCompleted: (data) => {
      if (data.doRefreshToken.success) {
        localStorage.setItem('auth-token', data.doRefreshToken.token)
      }
      localStorage.setItem('is-refreshing-token', 'false')
    },
    onError: () => {
      localStorage.setItem('is-refreshing-token', 'false')
    }
  })

  // this effect checks the JSON Web Token expiry and fetches a new one if the token has less than 2 minutes to live
  // also checks
  useEffect(() => {
    const intervalID = setInterval(() => {
      const token = localStorage.getItem('auth-token')
      try {
        if (token !== 'null' && !shouldReauthenticate) {
          const decodedToken = jwt_decode(token)
          const timeLeft = (decodedToken.exp - new Date() / 1000).toFixed(0)

          if (timeLeft > 0) {
            if (timeLeft < 2 * 60) {
              // if the token is expiring in less than two minutes, we're gonna try to reauth
              if (
                localStorage.getItem('is-refreshing-token') === 'false' ||
                !localStorage.getItem('is-refreshing-token')
              ) {
                localStorage.setItem('is-refreshing-token', 'true')
                doRefreshToken({
                  variables: {
                    refreshToken: localStorage.getItem('refresh-token')
                  }
                })
              } else
                console.log(
                  'passing on refreshing token, another tab picked it up'
                )
            }
          } else {
            // the token has expired, oh no! we have to reauth
            dispatch(setShouldReauthenticate(true))
          }
        }
      } catch (e) {
        if (localStorage.getItem('is-refreshing-token')) {
          toast({
            title: 'Något gick fel med Token Reauth',
            description: e.message,
            status: 'error',
            position: 'top-right',
            isClosable: true
          })
        }
      }
    }, (10000 + Math.random() * 300).toFixed()) // Add some jitter so multiple instances (read tabs) run at the same time
    return () => clearInterval(intervalID)
  }, [shouldReauthenticate])

  // Throw the user out if they aren't authenticated, the state has been rehydrated
  // and the token has been wiped
  useEffect(() => {
    if (
      !isAuthenticated &&
      isRehydrated &&
      localStorage.getItem('auth-token') === 'null'
    ) {
      dispatch(logout())
      history.push('/login')
    }
  }, [isAuthenticated])

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      dispatch(setShouldReauthenticate(true))
    },
    debounce: 500,
    crossTab: true
  })

  return (
    <Modal isOpen={shouldReauthenticate} size="lg">
      <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
      <ModalContent>
        <ModalBody py="5">
          <Box my="6" mb="2">
            <Image src={undrawBreak} w="50%" mx="auto" />
            <Heading textAlign="center" size="md" mt="5">
              Välkommen tillbaka {user.firstname}!
            </Heading>
            <Text textAlign="center" fontSize="sm" mx="10">
              På grund av inaktivitet har du loggats ut från Novasecur. Logga in
              igen för att fortsätta där du var.
            </Text>

            <Box mx="2">
              <Box mt="4" mb="6">
                <FormProvider {...form}>
                  <form
                    onSubmit={form.handleSubmit((vals) =>
                      doReauthenticate({
                        variables: {
                          input: {
                            token: localStorage.getItem('auth-token'),
                            password: vals.password
                          }
                        }
                      })
                    )}
                    spacing={8}>
                    <TextInput
                      variant="filled"
                      id="password"
                      type="password"
                      label="Lösenord"
                    />

                    <ButtonGroup w="100%">
                      <Button
                        ml="auto"
                        isLoading={loading}
                        type="submit"
                        color="#70a1ff">
                        Fortsätt
                      </Button>
                    </ButtonGroup>
                  </form>
                </FormProvider>
              </Box>

              <Box mt="10">
                <Divider mb="4" />
                <Button
                  w="100%"
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    dispatch(logout())
                    history.push('/login')
                  }}>
                  Logga in som annan användare
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoginOverlay

// const shouldReauthenticate = useSelector(
//   (state) => state.user.shouldReauthenticate
// )
// const hasLoadedSharedState = useSelector(
//   (state) => state.app.hasLoadedSharedState
// )

// const lastRequestTimestamp = useSelector(
//   (state) => state.app.lastRequestTimestamp
// )

// Setup the counter once
// useEffect(() => {
//   const checkReauthenticate = () => {
//     if (lastRequestTimestamp !== null) {
//       const timeSinceLast = (Date.now() - lastRequestTimestamp) / 60000
//       if (timeSinceLast > 59 && !shouldReauthenticate) {
//         console.log(
//           `Reauthenticate triggered. shouldReauthenticate: ${shouldReauthenticate}, timeSinceLast: ${timeSinceLast}, lastRequestTimestamp: ${lastRequestTimestamp}`
//         )
//         dispatch(setShouldReauthenticate(true))
//       }
//     }
//   }

//   clearInterval(checkReauthenticateTimer)
//   checkReauthenticateTimer = setInterval(() => {
//     checkReauthenticate()
//   }, 60000)
//   checkReauthenticate()
//   return () => clearInterval(checkReauthenticateTimer)
// }, [dispatch, lastRequestTimestamp, shouldReauthenticate])

// // If we're not logged in at all, go to /login
// if (
//   location.pathname !== '/login' &&
//   hasLoadedSharedState &&
//   isAuthenticated === false
// ) {
//   history.push('/login')
// }
