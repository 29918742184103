import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { history } from 'Router'
import { setPanels } from 'actions/panels'

export const Router = ({ routes, children }) => {
  const dispatch = useDispatch()
  const panelURI = useSelector((state) => state.panels.panelURI)
  const { hash } = useLocation()

  useEffect(() => {
    const unwrappedPaths = hash
      .replace('#', '')
      .split('/')
      .filter((r) => r !== '')
      .map((path) => {
        if (path.includes(':')) {
          const splitPath = path.split(':')
          const pathName = splitPath[0]
          const pathOptions = unwrapProps(splitPath.splice(1))
          return { name: pathName, options: pathOptions }
        } else {
          return { name: path, options: [] }
        }
      })

    const panels = unwrappedPaths.reduce((acc, path) => {
      if (routes.map((r) => r.route).includes(path.name)) {
        const currentRoute = routes.filter((r) => r.route === path.name)[0]
        return [
          ...acc,
          {
            key: JSON.stringify(path),
            ...currentRoute.panel,
            ...path,
            data: {
              ...currentRoute.panel.data,
              ...path.options
            }
          }
        ]
      } else {
        console.log('Got a panel that could not be resolved')
        return acc
      }
    }, [])

    dispatch(setPanels(panels))
  }, [hash])

  return children || null
}

export const pushPanel = (route, options = {}) => {
  const generatedRoute = `${route}${wrapProps(options)}`

  if (history.location.hash.slice(0, 2) !== '#/') {
    if (
      history.location.pathname[history.location.pathname.length - 1] !== '/'
    ) {
      history.replace({ hash: `#/${generatedRoute}` })
    } else {
      history.replace({ hash: `#/${generatedRoute}` })
    }
  } else if (history.location.hash[history.location.hash.length - 1] !== '/') {
    history.replace({ hash: `${history.location.hash}/${generatedRoute}` })
  } else {
    history.replace({ hash: `${history.location.hash}${generatedRoute}` })
  }
}

export const popPanel = () => {
  const newHash = history.location.hash
    .replace(/\/$/, '')
    .split('/')
    .filter((r) => r !== '')
  newHash.pop()
  history.replace({ hash: [...newHash].join('/') })
}

const unwrapProps = (wrappedProps) => {
  return wrappedProps.reduce((acc, prop) => {
    if (prop.includes('=')) {
      const splitProp = prop.split('=')
      return { ...acc, [splitProp[0]]: splitProp[1] }
    } else {
      return { ...acc, [prop]: true }
    }
  }, {})
}

const wrapProps = (unwrappedProps) => {
  return Object.keys(unwrappedProps).reduce((acc, prop) => {
    if (unwrappedProps[prop] === true) {
      return `${acc}:${prop}`
    } else {
      return `${acc}:${prop}=${unwrappedProps[prop]}`
    }
  }, '')
}
