import React, { useState, useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { ButtonGroup, Button } from '@chakra-ui/react'

import FreshTable from 'containers/FreshTable'
import WithHelpi from 'components/utils/WithHelpi'

import { pushPanel } from 'containers/PanelNavigation'
import { invoice, member } from 'constants/entities'
import TagRender from 'components/TagRender'

const GET_MEMBERS = gql`
  query members(
    $pageIndex: Int!
    $pageSize: Int!
    $search: String!
    $filters: [FilterInput!]
  ) {
    members(
      table: {
        pageIndex: $pageIndex
        pageSize: $pageSize
        search: $search
        filters: $filters
      }
    ) {
      pageCount
      filteredCount
      totalCount
      availableFilters {
        id
        label
        type
        options
      }
      activeFilters {
        id
        label
        type
        options
        value
      }
      downloadTypes {
        key
        label
      }
      members {
        id
        memberNumber
        tags {
          name
          value
          color
        }
        primaryAddress {
          fullname
          nationalId
          email
        }
      }
    }
  }
`

const ZReportTable = () => {
  const [selected, setSelected] = useState([])
  const [getMembers, { loading, error, data }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: 'network-only'
  })

  const onChange = useCallback((val) => {
    getMembers({ variables: val })
  }, [])

  const onDownload = useCallback((val) => {}, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Nr',
        accessor: 'number',
        maxWidth: 20
      },
      {
        Header: 'Anläggning',
        accessor: 'primaryAddress.fullname'
      },
      {
        Header: 'Datum',
        accessor: 'primaryAddress.nationalId'
      },
      {
        Header: 'Förfallo',
        accessor: ''
      },
      {
        Header: 'Kund',
        accessor: ''
      },
      {
        Header: 'Belopp',
        accessor: ''
      },
      {
        Header: 'Kvar att betala',
        accessor: ''
      },
      {
        Header: '',
        accessor: 'tags',
        Cell: (props) => <TagRender size="sm" tags={props.value} />
      }
    ],
    []
  )

  return (
    <>
      <ButtonGroup mb="2" ml="2"></ButtonGroup>

      <FreshTable
        title="Alla Dagsrapporter"
        color={invoice.color}
        icon={invoice.icon}
        loading={loading}
        columns={columns}
        data={data?.members ? data.members.members : []}
        pageCount={data?.members.pageCount}
        filteredCount={data?.members.filteredCount}
        totalCount={data?.members.totalCount}
        availableFilters={data?.members.availableFilters}
        activeFilters={data?.members.activeFilters}
        downloadTypes={data?.members.downloadTypes}
        onChange={onChange}
        onSelected={setSelected}
        onDownload={onDownload}
        onRowClick={(values) =>
          pushPanel('invoice', {
            id: values.memberNumber
          })
        }
      />
    </>
  )
}

export default ZReportTable
