import React from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import PageRoot from './containers/PageRoot'

import Dashboard from './scenes/Dashboard'
import Members from './scenes/Members'
import Memberships from './scenes/Memberships'
import Booking from './scenes/Booking'
import Course from './scenes/Course'
import Reports from './scenes/Reports'
import Crm from './scenes/Crm'
import Settings from './scenes/Settings'

import Login from './scenes/Login'
import UserSetup from './scenes/UserSetup'
// import Covid from './scenes/Covid'
import NotFound from 'scenes/NotFound'
import Invoices from 'scenes/Invoices'
import Receipts from 'scenes/Receipts'
import ZReport from 'scenes/ZReport'
import ForgottenPassword from 'scenes/ForgottenPassword'
import ResetPassword from 'scenes/ResetPassword'
import Register from 'scenes/Register'
import ReportGenerator from 'scenes/ReportGenerator'

export const history = createBrowserHistory()

const MainRouter = () => (
  <Router history={history}>
    <PageRoot>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/members" component={Members} />
        <Route exact path="/memberships" component={Memberships} />
        <Route exact path="/booking" component={Booking} />
        <Route exact path="/booking/courses" component={Course} />
        <Route exact path="/economy/invoices" component={Invoices} />
        <Route exact path="/economy/receipts" component={Receipts} />
        <Route exact path="/economy/z-report" component={ZReport} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/report-generator" component={ReportGenerator} />
        <Route exact path="/crm" component={Crm} />
        <Route exact path="/settings" component={Settings} />

        <Route exact path="/register" component={Register} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/login/setup" component={UserSetup} />
        <Route
          exact
          path="/login/forgotten-password"
          component={ForgottenPassword}
        />
        <Route exact path="/login/reset-password" component={ResetPassword} />
        {/* <Route exact path="/covid" component={Covid} /> */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </PageRoot>
  </Router>
)

export default MainRouter
