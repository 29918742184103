import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { lighten, darken, radialGradient, transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge,
  useDisclosure,
  Flex,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'

import { closeRegister } from 'actions/register'
import RegisterCart from 'containers/register/RegisterCart'
import RegisterButtonSet from 'containers/register/RegisterButtonSet'
import RegisterProductSet from 'containers/register/RegisterProductSet'
import RegisterPay from 'containers/register/RegisterPay'
import wooshSound from 'style/sounds/ogg/Woosh/woosh_10.ogg'
import RegisterPayments from 'containers/register/RegisterPayments'
import { member } from 'constants/entities'
import MemberPicker from 'containers/services/MemberPicker'

const GET_MEMBERS = gql`
  query getMembers(
    $pageIndex: Int!
    $pageSize: Int!
    $search: String!
    $filters: [FilterInput!]
  ) {
    members(
      table: {
        pageIndex: $pageIndex
        pageSize: $pageSize
        search: $search
        filters: $filters
      }
    ) {
      pageCount
      filteredCount
      totalCount
      members {
        id
        memberNumber
        primaryAddress {
          fullname
          nationalId
          email
        }
      }
    }
  }
`

const GET_REGISTER = gql`
  query Register($id: String) {
    register(id: $id) {
      categories {
        id
        type
        name
        color
      }

      paymentButtons {
        id
        type
        name
        color
        primary
      }

      customButtonsMain {
        id
        type
        name
        color
      }

      customButtonsUtil {
        id
        type
        name
        color
      }

      collectionModeAvailable
      registerInfoStr
    }
  }
`

const ON_BASKET = gql`
  subscription onPosBasket($basketId: Int) {
    onPosBasket(basketId: $basketId) {
      id
    }
  }
`

const Register = ({ registerId }) => {
  const dispatch = useDispatch()
  const fullscreen = useFullScreenHandle()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const infoDisclosure = useDisclosure()
  const [productEditing, setProductEditing] = useState(null)
  const [isPickingPayer, setIsPickingPayer] = useState(false)

  const productPanelDisclosure = useDisclosure({
    onOpen: () => {
      const audio = new Audio(wooshSound)
      audio.volume = 0.3
      audio.play()
    }
  })
  const payPanelDisclosure = useDisclosure()

  const productRef = useRef()
  const onScreenKeypadWrite = (char) => {
    productRef.current.value = productRef.current.value + char
  }

  useEffect(() => {
    const removeFcWidgetInterval = setInterval(() => {
      if (window.fcWidget) {
        window.fcWidget.hide()
        clearInterval(removeFcWidgetInterval)
      }
    }, 100)

    return () => window.fcWidget.show()
  }, [])

  const { loading, data, subscribeToMore } = useQuery(GET_REGISTER, {
    variables: { id: '1' }
  })

  // useEffect(() => {
  //   const unsubscribe = subscribeToMore({
  //     document: ON_BASKET,
  //     variables: { registerId: 0 },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData) return prev

  //       return {
  //         ...prev
  //       }
  //     }
  //   })

  //   return () => {
  //     if (unsubscribe) unsubscribe()
  //   }
  // }, [subscribeToMore])

  return (
    <>
      <FullScreen handle={fullscreen}>
        <Flex h="100vh" w="100%" bg="rgba(255, 255, 255, 0.2)">
          <Flex flexDir="column" flex="2">
            <Flex flexDir="column" maxH="100vh" flex="1" px="2">
              <Flex my="2" mr="4px">
                <Button
                  size="sm"
                  px="10"
                  mr="1"
                  variant="outline"
                  colorScheme="red"
                  leftIcon={
                    <FontAwesomeIcon
                      transform={{ rotate: 180 }}
                      icon={['fal', 'sign-out']}
                    />
                  }
                  onClick={() => {
                    dispatch(closeRegister())
                    fullscreen.exit()
                  }}>
                  Lämna
                </Button>
                <Button
                  disabled
                  size="sm"
                  mr="1"
                  px="10"
                  leftIcon={<FontAwesomeIcon icon={['fal', 'receipt']} />}>
                  Insamlingsläge
                </Button>{' '}
                <Alert rounded="md" h="32px" px="2" status="success" mr="1">
                  <FontAwesomeIcon icon={['fal', 'check-circle']} />
                  <AlertTitle ml="2" fontSize="xs">
                    Kassan är aktiv
                  </AlertTitle>
                  <AlertDescription ml="-1" fontSize="xs" opacity="0.8">
                    Kvittoskrivare ansluten
                  </AlertDescription>
                </Alert>
                <Button
                  size="sm"
                  px="2"
                  iconSpacing="0"
                  leftIcon={<FontAwesomeIcon icon={['fal', 'gear']} />}
                  onClick={infoDisclosure.onOpen}
                  mr="1"></Button>
                <Button
                  size="sm"
                  px="2"
                  iconSpacing="0"
                  leftIcon={
                    <FontAwesomeIcon
                      icon={[
                        'fal',
                        fullscreen.active
                          ? 'compress-arrows-alt'
                          : 'expand-arrows-alt'
                      ]}
                    />
                  }
                  onClick={() =>
                    fullscreen.active ? fullscreen.exit() : fullscreen.enter()
                  }></Button>
              </Flex>

              <Box
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px'
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '4px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                  }
                }}
                overflowY="scroll">
                <Flex pb="2" mt="auto">
                  <Flex
                    w="50%"
                    mr="1"
                    rounded="lg"
                    overflow="hidden"
                    justify="space-between"
                    align="center"
                    flexDir="column"
                    bg="whiteAlpha.50">
                    <Center flexGrow={1}>
                      <Center mx="4">
                        <FontAwesomeIcon
                          size="xl"
                          icon={['fal', 'empty-set']}
                        />
                      </Center>
                      <Text mr="auto">Ingen medlem vald</Text>
                    </Center>

                    <Flex
                      w="100%"
                      h="45px"
                      justify="center"
                      bg={transparentize(0.6, member.color)}>
                      <Button
                        h="100%"
                        w="100%"
                        borderTopRadius="0px"
                        size="sm"
                        variant="ghost"
                        my="auto"
                        onClick={() => setIsPickingPayer(true)}>
                        Välj eller skapa medlem
                      </Button>
                    </Flex>
                  </Flex>

                  <Flex w="50%" flexDir="column" ml="auto">
                    <Flex
                      w="100%"
                      justify="space-between"
                      align="center"
                      ml="auto"
                      px="3"
                      py="1"
                      roundedTop="lg"
                      bg="whiteAlpha.50">
                      <Text fontSize="xl">Summa</Text>
                      <Spacer />
                      <Text fontSize="4xl">234.00 kr</Text>
                    </Flex>

                    <Flex
                      w="100%"
                      justify="space-between"
                      align="center"
                      ml="auto"
                      px="3"
                      py="1"
                      roundedBottom="lg"
                      bg="whiteAlpha.100"
                      h="45px">
                      <Text fontSize="lg">Saldo</Text>
                      <Spacer />
                      <Text fontSize="2xl">0.00 kr</Text>
                    </Flex>
                  </Flex>
                </Flex>

                <RegisterCart />
                <RegisterPayments />
              </Box>
            </Flex>
          </Flex>

          <Flex
            flexDir="column"
            flex="3"
            h="100%"
            w="100%"
            alignItems="flex-end">
            <Flex flex="1" w="100%" align="center">
              <Flex
                w="100%"
                border="1px"
                borderColor="rgba(255, 255, 255, 0.1)"
                p="3"
                pt="2"
                px="3"
                rounded="md"
                m="2"
                ml="0">
                <FormControl flex="4" mr="1">
                  <FormLabel fontSize="sm" m="0">
                    Produktnummer
                  </FormLabel>
                  <Input
                    ref={productRef}
                    mt="-1"
                    variant="flushed"
                    placeholder="Produktnummer"
                    size="lg"
                  />
                </FormControl>

                <FormControl flex="1" mr="1">
                  <FormLabel fontSize="sm" m="0">
                    Belopp
                  </FormLabel>
                  <InputGroup>
                    <NumberInput
                      mt="-1"
                      size="lg"
                      variant="flushed"
                      placeholder="Antal">
                      <NumberInputField />
                    </NumberInput>
                    <InputRightElement
                      children={<Text opacity="0.2">Kr</Text>}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontSize="sm" m="0">
                    Antal
                  </FormLabel>
                  <NumberInput
                    mt="-1"
                    size="lg"
                    variant="flushed"
                    placeholder="Antal"
                    defaultValue={1}
                    min={1}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper border="none" />
                      <NumberDecrementStepper border="none" />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
            </Flex>

            <Flex flexDir="column" pos="relative" h="100%" w="100%">
              <RegisterButtonSet
                openProductPanel={productPanelDisclosure.onOpen}
                openPayPanel={payPanelDisclosure.onOpen}
                onScreenKeypadWrite={(c) => onScreenKeypadWrite(c)}
              />

              {productPanelDisclosure.isOpen && (
                <RegisterProductSet
                  closeProductPanel={productPanelDisclosure.onClose}
                />
              )}

              {payPanelDisclosure.isOpen && (
                <RegisterPay closePayPanel={payPanelDisclosure.onClose} />
              )}
            </Flex>
          </Flex>
        </Flex>

        {isPickingPayer && (
          <SelectPayerFlow setIsPickingPayer={setIsPickingPayer} />
        )}
      </FullScreen>

      <AlertDialog {...infoDisclosure}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Kassa information
            </AlertDialogHeader>

            <AlertDialogBody></AlertDialogBody>

            <AlertDialogFooter>
              <Button w="100%" size="sm" onClick={infoDisclosure.onClose}>
                Stäng
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Register

const SelectPayerFlow = ({ setIsPickingPayer }) => {
  const [getMembers, { loading, data }] = useLazyQuery(GET_MEMBERS, {})

  return (
    <MemberPicker
      data={data}
      loading={loading}
      getData={getMembers}
      onDone={(selected) => {
        setIsPickingPayer(false)
      }}
      max={1}
      onCancel={() => setIsPickingPayer(false)}
    />
  )
}
