// a simple wrapper over sessionStorage to help
// panels communicate data back/forward in the stack
// panels dispatch messages that stay in sessionstorage until another panel
// consumes it, deleting it
// This system is not bulletproof and should probably be limited to convenience functions rather than
// important business logic

// Provide a key for the consumer to resolve, and a message in serializable JSON
export const emit = (key, message) => {
  sessionStorage.setItem(key, JSON.stringify(message))
}

// Provide a key for the value that you want to consume
export const consume = (key) => {
  const data = sessionStorage.getItem(key)

  if (data) {
    sessionStorage.removeItem(key)
    return JSON.parse(data)
  }

  return null
}

export default {
  emit,
  consume
}
