import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
  Image,
  Text
} from '@chakra-ui/react'

import undrawGDPR from 'style/images/undraw_gdpr.svg'

import { setHasCookieConsented } from 'actions/user'

const CookieConsent = () => {
  const dispatch = useDispatch()
  const hasCookieConsent = useSelector((state) => state.user.CookieConsent)

  return (
    <Modal size="lg" isOpen={!hasCookieConsent}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Heading size="md" textAlign="center">
            Din upplevelse
          </Heading>
        </ModalHeader>
        <ModalBody pt="2">
          <Box mb="4">
            <Image src={undrawGDPR} w="40%" mx="auto" />
          </Box>

          <Box mb="4">
            <Text>
              Novasecur använder cookies på din enhet för att spara dina
              preferenser, förenkla utveckingen av nya funktioner och erbjuda
              support fuktioner genom vår support partner Freshdesk.
            </Text>
          </Box>

          <Accordion>
            <AccordionItem border="0">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    Nödvändiga Cookies
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Cookies och liknande tekonologi används av Novasecur för att
                identifera dig, spara dina val (så som tema) och möjliggöra
                synkning mellan webbläsar-tabbar.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="0">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    Tredjeparts Cookies
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Vi använder oss av leverantören Freshdesk för att erbjuda
                support tjänster till dig som användare.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <Button w="100%" onClick={() => dispatch(setHasCookieConsented())}>
            Jag förstår
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CookieConsent
