// Open actions

export const openMessageSender = () => {
  return { type: 'TOGGLE_MESSAGE_SENDER_OPEN', payload: true }
}

export const closeMessageSender = () => {
  return { type: 'TOGGLE_MESSAGE_SENDER_OPEN', payload: false }
}

export const openCardCollector = () => {
  return { type: 'TOGGLE_CARD_COLLECTOR', payload: true }
}

export const openSearchModal = () => {
  return { type: 'TOGGLE_SEARCH_MODAL', payload: true }
}

export const openEntryRegistration = () => {
  return { type: 'TOGGLE_ENTRY_REGISTRATION', payload: true }
}

export const openAllDoors = () => {
  return { type: 'TOGGLE_ALL_DOORS', payload: true }
}

export const openMemberPicker = (id) => {
  return { type: 'OPEN_MEMBER_PICKER', payload: { id } }
}

// Close actions

export const closeCardCollector = () => {
  return { type: 'TOGGLE_CARD_COLLECTOR', payload: false }
}

export const openPaySelector = () => {
  return { type: 'TOGGLE_PAY_SELECTOR', payload: true }
}

export const closePaySelector = () => {
  return { type: 'TOGGLE_PAY_SELECTOR', payload: false }
}

export const closeSearchModal = () => {
  return { type: 'TOGGLE_SEARCH_MODAL', payload: false }
}

export const closeEntryRegistration = () => {
  return { type: 'TOGGLE_ENTRY_REGISTRATION', payload: false }
}

export const closeAllDoors = () => {
  return { type: 'TOGGLE_ALL_DOORS', payload: false }
}

export const closeMemberPicker = (id, selected) => {
  return { type: 'CLOSE_MEMBER_PICKER', payload: selected }
}
