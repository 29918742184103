import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Center, Flex, Fade, Spinner, Text, Button } from '@chakra-ui/react'

import { setAppReady } from 'actions/app'
import { setUser, setIsAuthenticated } from 'actions/user'

const GET_ME = gql`
  query getMe {
    me {
      id
      username
      firstname
      lastname
      permissions {
        members
        memberships
        bookings
        finance
        reports
      }
      toDoLists {
        id
      }
      selectedMainFacility {
        id
        shortName
        name
        doors {
          id
          name
          isPrimary
          isOnline
        }
      }
      selectedFacilities {
        id
        shortName
        name
      }
    }
  }
`

const Preflight = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [preflightStep, setPreflightStep] = useState('LOADING')
  const isDisabledPath = !!location.pathname.match(/^\/login/gi)
  const appReady = useSelector((state) => state.app.appReady)

  // const selectedMainFacility = useSelector(
  //   (state) => state.user.user.selectedMainFacility
  // )

  const [getMe, { loading: meLoading, called: meCalled, data: meData }] =
    useLazyQuery(GET_ME, {
      fetchPolicy: 'network-only',
      onCompleted: ({ me }) => {
        dispatch(setUser(me))
        dispatch(setAppReady(true))

        // window.fcWidget.user.setFirstName(me.firstname)
        // window.fcWidget.user.setLastName(me.lastname)
        // window.fcWidget.user.setEmail(me.email)
        // window.fcWidget.user.setProperties({
        //   installation: window.location.host.split('.')[1]
        //     ? window.location.host.split('.')[0]
        //     : false // meta property 1
        //   //status: 'Active' // meta property 2
        // })
      },
      onError: (e) => {
        setPreflightStep('FAILED')
      }
    })

  //TODO fix this, should change on new selectedmainfacility, not on mount
  // useEffect(() => {
  //   if (meCalled) {
  //     getMe()
  //   }
  // }, [selectedMainFacility])

  useEffect(() => {
    if (!isDisabledPath && !appReady) {
      getMe()
    }
  }, [location, appReady, isDisabledPath, meLoading])

  useEffect(() => {
    if (isDisabledPath && appReady) {
      dispatch(setAppReady(false))
    }
  }, [isDisabledPath, location])

  return (
    <>
      {!appReady && !isDisabledPath && (
        <Center h="100vh" w="100w">
          <Flex flexDir="column" align="center">
            {preflightStep === 'FAILED' ? (
              <FontAwesomeIcon size="3x" icon={['fad', 'wifi-slash']} />
            ) : (
              <Spinner size="xl" speed="0.65s" />
            )}
            <Text fontSize="xs" mt="4">
              {preflightStep === 'FAILED'
                ? 'Kunde inte nå Novasecurs servrar'
                : 'Laddar Novsecur...'}
            </Text>
            {preflightStep === 'FAILED' && (
              <Button
                mt="2"
                variant="ghost"
                size="xs"
                onClick={() => window.location.reload()}>
                Ladda om
              </Button>
            )}
          </Flex>
        </Center>
      )}

      <Fade in={appReady || isDisabledPath} unmountOnExit>
        {((appReady && meData?.getMe?.id !== null) || isDisabledPath) &&
          children}
      </Fade>
    </>
  )
}

export default Preflight
