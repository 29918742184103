const init = {
  collectSignature: {
    open: false,
    nationalId: null
  },
  cardCollector: {
    open: false
  },
  messageSender: {
    open: false,
    recipients: []
  },
  paySelector: {
    open: false,
    recipients: []
  },
  searchModal: {
    open: false
  },
  entryRegistration: {
    open: false
  },
  allDoors: {
    open: false
  },
  memberPicker: {
    open: false,
    pickerYield: {}
  }
}

const servicesReducer = (state = init, action) => {
  switch (action.type) {
    case 'TOGGLE_MESSAGE_SENDER_OPEN':
      return {
        ...state,
        messageSender: { ...state.messageSender, open: action.payload }
      }

    case 'TOGGLE_CARD_COLLECTOR':
      return {
        ...state,
        cardCollector: { ...state.cardCollector, open: action.payload }
      }

    case 'TOGGLE_PAY_SELECTOR':
      return {
        ...state,
        paySelector: { ...state.paySelector, open: action.payload }
      }

    case 'TOGGLE_SEARCH_MODAL':
      return {
        ...state,
        searchModal: { ...state.searchModal, open: action.payload }
      }

    case 'TOGGLE_ENTRY_REGISTRATION':
      return {
        ...state,
        entryRegistration: { ...state.entryRegistration, open: action.payload }
      }

    case 'TOGGLE_ALL_DOORS':
      return {
        ...state,
        allDoors: { ...state.allDoors, open: action.payload }
      }

    case 'OPEN_MEMBER_PICKER':
      return {
        ...state,
        memberPicker: {
          ...state.memberPicker,
          open: true,
          pickerYield: { ...state.pickerYield, [action.payload.id]: [] }
        }
      }

    case 'CLOSE_MEMBER_PICKER':
      return {
        ...state,
        memberPicker: {
          ...state.memberPicker,
          open: false
        }
      }

    default:
      return state
  }
}

export default servicesReducer
