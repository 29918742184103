import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Boxed, Content, TopPadder } from 'style/wrappers'
import {
  Box,
  Flex,
  Fade,
  Center,
  SimpleGrid,
  Heading,
  Text,
  Container,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import bkgimg from 'style/images/login.jpg'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import MemberTable from 'containers/tables/MemberTable'
import PageHeader from 'components/PageHeader'
import ReceiptTable from 'containers/tables/ReceiptTable'
import ErrorBoundary from 'components/ErrorBoundary'

const Receipts = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Kvitton | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content pos="relative">
          <PageHeader image="https://images.pexels.com/photos/164686/pexels-photo-164686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="receipt"
              title="Kvitton"
              subTitle="Kvitton genererade genom Novasecurs kassa."
            />

            <ReceiptTable />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Receipts
