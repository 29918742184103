import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown'
import LazyLoad from 'react-lazyload'
import {
  Box,
  Flex,
  SimpleGrid,
  Center,
  Heading,
  Text,
  CircularProgress,
  CircularProgressLabel,
  UnorderedList,
  ListItem,
  Button,
  Wrap,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import SlideOutColumns, { Column } from 'components/slideouts/SlideOutColumns'
import SlideOutSection from 'components/slideouts/SlideOutSection'
import SectionTitle from 'components/slideouts/SectionTitle'

import BaseCard from 'components/cards/BaseCard'
import EntityCard from 'components/cards/EntityCard'
import { ListItem as CardListItem } from 'components/cards/ListCard'

import NumberInput from 'components/form/NumberInput'
import RadioInput from 'components/form/RadioInput'
import SelectorInput from 'components/form/SelectorInput'
import PinInput from 'components/form/PinInput'
import YesNoInput from 'components/form/YesNoInput'

import LabelRow from 'components/LabelRow'
import TagRender from 'components/TagRender'
import ProfilePictureHandler from 'components/ProfilePictureHandler'
import Note from 'containers/Note'
import AddressForm from 'containers/forms/AddressForm'
import InternalLog from 'containers/InternalLog'
import WithHelpi from 'components/utils/WithHelpi'

import {
  member,
  membership,
  invoice,
  booking,
  receipt
} from 'constants/entities'
import { uploadFileToS3 } from 'utils/s3Tools'
import { popPanel, pushPanel } from 'containers/PanelNavigation'

import { openMessageSender, openCardCollector } from 'actions/services'

import memberPanelSchema from 'validationSchemas/memberPanelSchema'
import AddressSchema from 'validationSchemas/AddressSchema'
import TextInput from 'components/form/TextInput'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'
import PanelHelp from 'components/PanelHelp'
import CardCollector from 'containers/services/CardCollector'
import SaveButton from 'components/SaveButton'
import AutogiroConsent from 'containers/services/AutogiroConsent'
import MemberInput from 'components/form/MemberInput'
import { merge } from 'lodash'
import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import MessageSender from 'containers/services/MessageSender'
import ReceiptCard from 'components/cards/ReceiptCard'
import SkeletonPanel from 'components/slideouts/SkeletonPanel'
import DefaultErrorHandler from 'utils/DefaultErrorHandler'
import EntityRow from 'components/EntityRow'

const GET_MEMBER = gql`
  query member($memberNumber: Int!) {
    member(memberNumber: $memberNumber) {
      id
      memberNumber
      pin

      consentNewsletter
      physicalAdmissionDenied
      onlineAdmissionDenied
      useWeightsensor
      vendingCredit
      useFingerprint
      specialEntrance
      startValueStatistics
      visitingStatistics

      invoiceExpiry
      invoiceDelivery
      invoiceReference
      invoicePostPay
      invoiceCreditLimit

      toDoProgress
      toDos

      facilities

      tags {
        name
        value
        color
      }

      profilePicture {
        url
      }

      primaryAddress {
        id
        createdAt

        nationalId
        email
        mobileNumber

        fullname
        firstname
        middlename
        lastname

        co
        street
        street2
        postcode
        city
        country
      }

      invoiceAddress {
        id
        createdAt

        nationalId
        email
        mobileNumber

        fullname
        firstname
        middlename
        lastname

        co
        street
        street2
        postcode
        city
        country
      }

      shippingAddress {
        id
        createdAt

        nationalId
        email
        mobileNumber

        fullname
        firstname
        middlename
        lastname

        co
        street
        street2
        postcode
        city
        country
      }

      notes {
        id
        createdAt

        title
        content
        done
      }

      mediaIds {
        id
        name
      }

      paymentMethods {
        id
        name
      }

      memberships {
        id
        name
        tags {
          name
          value
          color
        }
      }

      invoices {
        id
        name

        tags {
          name
          value
          color
        }
      }

      receipts {
        id
        name
        url

        tags {
          name
          value
          color
        }
      }

      bookings {
        id
        name
        tags {
          name
          value
          color
        }
      }
    }

    facilities {
      id
      shortName
      name
    }

    printoutTypes {
      id
      name
    }
  }
`

const GET_ADDRESS_FROM_NATIONALID = gql`
  query addressFromNationalId($nationalId: String!) {
    addressFromNationalId(nationalId: $nationalId) {
      email
      mobileNumber
      firstname
      lastname

      co
      street
      street2
      postcode
      city
      country
    }
  }
`

const SET_MEMBER = gql`
  mutation setMember($id: String!, $input: MemberInput!) {
    setMember(id: $id, input: $input) {
      success
    }
  }
`

const DELETE_MEMBER = gql`
  mutation deleteMember($id: String!) {
    setMember(id: $id) {
      success
    }
  }
`

const CREATE_NOTE = gql`
  mutation createNote($memberNumber: String!, $input: NoteInput) {
    createNote(memberNumber: $memberNumber, input: $input) {
      success
    }
  }
`

const DELETE_PAYMENTMETHOD = gql`
  mutation deletePaymentMethod($id: String!) {
    deletePaymentMethod(id: $id) {
      success
    }
  }
`

const DELETE_MEDIAID = gql`
  mutation deleteMediaId($id: String!) {
    deleteMediaId(id: $id) {
      success
    }
  }
`

const GET_MEMBER_PROFILE_PICTURE_UPLOAD_URL = gql`
  mutation getMemberprofilePictureUploadUrl($memberNumber: Int!) {
    getMemberprofilePictureUploadUrl(memberNumber: $memberNumber) {
      url
    }
  }
`

const CHECKIN_MEMBER = gql`
  mutation checkInMember($memberNumber: String!) {
    checkInMember(memberNumber: $memberNumber) {
      success
      message
    }
  }
`

const MERGE_MEMBER = gql`
  mutation mergeMember($memberNumber: String!, $mergeMemberNumber: String!) {
    mergeMember(
      memberNumber: $memberNumber
      mergeMemberNumber: $mergeMemberNumber
    ) {
      success
      message
    }
  }
`

const navigation = [
  { to: 'mem-home', icon: 'home', color: '#00a8ff' },
  { to: 'mem-memberships', icon: membership.icon, color: membership.color },
  { to: 'mem-bookings', icon: booking.icon, color: booking.color },
  { to: 'mem-invoices', icon: invoice.icon, color: invoice.color },
  { to: 'mem-notes', icon: 'sticky-note', color: '#f0932b' },
  { to: 'mem-help', icon: 'question-circle', color: '#e056fd' }
]

const MemberPanel = ({ uid, data: { id }, ...rest }) => {
  const toast = useToaster()

  const [canDelete, setCanDelete] = useState(false)
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  const PriAdDisclosure = useDisclosure()
  const invoiceAdDisclosure = useDisclosure()
  const shippingAdDisclosure = useDisclosure()

  const cardCollectorDisclosure = useDisclosure()
  const autogiroDisclosure = useDisclosure()
  const checkinDisclosure = useDisclosure()
  const mergeDisclosure = useDisclosure()
  const messageSenderDisclosure = useDisclosure()

  const [getMemberprofilePictureUploadUrl] = useMutation(
    GET_MEMBER_PROFILE_PICTURE_UPLOAD_URL
  )

  const [createNote] = useMutation(CREATE_NOTE, { refetchQueries: ['member'] })

  const [setMember] = useMutation(SET_MEMBER, {
    refetchQueries: ['member'],
    onCompleted: () => {
      toast({
        title: 'Medlem updaterad',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }
  })

  const [deleteMember] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      toast({
        title: 'Medlem Raderad',
        status: 'warning',
        position: 'top-right',
        isClosable: true
      })
      popPanel()
    },
    onError: (e) =>
      DefaultErrorHandler({ title: 'Kunde inte radera Medlem.', ...e })
  })

  const [deletePaymentMethod] = useMutation(DELETE_PAYMENTMETHOD, {
    refetchQueries: ['member'],
    onCompleted: (data) => {
      if (data.deletePaymentMethod.success) {
        toast({
          title: `Betalmetod raderat`,
          status: 'warning',
          position: 'top-right',
          isClosable: true
        })
      }
    },
    onError: (e) =>
      DefaultErrorHandler({ title: 'Kunde inte radera Betalmetod.', ...e })
  })

  const [deleteMediaId] = useMutation(DELETE_MEDIAID, {
    refetchQueries: ['member'],
    onCompleted: (data) => {
      if (data.deleteMediaId.success) {
        toast({
          title: `MediaID raderat`,
          status: 'warning',
          position: 'top-right',
          isClosable: true
        })
      }
    },
    onError: (e) =>
      DefaultErrorHandler({ title: 'Kunde inte radera MediaID.', ...e })
  })

  const [doCheckIn] = useMutation(CHECKIN_MEMBER, {
    refetchQueries: ['member'],
    onCompleted: (data) => {
      if (data.checkInMember.success) {
        toast({
          title: data.checkInMember.message,
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
        checkinDisclosure.onClose()
      }
    },
    onError: (e) => DefaultErrorHandler({ title: 'Kunde inte checka in', ...e })
  })

  const [doMergeMember] = useMutation(MERGE_MEMBER, {
    refetchQueries: ['member'],
    onCompleted: (data) => {
      if (data.mergeMember.success) {
        toast({
          title: data.mergeMember.message,
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
        mergeDisclosure.onClose()
      }
    },
    onError: (e) =>
      DefaultErrorHandler({ title: 'Kunde inte slå ihop medlemmar.', ...e })
  })

  const { loading, error, refetch, data } = useQuery(GET_MEMBER, {
    skip: !id,
    variables: { memberNumber: parseInt(id) },
    fetchPolicy: 'network-only',
    onCompleted: ({ member }) => {
      const {
        __typename,
        id,
        memberNumber,
        fullname,
        bookings,
        mediaIds,
        invoices,
        receipts,
        memberships,
        notes,
        paymentMethods,
        tags,
        profilePicture,
        toDos,
        toDoProgress,
        visitingStatistics,
        primaryAddress,
        invoiceAddress,
        shippingAddress,
        facilities,
        ...cleanValues
      } = member
      form.reset({
        ...cleanValues,
        ...(facilities && { facilities: facilities.join(', ') })
      })
      form.setValue(
        'hasInvoiceCreditLimit',
        Boolean(member.invoiceCreditLimit > 0)
      )
    }
  })

  const {
    profilePicture,
    primaryAddress = {},
    invoiceAddress = {},
    shippingAddress = {},
    tags = [],
    notes = [],
    mediaIds = [],
    paymentMethods = [],
    toDoProgress,
    toDos,
    memberships = [],
    invoices = [],
    receipts = [],
    bookings = [],
    visitingStatistics = null
  } = !error && !loading && data.member ? data.member : {}

  const onCroppedImage = async (file) => {
    const data = await getMemberprofilePictureUploadUrl({
      variables: { memberNumber: parseInt(id) }
    })

    await uploadFileToS3({
      url: data.data.getMemberprofilePictureUploadUrl.url,
      contentType: 'image/jpeg',
      file: file
    })
    refetch()
  }

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(memberPanelSchema)
  })

  const mergeYupSchema = yup.object().shape({
    member: yup.array().of(yup.string()).required()
  })

  const mergeForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(mergeYupSchema)
  })

  const actions = [
    {
      title: 'Nytt abonnemang',
      color: membership.color,
      icon: membership.icon,
      description: 'Skapa ett nytt abonnemang på kunden',
      onClick: () =>
        pushPanel('create-membership', {
          payerSignerId: data.member.id
        })
    },
    {
      title: 'Ny faktura',
      color: '#fd7272',
      icon: 'file-invoice',
      description: 'Skapa en ny faktura för kunden',
      onClick: () => pushPanel('create-invoice', { payerId: data.member.id })
    },
    {
      title: `Checka in ${
        primaryAddress?.firstname ? primaryAddress.firstname : 'medlem'
      }`,
      color: '#2ed573',
      icon: 'person-walking',
      description: 'Checka in denna medlem manuellt',
      onClick: checkinDisclosure.onOpen
    },
    {
      title: 'Nytt Autogiro medgivande',
      color: '#aaa69d',
      icon: 'signature',
      description:
        'Om kunden har bytt bank eller makulerat nuvarande medgivande',
      onClick: autogiroDisclosure.onOpen
    },
    {
      title: 'Hämta kort från läsare',
      color: '#e67e22',
      icon: 'signal-stream',
      description: 'Hämta kort från läsaren kopplad till din enhet',
      onClick: cardCollectorDisclosure.onOpen
    },
    {
      title: 'Lägg till på kvitto',
      color: receipt.color,
      icon: 'receipt',
      description: 'Lägg till kuden på ett kvitto i kassan'
    },
    {
      title: 'Slå ihop medlem',
      color: '#38ada9',
      icon: 'handshake-angle',
      description: 'Slå ihop den här medlemmen med en annan',
      onClick: mergeDisclosure.onOpen
    },
    {
      title: 'Mail & SMS utskick',
      color: '#2980b9',
      icon: 'paper-plane',
      description: 'Skicka ett meddelande via email eller sms',
      onClick: messageSenderDisclosure.onOpen
    },
    {
      title: `Radera ${
        primaryAddress?.firstname ? primaryAddress.firstname : 'medlem'
      }`,
      color: '#e17055',
      icon: 'ban',
      description: 'Detta kan inte ångras.',
      onClick: () => onOpenDelete()
    }
  ]

  const cardCollectorOnSave = (tag) => {
    setMember({ variables: { id: id, input: { mediaId: tag } } })
  }

  if (loading) return <SkeletonPanel loading={true} {...rest} />
  if (error) return <SkeletonPanel error={error} {...rest} />

  return (
    <>
      <SlideOutPanel
        actions={actions}
        navigation={navigation}
        {...rest}
        title={primaryAddress?.fullname}
        tabTitle={`Medlem: ${primaryAddress?.fullname}`}
        error={
          !loading && data?.member === null
            ? 'Medlemmen kunde inte hittats.'
            : error
        }
        actionRightAddon={
          <SaveButton
            formState={form.formState}
            onClick={form.handleSubmit(
              async (values) => {
                await setMember({ variables: { id: id, input: values } })
              },
              (e) => console.log(e)
            )}
            resetAction={() => form.reset()}
          />
        }
        loading={loading}>
        <SlideOutSection name="mem-home">
          <FormProvider {...form}>
            <form style={{ width: '100%' }}>
              <Flex px="6" py="5" mb="2" rounded="lg">
                <ProfilePictureHandler
                  boxSize="220px"
                  src={profilePicture?.url}
                  name={`${primaryAddress?.firstname} ${primaryAddress?.lastname}`}
                  alt={`${primaryAddress?.firstname} ${primaryAddress?.lastname}`}
                  onCroppedImage={onCroppedImage}
                />

                <Flex height="100%" direction="column" justify="end" ml="6">
                  <Heading size="xl">{primaryAddress?.fullname}</Heading>

                  <Box mt="1">
                    <TagRender tags={tags} />
                  </Box>

                  <Wrap mt="5" mr="12" spacing="1">
                    {notes.slice(0, 2).map(({ id, title }) => (
                      <CardListItem
                        key={id}
                        name={title}
                        icon="sticky-note"
                        color="#f0932b"
                        rightContent={<Center pr="3"></Center>}
                      />
                    ))}

                    {paymentMethods.map(({ id, name }) => (
                      <CardListItem
                        key={id}
                        name={name}
                        icon="credit-card-front"
                        color="#fd7272"
                        rightContent={
                          <Center>
                            <Menu h="100%">
                              <MenuButton
                                ml="2"
                                mr="-1.5"
                                rounded="full"
                                variant="ghost"
                                as={Button}
                                iconSpacing="0"
                                rightIcon={
                                  <FontAwesomeIcon
                                    color="#fff"
                                    icon={['fal', 'chevron-down']}
                                  />
                                }></MenuButton>
                              <MenuList>
                                <MenuItem
                                  color="#ff7675"
                                  onClick={() =>
                                    deletePaymentMethod({
                                      variables: { id: id }
                                    })
                                  }>
                                  Radera
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Center>
                        }
                      />
                    ))}

                    {mediaIds.map(({ id, name }) => (
                      <CardListItem
                        key={id}
                        name={name}
                        icon="id-card-alt"
                        color="#D6A2E8"
                        rightContent={
                          <Center>
                            <Menu h="100%">
                              <MenuButton
                                ml="2"
                                mr="-1.5"
                                rounded="full"
                                variant="ghost"
                                as={Button}
                                iconSpacing="0"
                                rightIcon={
                                  <FontAwesomeIcon
                                    color="#fff"
                                    icon={['fal', 'chevron-down']}
                                  />
                                }></MenuButton>
                              <MenuList>
                                <MenuItem
                                  color="#ff7675"
                                  onClick={() =>
                                    deleteMediaId({ variables: { id: id } })
                                  }>
                                  Radera
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Center>
                        }
                      />
                    ))}
                  </Wrap>
                </Flex>
              </Flex>

              <SlideOutColumns columns={3}>
                <Column>
                  <WithHelpi
                    w="100%"
                    title="Medlemmens status"
                    description="Här samlas information om vad medlemen saknar och punkter som måse återgärdas.">
                    <Box
                      w="100%"
                      bg="rgba(255, 255, 255, 0.1)"
                      py="3"
                      px="2"
                      mt="6px"
                      rounded="lg">
                      <Flex>
                        <Flex align="center">
                          {toDoProgress === 100 ? (
                            <FontAwesomeIcon
                              size="2x"
                              color="lightgreen"
                              icon={['fad', 'check-circle']}
                            />
                          ) : (
                            <CircularProgress
                              value={toDoProgress}
                              capIsRound
                              size="75px"
                              color="#1abc9c"
                              thickness="8px">
                              <CircularProgressLabel
                                color="#fff"
                                fontWeight="bold">
                                {toDoProgress}%
                              </CircularProgressLabel>
                            </CircularProgress>
                          )}
                        </Flex>

                        <Box ml="3" flex="2">
                          {toDoProgress === 100 ? (
                            <Flex h="100%" flexDir="column" justify="center">
                              <Heading size="sm">Medlemen är komplett</Heading>
                              <Text fontSize="xs">Allt ser bra ut!</Text>
                            </Flex>
                          ) : (
                            <>
                              <Heading size="sm">Medlemmen saknar:</Heading>
                              <UnorderedList>
                                {toDos?.map((todo) => (
                                  <ListItem key={todo.id} fontSize="sm">
                                    {todo}
                                  </ListItem>
                                ))}
                              </UnorderedList>
                            </>
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  </WithHelpi>

                  <BaseCard
                    withDisclosure
                    disclosureDefaultOpen
                    title="Medlemsuppgifter"
                    icon="id-card"
                    color="#2e86de">
                    <LabelRow
                      label="Förnamn"
                      value={primaryAddress.firstname}
                      color="green"
                    />
                    {primaryAddress.middleName && (
                      <LabelRow
                        label="Efternamn"
                        value={primaryAddress.middleName}
                        color="green"
                      />
                    )}
                    <LabelRow
                      label="Efternamn"
                      value={primaryAddress.lastname}
                      color="green"
                    />
                    <LabelRow
                      label="Personnr"
                      value={primaryAddress.nationalId}
                      color="green"
                    />

                    <Box mb="2"></Box>

                    {primaryAddress.co && (
                      <LabelRow
                        label="C/o"
                        value={primaryAddress.co}
                        color="cyan"
                      />
                    )}

                    <LabelRow
                      label="Adress"
                      value={primaryAddress.street}
                      color="cyan"
                    />

                    {primaryAddress.street2 && (
                      <LabelRow
                        label="Adress 2"
                        value={primaryAddress.street2}
                        color="cyan"
                      />
                    )}
                    <LabelRow
                      label="Postnummer"
                      value={primaryAddress.postcode}
                      color="cyan"
                    />
                    <LabelRow
                      label="Ort"
                      value={primaryAddress.city}
                      color="cyan"
                    />
                    <LabelRow
                      label="Land"
                      value={primaryAddress.country}
                      color="cyan"
                    />

                    <Box mb="2"></Box>

                    <LabelRow
                      label="Mobil"
                      value={primaryAddress.mobileNumber}
                      color="red"
                    />
                    <LabelRow
                      label="Mail"
                      value={primaryAddress.email}
                      color="red"
                    />

                    <Button
                      onClick={PriAdDisclosure.onOpen}
                      size="sm"
                      mt="2"
                      mb="-1">
                      Redigera Medlemsuppgifter
                    </Button>
                  </BaseCard>
                </Column>
                <Column>
                  <BaseCard icon="map-pin" color="#70a1ff" title="Anläggningar">
                    <SelectorInput
                      id="facilities"
                      label="Anläggningar Medlemmen visas på"
                      multi
                      options={data?.facilities?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />

                    <Box my="2">
                      <PinInput id="pin" label="Medlemmens pinkod" />
                    </Box>
                  </BaseCard>

                  <BaseCard
                    withDisclosure
                    disclosureDefaultOpen={invoiceAddress.street}
                    title="Faktureringsuppgifter"
                    icon="file-invoice"
                    color="#95a5a6">
                    <LabelRow
                      label="Förnamn"
                      value={invoiceAddress.firstname}
                      color="green"
                    />
                    {invoiceAddress.middleName && (
                      <LabelRow
                        label="Efternamn"
                        value={invoiceAddress.middleName}
                        color="green"
                      />
                    )}
                    <LabelRow
                      label="Efternamn"
                      value={invoiceAddress.lastname}
                      color="green"
                    />
                    <LabelRow
                      label="Personnr"
                      value={invoiceAddress.nationalId}
                      color="green"
                    />

                    <Box mb="2"></Box>

                    {primaryAddress.co && (
                      <LabelRow
                        label="C/o"
                        value={primaryAddress.co}
                        color="cyan"
                      />
                    )}

                    <LabelRow
                      label="Adress"
                      value={invoiceAddress.street}
                      color="cyan"
                    />

                    {primaryAddress.street2 && (
                      <LabelRow
                        label="Adress 2"
                        value={primaryAddress.street2}
                        color="cyan"
                      />
                    )}

                    <LabelRow
                      label="Postnummer"
                      value={invoiceAddress.postcode}
                      color="cyan"
                    />
                    <LabelRow
                      label="Ort"
                      value={invoiceAddress.city}
                      color="cyan"
                    />
                    <LabelRow
                      label="Land"
                      value={invoiceAddress.country}
                      color="cyan"
                    />

                    <Box mb="2"></Box>

                    <LabelRow
                      label="Mobil"
                      value={invoiceAddress.mobileNumber}
                      color="red"
                    />
                    <LabelRow
                      label="Mail"
                      value={invoiceAddress.email}
                      color="red"
                    />

                    <Button
                      onClick={invoiceAdDisclosure.onOpen}
                      size="sm"
                      mt="2"
                      mb="-1">
                      Redigera Faktureringsuppgifter
                    </Button>
                  </BaseCard>

                  <BaseCard
                    icon="money-bill-wave"
                    color="#fd7272"
                    title="Faktura">
                    <NumberInput
                      id="invoiceExpiry"
                      label="antal förfallodagar"
                    />

                    <RadioInput
                      id="invoiceDelivery"
                      label="Utskrift"
                      defaultValue="email"
                      options={data.printoutTypes.map(({ id, name }) => ({
                        value: id,
                        label: name
                      }))}
                    />

                    <TextInput id="invoiceReference" label="Referens" />

                    <YesNoInput
                      id="invoicePostPay"
                      label="Fakturera i efterhand"
                    />

                    <SwitchInputWithDisclosure
                      label="Personlig kreditgräns"
                      id="hasInvoiceCreditLimit">
                      <NumberInput
                        id="invoiceCreditLimit"
                        label="kreditgräns"
                      />
                    </SwitchInputWithDisclosure>
                  </BaseCard>
                </Column>

                <Column>
                  {/* <BaseCard
                  icon="shopping-bag"
                  color="#01a3a4"
                  title="Varuautomat">
                  <TextInput
                    id="vendingCredit"
                    placeholder="Engångskredit"
                    label="Engångskredit för Varuautomat"
                    rightAddon={'SEK'}
                  />
                  <SwitchInput id="varuautomat" label="Varuautomat BodyShake" />
                </BaseCard> */}

                  <BaseCard
                    withDisclosure
                    disclosureDefaultOpen={shippingAddress.street}
                    title="Leveransuppgifter"
                    icon="parachute-box"
                    color="#95a5a6">
                    <LabelRow
                      label="Förnamn"
                      value={shippingAddress.firstname}
                      color="green"
                    />
                    {shippingAddress.middleName && (
                      <LabelRow
                        label="Efternamn"
                        value={shippingAddress.middleName}
                        color="green"
                      />
                    )}
                    <LabelRow
                      label="Efternamn"
                      value={shippingAddress.lastname}
                      color="green"
                    />
                    <LabelRow
                      label="Personnr"
                      value={shippingAddress.nationalId}
                      color="green"
                    />

                    <Box mb="2"></Box>

                    {primaryAddress.co && (
                      <LabelRow
                        label="C/o"
                        value={primaryAddress.co}
                        color="cyan"
                      />
                    )}

                    <LabelRow
                      label="Adress"
                      value={shippingAddress.street}
                      color="cyan"
                    />

                    {primaryAddress.street2 && (
                      <LabelRow
                        label="Adress 2"
                        value={primaryAddress.street2}
                        color="cyan"
                      />
                    )}

                    <LabelRow
                      label="Postnummer"
                      value={shippingAddress.postcode}
                      color="cyan"
                    />
                    <LabelRow
                      label="Ort"
                      value={shippingAddress.city}
                      icon="city"
                      color="cyan"
                    />
                    <LabelRow
                      label="Land"
                      value={shippingAddress.country}
                      color="cyan"
                    />

                    <Box mb="2"></Box>

                    <LabelRow
                      label="Mobil"
                      value={shippingAddress.mobileNumber}
                      color="red"
                    />
                    <LabelRow
                      label="Mail"
                      value={shippingAddress.email}
                      color="red"
                    />

                    <Button
                      onClick={shippingAdDisclosure.onOpen}
                      size="sm"
                      mt="2"
                      mb="-1">
                      Redigera Leveransuppgifter
                    </Button>
                  </BaseCard>

                  <BaseCard
                    icon="sliders-h"
                    color="#535c68"
                    title="Övriga inställningar">
                    <YesNoInput
                      id="physicalAdmissionDenied"
                      label="Godkänd för insläpp"
                    />
                    <YesNoInput
                      id="onlineAdmissionDenied"
                      label="Godkänd för online"
                    />
                    <YesNoInput
                      id="consentNewsletter"
                      label="samtycke till nyhetsbrev"
                    />
                    <YesNoInput
                      id="useWeightsensor"
                      label="Använd golvplatta"
                    />
                    <YesNoInput
                      id="useFingerprint"
                      label="Använd fingeravtrycksläsare"
                    />
                    <YesNoInput id="specialEntrance" label="Särskild ingång" />
                    {/* <NumberInput
                      id="startValueStatistics"
                      label="Startvärde statistik"
                    /> */}
                  </BaseCard>
                </Column>
              </SlideOutColumns>
            </form>
          </FormProvider>
        </SlideOutSection>

        <SlideOutSection name="mem-memberships">
          <SectionTitle color="#ffbd59">Abonnemang</SectionTitle>

          <Flex flexDir="column" w="80%" mx="auto">
            {memberships.map(({ id, name, tags }) => (
              <EntityRow
                key={id}
                onClick={() => pushPanel('membership', { id: id })}
                color="#ffbd59"
                icon="repeat-alt"
                title={name}>
                <TagRender tags={tags} />
              </EntityRow>
            ))}
          </Flex>

          <Button disabled size="sm" mx="auto" mt="2">
            Se inaktiva abonnemang
          </Button>
        </SlideOutSection>

        <SlideOutSection name="mem-bookings">
          <SectionTitle color={booking.color}>Bokningar</SectionTitle>

          <Flex flexDir="column" w="80%" mx="auto">
            {bookings.map(({ id, name, tags }) => (
              <EntityRow
                key={id}
                onClick={() => pushPanel('booking', { id: id })}
                color={booking.color}
                icon={booking.icon}
                title={name}>
                <TagRender tags={tags} />
              </EntityRow>
            ))}
          </Flex>
        </SlideOutSection>

        <SlideOutSection name="mem-invoices">
          <SectionTitle color="#fd7272">Fakturor & kvitton</SectionTitle>

          <Flex flexDir="column" w="80%" mx="auto">
            {invoices.map(({ id, name, tags }) => (
              <EntityRow
                key={id}
                onClick={() => pushPanel('invoice', { id: id })}
                color={invoice.color}
                icon={invoice.icon}
                title={name}>
                <TagRender tags={tags} />
              </EntityRow>
            ))}

            {receipts.map(({ id, name, tags, url }) => (
              <ReceiptCard
                key={id}
                color={receipt.color}
                icon={receipt.icon}
                title={name}
                url={url}>
                <TagRender tags={tags} />
              </ReceiptCard>
            ))}
          </Flex>
        </SlideOutSection>

        <SlideOutSection name="mem-notes">
          <SectionTitle color="#f0932b">Anteckningar</SectionTitle>
          <SimpleGrid columns={2} spacing={5} alignItems="start" w="100%">
            <Box>
              <Button
                size="md"
                variant="outline"
                w="100%"
                mb="3"
                onClick={() => createNote({ variables: { memberNumber: id } })}>
                Skapa ny anteckning
              </Button>
              {notes && notes[0] && (
                <Note memberNumber={id} id={notes[0].id} onDelete={refetch} />
              )}
            </Box>

            {notes &&
              notes
                .slice(1)
                .map(({ id: noteId }) => (
                  <Note
                    key={noteId}
                    memberNumber={id}
                    id={noteId}
                    onDelete={refetch}
                  />
                ))}
          </SimpleGrid>
        </SlideOutSection>

        <SlideOutSection name="mem-help">
          <SectionTitle color="#e056fd">Hjälp</SectionTitle>
          <Box>
            {/* <LazyLoad once height="100%" scrollContainer="#slideout-inner0"> */}
            <Box width="100%" mt="2">
              <SimpleGrid columns={2} spacing={5} w="100%">
                <Column>
                  <Heading size="sm" textAlign="center" mb="3">
                    Hjälpartiklar
                  </Heading>
                  <PanelHelp tags={['member']} />
                </Column>

                <Column>
                  <Heading size="sm" textAlign="center" mb="3">
                    Interlogg
                  </Heading>
                  <InternalLog />
                  {visitingStatistics && (
                    <Box w="100%" p="2" mt="4" rounded="md" bg="whiteAlpha.100">
                      <ReactMarkdown>{visitingStatistics}</ReactMarkdown>
                    </Box>
                  )}
                </Column>
              </SimpleGrid>
            </Box>
            {/* </LazyLoad> */}
          </Box>
        </SlideOutSection>
      </SlideOutPanel>

      <AddressModal
        title="Ändra Medlemsuppgifter"
        {...PriAdDisclosure}
        defaultValues={primaryAddress}
        onSave={(vals) =>
          setMember({ variables: { id: id, input: { primaryAddress: vals } } })
        }
      />

      <AddressModal
        title="Ändra Faktureringsuppgifter"
        {...invoiceAdDisclosure}
        defaultValues={invoiceAddress}
        onSave={(vals) =>
          setMember({ variables: { id: id, input: { invoiceAddress: vals } } })
        }
      />

      <AddressModal
        title="Ändra Leveransuppgifter"
        {...shippingAdDisclosure}
        defaultValues={shippingAddress}
        onSave={(vals) =>
          setMember({ variables: { id: id, input: { shippingAddress: vals } } })
        }
      />

      <AlertDialog {...checkinDisclosure}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Checka in Medlem
            </AlertDialogHeader>

            <AlertDialogBody>
              Vill du manuellt checka in{' '}
              {primaryAddress?.firstname ? primaryAddress.firstname : 'medlem'}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="ghost" onClick={checkinDisclosure.onClose}>
                nej
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  doCheckIn({ variables: { memberNumber: id } })
                }}
                ml={3}>
                Checka in
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Radera medlem</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text>
              Är du säker på att du vill radera {primaryAddress.fullname}? Du
              kan inte ångra denna återgärd.
            </Text>
            <Text>
              Ange <b>{primaryAddress.firstname}</b> i rutan nedan
            </Text>
            <Input
              onChange={(e) =>
                setCanDelete(
                  e.target.value.toLowerCase() ===
                    primaryAddress.firstname.toLowerCase()
                )
              }
              mt="2"
              mb="1"
              placeholder="Fyll i rutan för att radera"
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseDelete}>
              Avbryt
            </Button>
            <Button
              isDisabled={!canDelete}
              onClick={() => deleteMember({ variables: { id: id } })}
              w="100%"
              type="submit"
              colorScheme="red">
              Radera
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="lg" {...mergeDisclosure}>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...mergeForm}>
            <form
              onSubmit={mergeForm.handleSubmit(({ member }) =>
                doMergeMember({
                  variables: { memberNumber: id, mergeMemberNumber: member[0] }
                })
              )}>
              <ModalHeader>Slå ihop medlem</ModalHeader>
              <ModalCloseButton />

              <ModalBody>
                <Text mt="-2" mb="4">
                  Allt från valt medlem överförs till medlemmen du nu står på.
                  Vald medlem raderas när du klickar slå ihop.
                </Text>

                <MemberInput
                  id="member"
                  label="Medlem att slå ihop med"
                  max={1}
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={mergeDisclosure.onClose}>
                  Avbryt
                </Button>
                <Button
                  w="100%"
                  disabled={!mergeForm.formState.isValid}
                  type="submit"
                  colorScheme="red">
                  Slå ihop
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>

      <CardCollector
        {...cardCollectorDisclosure}
        onSave={cardCollectorOnSave}
      />

      <AutogiroConsent {...autogiroDisclosure} />

      <MessageSender recipients={[{ id: id }]} {...messageSenderDisclosure} />
    </>
  )
}

export default MemberPanel

const AddressModal = ({
  title,
  isOpen,
  onOpen,
  onClose,
  onSave,
  defaultValues
}) => {
  const toast = useToaster()
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddressSchema)
  })

  useEffect(() => {
    const { __typename, createdAt, id, fullname, ...cleanDefaultValues } =
      defaultValues
    form.reset(cleanDefaultValues)
  }, [defaultValues])

  const { invalid } = form.getFieldState('nationalId', form.formState) // It is subscribed now and reactive to error state updated
  const nationalIdWatch = form.watch('nationalId')

  const [getAddressFromNationalId, { loading }] = useLazyQuery(
    GET_ADDRESS_FROM_NATIONALID,
    {
      variables: { nationalId: nationalIdWatch },
      onCompleted: ({ addressFromNationalId }) => {
        Object.entries(addressFromNationalId).forEach(([name, value]) =>
          form.setValue(name, value)
        )
        toast({
          title: `Adress hämtad från folkbokföringen!`,
          description: '',
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
      },
      onError: (e) =>
        DefaultErrorHandler({ title: 'Kunde inte radera Betalmetod.', ...e })
    }
  )

  useEffect(() => {
    if (!invalid) {
      getAddressFromNationalId()
    }
  }, [invalid])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((vals) => {
              onSave(vals)
              onClose()
            })}>
            <ModalBody>
              <AddressForm
                nationalIdButton={
                  <Button
                    size="sm"
                    w="100%"
                    mb="2"
                    mt="-1"
                    isLoading={loading}
                    isDisabled={
                      invalid ||
                      nationalIdWatch === undefined ||
                      nationalIdWatch === ''
                    }
                    onClick={getAddressFromNationalId}
                    leftIcon={
                      <FontAwesomeIcon size="md" icon={['fal', 'sparkles']} />
                    }>
                    Hämta från folkbokföringen
                  </Button>
                }
              />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Avbryt
              </Button>
              <Button
                isDisabled={!form.formState.isValid}
                type="submit"
                colorScheme="blue">
                Spara
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
