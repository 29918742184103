const init = {
  appReady: false,
  isOnline: true,
  screen_size: { width: undefined, height: undefined },
  helpMode: false
}

const appReducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_APP_READY':
      return { ...state, appReady: action.payload }

    case 'SET_IS_ONLINE':
      return { ...state, isOnline: action.payload }

    case 'SET_SCREEN_SIZE':
      return { ...state, screen_size: action.payload }

    case 'SET_HELP_MODE':
      return { ...state, helpMode: action.payload }

    default:
      return state
  }
}

export default appReducer
