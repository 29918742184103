import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 5px 0;
`

const Text = styled.small`
  font-size: 12px;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 2px 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
`

const InlineTitle = ({ center, children }) => {
  return (
    <Wrap>
      <Text center={center}>{children}</Text>
    </Wrap>
  )
}

export default InlineTitle
