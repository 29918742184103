import { combineReducers } from 'redux'

import user from './user'
import app from './app'
import notifications from './notifications'
import panels from './panels'
import bankid from './bankid'
import register from './register'
import services from './services'

const rootReducer = combineReducers({
  user,
  app,
  notifications,
  panels,
  bankid,
  register: register,
  services
})

export default rootReducer
