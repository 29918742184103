import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormContext, useFormState } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  useColorModeValue
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InputEl = styled(Input)`
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  color: ${({ active }) => active && '#333'};
  background: ${({ active }) => (active ? '#eee' : 'rgba(255, 255, 255, 0.1)')};
  ${({ error }) => error && `&:focus{outline: none;}`}
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`

const InputOuter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

const Gradient = styled.div`
  height: 100%;
  width: 40%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: ${({ error, disabled }) =>
    error
      ? `linear-gradient(90deg, transparent, #e74c3c)`
      : disabled
      ? `linear-gradient(90deg, transparent, #718093)`
      : null};
  cursor: ${({ error, disabled }) =>
    error ? 'text' : disabled ? 'not-allowed' : 'inherit'};
  z-index: 2;
  border-radius: 0 4px 4px 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;

  > i {
    font-size: 16px;
    color: #eee;
  }
`

const TextInput = ({
  id,
  type,
  label,
  helper,
  disabled,
  required = false,
  validation,
  validationError,
  as = 'input',
  large = false,
  noSpacer = false,
  leftAddon,
  rightAddon,
  rightElement,
  ...other
}) => {
  const color = useColorModeValue('gray.600', 'white')
  const [hasErrored, setHasErrored] = useState(false)
  const { register } = useFormContext()
  const { errors } = useFormState()
  const [ref, setRef] = useState(null)

  const error = errors[id]?.message

  useEffect(() => {
    if (error) {
      setHasErrored(true)
    }
  }, [error])

  return (
    <InputSpacer ignore={noSpacer}>
      <FormControl id={id} isRequired={required} isInvalid={error}>
        <InputWrapper>
          <FormLabel fontSize="sm" fontWeight="bold" mb="0">
            {label}
          </FormLabel>
          <InputGroup w="100%">
            {leftAddon && (
              <InputLeftAddon borderRight="none" children={leftAddon} />
            )}
            <Input
              w="100%"
              color={color}
              // borderLeft={leftAddon ? '0' : undefined}
              // borderRight={rightAddon ? '0' : undefined}
              id={id}
              name={id}
              type={type}
              {...(id ? register(id) : {})}
              disabled={disabled}
              autoComplete="off"
              {...other}
            />
            {rightAddon && <InputRightAddon children={rightAddon} />}
            {rightElement && (
              <InputRightElement height="100%" children={rightElement} />
            )}
          </InputGroup>
          {error ? (
            <FormErrorMessage mt="0.5" ml="0.5">
              {error}
            </FormErrorMessage>
          ) : (
            helper && (
              <FormHelperText mt="0.5" ml="0.5">
                {helper}
              </FormHelperText>
            )
          )}
        </InputWrapper>
      </FormControl>
    </InputSpacer>
  )
}

export default TextInput
