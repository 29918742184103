import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@chakra-ui/react'

const Wrapper = styled(Box)`
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;

  margin: 0;
  border-radius: 10px;
  transition: 0.3s ease;
  ${({ theme }) => theme.mixin.scaleFix}
  ${({ checked }) => checked && 'opacity: 0.6;'}

  &:hover {
    transform: scale(1.02);
    background: rgba(255, 255, 255, 0.1);
  }
`

const LeftWrap = styled.div`
  height: 45px;
  width: 45px;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
  border-radius: 60px;
  box-shadow: inset 5px 0px 14px 5px rgba(0, 0, 0, 0.2);

  > svg {
    font-size: 20px;
  }
`

const RightWrap = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-left: 15px;
  border-radius: 6px;
  margin-right: auto;
`

const Title = styled.p`
  font-size: 16px;
  font-weight: bolder;
  line-height: 1;
  margin: 0 0 4px 0;
  ${({ checked }) => checked && 'text-decoration: line-through;'}
`

const Description = styled.p`
  opacity: 0.85;
  font-size: 11px;
  line-height: 1;
  font-weight: normal;
  margin: 0;
  ${({ checked }) => checked && 'text-decoration: line-through;'}
`

const ListOption = ({
  title,
  description,
  icon,
  color,
  onClick,
  isDisabled,
  ...rest
}) => (
  <Wrapper
    onClick={() => {
      if (!isDisabled) {
        onClick()
      }
    }}
    {...rest}
    opacity={isDisabled && '0.4'}
    cursor={isDisabled ? 'not-allowed' : 'pointer'}>
    <LeftWrap color={color}>
      <FontAwesomeIcon color="#fff" icon={['fad', icon]} />
    </LeftWrap>
    <RightWrap>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </RightWrap>
  </Wrapper>
)

export default ListOption
