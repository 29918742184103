const init = {
  notifications: [],
  notifications_is_muted: false
}

const notificationsReducer = (state = init, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      const indexOfHook = state.notifications
        .map(({ hook }) => {
          return hook
        })
        .findIndex((hook) => hook === action.payload.hook)
      if (indexOfHook !== -1) {
        return {
          ...state,
          notifications: state.notifications.map((item, index) =>
            index === indexOfHook ? action.payload : item
          )
        }
      }
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      }
    }

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(
          ({ uid }) => uid !== action.payload
        )
      }

    case 'REMOVE_NOTIFICATION_BY_HOOK':
      return {
        ...state,
        notifications: state.notifications.filter(
          ({ hook }) => hook !== action.payload
        )
      }

    default:
      return state
  }
}

export default notificationsReducer
