import React from 'react'

import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  useColorModeValue,
  Tooltip,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import printJS from 'print-js'
import CreatableSelect from 'react-select/creatable'
import SelectorCreatableInput from './form/SelectorCreatableInput'

const transform = (slot) => {
  const { NumberOfPages } = slot

  // Override the `NumberOfPages` slot
  return Object.assign({}, slot, {
    Print: () => null,
    Open: () => null,
    Download: () => null,
    EnterFullScreen: () => null,
    SwitchTheme: () => null,
    NumberOfPages: () => (
      <>
        av <NumberOfPages />
      </>
    )
  })
}

const ShowFile = ({ isOpen, onOpen, onClose, url }) => {
  const pdfTheme = useColorModeValue('light', 'dark')
  const emailDisclosure = useDisclosure()

  const form = useForm()
  const emailsWatch = form.watch('emails')

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut
          } = slots
          return (
            <Flex justify="space-between" w="100%" px="2">
              <div style={{ padding: '0px 2px' }}>
                <ShowSearchPopover />
              </div>
              <Flex pl="2">
                <ZoomOut />
                <Zoom />
                <ZoomIn />
              </Flex>
              <Flex h="100%" justify="center" align="center" ml="auto">
                <GoToPreviousPage />

                <Flex justify="center" align="center" color="#333" mr="1">
                  <CurrentPageInput /> / <NumberOfPages />
                </Flex>
                <GoToNextPage />
              </Flex>
            </Flex>
          )
        }}
      </Toolbar>
    )
  })

  return (
    <>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="transparent" shadow="none" overflow="hidden">
          <Flex h="100%">
            <Box w="100%" rounded="md" overflow="hidden">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={
                    url || process.env.PUBLIC_URL + '/pdf-open-parameters.pdf'
                  }
                  plugins={[defaultLayoutPluginInstance]}
                  theme={pdfTheme}
                />
              </Worker>
            </Box>

            <Box>
              <Flex flexDir="column" ml="2" pos="sticky" top="0">
                <Tooltip placement="right" label="Skriv ut">
                  <Button
                    _hover={{ bg: 'whiteAlpha.400' }}
                    leftIcon={
                      <FontAwesomeIcon size="lg" icon={['fad', 'print']} />
                    }
                    iconSpacing={0}
                    bg="blackAlpha.400"
                    color="#fff"
                    border="1px solid"
                    borderColor="whiteAlpha.500"
                    shadow="2xl"
                    py="12"
                    mb="2"
                    onClick={() =>
                      printJS({
                        printable:
                          url ||
                          process.env.PUBLIC_URL + '/pdf-open-parameters.pdf',
                        type: 'pdf'
                      })
                    }
                  />
                </Tooltip>

                <Tooltip placement="right" label="Mejla dokument">
                  <Button
                    _hover={{ bg: 'whiteAlpha.400' }}
                    leftIcon={
                      <FontAwesomeIcon
                        size="lg"
                        icon={['fad', 'envelope-open-text']}
                      />
                    }
                    iconSpacing={0}
                    bg="blackAlpha.400"
                    color="#fff"
                    border="1px solid"
                    borderColor="whiteAlpha.500"
                    shadow="2xl"
                    py="7"
                    mb="2"
                    onClick={emailDisclosure.onOpen}
                  />
                </Tooltip>

                <Tooltip placement="right" label="Ladda ner">
                  <Button
                    _hover={{ bg: 'whiteAlpha.400' }}
                    leftIcon={
                      <FontAwesomeIcon size="lg" icon={['fad', 'download']} />
                    }
                    iconSpacing={0}
                    bg="blackAlpha.400"
                    color="#fff"
                    border="1px solid"
                    borderColor="whiteAlpha.500"
                    shadow="2xl"
                    py="7"
                    as="a"
                    download
                    href={
                      url || process.env.PUBLIC_URL + '/pdf-open-parameters.pdf'
                    }
                  />
                </Tooltip>
              </Flex>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={emailDisclosure.isOpen}
        onClose={() => {
          form.reset()
          emailDisclosure.onClose()
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mejla dokument</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column">
              <Text mb="2">
                Välj en eller flera mottagare att mejla dokumentet till.
              </Text>
              <FormProvider {...form}>
                <form
                  style={{ width: '100%' }}
                  onSubmit={form.handleSubmit((value) => {})}>
                  <SelectorCreatableInput id="emails" isClearable isMulti />
                </form>
              </FormProvider>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                form.reset()
                emailDisclosure.onClose()
              }}>
              avbryt
            </Button>
            <Button colorScheme="whiteAlpha" color="white">
              Skicka mejl
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShowFile
