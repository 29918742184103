import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import MembershipTable from 'containers/tables/MembershipTable'

import { membership } from 'constants/entities'
import PageHeader from 'components/PageHeader'
import ErrorBoundary from 'components/ErrorBoundary'

const Memberships = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Abonnemang | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <PageHeader image="https://images.pexels.com/photos/5383554/pexels-photo-5383554.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

          <Boxed>
            <PageTitle
              icon={membership.icon_multi}
              title="Abonnemang"
              subTitle="Hantera alla Abonnemang inom din organisation."
            />

            <MembershipTable />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Memberships
