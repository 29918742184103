import React from 'react'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const NumberInput = ({ id, label, helper, required, disabled, ...other }) => {
  const { control } = useFormContext()
  const { errors } = useFormState()

  return (
    <InputSpacer>
      <FormControl id={id}>
        <Controller
          control={control}
          name={id}
          render={({ field: { ref, onChange, value, ...restField } }) => (
            <Flex w="100%" flexDir="column">
              <Box>
                {label && (
                  <FormLabel fontSize="sm" fontWeight="bold" mb="0">
                    {label}
                  </FormLabel>
                )}
                <FormErrorMessage></FormErrorMessage>
              </Box>

              <Center>
                <ChakraNumberInput
                  w="100%"
                  {...restField}
                  {...other}
                  value={value || ''}
                  onChange={(valueAsString, valueAsNumber) =>
                    onChange(valueAsNumber)
                  }>
                  <NumberInputField ref={ref} name={restField.name} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </ChakraNumberInput>
              </Center>
            </Flex>
          )}
        />
      </FormControl>
    </InputSpacer>
  )
}

export default NumberInput
