import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Box,
  Flex,
  Center,
  Heading,
  Text,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton
} from '@chakra-ui/react'

import Loader from 'components/Loader'
import BaseCard from 'components/cards/BaseCard'

const Wrapper = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0 2px 0;
`

const ListWrap = styled.a`
  width: 100%;
  height: 42px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 3px 0;

  border: 2px solid ${({ color }) => color};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.secondary_body};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    transform: scale(1.018);
    box-shadow: none;
  }
`

const ItemContent = styled.div`
  height: 100%;
  margin: 5px auto 0 12px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${({ theme }) => theme.mixin.scaleFix}

  > span {
    color: ${({ theme }) => theme.primary_text};
    font-size: 14px;
    font-weight: normal;
  }

  > small {
    color: ${({ theme }) => theme.secondary_text};
    font-size: 11px;
    margin-top: -3px;
  }
`

const ItemIcon = styled.div`
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ color }) => color};
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.12);

  > svg {
    color: #fff;
    font-size: 21px;
  }
`

const Empty = styled.p`
  width: 100%;
  height: 42px;
  margin: 3px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.divider};
  border-radius: 4px;
`

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  box-shadow: inset 10px 10px 70px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`

const ListCard = ({
  children,
  onClick,
  color,
  icon,
  title,
  items,
  emptyText,
  loading,
  withSwitch
}) => {
  return (
    <BaseCard
      title={title}
      icon={icon}
      onClick={onClick}
      color={color}
      withSwitch={withSwitch}>
      {loading && (
        <LoaderOverlay color={color}>
          <Loader />
        </LoaderOverlay>
      )}
      <Wrapper>
        {!items.length && <Empty>{emptyText}</Empty>}
        {!loading &&
          items.map(({ name, color, icon, meta }) => (
            <ListItem name={name} icon={icon} color={color} key={name} />
          ))}
      </Wrapper>
      {!loading && children}
    </BaseCard>
  )
}

export const ListItem = ({ name, icon, color, rightContent, ...rest }) => {
  const themeContext = useContext(ThemeContext)

  return (
    <Flex
      bg={themeContext.gradients.vivid(color)}
      rounded="lg"
      px="3"
      py="1"
      pr="3"
      mb="1"
      minH="50px"
      align="center"
      boxShadow="lg"
      border="2px"
      borderColor="rgba(0, 0, 0, 0.2)"
      {...rest}>
      <Flex>
        <Center w="30px" pl="1" pr="1">
          <FontAwesomeIcon size="lg" color="#fff" icon={['fad', icon]} />
        </Center>
        <Flex flex="1" ml="2" mb="0.5" flexDir="column" justifyContent="center">
          <Heading size="xs" color="#fff">
            {name}
          </Heading>
          <Flex>
            <Text as="small" fontSize="10px" color="#fff" fontWeight="bold">
              11/03/22
            </Text>
          </Flex>
        </Flex>
        {rightContent}
      </Flex>
    </Flex>
  )
}

export default ListCard
