import React, { useContext } from 'react'
import { Box } from '@chakra-ui/react'

import { SpacingContext } from 'utils/spacingContext'

const InputSpacer = ({ children, ignore = false }) => {
  const spacing = useContext(SpacingContext)
  return <Box mb={`${!ignore ? spacing : 0}px`}>{children}</Box>
}

export default InputSpacer
