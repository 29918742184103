import React, { useEffect, useState } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const TextareaInput = ({
  id,
  type,
  label,
  helper,
  disabled,
  required = false,
  noSpacer = false,
  ...other
}) => {
  const [hasErrored, setHasErrored] = useState(false)
  const { register } = useFormContext()
  const { errors } = useFormState()

  const error = errors[id]?.message

  useEffect(() => {
    if (error) {
      setHasErrored(true)
    }
  }, [error])

  return (
    <InputSpacer ignore={noSpacer}>
      <FormControl id={id} isRequired={required}>
        <FormLabel fontSize="sm" fontWeight="bold" mb="0">
          {label}
        </FormLabel>

        <Textarea
          id={id}
          name={id}
          {...register(id)}
          disabled={disabled}
          autoComplete="off"
          {...other}
        />

        {(error || (!hasErrored && helper)) && (
          <FormHelperText>{error || helper}</FormHelperText>
        )}
      </FormControl>
    </InputSpacer>
  )
}

export default TextareaInput
