import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Boxed, Content, TopPadder } from 'style/wrappers'
import {
  Box,
  Flex,
  Fade,
  Center,
  SimpleGrid,
  Heading,
  Text,
  Container,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import bkgimg from 'style/images/login.jpg'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import MemberTable from 'containers/tables/MemberTable'
import PageHeader from 'components/PageHeader'
import InvoiceTable from 'containers/tables/InvoiceTable'
import ErrorBoundary from 'components/ErrorBoundary'

const Invoices = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Fakturor | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content pos="relative">
          <PageHeader image="https://images.pexels.com/photos/6964138/pexels-photo-6964138.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="file-invoice"
              title="Faktura"
              subTitle="Hantera fakturor inom din organisation."
            />

            <InvoiceTable />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Invoices
