import {
  Divider,
  Stack,
  Text,
  useBreakpointValue,
  Circle,
  Icon
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

export const WizardStep = (props) => {
  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    description,
    ...stackProps
  } = props
  const isMobile = useBreakpointValue({
    base: true,
    md: false
  })
  const orientation = useBreakpointValue({
    base: 'vertical',
    md: 'horizontal'
  })
  return (
    <Stack
      spacing="4"
      direction={{
        base: 'row',
        md: 'column'
      }}
      flex="1"
      {...stackProps}>
      <Stack
        spacing="0"
        align="center"
        direction={{
          base: 'column',
          md: 'row'
        }}>
        <Divider
          display={isMobile ? 'none' : 'initial'}
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isFirstStep
              ? 'transparent'
              : isCompleted || isActive
              ? 'accent'
              : 'inherit'
          }
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'
          }
        />
      </Stack>
      <Stack
        spacing="0.5"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{
          base: 'start',
          md: 'center'
        }}>
        <Text fontSize="sm" color="emphasized" fontWeight="medium">
          {title}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
    </Stack>
  )
}

export default WizardStep

export const StepCircle = (props) => {
  const { isCompleted, isActive } = props
  return (
    <Circle
      size="8"
      bg={isCompleted ? 'accent' : 'inherit'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'accent' : 'inherit'}
      {...props}>
      {isCompleted ? (
        <Icon
          as={() => (
            <FontAwesomeIcon size="2x" icon={['fad', 'check-circle']} />
          )}
          color="inverted"
          boxSize="5"
        />
      ) : (
        <Circle bg={isActive ? 'accent' : 'border'} size="3" />
      )}
    </Circle>
  )
}
