import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Flex } from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'

const CustomErrorBoundary = ({ children }) => {
  const toast = useToaster()

  return (
    <ErrorBoundary
      fallback={<Flex></Flex>}
      onError={(error) =>
        toast({
          title: 'Ett fel uppstod!',
          description:
            'Felet har rapporterats. Vänligen försök igen senare eller kontakta support. ' +
            error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }>
      {children}
    </ErrorBoundary>
  )
}

export default CustomErrorBoundary
