import React, { useMemo, useCallback } from 'react'

import FreshTable from 'containers/FreshTable'
import ProductSelectedRender from 'containers/tables/ProductSelectedRender'
import { product } from 'constants/entities'

const ProductPicker = ({ data, getData, loading, onDone, max }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 50
      },
      {
        Header: 'Art.nr',
        accessor: 'artnr'
      },
      {
        Header: 'typ',
        accessor: 'type'
      },
      {
        Header: 'Rubrik',
        accessor: 'name'
      },
      {
        Header: 'Pris inkl moms',
        accessor: 'price'
      },
      {
        Header: 'Pris exkl moms',
        accessor: 'priceWithoutVAT'
      }
    ],
    []
  )

  const onChange = useCallback((val) => {
    getData({ variables: val })
  }, [])

  return (
    <FreshTable
      title="Alla Produkter"
      color={product.color}
      icon={product.icon}
      loading={loading}
      columns={columns}
      data={data?.products ? data.products.products : []}
      pageCount={data?.products.pageCount}
      filteredCount={data?.products.filteredCount}
      totalCount={data?.products.totalCount}
      limitedMode={true}
      SelectedRender={ProductSelectedRender}
      selectionMode={true}
      requiredAmountSelected={max || 20}
      doneOnRequiredAmountSelected
      fullScreenOnly={true}
      onChange={onChange}
      onDone={onDone}
    />
  )
}

export default ProductPicker
