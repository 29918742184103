import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Box,
  Flex,
  Spacer,
  VStack,
  Tooltip,
  Skeleton,
  useColorModeValue,
  useOutsideClick,
  Button
} from '@chakra-ui/react'

import { setMainFacility, setSelectedFacilities } from 'actions/user'

import FacilitySelector from 'components/navigation/FacilitySelector'
import QuickOptions from 'components/navigation/QuickOptions'

const PrimaryNavigation = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const bg = useColorModeValue('gray.200', 'gray.800')
  const borderColor = useColorModeValue('gray.300', 'gray.700')
  const registerOpen = useSelector((state) => state.register.registerOpen)
  const shouldReauthenticate = useSelector(
    (state) => state.user.shouldReauthenticate
  )

  const permissions = useSelector((state) => state.user.user.permissions)
  const selectedMainFacility = useSelector(
    (state) => state.user.user.selectedMainFacility
  )
  const selectedFacilities = useSelector(
    (state) => state.user.user.selectedFacilities
  )

  const [bookingSubmeny, setBookingSubmeny] = useState(false)
  const [economySubmeny, setEconomySubmeny] = useState(false)

  const bookingRef = useRef()
  useOutsideClick({
    ref: bookingRef,
    handler: () => setBookingSubmeny(false)
  })

  const economyRef = useRef()
  useOutsideClick({
    ref: economyRef,
    handler: () => setEconomySubmeny(false)
  })

  const hasAccess = (permission) =>
    permission === 'WRITE' || permission === 'READ'

  const isActiveRoute = (route) => route === location.pathname

  return !shouldReauthenticate && !registerOpen ? (
    <Box zIndex="popover">
      <Box
        h="100vh"
        w="75px"
        pos="sticky"
        top="0"
        bottom="0"
        left="0"
        boxShadow="xl"
        borderRight="2px"
        bg={bg}
        borderColor={borderColor}>
        <Flex h="100%" maxH="100%" direction="column">
          <VStack>
            {selectedMainFacility ? (
              <FacilitySelector
                selectedMainFacility={selectedMainFacility}
                selectedFacilities={selectedFacilities}
                setMainFacility={(val) => dispatch(setMainFacility(val))}
                setSelectedFacilities={(val) =>
                  dispatch(setSelectedFacilities(val))
                }
              />
            ) : (
              <Skeleton w="55px" height="120px" m="5px" rounded="md" />
            )}
          </VStack>
          <VStack mt="1" spacing={1.5}>
            <Square
              name="Start"
              color="#74b9ff"
              icon="home"
              path="/dashboard/"
            />

            {hasAccess(permissions.members) && (
              <Square
                name="Medlemmar"
                color="#1abc9c"
                icon="users"
                path="/members/"
              />
            )}

            {hasAccess(permissions.memberships) && (
              <Square
                name="Abonnemang"
                color="#ffbd59"
                icon="repeat-alt"
                path="/memberships/"
              />
            )}

            {hasAccess(permissions.bookings) && (
              <>
                <Square
                  name="Kalender"
                  color="#fea47f"
                  icon="calendar"
                  path="/booking/"
                />
                <Square
                  name="Arrangemang"
                  color="#70a1ff"
                  icon="flag-checkered"
                  path="/booking/courses/"
                />
              </>
            )}

            {hasAccess(permissions.finance) && (
              <Square
                name="Ekonomi"
                color="#fd7272"
                icon="wallet"
                active={
                  isActiveRoute('/economy/invoices/') ||
                  isActiveRoute('/economy/receipts/') ||
                  isActiveRoute('/economy/z-report/')
                }
                onClick={() => setEconomySubmeny(!economySubmeny)}>
                {economySubmeny && (
                  <VStack
                    ref={economyRef}
                    pos="absolute"
                    top="0"
                    left="130%"
                    spacing={1.5}>
                    <Square
                      name="Fakturor"
                      color="#fd7272"
                      icon="file-invoice"
                      path="/economy/invoices/"
                      tooltipAlwaysOpen={true}
                    />
                    <Square
                      name="Kvitton"
                      color="#fd7272"
                      icon="receipt"
                      path="/economy/receipts/"
                      tooltipAlwaysOpen={true}
                    />
                    <Square
                      name="Dagsrapporter"
                      color="#fd7272"
                      icon="file-chart-pie"
                      path="/economy/z-report/"
                      tooltipAlwaysOpen={true}
                    />
                  </VStack>
                )}
              </Square>
            )}

            {hasAccess(permissions.reports) && (
              <Square
                name="Rapporter"
                color="#fd79a8"
                icon="chart-pie"
                path="/reports/"
              />
            )}

            {/* <Square
              name="Kundrelationer"
              color="#00cec9"
              icon="comments-alt-dollar"
              path="/crm/"
            /> */}
            <Square
              name="Inställningar"
              color="#b2bec3"
              icon="cogs"
              path="/settings/"
            />
          </VStack>

          <Spacer />

          <QuickOptions />
        </Flex>
      </Box>
    </Box>
  ) : null
}

export default PrimaryNavigation

const Square = ({
  name,
  icon,
  color,
  path,
  onClick = () => null,
  active = false,
  children,
  tooltipAlwaysOpen
}) => {
  return (
    <Box pos="relative">
      <Tooltip
        isOpen={tooltipAlwaysOpen}
        key={name}
        placement="right"
        label={name}
        aria-label={`tooltip för ${name}`}>
        {path ? (
          <Button
            h={'45px'}
            w={'55px'}
            justify="center"
            align="center"
            borderWidth="1.5px"
            borderColor={color}
            borderRadius="lg"
            fontSize="xl"
            transition={'0.2s'}
            _hover={{
              transform: 'scale(1.1)',
              bg: transparentize(0.75, color)
            }}
            as={NavLink}
            to={path}
            activeStyle={{
              backgroundColor: transparentize(0.4, color),
              color: '#fff'
            }}
            bg={active ? transparentize(0.4, color) : 'gray.800'}
            color={active ? '#fff' : color}>
            <FontAwesomeIcon icon={['fad', icon]} />
          </Button>
        ) : (
          <Button
            h={'45px'}
            w={'55px'}
            justify="center"
            align="center"
            borderWidth="1.5px"
            borderColor={color}
            borderRadius="lg"
            fontSize="xl"
            transition={'0.2s'}
            _hover={{
              transform: 'scale(1.1)',
              bg: transparentize(0.75, color)
            }}
            onClick={onClick}
            bg={active ? transparentize(0.4, color) : 'gray.800'}
            color={active ? '#fff' : color}>
            <FontAwesomeIcon icon={['fad', icon]} />
          </Button>
        )}
      </Tooltip>
      {children}
    </Box>
  )
}
