import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 0;
  color: #ffffff;

  margin: 8px 0;
  background: ${({ color, theme }) => theme.gradients.vivid(color)};
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
  ${({ theme }) => theme.mixin.scaleFix}

  &:hover {
    transform: scale(1.01);
  }
`

const OptionIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 45px;
  margin: 0 15px;
`

const LeftWrap = styled.div`
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RightWrap = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 5px 0px 14px 5px rgba(0, 0, 0, 0.1);
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const Title = styled.p`
  font-size: 24px;
  font-weight: bolder;
  line-height: 1;
  margin: 0 0 4px 0;
`

const Description = styled.p`
  opacity: 0.85;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  margin: 0;
`

const BigOption = ({
  title,
  description,
  color,
  icon,
  onClick,
  rightContent
}) => {
  return (
    <Wrapper onClick={onClick} color={color}>
      <LeftWrap>
        <OptionIcon icon={Array.isArray(icon) ? icon : ['fad', icon]} />
      </LeftWrap>
      <RightWrap>
        <InfoBlock>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </InfoBlock>

        {rightContent}
      </RightWrap>
    </Wrapper>
  )
}

export default BigOption
