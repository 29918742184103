import { Flex, Tag, TagLabel, Tooltip, useClipboard } from '@chakra-ui/react'

const LabelRow = ({ label, value, icon, color }) => {
  const { hasCopied, onCopy } = useClipboard(value)

  return (
    <Flex mb="1" justify="space-between">
      <Tag size="md" variant="subtle" colorScheme={color}>
        <TagLabel fontWeight="bold">{label}</TagLabel>
      </Tag>

      <Tooltip
        placement="top"
        closeDelay="50"
        label={hasCopied ? `${label} kopierad!` : `Kopiera ${label}`}>
        <Tag
          cursor="pointer"
          onClick={onCopy}
          variant="solid"
          size="md"
          variant="subtle">
          <TagLabel>{value}</TagLabel>
        </Tag>
      </Tooltip>
    </Flex>
  )
}

export default LabelRow
