import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Element } from 'react-scroll'

const Section = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5%;
`

const SlideOutSection = ({ name, children }) => {
  return (
    <Section name={name} className="element">
      {children}
    </Section>
  )
}

export default SlideOutSection
