import { darken, lighten, complement, saturate, desaturate } from 'polished'
import * as entities from 'constants/entities'
import * as moods from 'constants/moods'

const theme = {
  fonts: {
    heading: 'Roboto',
    text: 'Roboto'
  },
  viewport: {
    phone: 'max-width: 425px',
    tablet: 'max-width: 768px',
    desktop: 'max-width: 1200px'
  },
  mood: moods,
  entities: entities,
  colors: Object.keys({ ...entities }).reduce(
    (acc, key) => ({ ...acc, [key]: entities[key].color }),
    {}
  ),
  mixin: {
    scaleFix:
      'transform: perspective(1px) translateZ(0);backface-visibility: hidden;-webkit-font-smoothing: subpixel-antialiased;'
  },
  gradients: {
    subtle: (color) =>
      `linear-gradient(to left, ${darken(0.05, color)}, ${color}, ${darken(
        0.05,
        color
      )})`,
    vivid: (color) =>
      `linear-gradient(to right, ${color}, ${desaturate(
        0.2,
        color
      )}, ${saturate(0.4, color)})`
  }
}

export default theme
