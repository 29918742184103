import React, { useState, useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Center,
  Heading,
  Text,
  Button,
  ButtonGroup,
  useDisclosure,
  Avatar,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag
} from '@chakra-ui/react'

import SubtleCard from 'components/cards/SubtleCard'

const ProductSelectedRender = ({
  selected,
  setSelected,
  requiredAmountSelected
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {selected.length > 0 && (
        <Flex h="55px" mr="4" align="center">
          <Flex flexDir="column" cursor="pointer" onClick={onOpen}>
            {/* <Text fontSize="xs" as="small" fontWeight="bold" textAlign="center">
              Ditt Urval
            </Text> */}

            <Flex
              h="45px"
              p="1"
              pl="2"
              align="center"
              rounded="full"
              bg="blackAlpha.800"
              _hover={{ transform: 'scale(1.02)' }}
              transition="0.3s"
              boxShadow="2xl"
              title="Meny">
              <Flex>
                {selected.slice(0, 5).map(({ id, name }) => (
                  <Avatar size="sm" name={name} mr="-2" />
                ))}
                {selected.length > 5 && (
                  <Center
                    ml="3"
                    mr="-2"
                    boxSize="32px"
                    bg="whiteAlpha.400"
                    rounded="full"
                    fontWeight="bold"
                    fontSize="xs">
                    +{selected.length - 5}
                  </Center>
                )}

                {requiredAmountSelected && (
                  <Center
                    ml="3"
                    mr="-2"
                    h="32px"
                    px="2"
                    bg="whiteAlpha.400"
                    rounded="full"
                    fontWeight="bold"
                    fontSize="xs">
                    {selected.length}/{requiredAmountSelected}
                  </Center>
                )}
              </Flex>

              <Center ml="4" mr="2">
                <FontAwesomeIcon
                  size="xl"
                  color="#fff"
                  icon={[
                    'fad',
                    isOpen ? 'circle-chevron-down' : 'circle-chevron-up'
                  ]}
                />
              </Center>
            </Flex>
          </Flex>
        </Flex>
      )}

      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {selected.length}{' '}
            {selected.length === 1 ? 'Vald medlem' : 'Valda medlemmar'}
          </DrawerHeader>
          <DrawerBody>
            <Flex flexDir="column">
              {selected.map(({ id, name }) => (
                <SubtleCard mb="1">
                  <Flex align="center">
                    <Avatar size="sm" name={name} />
                    <Flex flexDir="column" ml="4" mr="2">
                      <Text fontWeight="bold">{name}</Text>
                      <Flex>
                        <Tag size="sm" mr="1" fontWeight="bold">
                          #{id}
                        </Tag>
                      </Flex>
                    </Flex>

                    <IconButton
                      colorScheme="red"
                      variant="ghost"
                      ml="auto"
                      onClick={() =>
                        setSelected(
                          selected.filter((selected) => selected.id !== id)
                        )
                      }
                      icon={
                        <FontAwesomeIcon
                          size="lg"
                          icon={['fad', 'circle-xmark']}
                        />
                      }
                    />
                  </Flex>
                </SubtleCard>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ProductSelectedRender
