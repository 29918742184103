import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import EntityCard from 'components/cards/EntityCard'
import ShowFile from 'components/ShowFile'

const ReceiptCard = ({ url, ...other }) => {
  const disclosure = useDisclosure()

  return (
    <>
      <EntityCard onClick={disclosure.onOpen} {...other}></EntityCard>
      <ShowFile {...disclosure} url={url} />
    </>
  )
}

export default ReceiptCard
