import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Container,
  Flex,
  Button,
  Spacer,
  Heading,
  Text,
  LightMode
} from '@chakra-ui/react'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'

const SET_ME = gql`
  mutation setMe($firstname: String, $lastname: String, $email: String) {
    setMe(
      input: { firstname: $firstname, lastname: $lastname, email: $email }
    ) {
      success
    }
  }
`

const validationSchema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),

  email: yup.string().email().required(),
  policy: yup
    .bool()
    .oneOf([true], 'Du måste godkänna Novasecurs villkor och integritetspolicy')
})

const LoginForm = () => {
  const history = useHistory()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: { policy: false }
  })

  const [setMe, { loading: mutationLoading, data, error: mutationError }] =
    useMutation(SET_ME, {
      onCompleted: (data) => {
        if (data.setMe.success === true) {
          window.location.href = '/'
        }
      }
    })

  return (
    <>
      <Box mb="6">
        <Heading textAlign="center">Välkommen!</Heading>
        <Text textAlign="center" px="10%">
          Vi skulle behöva lite mer information innan du sätter igång med
          Novasecur!
        </Text>
      </Box>
      <LightMode>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((input) =>
              setMe({ variables: input })
            )}>
            <Flex
              flexDir="column"
              bg="#fff"
              color="grey"
              px="4"
              py="4"
              mx="auto"
              rounded="lg"
              boxShadow="xl">
              <TextInput
                id="firstname"
                label="Förnamn"
                helper="Ange ditt förnamn"
                bg="#eee"
                color="#333"
              />

              <TextInput
                id="lastname"
                label="Efternamn"
                helper="Ange ditt efternamn"
                bg="#eee"
                color="#333"
              />

              <TextInput
                id="email"
                label="E-postadress"
                helper="Används för viktiga meddelanden från Novasecur"
                bg="#eee"
                color="#333"
              />

              <Box color="grey" mt="4">
                <SwitchInput
                  id="policy"
                  label="Jag har läst och godkänner Novasecur's villkor och integritetspolicy"
                />
              </Box>
            </Flex>

            <Flex justify="center" mt="6">
              <Button variant="ghost" onClick={() => history.push('/login')}>
                Tillbaka
              </Button>

              <Spacer />

              <Button
                type="submit"
                colorScheme="teal"
                isLoading={mutationLoading}
                isDisabled={!form.formState.isValid}>
                Kom igång!
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </LightMode>
    </>
  )
}

export default LoginForm
