import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { lighten, darken } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsVisible } from 'react-is-visible'
import { Element, scroller } from 'react-scroll'
import { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import {
  Box,
  Flex,
  Spacer,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ButtonGroup,
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  Input,
  Skeleton,
  LightMode,
  Button,
  Spinner,
  Tag,
  TagLabel,
  Avatar,
  Tooltip,
  Select,
  Collapse,
  useColorModeValue,
  toast,
  SlideFade
} from '@chakra-ui/react'

import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'

import WithHelpi from 'components/utils/WithHelpi'
import FreshTableFilter from 'containers/FreshTableFilter'
import FreshTableDownload from 'containers/FreshTableDownload'
import { useToaster } from 'utils/toaster'

const BoxOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ color, gradientColor, theme }) =>
    gradientColor ? theme.gradients.subtle(gradientColor) : color};
  border-radius: 12px;
  padding: 5px 5px;
  margin: 0 auto;
  transition: 0.3s;
`

const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: #fff;

  > div > span {
    font-size: 10px;
    font-weight: bolder;
    text-transform: uppercase;
    opacity: 0.85;
  }

  p {
    font-size: 20px;
    font-weight: bolder;
    margin: 0;
    margin-top: -7px;
  }

  & > svg:first-child {
    font-size: 30px;
    margin-right: 10px;
  }
`

const BoxHeaderLeft = styled.div`
  margin-right: auto;
  margin-top: -2px;
`

const BoxInner = styled(Box)`
  width: 100%;
  min-height: 400px;
  border-radius: 8px;
  padding: 0px;
  overflow: hidden;
`

const HeaderItem = styled.div`
  font-size: 13px;
  font-weight: bolder;
  color: #fff;
  border-radius: 8px;
  // padding: 15px 0 6px 5px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`

const BodyItem = styled(Box)`
  width: 100%;
  padding: 15px 0;
  transition: 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  font-size: 13px;
  &:last-child {
    border-bottom: 0;
  }
  /* .td {
    padding: 0 5px;
  } */
  * {
    cursor: pointer;
  }
`

const rowCountOptions = [25, 50, 100, 200, 300, 500, 1000, 2000, 5000]

const InnerTable = ({
  data = [],
  columns,
  pageCount: remotePageCount,
  filteredCount,
  totalCount,
  availableFilters,
  activeFilters,
  downloadTypes,
  loading = true,
  title,
  subTitle = 'tabell med',
  color,
  icon,
  inModal,
  limitedMode = false,
  selectionMode = false,
  onChange = () => null,
  onRowClick = () => null,
  onDone = () => null,
  SelectedRender = () => null,
  onSelected = () => null,
  requiredAmountSelected = null,
  doneOnRequiredAmountSelected,
  openFullscreen = null,
  closeFullscreen = null
}) => {
  const toast = useToaster()
  const [tableUID] = useState(uuidv4())
  const footerRef = useRef()
  const footerIsVisible = useIsVisible(footerRef)
  const [searchString, setSearchString] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)
  const [filters, setFilters] = useState([])

  const [selected, setSelected] = useState([])
  const [temp, setTemp] = useState([])

  const [DownloadOpen, setDownloadOpen] = useState(false)
  const [selectedPreview, setSelectedPreview] = useState(selectionMode)

  const rowBgColor = useColorModeValue('gray.200', 'gray.600')

  useEffect(() => {
    onSelected(selected)
  }, [selected])

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 100, // width is used for both the flex-basis and flex-grow
      maxWidth: 450 // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      autoResetSortBy: false,
      autoResetPage: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount: remotePageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 25
      },
      getRowId: (row) => row.id
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Column for selection
        // {
        //   id: 'selection',
        //   disableResizing: true,
        //   minWidth: 0,
        //   width: 0,
        //   maxWidth: 0
        // Header: () => {
        //   const indeterminate = selected.some((sel) =>
        //     page.map((val) => val.id).includes(sel.id)
        //   )

        //   const isAllRowsSelected = selected.filter((sel) =>
        //     page.map((val) => val.id).includes(sel.id)
        //   )

        //   return (
        //     <Box
        //       onClick={() => {
        //         if (indeterminate || isAllRowsSelected) {
        //           // setSelected(
        //           //   selected.filter((sel) =>
        //           //     page.map((val) => val.id).includes(sel.id)
        //           //   )
        //           // )
        //         }
        //       }}>
        //       <FontAwesomeIcon
        //         size="lg"
        //         icon={[
        //           'fad',
        //           isAllRowsSelected
        //             ? 'check-circle'
        //             : indeterminate
        //             ? 'minus-circle'
        //             : 'circle'
        //         ]}
        //         swapOpacity
        //       />
        //     </Box>
        //   )
        // }
        // Cell: ({ row }) => {
        //   const rowSelected = selected
        //     .map((i) => i.id)
        //     .includes(row.original.id)

        //   console.log('rowSelected', rowSelected)

        //   return (
        //     <Box
        //       opacity={rowSelected ? '1' : '0.4'}
        //       _hover={{ opacity: 1 }}
        //       onClick={(e) => {
        //         e.stopPropagation()

        //         console.log(row.original)
        //       }}>
        //       <FontAwesomeIcon
        //         size="lg"
        //         icon={[
        //           rowSelected ? 'fad' : 'fad',
        //           rowSelected ? 'check-circle' : 'circle'
        //         ]}
        //         swapOpacity
        //       />
        //     </Box>
        //   )
        // }
        // },
        ...columns
      ])
    }
  )

  // useEffect(() => {
  //   const tableSelected = selectedFlatRows.map((d) => d.original)

  //   const selectedIds = selected.map((i) => i.id)

  //   if (tableSelected.filter((i) => !selectedIds.includes(i.id)).length) {
  //     setSelected([
  //       ...selected,
  //       ...tableSelected.filter((ts) => !selected.find((s) => s.id === ts.id))
  //     ])
  //   }
  // }, [selectedFlatRows])

  // console.log('selectedFlatRows: ', selectedFlatRows)
  // console.log('selected: ', selected)

  // useEffect(() => {
  //   if (data.length) {
  //     selected.map((sel) => {
  //       const index = data.findIndex((row) => row.id === sel.id)
  //       if (index >= 0) {
  //         //toggleRowSelected(index, true)
  //       }
  //     })
  //   }
  // }, [data])

  useEffect(() => {
    if (doneOnRequiredAmountSelected && requiredAmountSelected) {
      if (selected.length >= requiredAmountSelected) {
        onDone(selected)
      }
    }
  }, [selected])

  const hasChanged = useCallback(
    debounce((values) => {
      onChange(values)
    }, 300),
    []
  )

  useEffect(() => {
    hasChanged({
      pageIndex,
      pageSize,
      search: searchString,
      filters: filters,
      sortBy: sortBy
    })
  }, [pageIndex, pageSize, searchString, filters, sortBy])

  const selectionToast = useRef()
  useEffect(() => {
    if (selectionMode) {
      selectionToast.current = toast({
        title: 'Tabellen är i urvalsläge',
        description: requiredAmountSelected
          ? `Välj ${requiredAmountSelected} ${
              requiredAmountSelected === 1 ? 'rad' : 'rader'
            } genom att klicka på raden. Hantera ditt urval i botten höger hörn.`
          : 'Gör ditt urval genom att klicka på raden. Hantera ditt urval i botten höger hörn. När du är klar klickar du på klar längst ner till höger.',
        status: 'info',
        position: 'top-right',
        duration: 9000,
        isClosable: true
      })
      return () => toast.close(selectionToast.current)
    }
  }, [selectionMode])

  return (
    <>
      <BoxOuter gradientColor={color}>
        <div style={{ width: '100%' }} name={`freshtable-${tableUID}-header`}>
          <Flex align="center" px="2" pt="1">
            <FontAwesomeIcon
              size="2x"
              icon={['fad', loading ? 'spinner-third' : icon]}
              spin={loading}
            />
            <Box ml="2" mr="auto">
              <Text fontSize="xs" mb="-1">
                {subTitle}
              </Text>
              <Heading size="md">
                {title}{' '}
                <Text as="sup">
                  {filteredCount}/{totalCount}
                </Text>
              </Heading>
            </Box>

            <InputGroup size="sm" width={200} mr="2">
              <InputLeftElement
                zIndex="inherit"
                children={
                  <FontAwesomeIcon color="#fff" icon={['fal', 'search']} />
                }
              />
              <Input
                border="1px"
                borderColor="rgba(255, 255, 255, 0.5)"
                variant="solid"
                bg="none"
                rounded="md"
                _placeholder={{ color: 'rgba(255, 255, 255, 0.8)' }}
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value)
                  gotoPage(0)
                }}
                placeholder="Sök i tabellen"
              />
            </InputGroup>

            {!limitedMode && (
              <ButtonGroup>
                <Button
                  size="sm"
                  onClick={() => setFilterOpen(true)}
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.5)"
                  bg="whiteAlpha.200"
                  leftIcon={<FontAwesomeIcon icon={['fal', 'filter']} />}>
                  Filter
                </Button>

                <Menu>
                  <MenuButton
                    as={ChakraButton}
                    size="sm"
                    border="1px"
                    borderColor="rgba(255, 255, 255, 0.5)"
                    bg="whiteAlpha.200"
                    rightIcon={
                      <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                    }>
                    Funktioner
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={['fal', 'sync']} />}
                      onClick={() => {
                        hasChanged({
                          pageIndex,
                          pageSize,
                          search: searchString,
                          filters: filters
                        })
                      }}>
                      Ladda om
                    </MenuItem>
                    {downloadTypes && (
                      <MenuItem
                        onClick={() => setDownloadOpen(true)}
                        icon={<FontAwesomeIcon icon={['fal', 'download']} />}>
                        Ladda ner data
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>

                {openFullscreen && (
                  <Button
                    size="sm"
                    onClick={() => openFullscreen()}
                    iconSpacing="0"
                    bg="whiteAlpha.200"
                    leftIcon={
                      <FontAwesomeIcon icon={['fal', 'expand-arrows-alt']} />
                    }></Button>
                )}
                {closeFullscreen && (
                  <Button
                    size="sm"
                    onClick={() => closeFullscreen()}
                    leftIcon={
                      <FontAwesomeIcon icon={['fal', 'compress-arrows-alt']} />
                    }>
                    Stäng fullskärmsläge
                  </Button>
                )}
              </ButtonGroup>
            )}
          </Flex>
        </div>

        <div
          {...getTableProps()}
          className="Ftable"
          style={{
            position: 'relative',
            width: '100%',
            marginBottom: 0
          }}>
          <Box px="2" pt="3" pb="1">
            {headerGroups.map((headerGroup) => {
              const pageIds = page.map((i) => i.id)
              const selectedIds = selected.map((i) => i.id)

              const indeterminate = pageIds.some((pageId) =>
                selectedIds.includes(pageId)
              )

              const isAllRowsSelected = pageIds.every((pageId) =>
                selectedIds.includes(pageId)
              )

              return (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  <Flex
                    ml="3px"
                    mr="2"
                    justify="center"
                    align="center"
                    cursor="pointer"
                    opacity={0.8}
                    _hover={{ opacity: 1 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (isAllRowsSelected || indeterminate) {
                        setSelected(
                          selected.filter((sel) => !pageIds.includes(sel.id))
                        )
                      } else {
                        if (
                          requiredAmountSelected &&
                          requiredAmountSelected >= page.length
                        ) {
                          setSelected([
                            ...selected,
                            ...page.map((i) => i.original)
                          ])
                        } else {
                          toast({
                            title: 'Kunde inte välja alla rader',
                            description:
                              'Sidan innehåller fler rader än du kan välja.',
                            status: 'warning',
                            position: 'top-right',
                            isClosable: true
                          })
                        }
                      }
                    }}>
                    <FontAwesomeIcon
                      size="1x"
                      icon={[
                        'fad',
                        isAllRowsSelected
                          ? 'check-circle'
                          : indeterminate
                          ? 'minus-circle'
                          : 'circle'
                      ]}
                      swapOpacity
                    />
                  </Flex>
                  {headerGroup.headers.map((column) => (
                    <Flex
                      justify="space-between"
                      align="center"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      fontSize="sm"
                      fontWeight="bolder"
                      px="1">
                      <span>{column.render('Header')}</span>
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      {column.id !== 'selection' && column.id !== 'tags' && (
                        <span style={{ float: 'right' }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={['fad', 'sort-down']} />
                            ) : (
                              <FontAwesomeIcon icon={['fad', 'sort-up']} />
                            )
                          ) : (
                            <FontAwesomeIcon icon={['fad', 'sort']} />
                          )}
                        </span>
                      )}

                      {/* {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      )} */}
                    </Flex>
                  ))}
                </div>
              )
            })}
          </Box>

          <BoxInner
            bg={useColorModeValue('white', 'gray.700')}
            color={useColorModeValue('gray.700', 'white')}>
            <div className="tbody">
              {page.map((row) => {
                prepareRow(row)

                const rowSelected = selected
                  .map((i) => i.id)
                  .includes(row.original.id)

                return (
                  <Flex
                    align="center"
                    bg="whiteAlpha.100"
                    py="3"
                    px="6px"
                    cursor="pointer"
                    key={row.id}
                    onClick={() => {
                      if (selectionMode) {
                        if (rowSelected) {
                          setSelected(
                            selected.filter((sel) => sel.id !== row.original.id)
                          )
                        } else {
                          setSelected([...selected, row.original])
                        }
                      } else {
                        onRowClick(row.values)
                      }
                    }}
                    {...row.getRowProps()}
                    className="tr"
                    _even={{ bg: rowBgColor }}
                    _hover={{
                      color: '#333',
                      background: 'rgba(255, 255, 255, 0.95)'
                    }}>
                    <Box
                      ml="1"
                      mr="2"
                      opacity={rowSelected ? '1' : '0.4'}
                      _hover={{ opacity: 1 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (rowSelected) {
                          setSelected(
                            selected.filter((sel) => sel.id !== row.original.id)
                          )
                        } else {
                          setSelected([...selected, row.original])
                        }
                      }}>
                      <FontAwesomeIcon
                        size="1x"
                        icon={[
                          rowSelected ? 'fad' : 'fad',
                          rowSelected ? 'check-circle' : 'circle'
                        ]}
                        swapOpacity
                      />
                    </Box>
                    {row.cells.map((cell) => {
                      return (
                        <Box
                          px="1"
                          fontSize="sm"
                          {...cell.getCellProps()}
                          className="td">
                          {cell.render('Cell')}
                        </Box>
                      )
                    })}
                  </Flex>
                )
              })}

              {!loading && data.length === 0 && (
                <SlideFade>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="center"
                    mt="8">
                    Inget att visa
                  </Text>
                </SlideFade>
              )}

              {loading &&
                Array(pageSize)
                  .fill(null)
                  .map((e, i) => (
                    <BodyItem key={i} className="tr">
                      <LightMode>
                        <Skeleton
                          fadeDuration={1}
                          height="24px"
                          ml="5"
                          mr="5"
                        />
                      </LightMode>
                    </BodyItem>
                  ))}
            </div>
          </BoxInner>

          <Element
            style={{ height: '50px' }}
            name={`freshtable-${tableUID}-footer`}></Element>

          <Box ref={footerRef}></Box>

          <Flex
            ml="auto"
            mx="1"
            mt="2"
            height="0px"
            pos="sticky"
            bottom="10px"
            right="0"
            left="0"
            alignItems="flex-end">
            {!limitedMode && (
              <Flex
                boxShadow="2xl"
                p="2"
                rounded="lg"
                bg={color}
                alignItems="center">
                <Select
                  size="md"
                  fontWeight="bold"
                  value={pageSize}
                  onChange={(event) =>
                    setPageSize(parseInt(event.target.value))
                  }>
                  {rowCountOptions.map((value) => (
                    <option key={value} value={value}>
                      {value} Rader
                    </option>
                  ))}
                </Select>
                <Tooltip
                  label={`Visar ${pageIndex * pageSize || 1} till
                ${
                  pageIndex * pageSize + pageSize > filteredCount
                    ? filteredCount
                    : pageIndex * pageSize + pageSize
                } av ${filteredCount} rader`}>
                  <Box ml="3" mr="1">
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fad', 'question-circle']}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            )}

            <Spacer />

            <Box>
              <SelectedRender
                selected={[...selected]}
                setSelected={setSelected}
                requiredAmountSelected={requiredAmountSelected}
                clearSelection={() => setSelected([])}
              />

              {/* <Collapse in={selectedPreview} animateOpacity>
                <Box overflow="hidden">
                  <Flex
                    flexDir="column"
                    alignItems="flex-start"
                    mr="2"
                    mb="1"
                    maxH="40vh"
                    overflowY="scroll"
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '6px'
                      },
                      '&::-webkit-scrollbar-track': {
                        width: '8px'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'white',
                        borderRadius: '24px'
                      }
                    }}>
                    <Box py="1"></Box>
                    {selected.map(
                      ({ memberNumber, primaryAddress: { fullname } }) => (
                        <Tag
                          key={memberNumber}
                          variant="solid"
                          color="#fff"
                          borderRadius="full"
                          size="lg"
                          fontSize="sm"
                          background={color}
                          boxShadow="2px 2px 1px rgba(0, 0, 0, 0.4)"
                          mr="1"
                          mb="1"
                          ml="2">
                          <Avatar size="xs" name={fullname} ml={-2} mr={2} />
                          <TagLabel>
                            #{memberNumber} <b>{fullname}</b>
                          </TagLabel>
                        </Tag>
                      )
                    )}
                  </Flex>
                </Box>
              </Collapse>
              {(Boolean(selected.length) || requiredAmountSelected) && (
                <Flex
                  w="360px"
                  mr="1"
                  boxShadow="2xl"
                  minH="59px"
                  p="1"
                  pb="2"
                  rounded="lg"
                  bg={color}
                  flexDir="column">
                  <Flex height="45px" align="center" justify="space-between">
                    {requiredAmountSelected ? (
                      <Heading size="sm" fontWeight="bold" ml="2">
                        {selected.length}/{requiredAmountSelected} Valda
                      </Heading>
                    ) : (
                      <Heading size="sm" ml="2">
                        {selected.length} valda av {filteredCount} rader
                      </Heading>
                    )}

                    <Spacer />
                    <Button
                      size="md"
                      border="1px"
                      borderColor="rgba(255, 255, 255, 0.5)"
                      mr="1"
                      onClick={() => {
                        setSelected([])
                      }}>
                      Rensa
                    </Button>
                    <Button
                      size="md"
                      border="1px"
                      borderColor="rgba(255, 255, 255, 0.5)"
                      onClick={() => setSelectedPreview(!selectedPreview)}>
                      <FontAwesomeIcon
                        icon={[
                          'fal',
                          selectedPreview ? 'chevron-down' : 'chevron-up'
                        ]}
                      />
                    </Button>
                  </Flex>
                </Flex>
              )} */}
            </Box>

            <Flex
              mr={inModal && '80px'}
              boxShadow="2xl"
              p="2"
              rounded="lg"
              bg={color}
              ml={selected.length ? '0' : 'auto'}>
              <Flex w="75px" align="center" justify="center">
                {loading && <Spinner size="lg" />}
                {!loading && (
                  <Heading size="sm">{`${pageIndex + 1 || 0}/${
                    pageCount || 0
                  }`}</Heading>
                )}
              </Flex>
              <ButtonGroup pos="sticky" bottom="0" right="0">
                <Tooltip label="Förgående sida">
                  <Button
                    size="md"
                    colorScheme="gray"
                    border="1px"
                    borderColor="whiteAlpha.600"
                    size="md"
                    px="6"
                    iconSpacing="0"
                    isDisabled={!canPreviousPage}
                    onClick={previousPage}
                    leftIcon={
                      <FontAwesomeIcon size="lg" icon={['fal', 'angle-left']} />
                    }
                  />
                </Tooltip>
                <Tooltip label="Nästa sida">
                  <Button
                    size="md"
                    colorScheme="gray"
                    border="1px"
                    borderColor="whiteAlpha.600"
                    size="md"
                    px="6"
                    iconSpacing="0"
                    isDisabled={!canNextPage}
                    onClick={nextPage}
                    leftIcon={
                      <FontAwesomeIcon
                        size="lg"
                        icon={['fal', 'angle-right']}
                      />
                    }
                  />
                </Tooltip>

                {!limitedMode && (
                  <WithHelpi
                    title="Scrolla till början eller slutet av tabellen"
                    description="Klicka på knappen för att scrolla ner, eller scrolla upp när du har nått slutet av tabellen.">
                    <Tooltip
                      label={
                        footerIsVisible ? 'Gå till början' : 'Gå till botten'
                      }>
                      <Button
                        colorScheme="gray"
                        size="md"
                        px="2"
                        iconSpacing="0"
                        onClick={() =>
                          scroller.scrollTo(
                            footerIsVisible
                              ? `freshtable-${tableUID}-header`
                              : `freshtable-${tableUID}-footer`,
                            {
                              smooth: true,
                              offset: -50,
                              containerId: inModal
                                ? 'tableModalContainer'
                                : null
                            }
                          )
                        }
                        leftIcon={
                          <FontAwesomeIcon
                            size="1x"
                            icon={[
                              'fal',
                              footerIsVisible
                                ? 'arrow-to-top'
                                : 'arrow-to-bottom'
                            ]}
                          />
                        }></Button>
                    </Tooltip>
                  </WithHelpi>
                )}

                {selectionMode && (
                  <Button onClick={() => onDone(selected)} px="6">
                    Klar
                  </Button>
                )}
              </ButtonGroup>
            </Flex>
          </Flex>
        </div>
      </BoxOuter>

      {!limitedMode && (
        <>
          <FreshTableFilter
            isOpen={filterOpen}
            onClose={() => setFilterOpen(false)}
            availableFilters={availableFilters}
            activeFilters={activeFilters}
            onFilterChange={(filters) => setFilters(filters)}
          />
          <FreshTableDownload
            isOpen={DownloadOpen}
            onClose={() => setDownloadOpen(false)}
            downloadTypes={downloadTypes}
            nrOfPages={pageCount}
          />
        </>
      )}
    </>
  )
}

const FreshTable = ({ fullScreenOnly, onCancel, ...props }) => {
  const [fsTableOpen, setFsTableOpen] = useState(false)
  const openFullscreen = () => setFsTableOpen(true)
  const closeFullscreen = () => setFsTableOpen(false)

  useEffect(() => {
    setFsTableOpen(fullScreenOnly)
  }, [fullScreenOnly])

  return (
    <>
      {!fullScreenOnly && (
        <InnerTable {...props} openFullscreen={openFullscreen} />
      )}
      <Modal
        isOpen={fsTableOpen}
        onClose={() => onCancel() || setFsTableOpen(false)}
        size="full">
        <ModalOverlay />
        <ModalContent bg="none" p="5px" ml="75px">
          {fsTableOpen && (
            <InnerTable
              {...props}
              inModal={true}
              closeFullscreen={closeFullscreen}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default FreshTable
