import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Box,
  Center,
  Collapse,
  useDisclosure,
  useColorModeValue
} from '@chakra-ui/react'

const Outer = styled(({ color, ...rest }) => <Box {...rest} />)`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6px 0;
  padding: 4px 3px;

  background: ${({ theme, color }) => theme.gradients.vivid(color || 'grey')};
  border-radius: 12px;

  ${({ onClick }) =>
    onClick &&
    `
    transition: 0.2s ease;
    box-shadow: -8px 0px 15px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover{
      transform: scale(1.02);
      box-shadow: none;
    }
  `}
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 5px 6px 8px;

  > svg {
    font-size: 20px;
  }
`

const Title = styled.h4`
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
  margin: 0 0 0 6px;
`

const CloseButton = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0 0 0 12px;
  background-color: #dfe6e9;
  color: #fff;
  font-size: 18px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #e17055;
  }
`

const Content = styled(({ color, ...rest }) => <Box {...rest} />)`
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  border-radius: 8px;
`

const BaseCard = ({
  title,
  color,
  icon,
  style,
  onClick,
  closeable,
  children,
  className,
  HeaderRight,
  withDisclosure,
  disclosureDefaultOpen,
  useControlledDisclosure = false,
  controlledDisclosureToggle = null,
  contentStyle = {},
  ...rest
}) => {
  const bg = useColorModeValue('white', 'gray.700')
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    defaultIsOpen: disclosureDefaultOpen
  })

  useEffect(() => {
    if (controlledDisclosureToggle !== null) {
      controlledDisclosureToggle ? onOpen() : onClose()
    }
  }, [controlledDisclosureToggle])

  return (
    <Outer className={className} onClick={onClick} color={color} {...rest}>
      <Header
        onClick={() =>
          withDisclosure && !useControlledDisclosure && onToggle()
        }>
        {icon && (
          <Center w="30px">
            <FontAwesomeIcon color="#fff" size="lg" icon={['fad', icon]} />
          </Center>
        )}
        {title && <Title color={color}>{title}</Title>}

        <Box ml="auto" mr="2">
          {HeaderRight}
          {!useControlledDisclosure && withDisclosure && (
            <Box cursor="pointer">
              <FontAwesomeIcon
                style={{ padding: 5 }}
                size="lg"
                icon={['fal', isOpen ? 'chevron-up' : 'chevron-down']}
              />
            </Box>
          )}
        </Box>

        {closeable && (
          <CloseButton color={color}>
            <FontAwesomeIcon icon={['fal', 'times']} />
          </CloseButton>
        )}
      </Header>

      <Box w="100%">
        <Collapse
          in={withDisclosure ? isOpen : true}
          animateOpacity
          overflow="visible">
          <Content bg={bg} w="100%" style={contentStyle}>
            {children}
          </Content>
        </Collapse>
      </Box>
    </Outer>
  )
}

export default BaseCard
