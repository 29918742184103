import React, { useEffect, useState, useMemo, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'
import { format, getUnixTime, addMilliseconds, formatDistance } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Rnd } from 'react-rnd'
import {
  Box,
  Flex,
  Text,
  Center,
  Tag,
  Button,
  IconButton
} from '@chakra-ui/react'

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #ddd',
  borderRadius: '5px',
  background: 'rgba(255, 255, 255, 0.08)',
  zIndex: 10
}

const TimeTraveler = ({
  startDate,
  endDate,
  width = 1200,
  scale = 400,
  showToday = false,
  rows = [],
  onChange = () => null,
  onThumbResize = () => null,
  canReverse,
  onReverse,
  canForward,
  onForward
}) => {
  let rndRef = useRef(null)
  const offset = (endDate - startDate) / scale
  const pixelsPerOffset = width / scale
  const [timelineSlots, setTimelineSlots] = useState(
    new Array(scale).fill(null)
  )
  const [thumbSize, setThumbSize] = useState(72)
  const [thumbOffset, setThumbOffset] = useState(0)
  const [thumb, setThumb] = useState({ start: null, end: null })

  useEffect(() => {
    setTimelineSlots(timelineSlots.map((val, i) => startDate + i * offset))
  }, [])

  useEffect(() => {
    if (rndRef && rndRef?.updateSize) {
      rndRef.updateSize({
        height: `${20 + rows.length * 20}px`
      })
    }
  }, [rows, rndRef])

  const getTimelineKey = (value) => {
    const closest = timelineSlots.reduce((a, b) => {
      return Math.abs(b - value) < Math.abs(a - value) ? b : a
    })
    //console.log(timelineSlots[timelineSlots.indexOf(closest)])
    return timelineSlots.indexOf(closest)
  }

  const memoizedRows = useMemo(
    () => (
      <>
        {rows.map(({ size = 'normal', entries }) => (
          <Box
            key={uuidv4()}
            w="100%"
            px="3px"
            bg="rgba(255, 255, 255, 0.08)"
            rounded="full"
            mb="3px">
            <Box pos="relative" h={size === 'large' ? '24px' : '16px'}>
              {entries.map(({ color, startDate, endDate }) => (
                <Box
                  key={uuidv4()}
                  bg={color}
                  pos="absolute"
                  top="3px"
                  left={`${
                    getTimelineKey(new Date(startDate).getTime()) *
                    pixelsPerOffset
                  }px`}
                  w={`${
                    ((new Date(endDate).getTime() -
                      new Date(startDate).getTime()) /
                      offset) *
                    pixelsPerOffset
                  }px`}
                  rounded="full"
                  h={size === 'large' ? '18px' : '10px'}
                />
              ))}
            </Box>
          </Box>
        ))}
        {showToday && (
          <Box
            opacity="0.2"
            pos="absolute"
            top="110%"
            left={`${
              getTimelineKey(getUnixTime(new Date().getTime() * 1000)) *
              pixelsPerOffset
            }px`}>
            <Box
              color="rgba(9, 132, 227, 1)"
              fontSize="10px"
              textAlign="center"
              fontWeight="bold"
              w="40px"
              py="1px"
              border="1px solid rgba(9, 132, 227, 1)"
              rounded="md"
              pos="absolute"
              top="0"
              left="-21.5px">
              IDAG
              <Box
                pos="absolute"
                top="-6px"
                right="12px"
                w="0"
                h="0"
                borderLeft="6px solid transparent"
                borderRight="6px solid transparent"
                borderBottom="6px solid rgba(9, 132, 227, 1)"></Box>
            </Box>
          </Box>
        )}
      </>
    ),
    [rows, timelineSlots]
  )

  const memoizedThumbLabel = useMemo(() => {
    if (
      !(
        timelineSlots.length &&
        thumbSize &&
        thumbOffset !== null &&
        pixelsPerOffset
      )
    ) {
      return
    }

    let thumbStartDate =
      timelineSlots[(thumbOffset / pixelsPerOffset).toFixed(0)]
    let thumbEndDate = null

    if (
      (thumbOffset / pixelsPerOffset + thumbSize / pixelsPerOffset).toFixed(0) <
      timelineSlots.length
    ) {
      thumbEndDate =
        timelineSlots[
          (thumbOffset / pixelsPerOffset + thumbSize / pixelsPerOffset).toFixed(
            0
          )
        ]
    } else {
      thumbEndDate = timelineSlots[timelineSlots.length - 1]
    }

    setThumb({
      start: thumbStartDate,
      end: thumbEndDate
    })

    return (
      <Text
        fontSize="xs"
        textAlign="center"
        pos="absolute"
        w="200px"
        bottom="110%">
        {formatDistance(
          addMilliseconds(new Date(), (thumbSize / pixelsPerOffset) * offset),
          new Date(),
          { locale: sv }
        )}
        <br />
        {thumbStartDate && format(thumbStartDate, 'MMMM yyyy', { locale: sv })}
        {' - '}
        {thumbEndDate && format(thumbEndDate, 'MMMM yyyy', { locale: sv })}
      </Text>
    )
  }, [timelineSlots, thumbSize, thumbOffset, pixelsPerOffset, offset])

  return (
    <Box>
      <Box w="100%" mb="0" overflow="hidden">
        <Center w="auto" overflowX="auto">
          <Box w={width} mt={`${20 + rows.length * 10}px`}>
            <Flex
              h={`${20 + rows.length * 20}px`}
              w="100%"
              px="2"
              align="center">
              <Rnd
                ref={(r) => (rndRef = r)}
                enableResizing={{ right: true }}
                resizeHandleComponent={{
                  right: (
                    <Center h="100%">
                      <Center
                        h="30px"
                        w="15px"
                        px="2"
                        zIndex="10"
                        bg="#ecf0f1"
                        rounded="full">
                        <FontAwesomeIcon
                          size="sm"
                          color="rgba(0, 0, 0, 0.4)"
                          icon={['fal', 'grip-vertical']}
                        />
                      </Center>
                    </Center>
                  )
                }}
                minWidth="72px"
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: thumbSize,
                  height: 0
                }}
                onResize={(e, direction, ref, delta, position) => {
                  setThumbSize(ref.offsetWidth)
                  onThumbResize({ ...thumb })
                }}
                onDrag={(e, d) => {
                  setThumbOffset(d.x - 91)
                }}
                onDragStop={() => {
                  onChange({
                    ...thumb
                  })
                }}
                onResizeStop={() => {
                  onChange({
                    ...thumb
                  })
                }}
                bounds="parent">
                {memoizedThumbLabel}
              </Rnd>

              <Flex w="100%" flexDir="column" pos="relative">
                {memoizedRows}
              </Flex>
            </Flex>

            <Flex w="100%" justify="space-between" mt="0">
              <Text fontSize="sm" color="gray.400">
                {format(new Date(startDate), 'MMMM yyyy')}
              </Text>
              <Text fontSize="sm" color="gray.400">
                {format(new Date(endDate), 'MMMM yyyy')}
              </Text>
            </Flex>
          </Box>
        </Center>
        <Flex justify="space-between" w="100%" px="5">
          <Button
            size="sm"
            variant="ghost"
            isDisabled={!canReverse}
            onClick={() => onReverse(thumb)}
            leftIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />}>
            Tidigare
          </Button>
          <Button
            size="sm"
            variant="ghost"
            isDisabled={!canForward}
            onClick={() => onForward}
            rightIcon={<FontAwesomeIcon icon={['fal', 'arrow-right']} />}>
            Senare
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default TimeTraveler
