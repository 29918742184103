import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

const ColumnWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Column = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`

const SlideOutColumns = ({ columns = 3, children }) => (
  <ColumnWrapper>{children}</ColumnWrapper>
)

export default SlideOutColumns
