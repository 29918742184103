import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  VStack,
  Heading,
  ButtonGroup,
  Button,
  Badge
} from '@chakra-ui/react'

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
`

const Blob = styled(({ on, ...props }) => <div {...props} />)`
  background: ${({ color }) => color};
  ${({ on }) =>
    on &&
    css`
      animation: ${pulse} 1s infinite;
    `}

  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: auto 8px;
  height: 10px;
  width: 10px;
  transform: scale(1);
`

const Blinker = ({ on, color }) => <Blob on={on} color={color} />

export default Blinker
