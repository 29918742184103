import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Box, Center, VStack, Heading, Text } from '@chakra-ui/react'

const PageTitle = ({ title, subTitle, icon, center = false }) => (
  <Box mb="12">
    <Box
      display="flex"
      flexDir="column"
      justify={center ? 'center' : 'flex-start'}>
      <Box mr="auto" ml={center && 'auto'}>
        {icon && <FontAwesomeIcon size="3x" icon={['fal', icon]} />}
      </Box>
      <Heading mt="4" textAlign={center && 'center'}>
        {title}
      </Heading>
      <Text textAlign={center && 'center'}>{subTitle}</Text>
    </Box>
  </Box>
)

export default PageTitle
