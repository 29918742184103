import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Flex,
  Box,
  Button,
  ButtonGroup,
  Center,
  IconButton,
  useDisclosure,
  Menu,
  MenuList,
  MenuItemOption,
  MenuButton,
  Text
} from '@chakra-ui/react'

const SaveButton = ({ formState, resetAction, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ButtonGroup isAttached={resetAction}>
        <Button
          px="5"
          colorScheme="blue"
          leftIcon={<FontAwesomeIcon size="lg" icon={['fal', 'save']} />}
          isDisabled={!formState.isValid || !formState.isDirty}
          isLoading={formState.isSubmitting}
          h="55px"
          rounded="full"
          ml="2"
          {...rest}>
          Spara
        </Button>

        {resetAction && (
          <Menu isOpen={isOpen} onClose={onClose} gutter="12">
            <MenuButton
              as={IconButton}
              h="55px"
              pr="4"
              roundedRight="full"
              colorScheme="blue"
              aria-label=""
              onClick={onOpen}
              isDisabled={!formState.isDirty}
              icon={
                <Center mr="-3" ml="2">
                  <FontAwesomeIcon
                    size="xl"
                    icon={[
                      'fad',
                      isOpen ? 'circle-chevron-down' : 'circle-chevron-up'
                    ]}
                  />
                </Center>
              }
            />
            <MenuList minWidth="240px">
              <MenuItemOption minH="40px" onClick={resetAction}>
                <Flex align="center">
                  <Box ml="-4">
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fal', 'clock-rotate-left']}
                    />
                  </Box>
                  <Text
                    fontSize="sm"
                    lineHeight="1.15"
                    ml="4"
                    fontWeight="bold"
                    maxW="180px">
                    Återställ alla fält till senaste sparade versionen
                  </Text>
                </Flex>
              </MenuItemOption>
            </MenuList>
          </Menu>
        )}
      </ButtonGroup>
    </>
  )
}

export default SaveButton
