import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'

import * as entities from 'constants/entities'
import CheckboxOption from 'components/CheckboxOption'

const BoxOuter = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ color, gradientColor, theme }) =>
    gradientColor ? theme.gradients.vivid(gradientColor) : color};
  color: #fff;

  border-radius: 12px;
  padding: 3px;
  margin: 3px 0;
  cursor: pointer;
  transition: 0.3s;
`

const BoxHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  padding-right: 15px;
  margin-left: 15px;

  span {
    font-size: 10px;
    font-weight: bolder;
    text-transform: uppercase;
    opacity: 0.85;
  }

  p {
    font-size: 20px;
    font-weight: bolder;
    margin: 0;
    margin-top: -7px;
  }

  svg:first-child {
    font-size: 30px;
    margin-right: 10px;
  }

  svg {
    font-size: 35px;
  }
`

const BoxHeaderLeft = styled.div`
  margin-right: auto;
  margin-top: -2px;
`

const GET_CHECKLIST = gql`
  query checklist($id: ID) {
    checklist(id: $id) {
      id
      location
      name
      color
      items {
        id
        name
        description
        type
        priority
        link
        checked
        automatic
        automaticCondition
      }
    }
  }
`

const DO_CHECK_ITEM = gql`
  mutation doCheckItem($checklistId: ID!, $itemId: ID!, $checked: Boolean!) {
    doCheckItem(
      checklistId: $checklistId
      itemId: $itemId
      isChecked: $checked
    ) {
      success
    }
  }
`

const Checklist = ({ id }) => {
  const user = useSelector((state) => state.user)
  const bg = useColorModeValue('gray.100', 'gray.800')

  const { loading, error, data, refetch } = useQuery(GET_CHECKLIST, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  const [
    doCheckItem,
    { loading: mutationLoading, data: mutationData, error: mutationError }
  ] = useMutation(DO_CHECK_ITEM)

  return (
    <>
      <BoxOuter
        color={`linear-gradient(to right,  ${
          data?.checklist?.color || '#8360c3'
        }, #8360c3)`}>
        <BoxHeader>
          <FontAwesomeIcon
            icon={[
              'fad',
              mutationLoading || loading ? 'spinner-third' : 'list'
            ]}
            spin={mutationLoading || loading}
          />
          <BoxHeaderLeft>
            <span>{data?.checklist?.location || '---'}</span>
            <p>
              {data?.checklist?.name || '------'}{' '}
              <Text as="sup">
                {data?.checklist.items.reduce(
                  (acc, i) => (i.checked ? acc + 1 : acc),
                  0
                )}
                /{data?.checklist.items.length}
              </Text>
            </p>
          </BoxHeaderLeft>
        </BoxHeader>
        <Box
          width="100%"
          height="400px"
          borderRadius="8px"
          p="4px"
          mt="8px"
          bg={bg}>
          {data?.checklist?.items.map(
            ({ id, name, description, type, checked, automatic, priority }) => (
              <CheckboxOption
                key={id}
                id={id}
                checked={checked}
                onCheck={async (checked) => {
                  await doCheckItem({
                    variables: {
                      checklistId: data?.checklist.id,
                      itemId: id,
                      checked: checked
                    }
                  })
                  await refetch()
                }}
                title={name}
                description={description}
                color={
                  type && entities[type.toLowerCase()]
                    ? entities[type.toLowerCase()].color
                    : 'transparent'
                }
                isAutomatic={automatic}
                priority={priority}
              />
            )
          )}
        </Box>
      </BoxOuter>
    </>
  )
}

export default Checklist
