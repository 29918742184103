export const neutral = {
  icon: 'circle',
  color: '#dcdde1'
}

export const info = {
  icon: 'info-circle',
  color: '#3498db'
}

export const success = {
  icon: 'check-circle',
  color: '#00b894'
}

export const warning = {
  icon: 'exclamation-circle',
  color: '#fdcb6e'
}

export const danger = {
  icon: 'minus-circle',
  color: '#e17055'
}
