import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useMutation, useSubscription } from '@apollo/client'
import {
  Box,
  Flex,
  VStack,
  Heading,
  ButtonGroup,
  Button,
  Badge
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import Blinker from 'components/Blinker'

const OPEN_DOOR = gql`
  mutation openDoor($id: ID) {
    openDoor(id: $id) {
      success
    }
  }
`

const DOOR_SUBSCRIPTION = gql`
  subscription doorUpdated($id: ID) {
    doorUpdated(id: $id) {
      isOpen
      isLocked
      isBlocked
    }
  }
`

const DoorController = ({
  id,
  name,
  isOnline,
  offlineReason,
  isOpen,
  isLocked,
  isBlocked,
  openAction
}) => {
  const toast = useToaster()
  const { data: subscriptionData, loading: subscriptionLoading } =
    useSubscription(DOOR_SUBSCRIPTION, { variables: { id } })

  const [
    doOpenDoor,
    { loading: openDoorLoading, data: openDoorData, error: openDoorError }
  ] = useMutation(OPEN_DOOR, {
    variables: { id },
    onCompleted: (openDoorData) => {
      if (openDoorData?.openDoor.success) {
        toast({
          title: `${name} öppnad`,
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
      }
    }
  })

  return (
    <Box w="100%" py="2" px="2" mb="1" borderWidth="1px" borderRadius="lg">
      <VStack>
        <Flex w="100%">
          <Blinker on={isOnline} color={isOnline ? '#9ae6b4' : '#feb2b2'} />

          <VStack ml="2">
            <Heading size="sm" mr="auto">
              {name}
            </Heading>
            <Flex mt="0" style={{ marginTop: '2px' }}>
              {!isOnline ? (
                <>
                  <Badge mr="1" colorScheme="red">
                    offline
                  </Badge>
                  {offlineReason && (
                    <Badge colorScheme="red">{offlineReason}</Badge>
                  )}
                </>
              ) : (
                <>
                  <Badge
                    mr="1"
                    colorScheme={
                      (
                        subscriptionData?.doorUpdated
                          ? subscriptionData.doorUpdated.isOpen
                          : isOpen
                      )
                        ? 'red'
                        : 'green'
                    }>
                    {(
                      subscriptionData?.doorUpdated
                        ? subscriptionData.doorUpdated.isOpen
                        : isOpen
                    )
                      ? 'öppen'
                      : 'stängd'}
                  </Badge>
                  <Badge mr="1" colorScheme={isLocked ? 'green' : 'yellow'}>
                    {isLocked ? 'låst' : 'olåst'}
                  </Badge>
                  {isBlocked && <Badge colorScheme="purple">spärrad</Badge>}
                </>
              )}
            </Flex>
          </VStack>
        </Flex>

        {isOnline && (
          <ButtonGroup w="100%">
            <Button
              w="100%"
              colorScheme="gray"
              size="sm"
              isLoading={openDoorLoading}
              leftIcon={<FontAwesomeIcon icon={['fal', 'door-open']} />}
              onClick={() => doOpenDoor()}>
              Öppna
            </Button>

            <Button
              w="100%"
              colorScheme="gray"
              size="sm"
              leftIcon={<FontAwesomeIcon icon={['fal', 'key']} />}>
              Lås upp
            </Button>

            <Button
              w="100%"
              colorScheme="gray"
              size="sm"
              leftIcon={<FontAwesomeIcon icon={['fal', 'unlock-alt']} />}>
              Spärra
            </Button>
          </ButtonGroup>
        )}
      </VStack>
    </Box>
  )
}

export default DoorController
