import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import {
  Box,
  Flex,
  Tag,
  TagLabel,
  Avatar,
  Text,
  FormLabel,
  Spinner,
  Skeleton,
  Button,
  TagCloseButton
} from '@chakra-ui/react'

import { member } from 'constants/entities'
import { pushPanel } from 'containers/PanelNavigation'

import InputSpacer from 'components/form/InputSpacer'
import MemberPicker from 'containers/services/MemberPicker'
import eventInterchange from 'utils/eventInterchange'

const GET_MEMBERS = gql`
  query getMembers(
    $pageIndex: Int!
    $pageSize: Int!
    $search: String!
    $filters: [FilterInput!]
  ) {
    members(
      table: {
        pageIndex: $pageIndex
        pageSize: $pageSize
        search: $search
        filters: $filters
      }
    ) {
      pageCount
      filteredCount
      totalCount
      members {
        id
        memberNumber
        primaryAddress {
          fullname
          nationalId
          email
        }
      }
    }
  }
`

const GET_MEMBER = gql`
  query getMember($id: String!) {
    member(id: $id) {
      memberNumber
      primaryAddress {
        fullname
      }
    }
  }
`

const MemberInput = ({ id, label, max }) => {
  const { control } = useFormContext()
  const [pickerOpen, setPickerOpen] = useState(false)
  const [clickAwayLock, setClickAwayLock] = useState(false)

  const [getMembers, { loading, data }] = useLazyQuery(GET_MEMBERS, {})

  useEffect(() => {
    setTimeout(() => setClickAwayLock(false), 500)
  }, [clickAwayLock])

  const MemberInputRender = ({ field }) => {
    useEffect(() => {
      const data = eventInterchange.consume(`${id}Picker`)
      if (data) {
        setTimeout(() => field.onChange(max === 1 ? data : [data]), 500)
      }
    }, [])

    return (
      <>
        {pickerOpen && (
          <MemberPicker
            data={data}
            loading={loading}
            getData={getMembers}
            onDone={(selected) => {
              if (max === 1) {
                field.onChange(selected[0])
              } else {
                field.onChange(selected)
              }

              setPickerOpen(false)
              field.onBlur()
              setClickAwayLock(true)
            }}
            max={max}
            onCancel={() => setPickerOpen(false)}
          />
        )}
        <InputSpacer>
          <FormLabel fontSize="sm" fontWeight="bold" mb="0">
            {label}
          </FormLabel>
          <Flex
            flexDir="column"
            align="center"
            borderWidth="1px"
            borderColor="gray.600"
            px="2"
            py="2"
            rounded="md"
            cursor="pointer"
            //_hover={{ bg: 'whiteAlpha.200' }}
            onClick={() => setPickerOpen(true)}>
            {field.value !== null &&
              !loading &&
              (Array.isArray(field.value) ? field.value : [field.value]).map(
                (member) =>
                  member !== undefined && (
                    <MemberTagRender
                      key={member?.id}
                      id={member?.id}
                      clickAwayLock={clickAwayLock}
                      onRemoveAction={(id) => {
                        if (max === 1) {
                          field.onChange(null)
                        } else {
                          field.onChange(
                            field.value.filter((val) => val.id !== id)
                          )
                        }
                      }}
                    />
                  )
              )}
            <Flex w="100%" justify="space-between" align="center">
              <Button
                variant="ghost"
                size="xs"
                opacity={0.8}
                fontWeight="bold"
                mt={field.value && field.value.length ? '1' : '0'}
                ml="1">
                {field.value && field.value.length
                  ? max === 1
                    ? 'Ändra medlem'
                    : 'Ändra medlemar'
                  : max === 1
                  ? 'Välj medlem'
                  : 'Välj medlemar'}
              </Button>

              <Button
                size="xs"
                onClick={(e) => {
                  e.stopPropagation()
                  pushPanel('create-member', {
                    source: `${id}Picker`,
                    limitedMode: true
                  })
                }}>
                Ny medlem
              </Button>
            </Flex>
          </Flex>
        </InputSpacer>
      </>
    )
  }

  return (
    <>
      <Controller control={control} name={id} render={MemberInputRender} />
    </>
  )
}

const MemberTagRender = ({ id, clickAwayLock, onRemoveAction }) => {
  const { loading, error, data } = useQuery(GET_MEMBER, {
    skip: !id,
    variables: { id }
  })

  if (error) return null
  if (loading || !data?.member?.primaryAddress?.fullname)
    return <Skeleton height="32px" w="200px" rounded="full" mr="auto" mb="1" />

  return (
    <Tag
      key={data.member.memberNumber}
      variant="subtle"
      borderRadius="full"
      size="lg"
      fontSize="sm"
      mb="1"
      mr="auto"
      transition={'0.1s'}
      color={member.color}
      border="1px solid"
      borderColor="whiteAlpha.400"
      _hover={!clickAwayLock && { borderColor: 'whiteAlpha.600' }}
      onClick={(e) => {
        e.stopPropagation()
        !clickAwayLock &&
          pushPanel('member', {
            id: data.member.memberNumber
          })
      }}>
      <Avatar
        size="xs"
        name={data.member.primaryAddress.fullname}
        ml={-2}
        mr={2}
      />
      <TagLabel>
        #{data.member.memberNumber} <b>{data.member.primaryAddress.fullname}</b>
      </TagLabel>
      <TagCloseButton
        color="white"
        onClick={(e) => {
          e.stopPropagation()
          onRemoveAction(id)
        }}
      />
    </Tag>
  )
}

export default MemberInput
