import React from 'react'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Box,
  Flex,
  VisuallyHidden,
  useBoolean,
  ButtonGroup,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Switch
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'

const YesNoInput = ({
  id,
  label,
  helper,
  required,
  disabled,
  defaultChecked,
  noSpacer = false,
  reverseColors = false,
  ...other
}) => {
  const { control } = useFormContext()
  const { errors } = useFormState()

  return (
    <InputSpacer ignore={noSpacer}>
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <FormControl>
            <Flex w="100%" justify="space-between" align="center">
              <Box>
                <FormLabel fontSize="sm" fontWeight="bold" mb="0">
                  {label}
                </FormLabel>
                <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
              </Box>

              <ButtonGroup size="sm" isAttached variant="outline">
                <Button
                  onClick={() => {
                    field.onChange(true)
                    field.onBlur()
                  }}
                  mr="-px"
                  colorScheme={
                    field.value ? (reverseColors ? 'red' : 'green') : 'gray'
                  }>
                  Ja
                </Button>

                <Button
                  onClick={() => {
                    field.onChange(false)
                    field.onBlur()
                  }}
                  colorScheme={
                    !field.value ? (reverseColors ? 'green' : 'red') : 'gray'
                  }>
                  Nej
                </Button>
              </ButtonGroup>
            </Flex>
          </FormControl>
        )}
      />
    </InputSpacer>
  )
}

export default YesNoInput
