import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import {
  isValid,
  format,
  fromUnixTime,
  differenceInCalendarMonths
} from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Rnd } from 'react-rnd'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  Divider,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  ButtonGroup,
  Spinner
} from '@chakra-ui/react'

import { member, membership, invoice } from 'constants/entities'
import { openPaySelector } from 'actions/services'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import SlideOutSection from 'components/slideouts/SlideOutSection'
import SlideOutColumns, { Column } from 'components/slideouts/SlideOutColumns'
import SectionTitle from 'components/slideouts/SectionTitle'
import BaseCard from 'components/cards/BaseCard'
import SwitchDisclosureCard from 'components/cards/SwitchDisclosureCard'
import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'
import MemberPicker from 'containers/MemberPicker'

import TimeTraveler from 'components/TimeTraveler'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import SelectInput from 'components/form/SelectInput'
import SelectorInput from 'components/form/SelectorInput'
import DateRangeInput from 'components/form/DateRangeInput'
import DateInput from 'components/form/DateInput'
import RadioInput from 'components/form/RadioInput'
import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import MemberInput from 'components/form/MemberInput'
import NumberInput from '../../components/form/NumberInput'
import YesNoInput from '../../components/form/YesNoInput'
import TimeRangeDisplay from 'components/TimeRangeDisplay'
import { useToaster } from 'utils/toaster'
import { success } from 'constants/moods'
import ProductInput from 'components/form/ProductInput'

const CREATE_DEVIATION = gql`
  mutation createDeviation($input: DeviationInput) {
    createDeviation(input: $input) {
      success
      message
    }
  }
`

const CHECK_TIMESPAN = gql`
  query checkDeviationTimeSpan($span: String) {
    checkDeviationTimeSpan(span: $span) {
      isValid
      message
      suggestedSpan
    }
  }
`

const MembershipDeviation = ({
  isOpen,
  onClose,
  periods,
  timeRange,
  usePeriods
}) => {
  const [deviationsToBeCreated, setDeviationsToBeCreated] = useState([])
  const [cursor, setCursor] = useState(0)

  useEffect(() => {
    if (isOpen) {
      if (usePeriods) {
        const sortedPeriods = periods.sort(
          (a, b) => a.periodNumber > b.periodNumber
        )

        const producedPeriods = sortedPeriods
          .reduce((prev, current, index) => {
            if (index === 0) {
              return [[current.periodNumber]]
            }

            if (prev.at(-1).at(-1) + 1 === current.periodNumber) {
              const previous = prev.pop()
              return [...prev, [...previous, current.periodNumber]]
            } else {
              return [...prev, [current.periodNumber]]
            }
          }, [])
          .map((s) =>
            s.map((i) => periods.find((period) => period.periodNumber === i))
          )

        setDeviationsToBeCreated(producedPeriods)
      } else {
        setDeviationsToBeCreated([{ ...timeRange }])
      }
    } else {
      setDeviationsToBeCreated([])
      setCursor(0)
    }
  }, [isOpen])

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Skapa en avvikelse</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Text mt="-4" mb="4">
              Avvikelser är temporära modifikationer av ett abonnemang. Flera
              avvikelser kan skapas under samma perioder förutsatt att de inte
              krockar, till exempel frysning och friperiod.
            </Text>
            <Text as="small" fontWeight="bold" ml="2" mb="-0.5">
              Skapar avvikelse {cursor + 1} av {deviationsToBeCreated.length}
            </Text>
            <DeviationFlow
              timeSpans={deviationsToBeCreated[cursor]}
              usePeriods={usePeriods}
              onDeviationCreated={() => {
                // Dont up the cursor if we have created all the deviations we should
                if (cursor < deviationsToBeCreated.length - 1) {
                  setCursor(cursor + 1)
                } else {
                  onClose()
                }
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              size="xs"
              variant="ghost"
              mr="auto"
              mt="-2"
              onClick={onClose}>
              avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MembershipDeviation

const DeviationFlow = ({ timeSpans, usePeriods, onDeviationCreated }) => {
  const toast = useToaster()
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      type: 'FREEZE'
    }
  })

  const typeWatch = form.watch('type')
  const isFreeze = typeWatch === 'FREEZE'
  const isNewPrice = typeWatch === 'NEW_PRICE'
  const isAddon = typeWatch === 'ADDON'
  const isFreePeriod = typeWatch === 'FREE_PERIOD'
  const isPercentDiscount = typeWatch === 'DISCOUNT_PERCENT'
  const isAmountDiscount = typeWatch === 'DISCOUNT_AMOUNT'

  useEffect(() => {
    if (!usePeriods && timeSpans?.start) {
      form.setValue(
        'span',
        `${new Date(timeSpans.start).toLocaleDateString()}, ${new Date(
          timeSpans.end
        ).toLocaleDateString()}`
      )
    }
  }, [])

  const [doCreateDeviation, { loading }] = useMutation(CREATE_DEVIATION, {
    onCompleted: ({ createDeviation }) => {
      toast({
        title: `Avvikelse skapad!`,
        description: createDeviation.message,
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      onDeviationCreated()
    }
  })

  const [doCheckTimespan, { data: checkData, loading: checkLoading }] =
    useLazyQuery(CHECK_TIMESPAN, {
      fetchPolicy: 'network-only'
    })

  const spanWatch = form.watch('span')
  useEffect(() => {
    doCheckTimespan({ variables: { span: spanWatch } })
  }, [spanWatch])

  if (loading)
    return (
      <Flex p="6" border="1px solid" borderColor="whiteAlpha.100" rounded="lg">
        <Center w="100%">
          <Spinner size="lg" />
        </Center>
      </Flex>
    )

  return (
    <Flex p="2" border="1px solid" borderColor="whiteAlpha.100" rounded="lg">
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            if (usePeriods) {
              doCreateDeviation({
                variables: {
                  ...data,
                  startDate: timeSpans.sort(
                    (a, b) => new Date(a.startDate) > new Date(b.startDate)
                  )[0].startDate,
                  endDate: timeSpans
                    .sort((a, b) => new Date(a.endDate) > new Date(b.endDate))
                    .at(-1).endDate
                }
              })
            } else {
            }
          })}>
          {usePeriods ? (
            <TimeRangeDisplay
              start={
                timeSpans.sort(
                  (a, b) => new Date(a.startDate) > new Date(b.startDate)
                )[0].startDate
              }
              end={
                timeSpans
                  .sort((a, b) => new Date(a.endDate) > new Date(b.endDate))
                  .at(-1).endDate
              }
              mb="3"
            />
          ) : (
            <Flex flexDir="column" mb="4">
              <DateRangeInput id="span" label="Datum från, till" />
              {!checkLoading && checkData ? (
                <Alert
                  size="sm"
                  status={
                    checkData?.checkDeviationTimeSpan?.isValid
                      ? 'success'
                      : checkData.checkDeviationTimeSpan.suggestedSpan
                      ? 'warning'
                      : 'error'
                  }
                  pt="1.5"
                  mt="1"
                  rounded="lg">
                  <AlertIcon />
                  <Flex flexDir="column" ml="2">
                    <AlertTitle fontSize="sm">
                      {checkData?.checkDeviationTimeSpan?.isValid
                        ? 'Tidspann är giltig'
                        : 'Tidspann kräver återgärd'}
                    </AlertTitle>
                    <AlertDescription
                      fontSize="sm"
                      display="flex"
                      flexDir="column"
                      mt="-1.5">
                      {checkData.checkDeviationTimeSpan.message}
                      {checkData.checkDeviationTimeSpan.suggestedSpan && (
                        <Button
                          size="xs"
                          mt="1"
                          mr="auto"
                          onClick={() =>
                            form.setValue(
                              'span',
                              checkData.checkDeviationTimeSpan.suggestedSpan
                            )
                          }>
                          Använd rekommenderade tider
                        </Button>
                      )}
                    </AlertDescription>
                  </Flex>
                </Alert>
              ) : (
                <Center py="2">
                  <Spinner size="md" />
                </Center>
              )}
              <Divider w="85%" mx="auto" mt="4" />
            </Flex>
          )}

          <RadioInput
            id="type"
            label="Typ av Avvikelse"
            defaultValue="newPrice"
            options={[
              {
                icon: 'snowflake',
                color: 'blue.200',
                value: 'FREEZE',
                label: 'Frysning'
              },
              {
                icon: 'arrow-down-up-across-line',
                color: 'green.200',
                value: 'NEW_PRICE',
                label: 'Nytt pris'
              },
              {
                icon: 'arrow-up-right-dots',
                color: 'teal.200',
                value: 'ADDON',
                label: 'Extra kostnad'
              },
              {
                icon: 'sparkles',
                color: 'yellow.200',
                value: 'FREE_PERIOD',
                label: 'Friperiod'
              },
              {
                icon: 'badge-percent',
                color: 'red.200',
                value: 'DISCOUNT_PERCENT',
                label: 'Procentrabatt'
              },
              {
                icon: 'money-bill-wave',
                color: 'pink.200',
                value: 'DISCOUNT_AMOUNT',
                label: 'Belopprabatt'
              }
            ]}
          />

          {isFreeze && (
            <>
              <YesNoInput
                id="freezeCreditFreePeriods"
                label="Skapa friperioder som tillgodo på betalda perioder"
              />
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          {isNewPrice && (
            <>
              <NumberInput id="amount" label="Nytt pris, inklusive moms" />
              <Box mt="4">
                <SwitchInputWithDisclosure
                  label="Använd annan produkt för avvikelsen?"
                  id="sameProductAsMembership">
                  <ProductInput label="Produkt" id="produkt" max={1} />
                </SwitchInputWithDisclosure>
              </Box>
              <TextInput id="invoiceText" label="Fakturatext" />
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          {isAddon && (
            <>
              <NumberInput id="amount" label="Belopp, inklusive moms" />
              <TextInput id="invoiceText" label="Fakturatext" />
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          {isFreePeriod && (
            <>
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          {isPercentDiscount && (
            <>
              <NumberInput
                id="discountPercentage"
                label="Rabatt i procent"
                min="1"
                max="100"
              />
              <TextInput id="invoiceText" label="Fakturatext" />
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          {isAmountDiscount && (
            <>
              <NumberInput
                id="discountAmount"
                label="Rabatt i kronor, inklusive moms"
              />
              <TextInput id="invoiceText" label="Fakturatext" />
              <TextInput id="comment" label="Kommentar" />
            </>
          )}

          <ButtonGroup w="100%">
            <Button
              size="sm"
              type="submit"
              leftIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              ml="auto"
              mt="2">
              Skapa avvikelse
            </Button>
          </ButtonGroup>
        </form>
      </FormProvider>
    </Flex>
  )
}

// const sortedPeriods = periods.sort(
//   (a, b) => new Date(a.startDate) - new Date(b.startDate)
// )

// const producedPeriods = sortedPeriods.reduce((prev, current, index) => {
//   console.log(index)
//   if (index === 0) {
//     return [{ startDate: current.startDate, endDate: current.endDate }]
//   }

//   // If the current periods startDate is less than 30 hours from
//   // the saved periods endDate we add to the currentPeriod instead
//   // of creating a new period, as we understand them to be right after eachother
//   const currentTimespan = prev[prev.length - 1]
//   const hoursBetween =
//     new Date(current.startDate).getTime() -
//     new Date(currentTimespan.endDate).getTime()

//   if (hoursBetween < 30) {
//     const lastElement = prev.pop()
//     return [...prev, { ...lastElement, endDate: current.endDate }]
//   } else {

//   }

//   return prev
// }, [])
