import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Wrap,
  Text,
  Spinner,
  Button
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useMutation, useSubscription } from '@apollo/client'
import LazyLoad from 'react-lazyload'
import SubtleCard from './cards/SubtleCard'

const GET_HELP_ARTICLES = gql`
  query helpArticles($tags: [String], $max: Int) {
    helpArticles(tags: $tags, max: $max) {
      id
      title
      excerpt
    }
  }
`

const PageHelp = ({ tags }) => {
  const { loading, error, data } = useQuery(GET_HELP_ARTICLES, {})

  if (error) return null
  if (loading) return <Spinner size="lg" />

  return (
    <Box mt="5%">
      <Flex align="center">
        <FontAwesomeIcon size="2x" icon={['fal', 'life-ring']} />
        <Heading size="md" ml="4">
          Support & Hjälpartiklar
        </Heading>
      </Flex>
      <SimpleGrid columns={3} spacing={5} mt="5">
        {data.helpArticles.map(({ id, title, excerpt }) => (
          <SubtleCard key={id} onClick={() => null} py="5">
            <Box color="blue.100">
              <FontAwesomeIcon size="2x" icon={['fal', 'align-left']} />
            </Box>
            <Heading size="sm" mt="2" mb="1">
              {title}
            </Heading>
            <Text fontSize="sm">{excerpt}</Text>
          </SubtleCard>
        ))}
      </SimpleGrid>

      <Flex>
        <Flex
          p="6"
          py="12"
          rounded="lg"
          flexDir="column"
          justify="space-between"
          align="flex-start"
          mt="4%"
          ml="2">
          <Box mb="4" color="blue.100">
            <FontAwesomeIcon
              size="3x"
              icon={['fal', 'comments-question-check']}
            />
          </Box>
          <Heading sie="sm">
            Behöver du hjälp <br /> med något annat?
          </Heading>
          <Button mt="4" onClick={() => window.fcWidget.open()}>
            Öppna supportchatt
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default () => (
  <LazyLoad once>
    <PageHelp />
  </LazyLoad>
)
