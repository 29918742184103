const init = {
  registerOpen: false
}

const RegisterReducer = (state = init, action) => {
  switch (action.type) {
    case 'OPEN_REGISTER':
      return { ...state, registerOpen: true }

    case 'CLOSE_REGISTER':
      return { ...state, registerOpen: false }

    default:
      return state
  }
}

export default RegisterReducer
