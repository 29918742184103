import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cropper from 'react-easy-crop'
import Webcam from 'react-webcam'

import {
  Box,
  Flex,
  Center,
  Image,
  Heading,
  Text,
  CircularProgress,
  CircularProgressLabel,
  UnorderedList,
  ListItem,
  Button,
  Tag,
  TagLeftIcon,
  TagLabel,
  Tooltip,
  useClipboard,
  Wrap,
  VStack,
  HStack,
  useBoolean,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Avatar
} from '@chakra-ui/react'

import getCroppedImg from 'utils/cropImage'

const PictureHandler = ({
  boxSize,
  src,
  alt,
  name,
  placeholder,
  placeholderColorKey,
  onCroppedImage = () => null
}) => {
  const [isLoading, setLoading] = useBoolean()
  const [isCropping, setIsCropping] = useBoolean()
  const {
    isOpen: isOpenDisplay,
    onOpen: onOpenDisplay,
    onClose: onCloseDisplay
  } = useDisclosure()
  const {
    isOpen: isOpenTake,
    onOpen: onOpenTake,
    onClose: onCloseTake
  } = useDisclosure()
  const [facingMode, setFacingMode] = useState('user')
  const [uncroppedImage, setUncroppedImage] = useState()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const CroppedImageDone = useCallback(async () => {
    setLoading.on()
    try {
      const croppedImage = await getCroppedImg(
        uncroppedImage,
        croppedAreaPixels,
        0 //rotation
      )

      await onCroppedImage(croppedImage)
      onCloseTake()
      setIsCropping.off()
      setLoading.off()
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  const videoConstraints = {
    facingMode: facingMode
  }

  const webcamRef = useRef(null)
  const capture = useCallback(() => {
    setUncroppedImage(webcamRef.current.getScreenshot())
    setIsCropping.on()
  }, [webcamRef])

  return (
    <>
      <Box pos="relative">
        {/* {        <Image
          borderRadius="full"
          boxShadow="inner"
          boxSize={boxSize}
          maxWidth={boxSize}
          src={src}
          fallback={
            <Box>
              <Center
                h={boxSize}
                w={boxSize}
                borderRadius="full"
                bg={getColorFromString(placeholderColorKey)}
                boxShadow="inner">
                <Heading size="4xl">{placeholder}</Heading>
              </Center>
            </Box>
          }
          alt={alt}
        />} */}

        <Avatar
          rounded="full"
          boxShadow="inner"
          boxSize={boxSize}
          maxWidth={boxSize}
          size="2xl"
          src={src}
          alt={alt}
          name={name}
        />

        <Box
          pos="absolute"
          top="0"
          left="0"
          h="100%"
          w="100%"
          py="12"
          borderRadius="full"
          transition="0.3s"
          opacity="0"
          _hover={{ opacity: 1, bg: 'rgba(0, 0, 0, 0.6)' }}>
          <Center h="100%">
            <Box
              onClick={onOpenDisplay}
              mr="6"
              cursor="pointer"
              transition="0.3s"
              _hover={{ transform: 'scale(1.1)' }}>
              <FontAwesomeIcon size="2x" icon={['fal', 'expand-arrows']} />
            </Box>

            <Divider orientation="vertical" />

            <Box
              onClick={onOpenTake}
              ml="6"
              cursor="pointer"
              transition="0.3s"
              _hover={{ transform: 'scale(1.1)' }}>
              <FontAwesomeIcon size="2x" icon={['fal', 'camera']} />
            </Box>
          </Center>
        </Box>
      </Box>

      <Modal isOpen={isOpenDisplay} onClose={onCloseDisplay}>
        <ModalOverlay />
        <ModalContent bg="none" boxShadow="0">
          <Center>
            {/* <Image
              borderRadius="full"
              boxShadow="inner"
              boxSize={'80vh'}
              maxWidth={'80vh'}
              src={src}
              fallback={
                <Box>
                  <Center
                    h={'80vh'}
                    w={'80vh'}
                    borderRadius="full"
                    bg={getColorFromString(placeholderColorKey)}
                    boxShadow="inner">
                    <Heading size="4xl">{placeholder}</Heading>
                  </Center>
                </Box>
              }
              alt={alt}
            /> */}
            <Avatar
              rounded="full"
              boxShadow="inner"
              boxSize={'80vh'}
              maxWidth={'80vh'}
              size="2xl"
              src={src}
              alt={alt}
              name={name}
            />
          </Center>
        </ModalContent>
      </Modal>

      <Modal size="xl" isOpen={isOpenTake} onClose={onCloseTake}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          {isCropping ? (
            <>
              <ModalHeader>Beskär profilbild</ModalHeader>

              <Box pos="relative" h="400px" w="100%">
                <Box pos="absolute" top="0" right="0" bottom="0" left="0">
                  <Cropper
                    image={uncroppedImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </Box>
              </Box>

              <ModalFooter>
                <Button mr="2" onClick={setIsCropping.off}>
                  Ta om bild
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="teal"
                  onClick={CroppedImageDone}>
                  Använd bild
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader>Fota profilbild</ModalHeader>

              <Box mx="4" rounded="lg" overflow="hidden">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                  screenshotFormat="image/jpeg"
                />
              </Box>

              <ModalFooter>
                <Button
                  mr="2"
                  onClick={() =>
                    setFacingMode(
                      facingMode === 'user' ? 'environment' : 'user'
                    )
                  }>
                  Byt kamera
                </Button>
                <Button onClick={capture} colorScheme="teal">
                  Ta bild
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default PictureHandler

const getColorFromString = (string) => {
  var hash = 0
  if (string.length === 0) return hash
  for (var i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  var rgb = [0, 0, 0]
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255
    rgb[i] = value
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
}
