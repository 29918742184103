import React, { useState, createContext } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import styled, { keyframes } from 'styled-components'
import { darken } from 'polished'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Flex,
  Center,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Heading,
  Text,
  Spinner
} from '@chakra-ui/react'

import TextInput from 'components/form/TextInput'
import EventList from 'components/EventList'
import { FormProvider, useForm } from 'react-hook-form'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'

const SEARCH_EVENTS = gql`
  query searchEvents($searchString: String) {
    searchEvents(searchString: $searchString) {
      id
      type
      title
      color
      duration
    }
  }
`

const BigButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 35px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 40px;
  background: ${({ color }) =>
    `linear-gradient(to left, ${darken(0.05, color)}, ${color}, ${darken(
      0.05,
      color
    )})`};
  cursor: pointer;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3), -3px -3px 6px rgba(0, 0, 0, 0.2);
  margin: 0 25px;
  transition: 0.3s ease;

  &:hover {
    transform: translateY(2px);
  }
`

const Inner = styled.div`
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;

  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset 5px 0px 14px 5px rgba(0, 0, 0, 0.05);
  border-radius: 60px;
`

const CalendarAdd = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchString, setSearchString] = useState('')
  const { loading, error, data } = useQuery(SEARCH_EVENTS, {
    variables: { searchString: searchString },
    skip: !isOpen
  })

  const form = useForm()

  useFormAutoSubmit({
    form,
    onSubmit: form.handleSubmit(({ searchString }) =>
      setSearchString(searchString)
    )
  })

  return (
    <>
      <Flex w="100%" justify="flex-end" p="2">
        <BigButton color={'#ffbd59'} onClick={onOpen}>
          <Inner>
            <FontAwesomeIcon icon={['fad', 'calendar-plus']} />
          </Inner>
        </BigButton>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
        blockScrollOnMount={false}>
        {/* <DrawerOverlay /> */}
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Lägg till i kalendern</DrawerHeader>

          <DrawerBody>
            <FormProvider {...form}>
              <form>
                <TextInput
                  id="searchString"
                  placeholder="Sök bokningsbara händelser"
                />
              </form>
            </FormProvider>
            <Flex py="2"></Flex>

            {!loading ? (
              <>
                {' '}
                {data?.searchEvents.filter(({ type }) => type === 'CLASS')
                  .length !== 0 && (
                  <Heading size="xs" ml="1" mb="1">
                    Pass
                  </Heading>
                )}
                <EventList
                  category="classes"
                  onDragStart={onClose}
                  events={data?.searchEvents.filter(
                    ({ type }) => type === 'CLASS'
                  )}
                />
                {data?.searchEvents.filter(({ type }) => type === 'SERVICE')
                  .length !== 0 && (
                  <Heading size="xs" mt="5" ml="1" mb="1">
                    Tjänster
                  </Heading>
                )}
                <EventList
                  category="services"
                  onDragStart={onClose}
                  events={data?.searchEvents.filter(
                    ({ type }) => type === 'SERVICE'
                  )}
                />
                {data?.searchEvents.length === 0 && (
                  <Center fontWeight="bold">Inga resultat</Center>
                )}
                {data?.searchEvents.length !== 0 && (
                  <Text mt="2" fontSize="xs" textAlign="center" opacity="0.4">
                    Håll och dra in på kalendern för att lägga till
                  </Text>
                )}
              </>
            ) : (
              <Center my="10">
                <Spinner size="xl" />
              </Center>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CalendarAdd
