import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'

import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Center,
  Heading,
  Text
} from '@chakra-ui/react'

import ListOption from 'components/ListOption'
import FloatingButton from 'components/FloatingButton'
import BigOption from 'components/BigOption'

const entry = keyframes`
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 100px;
  display: flex;
  flex-direction: column;
  margin: 4px 0 8px 0;
  -webkit-backface-visibility: hidden;
`

const ActionMenu = ({
  options = [],
  color,
  onToggle,
  leftAddon,
  rightAddon
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  return (
    <Wrapper>
      <Popover
        placement="top"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        gutter="4">
        <PopoverTrigger>
          <Flex p="2" align="flex-end">
            {leftAddon}

            <Flex flexDir="column" cursor="pointer" onClick={open}>
              <Text
                fontSize="xs"
                as="small"
                fontWeight="bold"
                textAlign="center">
                Menu
              </Text>

              <Flex
                h="55px"
                p="1"
                align="center"
                rounded="full"
                bg="whiteAlpha.300"
                _hover={{ transform: 'scale(1.02)' }}
                transition="0.3s"
                boxShadow="2xl"
                title="Meny">
                <Flex>
                  {options.map((option) =>
                    option.isDisabled ? (
                      <></>
                    ) : (
                      <Center
                        h="45px"
                        w="45px"
                        rounded="full"
                        mr="-4"
                        boxShadow="inset 5px 0px 4px 5px rgba(0, 0, 0, 0.05)"
                        bg={option.color}
                        key={option.title}>
                        <FontAwesomeIcon
                          size="1x"
                          icon={['fad', option.icon]}
                        />
                      </Center>
                    )
                  )}
                </Flex>

                <Center ml="6" mr="2">
                  <FontAwesomeIcon
                    size="xl"
                    color="#fff"
                    icon={[
                      'fad',
                      isOpen ? 'circle-chevron-down' : 'circle-chevron-up'
                    ]}
                  />
                </Center>
              </Flex>
            </Flex>

            {rightAddon}
          </Flex>
        </PopoverTrigger>
        <PopoverContent p="2" borderColor="whiteAlpha.400" boxShadow="2xl">
          {options.map((option) => (
            <ListOption key={option.title} {...option} />
          ))}
        </PopoverContent>
      </Popover>
    </Wrapper>
  )
}

export default ActionMenu

// offset={[-45, 0]}
