import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Label = styled.span`
  margin: 5px 0 0 0;
  font-weight: lighter;
`

const Loader = ({ label }) => (
  <Wrapper>
    <FontAwesomeIcon icon={['fal', 'spinner-third']} spin size="3x" />
    <Label>{label || 'Laddar...'}</Label>
  </Wrapper>
)

export default Loader
