import styled from 'styled-components'
import bkgimg from 'style/images/login.jpg'
import { Box } from '@chakra-ui/react'

export const Page = styled(Box)`
  display: flex;
`

export const Boxed = styled(Box)`
  width: 1400px;
  margin: 75px auto 0 auto;
`

export const Content = styled(Box)`
  width: 100%;
  padding: 0 0 650px 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const EntryBackground = styled(Box)`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: url(${bkgimg});
  background-repeat: no-repeat;
  background-size: cover;
`

export const EntryLiner = styled.div`
  height: 100%;
  width: 40%;
  padding-top: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-right: auto;
  backdrop-filter: blur(60px);
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2),
    -2px -2px 16px rgba(0, 0, 0, 0.05);
`
