export const member = {
  singular: '',
  plural: '',
  color: '#1abc9c',
  icon: 'user',
  icon_multi: 'users'
}

export const membership = {
  singular: '',
  plural: '',
  color: '#ffbd59',
  icon: 'repeat-alt',
  icon_multi: 'repeat-alt'
}

export const booking = {
  singular: '',
  plural: '',
  color: '#fea47f',
  icon: 'ticket',
  icon_multi: 'ticket'
}

export const facility = {
  singular: '',
  plural: '',
  color: '#70a1ff',
  icon: 'map-pin',
  icon_multi: 'map-pin'
}

export const invoice = {
  singular: '',
  plural: '',
  color: '#fd7272',
  icon: 'file-invoice',
  icon_multi: 'file-invoice'
}

export const receipt = {
  singular: '',
  plural: '',
  color: '#ff9ff3',
  icon: 'receipt',
  icon_multi: 'receipt'
}

export const externalPayment = {
  singular: '',
  plural: '',
  color: '#c23616',
  icon: 'money-check-pen',
  icon_multi: 'money-check-pen'
}

export const bookkeeping = {
  singular: '',
  plural: '',
  color: '#9aecdb',
  icon: 'wallet',
  icon_multi: 'wallet'
}

export const product = {
  singular: '',
  plural: '',
  color: '#38ada9',
  icon: 'box-open-full',
  icon_multi: 'box-open-full'
}
