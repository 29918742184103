import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useToaster } from 'utils/toaster'
import { setIsOnline } from 'actions/app'

const NetworkManager = () => {
  const toast = useToaster()
  const dispatch = useDispatch()
  const toastIdRef = useRef()
  const online = useOnlineStatus()

  useEffect(() => {
    dispatch(setIsOnline(online))

    if (!online) {
      toastIdRef.current = toast({
        title: `Du är offline!`,
        description: 'Novasecur kräver en internetuppkoppling för att funka.',
        status: 'error',
        isCloseable: false,
        duration: null
      })
    } else {
      if (toastIdRef.current) toast.close(toastIdRef.current)
    }
  }, [online])

  return null
}

function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(true)

  useEffect(() => {
    const setOnline = () => setOnlineStatus(true)
    const setOffline = () => setOnlineStatus(false)

    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  return onlineStatus
}

export default NetworkManager
