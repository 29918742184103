import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Button,
  Spinner,
  Image,
  Text,
  Flex,
  Box,
  Center,
  Textarea,
  CircularProgress
} from '@chakra-ui/react'
import EmailEditor from 'react-email-editor'
import { count } from 'sms-length'

import RadioInput from 'components/form/RadioInput'
import TextInput from 'components/form/TextInput'
import DateInput from 'components/form/DateInput'
import { useToaster } from 'utils/toaster'

const SEND_MESSAGE = gql`
  mutation sendMemberMessage(
    $type: String
    $recipients: [MemberInput]
    $deliveryDate: String
    $title: String
    $message: String
  ) {
    sendMemberMessage(
      type: $type
      recipients: $recipients
      deliveryDate: $deliveryDate
      title: $title
      message: $message
    ) {
      success
      message
    }
  }
`

const MessageSender = ({ recipients, isOpen, onClose }) => {
  const toast = useToaster()
  const emailEditorRef = useRef(null)

  const form = useForm({ defaultValues: { type: 'email' } })
  const typeWatch = form.watch('type')
  const smsWatch = form.watch('smsMessage')
  const smsLength = count(smsWatch || '')

  const [doSendMessage] = useMutation(SEND_MESSAGE, {
    onCompleted: (data) => {
      toast({
        title: `Meddelande skickat!`,
        description: data.sendMemberMessage.message,
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
      onClose()
    }
  })

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  }

  const onReady = () => {
    // editor is ready
    console.log('onReady')
  }

  return (
    <>
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <FormProvider {...form}>
          <form
            style={{ width: '100%' }}
            onSubmit={form.handleSubmit((values) => {
              if (values.type === 'email') {
                emailEditorRef.current.editor.exportHtml((data) => {
                  const { design, html } = data

                  doSendMessage({
                    variables: {
                      ...values,
                      message: html
                    }
                  })
                })
              } else {
                doSendMessage({
                  variables: {
                    ...values,
                    message: values.smsMessage
                  }
                })
              }
            })}>
            <ModalContent ml="70px">
              <ModalHeader>Skicka meddelande</ModalHeader>
              <ModalCloseButton />
              <ModalBody w="85%" mx="auto">
                <RadioInput
                  id="type"
                  label="Skicka ett"
                  options={[
                    { value: 'email', label: 'Mejl utskick' },
                    { value: 'sms', label: 'SMS utskick' }
                  ]}
                />

                {typeWatch === 'email' && (
                  <>
                    <TextInput id="title" label="Titel" placeholder="Titel" />

                    <Box h="750px" rounded="lg" overflow="hidden" mt="8" mb="4">
                      <EmailEditor
                        style={{ height: '750px' }}
                        ref={emailEditorRef}
                        onLoad={onLoad}
                        onReady={onReady}
                        options={{
                          locale: 'sv-SE',
                          tools: {
                            row: {
                              enabled: false
                            },
                            menu: {
                              enabled: false
                            },
                            html: {
                              enabled: false
                            }
                          }
                        }}
                      />
                    </Box>

                    <DateInput id="deliveryDate" label="Leveransdatum" />
                  </>
                )}

                {typeWatch === 'sms' && (
                  <>
                    <DateInput id="deliveryDate" label="Leveransdatum" />

                    <Flex mt="8" mb="-2">
                      <Button
                        size="xs"
                        mr="1"
                        onClick={() =>
                          form.setValue(
                            'smsMessage',
                            `${form.getValues('smsMessage')} #Name`
                          )
                        }>
                        Infoga namn
                      </Button>
                      <Button
                        size="xs"
                        mr="1"
                        onClick={() =>
                          form.setValue(
                            'smsMessage',
                            `${form.getValues('smsMessage')} #Firstname`
                          )
                        }>
                        Infoga förnamn
                      </Button>
                      <Button
                        size="xs"
                        mr="1"
                        onClick={() =>
                          form.setValue(
                            'smsMessage',
                            `${form.getValues('smsMessage')} #Lastname`
                          )
                        }>
                        Infoga efternamn
                      </Button>
                      <Button
                        size="xs"
                        mr="1"
                        onClick={() =>
                          form.setValue(
                            'smsMessage',
                            `${form.getValues('smsMessage')} #Mid`
                          )
                        }>
                        Infoga Medlemsnumret
                      </Button>
                      <Button
                        size="xs"
                        onClick={() =>
                          form.setValue(
                            'smsMessage',
                            `${form.getValues('smsMessage')} #Mail`
                          )
                        }>
                        Infoga mejladdress
                      </Button>
                    </Flex>
                    <Textarea
                      {...form.register('smsMessage')}
                      rows="6"
                      mt="4"
                      placeholder="Skriv ett meddelande"></Textarea>
                    <Flex mt="1">
                      <Text as="small" ml="2">
                        {smsLength.remaining} tecken kvar.{' '}
                        {smsLength.messages !== 0 &&
                          'Meddelandet blir ca ' +
                            smsLength.messages +
                            ' SMS långt. '}
                      </Text>
                    </Flex>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Center w="100%" mb="6">
                  <Button variant="ghost" mr="2" onClick={onClose}>
                    Avbryt
                  </Button>
                  <Button colorScheme="blue" type="submit">
                    Skicka meddelande
                  </Button>
                </Center>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default MessageSender
