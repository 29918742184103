import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Spinner,
  Image,
  Text,
  Box,
  Center
} from '@chakra-ui/react'

import bankIdLogo from 'style/images/BankID_logo_white.svg'
import { startBankId, endBankId } from 'actions/bankid'

const BankIDFlow = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const isActive = useSelector((state) => state.bankid.active)
  const type = useSelector((state) => state.bankid.type)

  const start = () => {
    onOpen()
  }
  const end = () => {
    dispatch(endBankId())
    onClose()
  }

  useEffect(() => {
    if (isActive) {
      start()
    } else {
      end()
    }
  }, [isActive])

  return (
    <>
      <Modal isOpen={isOpen} onClose={end}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            BankId {type === 'signing' ? 'Signering' : 'Identifiering'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box my="1">
              <Center flexDir="column">
                <Center
                  h="180px"
                  w="180px"
                  background="rgba(0, 0, 0, 0.2)"
                  rounded="full"
                  boxShadow="inner"
                  mx="auto"
                  mb="4">
                  <Image src={bankIdLogo} w="170px" />
                </Center>

                <Text fontWeight="bold">Väntar på BankID...</Text>
                <Spinner mt="4" size="lg" speed="1s" />
              </Center>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" w="100%" onClick={end}>
              Avbryt {type === 'signing' ? 'Signering' : 'Identifiering'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BankIDFlow
