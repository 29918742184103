import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { gql, useApolloClient } from '@apollo/client'
import AsyncSelect from 'react-select/async'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'

import { member } from 'constants/entities'

const GET_MEMBERS = gql`
  query members($pageIndex: Int!, $pageSize: Int!, $search: String!) {
    members(
      table: { pageIndex: $pageIndex, pageSize: $pageSize, search: $search }
    ) {
      pageCount
      filteredCount
      totalCount
      members {
        id
        memberNumber
        primaryAddress {
          fullname
          firstname
          lastname
          nationalId
          email
        }
      }
    }
  }
`

const MemberPicker = ({ id, label, required, multi }) => {
  const client = useApolloClient()
  const themeContext = useContext(ThemeContext)

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.1)'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: themeContext.primary_body
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? themeContext.secondary_body : ''
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: themeContext.secondary_body
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: themeContext.primary_text
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#fff',
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: member.color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 12,
        width: 12
      }
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#fff'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: member.color,
      borderRadius: 6,
      overflow: 'hidden'
    })
  }

  return (
    <InputSpacer>
      <FormControl id={id} isRequired={required}>
        <FormLabel fontSize="sm" fontWeight="bold" mb="2px">
          {label}
        </FormLabel>
        <AsyncSelect
          styles={customStyles}
          isMulti={Boolean(multi)}
          cacheOptions
          defaultOptions
          placeholder="Skriv för att söka"
          isClearable={true}
          noOptionsMessage={() => 'Inga medlemmar matchar sökningen'}
          loadOptions={async (val) => {
            const response = await client.query({
              query: GET_MEMBERS,
              variables: {
                pageIndex: 0,
                pageSize: 10,
                search: val
              },
              fetchPolicy: 'network-only'
            })
            return response.data.members.members.map(
              ({ id, memberNumber, fullname }) => ({
                value: id,
                label: `#${memberNumber} ${fullname}`
              })
            )
          }}
        />
      </FormControl>
    </InputSpacer>
  )
}

export default MemberPicker
