import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { chunk } from 'lodash'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Box,
  Wrap,
  Heading,
  Text,
  Center,
  Progress,
  Tag,
  TagLeftIcon,
  TagLabel,
  useColorModeValue,
  Button
} from '@chakra-ui/react'

const NewFacilitySelector = styled(Button)`
  display: flex;
  height: auto;
  width: 55px;
  display: flex;
  flex-direction: column;
  padding: 0;

  border: 1px solid ${({ theme }) => theme.divider};
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: 0.3s ease;

  margin: 5px;
  cursor: pointer;
`

const MainFacility = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 0;
`

const FacilityList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.divider};
`

const FacilityListRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid ${({ theme }) => theme.divider};
  &:last-child {
    border-bottom: 0;
  }
`

const FacilityListItem = styled.div`
  width: 27px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 11px;
  font-weight: normal;
  border-right: 0.5px solid ${({ theme }) => theme.divider};
  background: rgba(255, 255, 255, 0.1);

  &:nth-child(2) {
    border-right: none;
    margin-right: auto;
  }
`

const GET_FACILITIES = gql`
  query myFacilities {
    myFacilities {
      id
      shortName
      name
    }
  }
`

const UPDATE_SELECTED_FACILITIES = gql`
  mutation updateSelectedFacilities(
    $selectedMainFacility: ID!
    $selectedFacilities: [ID!]
  ) {
    updateSelectedFacilities(
      selectedMainFacility: $selectedMainFacility
      selectedFacilities: $selectedFacilities
    ) {
      selectedMainFacility {
        id
        shortName
        name
      }
      selectedFacilities {
        id
        shortName
        name
      }
    }
  }
`

const FacilitySelector = ({
  selectedMainFacility,
  selectedFacilities,
  setMainFacility,
  setSelectedFacilities
}) => {
  const color = useColorModeValue('gray.700', 'white')
  // handle popover state manually
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  const panels = useSelector((state) => state.panels.panels)

  // local state for instant effect on click (instead of click > remote state > update)
  const [choosenMainFacility, setChoosenMainFacility] = useState('')
  const [choosenFacilities, setChoosenFacilities] = useState([])

  // update local component state from redux state
  useEffect(
    () => setChoosenMainFacility(selectedMainFacility.id),
    [selectedMainFacility]
  )
  useEffect(
    () => setChoosenFacilities(selectedFacilities.map(({ id }) => id)),
    [selectedFacilities]
  )

  const { loading, data } = useQuery(GET_FACILITIES, { skip: !isOpen })

  const [
    updateSelectedFacilities,
    { loading: mutationLoading, data: mutationData }
  ] = useMutation(UPDATE_SELECTED_FACILITIES)

  // update redux state with data from mutation
  useEffect(() => {
    if (mutationData?.updateSelectedFacilities) {
      setMainFacility(
        mutationData.updateSelectedFacilities.selectedMainFacility
      )
      setSelectedFacilities(
        mutationData.updateSelectedFacilities.selectedFacilities
      )
    }
  }, [mutationData])

  // call on the remote mutation, debounced
  const doUpdateSelectedFacilities = useDebouncedCallback(
    ({ choosenMainFacility, choosenFacilities }) => {
      updateSelectedFacilities({
        variables: {
          selectedMainFacility: choosenMainFacility,
          selectedFacilities: choosenFacilities
        }
      })
    },
    1000
  )

  console.log(selectedFacilities)

  return (
    <Popover
      isOpen={isOpen}
      onClose={close}
      closeOnBlur={!panels.length}
      offset={[0, 15]}
      placement="right-end">
      <PopoverTrigger>
        <NewFacilitySelector onClick={open}>
          <MainFacility>{selectedMainFacility?.shortName}</MainFacility>
          <FacilityList
            style={{
              border:
                selectedFacilities?.filter(
                  ({ id }) => id !== selectedMainFacility?.id
                ).length === 0 && 'none'
            }}>
            {chunk(
              selectedFacilities?.filter(
                ({ id }) => id !== selectedMainFacility?.id
              ),
              2
            ).map((inner) => (
              <FacilityListRow key={JSON.stringify(inner)}>
                {inner.map((item) => (
                  <FacilityListItem key={item.id}>
                    {item.shortName}
                  </FacilityListItem>
                ))}
              </FacilityListRow>
            ))}
          </FacilityList>
        </NewFacilitySelector>
      </PopoverTrigger>

      <PopoverContent p="4" width="450px">
        <PopoverCloseButton mr="-1" size="md" />
        <Center>
          <Heading mb="15" size="md">
            Välj anläggningar
          </Heading>
        </Center>

        <Heading size="sm">Välj primär anläggning</Heading>
        <Text fontSize="xs" color="gray.500">
          Välj anläggningen du just nu står på.
        </Text>
        <Wrap mt="2" mb="4">
          {data?.myFacilities.map(({ id, name, shortName }) => {
            const isActive = choosenMainFacility === id
            return (
              <Tag
                key={id}
                onClick={() => {
                  setChoosenFacilities([...choosenFacilities, id])
                  setChoosenMainFacility(id)
                  doUpdateSelectedFacilities({
                    choosenMainFacility: id,
                    choosenFacilities: [...choosenFacilities, id]
                  })
                }}
                size="lg"
                variant={isActive ? 'solid' : 'subtle'}
                colorScheme={isActive ? 'green' : 'gray'}
                cursor="pointer"
                _hover={{ transform: 'scale(1.04)' }}>
                <TagLeftIcon
                  as={() => (
                    <FontAwesomeIcon
                      icon={['fal', isActive ? 'check-circle' : 'circle']}
                    />
                  )}
                />
                <TagLabel ml="2">
                  {name}
                  <Text as="sup">{shortName}</Text>
                </TagLabel>
              </Tag>
            )
          })}
        </Wrap>

        <Heading size="sm">Välj anläggningar du vill se data från</Heading>
        <Text fontSize="xs" color="gray.500" mb="2">
          Data från valda anläggningar kommer dyka upp i tabeller och listor.
        </Text>
        <Wrap mt="2" mb="4">
          {data?.myFacilities.map(({ id, name, shortName }) => {
            const isActive = choosenFacilities.includes(id)
            return (
              <Tag
                key={id}
                onClick={() => {
                  if (id !== choosenMainFacility) {
                    setChoosenFacilities(
                      isActive
                        ? choosenFacilities.filter((i) => i !== id)
                        : [...choosenFacilities, id]
                    )
                  }
                  doUpdateSelectedFacilities({
                    choosenMainFacility,
                    choosenFacilities: isActive
                      ? choosenFacilities.filter((i) => i !== id)
                      : [...choosenFacilities, id]
                  })
                }}
                size="lg"
                variant="subtle"
                variant={isActive ? 'solid' : 'subtle'}
                colorScheme={isActive ? 'cyan' : 'gray'}
                cursor="pointer"
                _hover={{ transform: 'scale(1.04)' }}>
                <TagLeftIcon
                  as={() => (
                    <FontAwesomeIcon
                      icon={['fal', isActive ? 'check-circle' : 'circle']}
                    />
                  )}
                />
                <TagLabel ml="2">
                  {name}
                  <Text as="sup">{shortName}</Text>
                </TagLabel>
              </Tag>
            )
          })}
        </Wrap>

        {(loading || mutationLoading) && <Progress size="xs" isIndeterminate />}
      </PopoverContent>
    </Popover>
  )
}

export default FacilitySelector
