import React from 'react'
import { Box } from '@chakra-ui/react'

const SubtleCard = ({ children, onClick, ...other }) => (
  <Box
    bg="whiteAlpha.50"
    p="3"
    rounded="lg"
    boxShadow="2xl"
    border="1px"
    borderColor="whiteAlpha.300"
    transition="0.1s"
    _hover={{ bg: onClick && 'whiteAlpha.300' }}
    cursor={onClick && 'pointer'}
    onClick={onClick}
    {...other}>
    {children}
  </Box>
)

export default SubtleCard
