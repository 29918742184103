import React from 'react'
import { Helmet } from 'react-helmet'

import { Page, Content } from 'style/wrappers'
import PrimaryNavigation from 'containers/PrimaryNavigation'

import DashboardContent from 'containers/DashboardContent'
import ErrorBoundary from 'components/ErrorBoundary'

const Dashboard = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Start | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <DashboardContent />
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Dashboard
