import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react'

import InputSpacer from './InputSpacer'

const SelectorCreatableInput = ({
  id,
  label,
  options,
  multi = false,
  defaultValue = '',
  noOptionsMessage,
  ...other
}) => {
  const themeContext = useContext(ThemeContext)
  const { control, errors } = useFormContext()

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '1px 0',
      backgroundColor: 'transparent',
      border: '1px solid var(--chakra-colors-whiteAlpha-300)',
      borderRadius: 'var(--chakra-radii-md)'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: themeContext.primary_body
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? themeContext.secondary_body : ''
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: themeContext.secondary_body
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: themeContext.primary_text
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#fff',
      alignItems: 'center',
      display: 'flex'
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#fff'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 6,
      overflow: 'hidden'
    })
  }

  return (
    <InputSpacer>
      <Controller
        control={control}
        name={id}
        render={({ field: { value = '', onChange, ...field } }) => (
          <FormControl id={id}>
            {label && (
              <FormLabel fontSize="sm" fontWeight="bold" mb="1px">
                {label}
              </FormLabel>
            )}
            <CreatableSelect
              styles={customStyles}
              placeholder="Välj..."
              noOptionsMessage={() =>
                noOptionsMessage || 'Inget val matchar sökningen'
              }
              formatCreateLabel={(val) => `Lägg till "${val}"`}
              options={options}
              isMulti={Boolean(multi)}
              value={
                value
                  ? value.split(', ').reduce((acc, val) => {
                      return [
                        ...acc,
                        {
                          value: val,
                          label:
                            (options || []).find(({ value }) => value === val)
                              ?.label || val
                        }
                      ]
                    }, [])
                  : []
              }
              {...field}
              onChange={(vals) => {
                console.log(vals)
                onChange(vals.map(({ value }) => value).join(', '))
              }}
              {...other}
            />
          </FormControl>
        )}
      />
    </InputSpacer>
  )
}

export default SelectorCreatableInput
