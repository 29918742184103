import React, { useState, useCallback, useEffect } from 'react'
import * as yup from 'yup'
import { Wizard, useWizard } from 'react-use-wizard'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import { useDispatch } from 'react-redux'
import {
  Box,
  ButtonGroup,
  Button,
  Textarea,
  Spinner,
  Center,
  Flex,
  Text,
  Heading,
  Stack,
  VStack,
  Tag,
  Divider
} from '@chakra-ui/react'

import { member, membership } from 'constants/entities'
import bankidSchema from 'validationSchemas/bankidSchema'
import AddressSchema from 'validationSchemas/AddressSchema'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import AddressForm from 'containers/forms/AddressForm'

import PageTitle from 'components/PageTitle'
import TextInput from 'components/form/TextInput'
import YesNoInput from 'components/form/YesNoInput'
import SelectorInput from 'components/form/SelectorInput'
import PinInput from 'components/form/PinInput'
import { number } from 'yup/lib/locale'
import LabelRow from 'components/LabelRow'
import WizardStep from 'components/WizardStep'
import MemberInput from 'components/form/MemberInput'
import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import EntityCard from 'components/cards/EntityCard'
import SubtleCard from 'components/cards/SubtleCard'
import DateInput from 'components/form/DateInput'
import ListOption from 'components/ListOption'
import { consumePanelMessage, createMessage } from 'utils/eventInterchange'

const CreateMembershipPanel = ({ data: { payerSignerId }, ...rest }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({ payerSigner: { id: payerSignerId } })
  const doSetData = (val) => setData({ ...data, ...val })

  return (
    <SlideOutPanel
      {...rest}
      onBack={
        () => window.sessionStorage.removeItem('PayerSignerCreateSubscription') // Clear "PayerSigner"-steps persistance on back
      }>
      <Wizard>
        <PayerSigner data={data} setData={doSetData} />

        <SelectMembership data={data} setData={doSetData} />
        <StartDate data={data} setData={doSetData} />
        <Users data={data} setData={doSetData} />
        <Payment data={data} setData={doSetData} />
      </Wizard>
    </SlideOutPanel>
  )
}

const PayerSigner = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const form = useForm({
    defaultValues: data
  })

  // We have to persist this form since the user can
  // create new member(s) and technically leave this panel (this panel is put behind the "create new member"-panel, but panels behind the primary one is unmounted from the DOM to save resources)
  const { clear } = useFormPersist('PayerSignerCreateSubscription', form)

  return (
    <>
      <Progress step={activeStep} />
      <Box py="2" px="30%">
        <PageTitle
          icon={membership.icon}
          title="Betalare & signerare"
          subTitle="Välj vem som ska betala och signera för abonnemanget."
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((val) => {
              setData(val)
              clear()
              nextStep()
            })}>
            <MemberInput
              label="Betalare & signerare"
              id="payerSigner"
              max={1}
            />

            <Box mt="4">
              <SwitchInputWithDisclosure
                label="Ska någon annan betala?"
                id="hasOtherPayer">
                <MemberInput label="Annan betalare" id="otherPayer" max={1} />
              </SwitchInputWithDisclosure>
            </Box>

            <Box mt="4">
              <SwitchInputWithDisclosure
                label="Ska någon annan signera?"
                id="hasOtherSigner">
                <MemberInput label="Annan signerare" id="otherSigner" max={1} />
              </SwitchInputWithDisclosure>
            </Box>

            <ButtonGroup float="right" mt="8">
              <Button type="submit" isDisabled={false} color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const SelectMembership = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()
  const [filter, setFilter] = useState('all')

  const form = useForm({
    mode: 'onChange'
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%" mb="10%">
        <PageTitle
          title="Välj abonnemang att lägga på"
          subTitle="Välj abonnemang i listan. I nästa steg väljer du startdatum. Filtrera med knapparna nedan för att filtrera"
          center
        />
        <Center mt="-8" mb="8">
          <Button
            size="sm"
            variant={filter === 'all' ? 'solid' : 'ghost'}
            onClick={() => setFilter('all')}
            mr="1">
            Alla
          </Button>
          <Button
            size="sm"
            variant={filter === 'direct_pay' ? 'solid' : 'ghost'}
            onClick={() => setFilter('direct_pay')}
            mr="1">
            Direktbetalda
          </Button>
          <Button
            size="sm"
            variant={filter === 'part_pay' ? 'solid' : 'ghost'}
            onClick={() => setFilter('part_pay')}
            mr="1">
            delbetalda
          </Button>
          <Button
            size="sm"
            variant={filter === 'punch_card' ? 'solid' : 'ghost'}
            onClick={() => setFilter('punch_card')}
            mr="1">
            klippkort
          </Button>
          <Button
            size="sm"
            variant={filter === 'voucher' ? 'solid' : 'ghost'}
            onClick={() => setFilter('voucher')}>
            värdekort
          </Button>
        </Center>

        <VStack>
          <EntityCard
            icon={membership.icon}
            title="En abonnemangsmall"
            subTitle="200kr/mån"
            color={membership.color}
            onClick={() => nextStep()}
          />
          <EntityCard
            icon={membership.icon}
            title="En abonnemangsmall"
            subTitle="200kr/mån"
            color={membership.color}
            onClick={() => nextStep()}
          />
          <EntityCard
            icon={membership.icon}
            title="En abonnemangsmall"
            subTitle="200kr/mån"
            color={membership.color}
            onClick={() => nextStep()}
          />
        </VStack>

        <ButtonGroup mt="5">
          <Button
            variant="ghost"
            color="#8395a7"
            onClick={() => previousStep(0)}>
            Föregående
          </Button>
        </ButtonGroup>
      </Box>
    </>
  )
}

const StartDate = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const form = useForm({
    defaultValues: data
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          icon="calendar-day"
          title="Väj startdatum"
          subTitle="Välj ett rekommenderat eller eget startdatum nedan."
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((val) => {
              setData(val)
              nextStep()
            })}>
            <VStack mb="4">
              <SubtleCard w="100%" onClick={() => nextStep()}>
                <Heading size="sm" mb="2">
                  12 Jan
                </Heading>
                <Tag size="sm" colorScheme="green">
                  Rekommenderat
                </Tag>
              </SubtleCard>

              <SubtleCard onClick={() => nextStep()} w="100%">
                <Heading size="sm" mb="1">
                  16 Jan
                </Heading>
                <Text fontSize="xs">Om en vecka</Text>
              </SubtleCard>

              <SubtleCard w="100%" onClick={() => nextStep()}>
                <Heading size="sm" mb="1">
                  1 Feb
                </Heading>
                <Text fontSize="xs">Om en månad</Text>
              </SubtleCard>

              <Divider />

              <Flex w="100%" flexDir="column" pt="2">
                <Heading textAlign="center" size="xs">
                  Välj ett eget startdatum
                </Heading>
                <Box pt="2">
                  <DateInput id="date" />
                </Box>
              </Flex>
            </VStack>

            <ButtonGroup float="right">
              <Button
                variant="ghost"
                color="#8395a7"
                onClick={() => previousStep()}>
                Föregående
              </Button>
              <Button type="submit" isDisabled={false} color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const Users = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const form = useForm({
    defaultValues: data
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          title="Välj användare"
          subTitle="Välj vem eller vilka som ska kunna använda detta abonnemang"
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((val) => {
              setData(val)
              nextStep()
            })}>
            <MemberInput label="" id="s" max={20} />

            <ButtonGroup float="right" mt="4">
              <Button
                variant="ghost"
                color="#8395a7"
                onClick={() => previousStep()}>
                Föregående
              </Button>
              <Button type="submit" isDisabled={false} color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const Payment = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const schema = yup.object().shape({
    pin: yup.string().required().length(4)
  })

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(schema)
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          title="Betalning"
          subTitle="Välj hur abonnemanget ska betalas"
          center
        />

        <VStack>
          <ListOption
            icon="cash-register"
            title="Betala i kassan"
            description="Kassan kommer öppnas som ett fönster"
          />
          <ListOption
            icon="file-invoice"
            title="Fakturera"
            description="Skicka en faktura till kunden"
          />

          <Divider />

          <ListOption
            icon="snooze"
            title="Hantera senare"
            description="Ta inte betalt nu"
            color="#e17055"
          />
        </VStack>

        <Center mt="5">
          <ButtonGroup mt="4">
            <Button
              variant="ghost"
              color="#8395a7"
              onClick={() => previousStep()}>
              Föregående
            </Button>
          </ButtonGroup>
        </Center>
      </Box>
    </>
  )
}

export default CreateMembershipPanel

const Progress = ({ step }) => {
  return (
    <Stack
      mt="3"
      mb="12"
      spacing="0"
      direction={{
        base: 'column',
        md: 'row'
      }}>
      <WizardStep
        title="Betalare & signerare"
        description=""
        isActive={step === 0}
        isCompleted={step > 0}
        isFirstStep
      />
      <WizardStep
        title="Välj abonnemang"
        description=""
        isActive={step === 1}
        isCompleted={step > 1}
      />
      <WizardStep
        title="Välj startdatum"
        description=""
        isActive={step === 2}
        isCompleted={step > 2}
      />
      <WizardStep
        title="Användare"
        description=""
        isActive={step === 3}
        isCompleted={step > 3}
      />
      <WizardStep
        title="Betalning"
        description=""
        isActive={step === 4}
        isCompleted={step > 4}
        isLastStep
      />
    </Stack>
  )
}
