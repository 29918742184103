import React, { useState, useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Center,
  Heading,
  Text,
  Button,
  ButtonGroup,
  useDisclosure,
  Avatar,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag
} from '@chakra-ui/react'

import FreshTable from 'containers/FreshTable'
import WithHelpi from 'components/utils/WithHelpi'

import { pushPanel } from 'containers/PanelNavigation'
import { member } from 'constants/entities'
import TagRender from 'components/TagRender'
import MessageSender from 'containers/services/MessageSender'
import MemberSelectedRender from 'containers/tables/MemberSelectedRender'

const GET_MEMBERS = gql`
  query members(
    $pageIndex: Int!
    $pageSize: Int!
    $search: String!
    $filters: [FilterInput!]
  ) {
    members(
      table: {
        pageIndex: $pageIndex
        pageSize: $pageSize
        search: $search
        filters: $filters
      }
    ) {
      pageCount
      filteredCount
      totalCount
      availableFilters {
        id
        label
        type
        options
      }
      activeFilters {
        id
        label
        type
        options
        value
      }
      downloadTypes {
        key
        label
      }
      members {
        id
        memberNumber
        tags {
          name
          value
          color
        }
        primaryAddress {
          fullname
          nationalId
          email
        }
      }
    }
  }
`

const MemberTable = () => {
  const [selected, setSelected] = useState([])
  const [getMembers, { loading, error, data }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: 'network-only'
  })

  const messageSenderDisclosure = useDisclosure()

  const onChange = useCallback((val) => {
    getMembers({ variables: val })
  }, [])

  const onDownload = useCallback((val) => {}, [])

  const columns = useMemo(
    () => [
      {
        Header: 'M.nr',
        accessor: 'memberNumber',
        maxWidth: 50
      },
      {
        Header: 'Namn',
        accessor: 'primaryAddress.fullname'
      },
      {
        Header: 'Personnr',
        accessor: 'primaryAddress.nationalId'
      },
      {
        Header: 'Epost',
        accessor: 'primaryAddress.email'
      },
      {
        Header: '',
        accessor: 'tags',
        Cell: (props) => <TagRender size="sm" tags={props.value} />
      }
    ],
    []
  )

  return (
    <>
      <ButtonGroup mb="2" ml="2">
        <Button
          onClick={() => pushPanel('create-member')}
          leftIcon={<FontAwesomeIcon icon={['fad', member.icon]} />}
          color={member.color}
          helpi="skapa en ny medlem">
          Skapa en ny Medlem
        </Button>

        <WithHelpi>
          <Button
            disabled={!selected.length}
            leftIcon={<FontAwesomeIcon icon={['fad', 'paper-plane']} />}
            onClick={messageSenderDisclosure.onOpen}>
            {selected.length
              ? `Mejl / SMS utskick: ${selected.length} mottagare`
              : 'Välj medlemar för att göra utskick'}
          </Button>
        </WithHelpi>
      </ButtonGroup>

      <FreshTable
        title="Alla Medlemmar"
        color="#1abc9c"
        icon="users"
        loading={loading}
        columns={columns}
        data={data?.members ? data.members.members : []}
        pageCount={data?.members.pageCount}
        filteredCount={data?.members.filteredCount}
        totalCount={data?.members.totalCount}
        availableFilters={data?.members.availableFilters}
        activeFilters={data?.members.activeFilters}
        downloadTypes={data?.members.downloadTypes}
        onChange={onChange}
        SelectedRender={MemberSelectedRender}
        onSelected={setSelected}
        onDownload={onDownload}
        onRowClick={(values) =>
          pushPanel('member', {
            id: values.memberNumber
          })
        }
      />

      <MessageSender {...messageSenderDisclosure} recipients={selected} />
    </>
  )
}

export default MemberTable
