import { useToaster } from './toaster'

export default (e, title) => {
  const toast = useToaster()

  toast({
    title: title || `Något gick fel`,
    description: e.message,
    status: 'error',
    position: 'top-right',
    isClosable: true
  })
}
