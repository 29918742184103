import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { getUnixTime } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  Divider,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Skeleton,
  Progress,
  SkeletonText,
  Checkbox
} from '@chakra-ui/react'

import {
  member,
  membership,
  invoice as invoiceEntity,
  receipt as receiptEntity,
  externalPayment as externalPaymentEntity
} from 'constants/entities'
import { openPaySelector } from 'actions/services'
import { ListItem as CardListItem } from 'components/cards/ListCard'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import SkeletonPanel from 'components/slideouts/SkeletonPanel'
import SlideOutSection from 'components/slideouts/SlideOutSection'
import SlideOutColumns, { Column } from 'components/slideouts/SlideOutColumns'
import SectionTitle from 'components/slideouts/SectionTitle'
import BaseCard from 'components/cards/BaseCard'
import SwitchDisclosureCard from 'components/cards/SwitchDisclosureCard'
import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'
import MemberPicker from 'containers/MemberPicker'

import TimeTraveler from 'components/TimeTraveler'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import SelectInput from 'components/form/SelectInput'
import SelectorInput from 'components/form/SelectorInput'
import DateRangeInput from 'components/form/DateRangeInput'
import DateInput from 'components/form/DateInput'
import RadioInput from 'components/form/RadioInput'
import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import MemberInput from 'components/form/MemberInput'
import MembershipDeviation from 'containers/membership/MembershipDeviation'
import SaveButton from 'components/SaveButton'
import ShowFile from 'components/ShowFile'
import { useToaster } from 'utils/toaster'
import TagRender from 'components/TagRender'
import ProductInput from 'components/form/ProductInput'
import NumberInput from 'components/form/NumberInput'
import SubtleCard from 'components/cards/SubtleCard'
import DefaultErrorHandler from 'utils/DefaultErrorHandler'
import PaySelector from 'containers/services/PaySelector'
import MembershipTimeline from 'containers/membership/MembershipTimeline'
import MembershipCancel from 'containers/membership/MembershipCancel'

const GET_MEMBERSHIP = gql`
  query getMembership($id: String!) {
    membership(id: $id) {
      id
      name
      date
      status
      facility {
        id
      }

      hasPayment
      paymentInterval
      paymentIntervalUnit
      paymentType
      paymentProduct {
        id
        name
      }
      paymentPrice
      paymentFrom
      paymentTo
      phaseInDay
      paymentInvoiceText

      friskvardsintyg

      tags {
        name
        value
        color
      }

      canPay
      canCancel
      canFreeze
      canChangeMembership

      preferredPaymentMethod

      hasCard
      hasAutogiro
      autogiroNationalId
      autogiroClearing
      autogiroAccount

      hasAdmission
      admissionType
      zone {
        id
      }
      admissionFrom
      admissionTo

      hasBoundUntil
      boundUntil

      payerSigner {
        id
        primaryAddress {
          fullname
        }
        memberNumber
        profilePicture {
          url
        }
      }

      otherPayer {
        id
      }

      users {
        id
      }
    }

    membershipRules {
      id
      name
    }

    facilities {
      id
      shortName
      name
    }

    zones {
      id
      name
    }

    admissionTypes {
      id
      name
    }
  }
`

const SET_MEMBERSHIP = gql`
  mutation setMembership($id: String!, $input: MembershipInput!) {
    setMembership(id: $id, input: $input) {
      success
    }
  }
`

const navigation = [
  { to: 'sub-home', icon: 'home', color: '#00a8ff' },
  { to: 'sub-betalperioder', icon: 'history', color: '#00cec9' }
]

const MembershipPanel = ({ uid, data: { id }, ...rest }) => {
  const toast = useToaster()
  const dispatch = useDispatch()

  const [timespans, setTimespans] = useState([])

  const form = useForm({
    mode: 'onChange',
    defaultValues: { hasOtherPayer: false }
  })

  const hasOtherPayerWatch = form.watch('hasOtherPayer')

  const createDeviationDisclosure = useDisclosure()
  const changePaymentMethodDisclosure = useDisclosure()
  const friskvardsintygDisclosure = useDisclosure()
  const paySelectorDisclosure = useDisclosure()
  const cancelDisclosure = useDisclosure()

  const { loading, error, data } = useQuery(GET_MEMBERSHIP, {
    variables: { id },
    fetchPolicy: 'network-only',
    onCompleted: ({ membership }) =>
      form.reset({
        ...membership,
        facility: membership?.facility?.id,
        zone: membership?.zone?.id
      })
  })

  const [setMembership] = useMutation(SET_MEMBERSHIP, {
    variables: { id },
    refetchQueries: ['member'],
    onCompleted: () => {
      toast({
        title: 'Abonnemang updaterad',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    },
    onError: DefaultErrorHandler
  })

  if (loading) return <SkeletonPanel loading={true} {...rest} />
  if (error) return <SkeletonPanel error={error} {...rest} />

  const actions = [
    {
      title: 'Betala',
      color: '#00b894',
      icon: 'money-bill-wave',
      description: 'Betala hela eller delar av utstående summa',
      isDisabled: data.membership.canPay,
      onClick: paySelectorDisclosure.onOpen
    },
    {
      title: 'Friskvårdsintyg',
      color: '#fd7272',
      icon: 'file-certificate',
      description: 'Se och skriv ut',
      isDisabled: !data.membership.friskvardsintyg,
      onClick: friskvardsintygDisclosure.onOpen
    },
    {
      title: 'Frys abonnemang',
      color: '#74b9ff',
      icon: 'snowflake',
      description: 'Detta blockerar inträde för kunden',
      onClick: createDeviationDisclosure.onOpen
    },
    {
      title: 'Säg upp',
      color: '#e17055',
      icon: 'ban',
      description: 'Säg upp det här abonnemanget',
      isDisabled: !data.membership.canCancel,
      onClick: cancelDisclosure.onOpen
    }
  ]

  const preferredPaymentMethodWatch = form.watch('preferredPaymentMethod')

  const prefersCard = preferredPaymentMethodWatch === 'card'
  const prefersAutogiro = preferredPaymentMethodWatch === 'autogiro'
  const prefersInvoice = preferredPaymentMethodWatch === 'invoice'

  return (
    <>
      <SlideOutPanel
        actions={actions}
        navigation={navigation}
        {...rest}
        title={form.watch('name') || data.membership.name || 'Abonnemang'}
        error={error}
        loading={loading}
        actionRightAddon={
          <SaveButton
            formState={form.formState}
            onClick={form.handleSubmit(
              async (values) => {
                await setMembership({ variables: { input: values } })
              },
              (e) => console.log(e)
            )}
            resetAction={() => form.reset()}
          />
        }>
        <SlideOutSection name="sub-home">
          <FormProvider {...form}>
            <form style={{ width: '100%' }}>
              <Flex px="6" py="2" rounded="lg">
                <Box pos="relative" h="100px" w="100px">
                  <FontAwesomeIcon
                    color={membership.color}
                    size="5x"
                    icon={['fad', membership.icon]}
                  />
                  {data.membership.status === 'FROZEN' && (
                    <Center
                      pos="absolute"
                      bottom="10px"
                      right="10px"
                      h="40px"
                      w="40px"
                      rounded="full"
                      bg="#0984e3">
                      <FontAwesomeIcon size="lg" icon={['fad', 'snowflake']} />
                    </Center>
                  )}
                </Box>

                <Flex height="100%" direction="column" justify="end" ml="6">
                  <Heading size="xl">
                    {form.watch('name') || 'Abonnemang'}
                  </Heading>
                  <Flex my="1" align="center">
                    <FontAwesomeIcon
                      size="lg"
                      icon={['fad', 'long-arrow-right']}
                    />

                    {!loading && (
                      <Tag
                        color="#fff"
                        variant="subtle"
                        borderRadius="full"
                        size="lg"
                        fontSize="sm"
                        mr="auto"
                        ml="2"
                        transition={'0.1s'}
                        color={member.color}
                        border="1px solid"
                        borderColor="whiteAlpha.400">
                        <Avatar
                          size="xs"
                          name={
                            data.membership.payerSigner.primaryAddress.fullname
                          }
                          ml={-2}
                          mr={2}
                        />
                        <TagLabel>
                          #{data.membership.payerSigner.memberNumber}{' '}
                          <b>
                            {
                              data.membership.payerSigner.primaryAddress
                                .fullname
                            }
                          </b>
                        </TagLabel>
                      </Tag>
                    )}
                  </Flex>

                  <Box mt="1">
                    <TagRender tags={data.membership.tags} />
                  </Box>

                  <Wrap mt="5"></Wrap>
                </Flex>
              </Flex>

              <SlideOutColumns columns={3}>
                <Column>
                  <BaseCard
                    icon="pencil-ruler"
                    color="#01a3a4"
                    title="Registrering">
                    <TextInput
                      id="name"
                      placeholder="Namn"
                      label="Abonnemangets namn"
                    />

                    {data?.membershipRules?.length > 0 && (
                      <SelectInput
                        id="Regel"
                        label="regel"
                        multi
                        options={data?.membershipRules?.map(({ id, name }) => {
                          return {
                            value: id,
                            label: name
                          }
                        })}
                      />
                    )}

                    <SelectInput
                      id="facility"
                      label="Anläggning"
                      options={data?.facilities?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />
                  </BaseCard>

                  <BaseCard
                    icon={member.icon_multi}
                    color={member.color}
                    title="Betalare och användare">
                    <MemberInput
                      id="payerSigner"
                      label={
                        hasOtherPayerWatch
                          ? 'Signatär'
                          : 'Betalare och signatär'
                      }
                      max={1}
                    />

                    <Box mb="2">
                      <SwitchInputWithDisclosure
                        noSpacer
                        id="hasOtherPayer"
                        label="Annan betalare">
                        <MemberInput id="otherPayer" label="Betalare" max={1} />
                      </SwitchInputWithDisclosure>
                    </Box>

                    <Divider
                      orientation="horizontal"
                      w="60%"
                      mx="auto"
                      mt="4"
                      mb="3"
                    />

                    <MemberInput
                      id="users"
                      label="Användare av abonnemanget"
                      max={20}
                    />
                  </BaseCard>
                </Column>

                <Column>
                  <SwitchDisclosureCard
                    id="hasPayment"
                    icon="credit-card"
                    color="#ff7675"
                    title="Betalning">
                    <LabelRow
                      label="Betalsätt"
                      value={data.membership.paymentType}
                      color="cyan"
                    />
                    <LabelRow
                      label="Intervall"
                      value={`${data.membership.paymentInterval} ${data.membership.paymentIntervalUnit}`}
                      color="cyan"
                    />
                    {data.membership.paymentProduct && (
                      <LabelRow
                        label="produkt"
                        value={data.membership.paymentProduct.name}
                        color="cyan"
                      />
                    )}

                    <Box mb="2"></Box>

                    <LabelRow
                      label="Från"
                      value={data.membership.paymentFrom}
                      color="green"
                    />
                    <LabelRow
                      label="Till"
                      value={data.membership.paymentTo}
                      color="green"
                    />

                    <Box mb="2"></Box>

                    <LabelRow
                      label="Pris (inkl. moms)"
                      value={data.membership.paymentPrice}
                      color="red"
                    />

                    <LabelRow
                      label="Infasningsdag"
                      value={data.membership.phaseInDay}
                      color="red"
                    />
                    <LabelRow
                      label="Fakturatext"
                      value={data.membership.paymentInvoiceText}
                      color="red"
                    />

                    <Box mb="2"></Box>

                    <Button
                      onClick={changePaymentMethodDisclosure.onOpen}
                      size="sm"
                      mt="2"
                      mb="-1">
                      Ändra betalning
                    </Button>
                  </SwitchDisclosureCard>

                  <BaseCard
                    icon="money-bill-wave"
                    color="#f39c12"
                    title="Betalningsmetod">
                    {!form.formState.dirtyFields['payerSigner'] &&
                    !form.formState.dirtyFields['otherPayer'] &&
                    data.membership.payerSigner ? (
                      <>
                        <SelectInput
                          id="preferredPaymentMethod"
                          label="Önskad återkommande betalmetod"
                          helper="Hur ska abonnemanget betalas månad till månad?"
                          options={[
                            { label: 'Betalkort', value: 'card' },

                            { label: 'Autogiro', value: 'autogiro' },
                            { label: 'Faktura', value: 'invoice' }
                          ]}
                        />

                        <Box
                          w="100%"
                          py="2"
                          px="1"
                          pb="1"
                          rounded="lg"
                          border="2px"
                          borderColor="rgba(255, 255, 255, 0.1)">
                          {prefersCard && (
                            <>
                              <Flex w="100%" py="1" px="2">
                                <FontAwesomeIcon
                                  size="3x"
                                  icon={[
                                    'fad',
                                    data.membership.hasCard
                                      ? 'check-circle'
                                      : 'circle-exclamation'
                                  ]}
                                  color={
                                    data.membership.hasCard
                                      ? 'lightgreen'
                                      : '#f6b93b'
                                  }
                                />
                                <Box ml="3">
                                  <Heading size="sm" mb="0.5">
                                    Betalkort:{' '}
                                    {data.membership.hasCard
                                      ? 'aktiv'
                                      : 'betalning krävs'}
                                  </Heading>
                                  <Text fontSize="xs">
                                    {data.membership.hasCard
                                      ? 'Kortbetalning aktivt'
                                      : 'Kunden behöver göra en kortbetalning för att aktivera betalsättet.'}
                                  </Text>
                                </Box>
                              </Flex>
                            </>
                          )}

                          {prefersAutogiro && (
                            <>
                              <Flex w="100%" py="1" px="2">
                                <FontAwesomeIcon
                                  size="3x"
                                  icon={[
                                    'fad',
                                    data.membership.hasAutogiro
                                      ? 'check-circle'
                                      : 'circle-exclamation'
                                  ]}
                                  color={
                                    data.membership.hasAutogiro
                                      ? 'lightgreen'
                                      : '#f6b93b'
                                  }
                                />
                                <Box ml="4">
                                  <Heading size="sm">
                                    Autogiro:{' '}
                                    {data.membership.hasAutogiro
                                      ? 'aktiv'
                                      : 'medgivande krävs'}
                                  </Heading>
                                  <Text fontSize="sm">
                                    {data.membership.hasAutogiro
                                      ? 'Autogrio aktiv'
                                      : 'Skapa ett medgivande för att möjliggöra autogirobetalning'}
                                  </Text>
                                </Box>
                              </Flex>

                              {!data.membership.hasAutogiro && (
                                <Button onClick={() => null} size="sm" mt="2">
                                  Skapa autogiro medgivande
                                </Button>
                              )}
                            </>
                          )}
                          {prefersInvoice && (
                            <>
                              <Flex w="100%" py="1" px="2">
                                <FontAwesomeIcon
                                  size="3x"
                                  icon={['fad', 'check-circle']}
                                  color={'lightgreen'}
                                />
                                <Box ml="4">
                                  <Heading size="sm">Faktura: Aktivt</Heading>
                                  <Text fontSize="sm">
                                    Fakturabetalning aktivt
                                  </Text>
                                </Box>
                              </Flex>
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Center p="8">
                        <FontAwesomeIcon
                          size="3x"
                          icon={['fad', 'circle-exclamation']}
                        />
                        <Heading size="xs" ml="6">
                          Välja betalare och spara för att ändra betalningsmetod
                        </Heading>
                      </Center>
                    )}
                  </BaseCard>
                </Column>

                <Column>
                  <SwitchDisclosureCard
                    id="hasAdmission"
                    icon="door-open"
                    color="#00b894"
                    title="Insläpp">
                    <RadioInput
                      id="admissionType"
                      label="Typ av insläpp"
                      options={data?.admissionTypes?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />

                    <SelectInput
                      id="zone"
                      label="Zon"
                      options={data?.zones?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />

                    <DateInput id="admissionFrom" label="Insläpp från" />

                    <DateInput
                      id="admissionTo"
                      label="Insläpp från"
                      placeholder="Till och med"
                    />
                  </SwitchDisclosureCard>

                  <SwitchDisclosureCard
                    id="has_binding"
                    icon="arrow-from-left"
                    color="#a29bfe"
                    title="Bindningstid">
                    <DateInput
                      label="Kunden är bundet till"
                      id="boundUntil"></DateInput>
                  </SwitchDisclosureCard>

                  <SwitchDisclosureCard
                    id="hasBoundUntil"
                    icon="ban"
                    color="#e17055"
                    title="Uppsägningstid">
                    <DateInput
                      label="Abonnemangets uppsägningstid"
                      id="boundUntil"></DateInput>
                  </SwitchDisclosureCard>

                  {/* <BaseCard
                  icon="shopping-bag"
                  color="#01a3a4"
                  title="Varuautomat">
                  <TextInput
                    id="fornamn"
                    placeholder="Engångskredit"
                    label="Engångskredit för Varuautomat"
                    rightAddon={'SEK'}
                  />
                  <SwitchInput id="varuautomat" label="Varuautomat BodyShake" />
                </BaseCard> */}
                </Column>
              </SlideOutColumns>
            </form>
          </FormProvider>
        </SlideOutSection>

        <SlideOutSection name="sub-betalperioder">
          <SectionTitle color="#00cec9">betalperioder</SectionTitle>
          <MembershipTimeline
            id={id}
            createDeviation={({ timespans }) => {
              createDeviationDisclosure.onOpen()
              setTimespans(timespans)
            }}
          />
        </SlideOutSection>
      </SlideOutPanel>

      <ChangePaymentMethodModal
        {...changePaymentMethodDisclosure}
        data={data}
        setMembership={setMembership}
      />

      <PaySelector
        {...paySelectorDisclosure}
        payment={{ membershipId: data.membership.id }}
      />

      <MembershipDeviation
        {...createDeviationDisclosure}
        timeRange={{ start: null, end: null }}
        usePeriods={false}
      />

      <MembershipCancel {...cancelDisclosure} id={id} />

      <ShowFile
        {...friskvardsintygDisclosure}
        url={data?.membership?.friskvardsintyg}
      />
    </>
  )
}

export default MembershipPanel

const ChangePaymentMethodModal = ({
  isOpen,
  onOpen,
  onClose,
  data,
  setMembership
}) => {
  const form = useForm({ defaultValues: data })

  useEffect(() => {
    if (isOpen) {
      form.reset({
        paymentType: data.paymentType,
        paymentInterval: data.paymentInterval,
        paymentIntervalUnit: data.paymentIntervalUnit,
        paymentProduct: data.paymentProduct,
        paymentPrice: data.paymentPrice,
        paymentFrom: data.paymentFrom,
        paymentTo: data.paymentTo,
        phaseInDay: data.phaseInDay,
        paymentInvoiceText: data.paymentInvoiceText
      })
    }
  }, [form, isOpen])

  const paymentTypeWatch = form.watch('paymentType')
  const isPartpay = paymentTypeWatch === 'PARTPAY'
  const isDirect = paymentTypeWatch === 'DIRECT'

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ändra betalmetod</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...form}>
            <form>
              <Box mb="2">
                <SelectInput
                  id="paymentType"
                  label="Typ av betalning"
                  options={[
                    { value: 'PARTPAY', label: 'Delbetalning' },
                    { value: 'DIRECT', label: 'Direktbetalning' }
                  ]}
                />
              </Box>
              {paymentTypeWatch !== '' && (
                <>
                  {isPartpay && (
                    <Flex>
                      <NumberInput
                        id="paymentInterval"
                        label="Intervall"
                        w="100%"
                        mr="3"
                      />
                      <Box w="50%">
                        <SelectInput
                          id="paymentIntervalUnit"
                          label="Dag / Månad"
                          options={[
                            { value: 'DAY', label: 'Dag' },
                            { value: 'MONTH', label: 'Månad' }
                          ]}
                        />
                      </Box>
                    </Flex>
                  )}
                  <ProductInput id="paymentProduct" label="Produkt" max={1} />

                  <TextInput
                    id="paymentPrice"
                    label="Pris"
                    rightAddon="inkl. moms"
                  />

                  <Box
                    p="4"
                    my="4"
                    border="1px"
                    borderColor="whiteAlpha.300"
                    rounded="lg">
                    <DateInput id="paymentFrom" label="Från" />
                    <DateInput id="paymentProductTo" label="Till" />
                  </Box>

                  {isPartpay && (
                    <SelectInput
                      id="phaseInDay"
                      label="Infasningsdag"
                      options={phaseInOptions}
                    />
                  )}
                  <TextInput id="paymentInvoiceText" label="Fakturatext" />
                </>
              )}
            </form>
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="ghost" mr="2" onClick={onClose}>
            avbryt
          </Button>
          <Button
            size="sm"
            colorScheme="teal"
            onClick={form.handleSubmit(
              async (values) => {
                await setMembership({ variables: { input: values } })
                onClose()
              },
              (e) => console.log(e)
            )}>
            Uppdatera betalmetod
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const phaseInOptions = [
  { value: 'last_day', label: 'Sista dagen i månaden' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' }
]
