export const uploadFileToS3 = async ({ url, contentType, file }) => {
  await fetch(url, {
    // Your POST endpoint
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
      'Content-Encoding': 'base64'
    },
    body: file
  })
}
