import React, { useState } from 'react'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { Switch, LightMode } from '@chakra-ui/react'

import {
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'

const InputLabel = styled.label`
  max-width: 85%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: ${({ active, error }) =>
    active && error ? '#fff' : active ? '#333' : '#fff'};
`

const InputHighlighter = styled.div`
  width: 100%;
  padding: 1px;
  display: flex;
  flex-direction: column;
  background: ${({ active, error }) =>
    active ? (error ? '#e74c3c' : '#fff') : 'none'};
  border-radius: 4px;
`

const InputOuter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const SwitchInput = ({
  id,
  label,
  helper,
  error,
  required,
  disabled,
  defaultChecked,
  noSpacer = false,
  onChange = () => null,
  ...other
}) => {
  const { control, errors } = useFormContext()

  return (
    <InputSpacer ignore={noSpacer}>
      <FormControl>
        <Controller
          control={control}
          name={id}
          render={({ field }) => {
            onChange(field.value)
            return (
              <InputOuter>
                <FormLabel fontSize="sm" fontWeight="bold" mb="0" htmlFor={id}>
                  {label}
                </FormLabel>

                <LightMode>
                  <Switch
                    h="20px"
                    name={id}
                    size="md"
                    colorScheme="green"
                    onBlur={field.onBlur}
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    {...other}
                  />
                </LightMode>
              </InputOuter>
            )
          }}
        />
      </FormControl>
    </InputSpacer>
  )
}

export default SwitchInput
