import React, { useState, useRef, useCallback } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext
} from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import {
  Box,
  Flex,
  Center,
  Tabs,
  TabList,
  Tab,
  Spinner,
  Button,
  HStack,
  Skeleton,
  ButtonGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Spacer,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'

import Calendar from 'containers/Calendar'
import CalendarAdd from 'containers/CalendarAdd'
import { pushPanel } from 'containers/PanelNavigation'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'components/form/TextInput'
import SelectInput from 'components/form/SelectInput'
import CalendarOrders from './CalendarOrders'
import { SpacingContext } from 'utils/spacingContext'
import CalendarCopy from './CalendarCopy'
import DateInput from 'components/form/DateInput'

const GET_CALENDAR = gql`
  query getCalendar(
    $view: String
    $start: String
    $end: String
    $grouptrainingActive: Boolean
    $servicesActive: Boolean
    $courseActive: Boolean
    $ordersActive: Boolean
    $activeInstructor: String
    $activeOrder: String
    $timeSlots: Int
    $calendarStartTime: String
    $calendarEndTime: String
  ) {
    calendar(
      input: {
        view: $view
        start: $start
        end: $end
        grouptrainingActive: $grouptrainingActive
        servicesActive: $servicesActive
        courseActive: $courseActive
        ordersActive: $ordersActive
        activeInstructor: $activeInstructor
        activeOrder: $activeOrder
        timeSlots: $timeSlots
        calendarStartTime: $calendarStartTime
        calendarEndTime: $calendarEndTime
      }
    ) {
      start
      end

      views {
        id
        name
      }

      events {
        id
        type
        title
        color
        accentColor
        editable

        topRight

        start
        end

        options
      }

      activeInstructor {
        id
        firstname
        lastname
      }

      instructors {
        id
        firstname
        lastname
      }

      grouptrainingActive
      servicesActive
      courseActive
      ordersActive
    }
  }
`

const DELETE_EVENT = gql`
  mutation deleteEvent($id: String) {
    deleteEvent(id: $id) {
      success
      message
    }
  }
`

const RESCHEDULE_EVENT = gql`
  mutation rescheduleEvent(
    $id: String
    $startTimestamp: String
    $endTimestamp: String
  ) {
    rescheduleEvent(
      id: $id
      startTimestamp: $startTimestamp
      endTimestamp: $endTimestamp
    ) {
      id
      name
    }
  }
`

const RESCHEDULE_DECISION = gql`
  mutation rescheduleDecision($eventId: String, $id: String) {
    rescheduleDecision(eventId: $eventId, id: $id) {
      success
      message
    }
  }
`

const CLEAR_WEEK = gql`
  mutation clearWeek($date: String) {
    clearWeek(date: $date) {
      success
      message
    }
  }
`

// const BookingCalendar = () => {
//   const calendarRef = useRef()
//   const toast = useToaster()
//   const [state, setState] = useState({})
//   const [rescheduleOptions, setRescheduleOptions] = useState([])
//   const [currentlyRescheduling, setCurrentlyRescheduling] = useState(null)
//   const [isDeleting, setIsDeleting] = useState(null)

//   const {
//     isOpen: isOpenOrders,
//     onOpen: onOpenOrders,
//     onClose: onCloseOrders
//   } = useDisclosure()

//   const {
//     isOpen: isOpenSettings,
//     onOpen: onOpenSettings,
//     onClose: onCloseSettings
//   } = useDisclosure()

//   const {
//     isOpen: isOpenGoToDay,
//     onOpen: onOpenGoToDay,
//     onClose: onCloseGoToDay
//   } = useDisclosure()

//   const {
//     isOpen: isOpenCopyWeek,
//     onOpen: onOpenCopyWeek,
//     onClose: onCloseCopyWeek
//   } = useDisclosure()

//   const {
//     isOpen: isOpenClearWeek,
//     onOpen: onOpenClearWeek,
//     onClose: onCloseClearWeek
//   } = useDisclosure()

//   const form = useForm()

//   const { loading, error, data, refetch } = useQuery(GET_CALENDAR, {
//     fetchPolicy: 'network-only',
//     onCompleted: ({ calendar }) => {
//       form.reset(calendar)
//     }
//   })

//   useFormAutoSubmit({
//     form,
//     onSubmit: form.handleSubmit((vals) => {
//       console.log('form did submit:', vals)
//       setState(vals)
//       doRefetchCalendar()
//     })
//   })

//   const [doDeleteEvent, { loading: deleteEventLoading }] = useMutation(
//     DELETE_EVENT,
//     {
//       onError: (e) => {
//         toast({
//           title: `Kunde inte radera händelsen`,
//           description: e.message,
//           status: 'error',
//           position: 'top-right',
//           isClosable: true
//         })
//         setIsDeleting(null)
//         doRefetchCalendar()
//       },
//       onCompleted: (data) => {
//         toast({
//           title: `Händelse raderad`,
//           description: data.deleteEvent.message,
//           status: 'warning',
//           position: 'top-right',
//           isClosable: true
//         })
//         setIsDeleting(null)
//         doRefetchCalendar()
//       }
//     }
//   )

//   const [doRescheduleEvent] = useMutation(RESCHEDULE_EVENT, {
//     onError: (e) => {
//       toast({
//         title: `Kunde inte flytta händelse.`,
//         description: e.message,
//         status: 'error',
//         position: 'top-right',
//         isClosable: true
//       })
//     },
//     onCompleted: (data) => {
//       if (data.rescheduleEvent.length) {
//         setRescheduleOptions(data.rescheduleEvent)
//       } else {
//         toast({
//           title: `Händelse flyttad!`,
//           description: '',
//           status: 'success',
//           position: 'top-right',
//           isClosable: true
//         })
//         doRefetchCalendar()
//       }
//     }
//   })

//   const [doMakeResheduleDecision] = useMutation(RESCHEDULE_DECISION, {
//     onError: (e) => {
//       toast({
//         title: `Kunde inte flytta händelse.`,
//         description: e.message,
//         status: 'error',
//         position: 'top-right',
//         isClosable: true
//       })
//       setRescheduleOptions([])
//     },
//     onCompleted: (data) => {
//       setCurrentlyRescheduling(null)
//       setRescheduleOptions([])
//       toast({
//         title: `Händelse flyttad!`,
//         description: data.rescheduleDecision.message,
//         status: 'success',
//         position: 'top-right',
//         isClosable: true
//       })
//       doRefetchCalendar()
//     }
//   })

//   const [doClearWeek] = useMutation(CLEAR_WEEK, {
//     onError: (e) => {
//       toast({
//         title: `Kunde inte rensa veckan`,
//         description: e.message,
//         status: 'error',
//         position: 'top-right',
//         isClosable: true
//       })
//       onCloseClearWeek()
//     },
//     onCompleted: (data) => {
//       toast({
//         title: `Vecka rensad.`,
//         description: data.clearWeek.message,
//         status: 'warning',
//         position: 'top-right',
//         isClosable: true
//       })
//       doRefetchCalendar()
//       onCloseClearWeek()
//     }
//   })

//   const handleEventChange = ({ event, oldEvent }) => {
//     setCurrentlyRescheduling(oldEvent.extendedProps.id)
//     doRescheduleEvent({
//       variables: {
//         id: `${oldEvent.extendedProps.id}`,
//         startTimestamp: event.end,
//         endTimestamp: event.end
//       }
//     })
//   }

//   const doFetchEvents = useCallback(
//     async ({ startStr, endStr }) => {
//       // const result = await refetch({
//       //   ...state
//       //   //        activeInstructor: state?.activeInstructor?.id,
//       //   // start: startStr,
//       //   // end: endStr
//       // })
//       // return result.data.calendar.events
//       return []
//     },
//     [refetch]
//   )

//   const doRefetchCalendar = () => {
//     let calendarApi = calendarRef.current.getApi()
//     calendarApi.refetchEvents()
//   }

//   if (error) {
//     return (
//       <Center>
//         <Alert status="error" rounded="lg" mb="2" w="40%">
//           <AlertIcon />
//           <AlertTitle>Kunde inte ladda in kalendern</AlertTitle>
//           <AlertDescription fontSize="sm">försök igen senare.</AlertDescription>
//         </Alert>
//       </Center>
//     )
//   }

//   return (
//     <>
//       <FormProvider {...form}>
//         <form>
//           <Box pos="relative">
//             {loading || error ? (
//               <Center mb="4">
//                 <Flex flexDir="column" align="center">
//                   <Skeleton h="25px" w="100%" mb="2" />
//                   <Flex>
//                     <Skeleton h="25px" w="60px" mr="2" />
//                     <Skeleton h="25px" w="60px" mr="2" />
//                     <Skeleton h="25px" w="60px" mr="2" />
//                     <Skeleton h="25px" w="60px" />
//                   </Flex>
//                 </Flex>
//               </Center>
//             ) : (
//               <Box
//                 className="topper"
//                 pos="sticky"
//                 top="1"
//                 zIndex="sticky"
//                 mb="1">
//                 <Flex justify="center">
//                   <Center
//                     h="35px"
//                     pl="1"
//                     mb="1"
//                     rounded="full"
//                     bg="gray.600"
//                     boxShadow="xl">
//                     <Controller
//                       control={form.control}
//                       name={'view'}
//                       render={({ field }) => (
//                         <Tabs
//                           onChange={(val) => field.onChange(val)}
//                           value={field.value}
//                           size="sm"
//                           colorScheme="blue"
//                           align="center"
//                           variant="soft-rounded">
//                           <TabList borderBottom="0" mb="1px">
//                             {data.calendar.views.map(({ id, name }, index) => (
//                               <Tab
//                                 fontSize="xs"
//                                 key={id}
//                                 color="#fff"
//                                 borderBottom="none"
//                                 mr={
//                                   data.calendar.views.length === index ? 0 : 1
//                                 }
//                                 _hover={{ bg: 'whiteAlpha.300' }}>
//                                 {name}
//                               </Tab>
//                             ))}
//                           </TabList>
//                         </Tabs>
//                       )}
//                     />
//                   </Center>
//                 </Flex>

//                 <Center w="100%" my="2">
//                   <HStack>
//                     <SubsetButton id="grouptrainingActive">
//                       Gruppträning
//                     </SubsetButton>
//                     <SubsetButton id="servicesActive">Tjänster</SubsetButton>
//                     <SubsetButton id="coursesActive">Arrangemang</SubsetButton>
//                     <SubsetButton id="ordersActive">Beställningar</SubsetButton>
//                   </HStack>
//                   <Box ml="2">
//                     <SubsetButton id="businessHours">Arbetstider</SubsetButton>
//                   </Box>
//                 </Center>

//                 <Text fontSize="xs" textAlign="center" opacity={0.8}>
//                   {loading ? (
//                     'Laddar...'
//                   ) : (
//                     <>
//                       {'Du företräder: '}
//                       <b>
//                         {data.calendar.activeInstructor.firstname}{' '}
//                         {data.calendar.activeInstructor.lastname}
//                       </b>
//                     </>
//                   )}
//                 </Text>

//                 <Center w="100%">
//                   {data.calendar.activeOrder && (
//                     <Tag
//                       colorScheme="yellow"
//                       variant="outline"
//                       rounded="full"
//                       mt="2">
//                       <TagLabel>Bokning "Simhall" aktiv</TagLabel>
//                       <TagCloseButton />
//                     </Tag>
//                   )}
//                 </Center>
//               </Box>
//             )}

//             <Box>
//               <Box pos="relative">
//                 <Flex align="center" pb="1" px="3">
//                   {!loading && data.calendar.instructors.length && (
//                     <Box>
//                       <SpacingContext.Provider value="0">
//                         <SelectInput
//                           id="activeInstructor"
//                           size="sm"
//                           rounded="full"
//                           fontWeight="bold"
//                           placeholder="Välj utövare"
//                           options={data.calendar.instructors.map(
//                             ({ id, firstname, lastname }) => ({
//                               value: id,
//                               label: `${firstname} ${lastname}`
//                             })
//                           )}></SelectInput>
//                       </SpacingContext.Provider>
//                     </Box>
//                   )}
//                   <Button
//                     leftIcon={
//                       <FontAwesomeIcon icon={['fad', 'flag-pennant']} />
//                     }
//                     colorScheme="yellow"
//                     size="sm"
//                     rounded="full"
//                     ml="2"
//                     px="5"
//                     onClick={onOpenOrders}>
//                     Beställningar
//                   </Button>
//                   <Spacer w="100%" />
//                   <ButtonGroup>
//                     <Button
//                       size="sm"
//                       leftIcon={<FontAwesomeIcon icon={['fad', 'copy']} />}
//                       color=""
//                       onClick={onOpenCopyWeek}>
//                       Kopiera vecka
//                     </Button>
//                     <Button
//                       size="sm"
//                       leftIcon={<FontAwesomeIcon icon={['fad', 'trash-alt']} />}
//                       color="red.300"
//                       onClick={onOpenClearWeek}>
//                       Töm vecka
//                     </Button>
//                     <Button
//                       size="sm"
//                       leftIcon={
//                         <FontAwesomeIcon
//                           icon={['fad', 'calendar-arrow-down']}
//                         />
//                       }
//                       color="blue.50"
//                       onClick={onOpenGoToDay}>
//                       Gå till dag
//                     </Button>
//                     <Button
//                       size="sm"
//                       leftIcon={<FontAwesomeIcon icon={['fad', 'cogs']} />}
//                       color=""
//                       onClick={onOpenSettings}>
//                       Inställningar
//                     </Button>
//                   </ButtonGroup>
//                 </Flex>
//                 {!loading && (
//                   <Calendar
//                     ref={calendarRef}
//                     onEventChange={handleEventChange}
//                     doFetchEvents={doFetchEvents}
//                     eventContent={(eventInfo) => {
//                       const hasOption = (option) =>
//                         eventInfo.event.extendedProps.options.includes(option)

//                       return (
//                         <Menu>
//                           <MenuButton as={Box} w="100%" h="100%" p="1">
//                             <Flex
//                               h="100%"
//                               flexDir="column"
//                               justify="flex-start"
//                               align="flex-start">
//                               <Text fontSize="xs" fontWeight="bold">
//                                 {eventInfo.timeText}{' '}
//                                 {eventInfo.event.extendedProps.topRight}
//                               </Text>
//                               <Text fontSize="xs">
//                                 <ReactMarkdown>
//                                   {eventInfo.event.title}
//                                 </ReactMarkdown>
//                               </Text>
//                             </Flex>
//                           </MenuButton>
//                           <MenuList>
//                             {hasOption('OPEN') && (
//                               <MenuItem
//                                 onClick={() =>
//                                   pushPanel('event', { id: eventInfo.event.id })
//                                 }
//                                 icon={
//                                   <FontAwesomeIcon
//                                     size="lg"
//                                     icon={['fal', 'box-open']}
//                                   />
//                                 }>
//                                 Öppna bokning
//                               </MenuItem>
//                             )}
//                             {hasOption('COPY') && (
//                               <MenuItem
//                                 icon={
//                                   <FontAwesomeIcon
//                                     size="lg"
//                                     icon={['fal', 'copy']}
//                                   />
//                                 }>
//                                 Kopiera händelse
//                               </MenuItem>
//                             )}
//                             {hasOption('COPY_DAY') && (
//                               <MenuItem
//                                 icon={
//                                   <FontAwesomeIcon
//                                     size="lg"
//                                     icon={['fal', 'calendar-day']}
//                                   />
//                                 }>
//                                 Kopiera dag
//                               </MenuItem>
//                             )}
//                             {hasOption('DELETE') && (
//                               <MenuItem
//                                 onClick={() =>
//                                   setIsDeleting(eventInfo.event.id)
//                                 }
//                                 icon={
//                                   <FontAwesomeIcon
//                                     size="lg"
//                                     icon={['fal', 'trash-alt']}
//                                   />
//                                 }
//                                 color="red.300">
//                                 Radera händelse
//                               </MenuItem>
//                             )}
//                           </MenuList>
//                         </Menu>
//                       )
//                     }}
//                   />
//                 )}
//               </Box>
//               <Box
//                 pos="sticky"
//                 bottom="15px"
//                 right="0px"
//                 w="100%"
//                 mt="-45px"
//                 zIndex="sticky"
//                 opacity={loading ? 0.2 : 1}>
//                 <CalendarAdd />
//               </Box>
//             </Box>
//           </Box>

//           <Modal size="xl" isOpen={isOpenSettings} onClose={onCloseSettings}>
//             <ModalOverlay />
//             <ModalContent>
//               <ModalHeader pb="0">Inställningar bokningskalender</ModalHeader>
//               <ModalCloseButton />
//               <ModalBody>
//                 <Text fontSize="sm" mb="4">
//                   Här kan du ändra stilen på kalendern för att passa dina behov.
//                   Dina inställningar sparas på servern men påverkar bara ditt
//                   konto.
//                 </Text>

//                 <SelectInput
//                   id="TimeSlots"
//                   label="Minutslag för kalenderrader"
//                   helper="Vilket minutslag ska raderna i kalendern börja på?"
//                   options={[
//                     { value: '15', label: '15' },
//                     { value: '30', label: '30' },
//                     { value: '45', label: '45' }
//                   ]}></SelectInput>
//                 <TextInput
//                   label="Kalenderns starttid"
//                   id="calendarStartTime"
//                   placeholder="HH:MM"
//                 />
//                 <TextInput
//                   label="Kalenderns sluttid"
//                   id="calendarEndTime"
//                   placeholder="HH:MM"
//                 />
//               </ModalBody>

//               <ModalFooter>
//                 <Button w="100%" size="sm" onClick={onCloseSettings}>
//                   Klar
//                 </Button>
//               </ModalFooter>
//             </ModalContent>
//           </Modal>

//           <Modal size="xl" isOpen={isOpenGoToDay} onClose={onCloseGoToDay}>
//             <ModalOverlay />
//             <ModalContent>
//               <ModalHeader pb="0">Gå till dag i kalendern</ModalHeader>
//               <ModalCloseButton />
//               <ModalBody>
//                 <Text fontSize="sm" mb="4">
//                   Välj dag i kalendern och klicka sedan klar.
//                 </Text>
//                 <Flex w="100%" justify="center" align="center">
//                   <Flex w="100%" rounded="md" overflow="hidden">
//                     <DateInput id="start" scroll={{ enabled: true }} />
//                   </Flex>
//                 </Flex>
//               </ModalBody>

//               <ModalFooter>
//                 <Button
//                   w="100%"
//                   size="sm"
//                   onClick={() => {
//                     onCloseGoToDay()
//                   }}>
//                   Klar
//                 </Button>
//               </ModalFooter>
//             </ModalContent>
//           </Modal>

//           <Controller
//             control={form.control}
//             name="activeOrder"
//             render={({ field }) => (
//               <CalendarOrders
//                 isOpen={isOpenOrders}
//                 onClose={onCloseOrders}
//                 onBookingSelected={() => {
//                   field.onClick()
//                   field.onBlur()
//                 }}
//               />
//             )}
//           />
//         </form>
//       </FormProvider>

//       <Modal
//         isCentered
//         size="xl"
//         isOpen={rescheduleOptions.length}
//         onClose={() => setRescheduleOptions([])}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader pb="0">Boka om / ändra händelse</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody pt="4" pb="6">
//             {rescheduleOptions.map(({ id, name }) => (
//               <Button
//                 w="100%"
//                 mb="2"
//                 onClick={() => {
//                   doMakeResheduleDecision({
//                     variables: {
//                       eventId: currentlyRescheduling,
//                       id: id
//                     }
//                   })
//                 }}>
//                 {name}
//               </Button>
//             ))}
//           </ModalBody>
//         </ModalContent>
//       </Modal>

//       <CalendarCopy isOpen={isOpenCopyWeek} onClose={onCloseCopyWeek} />

//       <DeleteConfirmDialog
//         id={isDeleting}
//         isOpen={isDeleting}
//         onClose={() => setIsDeleting(null)}
//         doDeleteEvent={doDeleteEvent}
//         isLoading={deleteEventLoading}
//       />

//       <ClearWeekDialog
//         isOpen={isOpenClearWeek}
//         onClose={onCloseClearWeek}
//         doClearWeek={() => {
//           let calendarApi = calendarRef.current.getApi()
//           doClearWeek({ variables: { date: calendarApi.getDate() } })
//         }}
//       />
//     </>
//   )
// }

// export default BookingCalendar

const BookingCalendar = () => {
  const calendarRef = useRef()
  const toast = useToaster()
  const [state, setState] = useState({})
  const [rescheduleOptions, setRescheduleOptions] = useState([])
  const [currentlyRescheduling, setCurrentlyRescheduling] = useState(null)
  const [isDeleting, setIsDeleting] = useState(null)

  const {
    isOpen: isOpenOrders,
    onOpen: onOpenOrders,
    onClose: onCloseOrders
  } = useDisclosure()

  const {
    isOpen: isOpenSettings,
    onOpen: onOpenSettings,
    onClose: onCloseSettings
  } = useDisclosure()

  const {
    isOpen: isOpenGoToDay,
    onOpen: onOpenGoToDay,
    onClose: onCloseGoToDay
  } = useDisclosure()

  const {
    isOpen: isOpenCopyWeek,
    onOpen: onOpenCopyWeek,
    onClose: onCloseCopyWeek
  } = useDisclosure()

  const {
    isOpen: isOpenClearWeek,
    onOpen: onOpenClearWeek,
    onClose: onCloseClearWeek
  } = useDisclosure()

  const form = useForm()

  const { loading, error, data, refetch, called } = useQuery(GET_CALENDAR, {
    fetchPolicy: 'network-only',
    onCompleted: ({ calendar }) => {
      form.reset(calendar)
    }
  })

  useFormAutoSubmit({
    form,
    onSubmit: form.handleSubmit((vals) => {
      setState(vals)
      doRefetchCalendar()
    })
  })

  const [doDeleteEvent, { loading: deleteEventLoading }] = useMutation(
    DELETE_EVENT,
    {
      onError: (e) => {
        toast({
          title: `Kunde inte radera händelsen`,
          description: e.message,
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
        setIsDeleting(null)
        doRefetchCalendar()
      },
      onCompleted: (data) => {
        toast({
          title: `Händelse raderad`,
          description: data.deleteEvent.message,
          status: 'warning',
          position: 'top-right',
          isClosable: true
        })
        setIsDeleting(null)
        doRefetchCalendar()
      }
    }
  )

  const [doRescheduleEvent] = useMutation(RESCHEDULE_EVENT, {
    onError: (e) => {
      toast({
        title: `Kunde inte flytta händelse.`,
        description: e.message,
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
    },
    onCompleted: (data) => {
      if (data.rescheduleEvent.length) {
        setRescheduleOptions(data.rescheduleEvent)
      } else {
        toast({
          title: `Händelse flyttad!`,
          description: '',
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
        doRefetchCalendar()
      }
    }
  })

  const [doMakeResheduleDecision] = useMutation(RESCHEDULE_DECISION, {
    onError: (e) => {
      toast({
        title: `Kunde inte flytta händelse.`,
        description: e.message,
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
      setRescheduleOptions([])
    },
    onCompleted: (data) => {
      setCurrentlyRescheduling(null)
      setRescheduleOptions([])
      toast({
        title: `Händelse flyttad!`,
        description: data.rescheduleDecision.message,
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
      doRefetchCalendar()
    }
  })

  const [doClearWeek] = useMutation(CLEAR_WEEK, {
    onError: (e) => {
      toast({
        title: `Kunde inte rensa veckan`,
        description: e.message,
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
      onCloseClearWeek()
    },
    onCompleted: (data) => {
      toast({
        title: `Vecka rensad.`,
        description: data.clearWeek.message,
        status: 'warning',
        position: 'top-right',
        isClosable: true
      })
      doRefetchCalendar()
      onCloseClearWeek()
    }
  })

  const handleEventChange = ({ event, oldEvent }) => {
    setCurrentlyRescheduling(oldEvent.extendedProps.id)
    doRescheduleEvent({
      variables: {
        id: `${oldEvent.extendedProps.id}`,
        startTimestamp: event.end,
        endTimestamp: event.end
      }
    })
  }

  const doFetchEvents = useCallback(
    async ({ startStr, endStr }) => {
      const result = await refetch({
        ...state,
        activeInstructor: state?.activeInstructor?.id,
        start: startStr,
        end: endStr
      })
      return result.data.calendar.events
      return []
    },
    [refetch]
  )

  const doRefetchCalendar = () => {
    let calendarApi = calendarRef.current.getApi()
    calendarApi.refetchEvents()
  }

  if (error) {
    return (
      <Center>
        <Alert status="error" rounded="lg" mb="2" w="40%">
          <AlertIcon />
          <AlertTitle>Kunde inte ladda in kalendern</AlertTitle>
          <AlertDescription fontSize="sm">försök igen senare.</AlertDescription>
        </Alert>
      </Center>
    )
  }

  if (loading && !data) {
    return (
      <>
        <Center mb="4">
          <Flex flexDir="column" align="center">
            <Skeleton h="25px" w="100%" mb="2" />
            <Flex>
              <Skeleton h="25px" w="60px" mr="2" />
              <Skeleton h="25px" w="60px" mr="2" />
              <Skeleton h="25px" w="60px" mr="2" />
              <Skeleton h="25px" w="60px" />
            </Flex>
          </Flex>
        </Center>
        <Skeleton mt="4" h="60vh" w="100%" rounded="lg" />
      </>
    )
  }

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Box className="topper" pos="sticky" top="1" zIndex="sticky" mb="1">
            <Flex justify="center">
              <Center
                h="35px"
                pl="1"
                mb="1"
                rounded="full"
                bg="gray.600"
                boxShadow="xl">
                <Controller
                  control={form.control}
                  name={'view'}
                  render={({ field }) => (
                    <Tabs
                      onChange={(val) => field.onChange(val)}
                      value={field.value}
                      size="sm"
                      colorScheme="blue"
                      align="center"
                      variant="soft-rounded">
                      <TabList borderBottom="0" mb="1px">
                        {data.calendar.views.map(({ id, name }, index) => (
                          <Tab
                            fontSize="xs"
                            key={id}
                            color="#fff"
                            borderBottom="none"
                            mr={data.calendar.views.length === index ? 0 : 1}
                            _hover={{ bg: 'whiteAlpha.300' }}>
                            {name}
                          </Tab>
                        ))}
                      </TabList>
                    </Tabs>
                  )}
                />
              </Center>
            </Flex>

            <Center w="100%" my="2">
              <HStack>
                <SubsetButton id="grouptrainingActive">
                  Gruppträning
                </SubsetButton>
                <SubsetButton id="servicesActive">Tjänster</SubsetButton>
                <SubsetButton id="coursesActive">Arrangemang</SubsetButton>
                <SubsetButton id="ordersActive">Beställningar</SubsetButton>
              </HStack>
              <Box ml="2">
                <SubsetButton color="blue" id="businessHours">
                  Arbetstider
                </SubsetButton>
              </Box>
            </Center>

            <Text fontSize="xs" textAlign="center" opacity={0.8}>
              {'Du företräder: '}
              <b>
                {data.calendar.activeInstructor.firstname}{' '}
                {data.calendar.activeInstructor.lastname}
              </b>
            </Text>

            <Center w="100%">
              {data.calendar.activeOrder && (
                <Tag
                  colorScheme="yellow"
                  variant="outline"
                  rounded="full"
                  mt="2">
                  <TagLabel>Bokning "Simhall" aktiv</TagLabel>
                  <TagCloseButton />
                </Tag>
              )}
            </Center>
          </Box>

          <Flex align="center" pb="1" px="3">
            <Box>
              <SpacingContext.Provider value="0">
                <SelectInput
                  id="activeInstructor"
                  size="sm"
                  rounded="full"
                  fontWeight="bold"
                  placeholder="Välj utövare"
                  options={data.calendar.instructors.map(
                    ({ id, firstname, lastname }) => ({
                      value: id,
                      label: `${firstname} ${lastname}`
                    })
                  )}></SelectInput>
              </SpacingContext.Provider>
            </Box>

            <Button
              leftIcon={<FontAwesomeIcon icon={['fad', 'flag-pennant']} />}
              colorScheme="yellow"
              size="sm"
              rounded="full"
              ml="2"
              px="5"
              onClick={onOpenOrders}>
              Beställningar
            </Button>
            <Center ml="4">{loading && <Spinner size="md" />}</Center>
            <Spacer w="100%" />
            <ButtonGroup>
              <Button
                size="sm"
                leftIcon={<FontAwesomeIcon icon={['fad', 'copy']} />}
                color=""
                onClick={onOpenCopyWeek}>
                Kopiera vecka
              </Button>
              <Button
                size="sm"
                leftIcon={<FontAwesomeIcon icon={['fad', 'trash-alt']} />}
                color="red.300"
                onClick={onOpenClearWeek}>
                Töm vecka
              </Button>
              <Button
                size="sm"
                leftIcon={
                  <FontAwesomeIcon icon={['fad', 'calendar-arrow-down']} />
                }
                color="blue.50"
                onClick={onOpenGoToDay}>
                Gå till dag
              </Button>
              <Button
                size="sm"
                leftIcon={<FontAwesomeIcon icon={['fad', 'cogs']} />}
                color=""
                onClick={onOpenSettings}>
                Inställningar
              </Button>
            </ButtonGroup>
          </Flex>

          <Calendar
            ref={calendarRef}
            onEventChange={handleEventChange}
            doFetchEvents={doFetchEvents}
            eventContent={(eventInfo) => {
              const hasOption = (option) =>
                eventInfo.event.extendedProps.options.includes(option)

              return (
                <Menu>
                  <MenuButton as={Box} w="100%" h="100%" p="1">
                    <Flex
                      h="100%"
                      flexDir="column"
                      justify="flex-start"
                      align="flex-start">
                      <Text fontSize="xs" fontWeight="bold">
                        {eventInfo.timeText}{' '}
                        {eventInfo.event.extendedProps.topRight}
                      </Text>
                      <Box fontSize="xs">
                        <ReactMarkdown>{eventInfo.event.title}</ReactMarkdown>
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {hasOption('OPEN') && (
                      <MenuItem
                        onClick={() =>
                          pushPanel('event', { id: eventInfo.event.id })
                        }
                        icon={
                          <FontAwesomeIcon
                            size="lg"
                            icon={['fal', 'box-open']}
                          />
                        }>
                        Öppna bokning
                      </MenuItem>
                    )}
                    {hasOption('COPY') && (
                      <MenuItem
                        icon={
                          <FontAwesomeIcon size="lg" icon={['fal', 'copy']} />
                        }>
                        Kopiera händelse
                      </MenuItem>
                    )}
                    {hasOption('COPY_DAY') && (
                      <MenuItem
                        icon={
                          <FontAwesomeIcon
                            size="lg"
                            icon={['fal', 'calendar-day']}
                          />
                        }>
                        Kopiera dag
                      </MenuItem>
                    )}
                    {hasOption('DELETE') && (
                      <MenuItem
                        onClick={() => setIsDeleting(eventInfo.event.id)}
                        icon={
                          <FontAwesomeIcon
                            size="lg"
                            icon={['fal', 'trash-alt']}
                          />
                        }
                        color="red.300">
                        Radera händelse
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )
            }}
          />
          <Box
            pos="sticky"
            bottom="15px"
            right="0px"
            w="100%"
            mt="-45px"
            zIndex="sticky"
            opacity={loading ? 0.2 : 1}>
            <CalendarAdd />
          </Box>

          <Modal size="xl" isOpen={isOpenSettings} onClose={onCloseSettings}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader pb="0">Inställningar bokningskalender</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize="sm" mb="4">
                  Här kan du ändra stilen på kalendern för att passa dina behov.
                  Dina inställningar sparas på servern men påverkar bara ditt
                  konto.
                </Text>

                <SelectInput
                  id="TimeSlots"
                  label="Minutslag för kalenderrader"
                  helper="Vilket minutslag ska raderna i kalendern börja på?"
                  options={[
                    { value: '15', label: '15' },
                    { value: '30', label: '30' },
                    { value: '45', label: '45' }
                  ]}></SelectInput>
                <TextInput
                  label="Kalenderns starttid"
                  id="calendarStartTime"
                  placeholder="HH:MM"
                />
                <TextInput
                  label="Kalenderns sluttid"
                  id="calendarEndTime"
                  placeholder="HH:MM"
                />
              </ModalBody>

              <ModalFooter>
                <Button w="100%" size="sm" onClick={onCloseSettings}>
                  Klar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal size="xl" isOpen={isOpenGoToDay} onClose={onCloseGoToDay}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader pb="0">Gå till dag i kalendern</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize="sm" mb="4">
                  Välj dag i kalendern och klicka sedan klar.
                </Text>
                <Flex w="100%" justify="center" align="center">
                  <Flex w="100%" rounded="md" overflow="hidden">
                    <DateInput w="100%" id="start" scroll={{ enabled: true }} />
                  </Flex>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  w="100%"
                  size="sm"
                  onClick={() => {
                    onCloseGoToDay()
                  }}>
                  Klar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Controller
            control={form.control}
            name="activeOrder"
            render={({ field }) => (
              <CalendarOrders
                isOpen={isOpenOrders}
                onClose={onCloseOrders}
                onBookingSelected={() => {
                  field.onClick()
                  field.onBlur()
                }}
              />
            )}
          />
        </form>
      </FormProvider>

      <Modal
        isCentered
        size="xl"
        isOpen={rescheduleOptions.length}
        onClose={() => setRescheduleOptions([])}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb="0">Boka om / ändra händelse</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt="4" pb="6">
            {rescheduleOptions.map(({ id, name }) => (
              <Button
                w="100%"
                mb="2"
                onClick={() => {
                  doMakeResheduleDecision({
                    variables: {
                      eventId: currentlyRescheduling,
                      id: id
                    }
                  })
                }}>
                {name}
              </Button>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>

      <CalendarCopy isOpen={isOpenCopyWeek} onClose={onCloseCopyWeek} />

      <DeleteConfirmDialog
        id={isDeleting}
        isOpen={isDeleting}
        onClose={() => setIsDeleting(null)}
        doDeleteEvent={doDeleteEvent}
        isLoading={deleteEventLoading}
      />

      <ClearWeekDialog
        isOpen={isOpenClearWeek}
        onClose={onCloseClearWeek}
        doClearWeek={() => {
          let calendarApi = calendarRef.current.getApi()
          doClearWeek({ variables: { date: calendarApi.getDate() } })
        }}
      />
    </>
  )
}

export default BookingCalendar

const SubsetButton = ({ id, color, children }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => (
        <Button
          size="xs"
          rounded="full"
          colorScheme={field.value ? color || 'green' : null}
          opacity={field.value ? 1 : 0.1}
          _hover={{ opacity: 1 }}
          onClick={() => {
            field.onChange(!field.value)
            field.onBlur()
          }}
          iconSpacing={1}
          rightIcon={
            <Center boxSize={'12px'} mt="0.5">
              {field.value ? (
                <FontAwesomeIcon size="lg" icon={['fal', 'check']} />
              ) : (
                <FontAwesomeIcon size="lg" icon={['fal', 'times']} />
              )}
            </Center>
          }>
          {children}
        </Button>
      )}
    />
  )
}

const DeleteConfirmDialog = ({
  id,
  isOpen,
  onClose,
  doDeleteEvent,
  isLoading = false
}) => {
  const cancelRef = useRef()

  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Radera händelse?
          </AlertDialogHeader>

          <AlertDialogBody>
            Är du säker på att du vill radera den här händelsen? Du kan inte
            ångra detta.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Avbryt
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              ml={3}
              onClick={() => doDeleteEvent({ variables: { id } })}>
              Radera
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const ClearWeekDialog = ({
  isOpen,
  onClose,
  doClearWeek,
  isLoading = false
}) => {
  const cancelRef = useRef()

  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Töm vecka?
          </AlertDialogHeader>

          <AlertDialogBody>
            Ta bort alla händelser i öppen vecka?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Avbryt
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              ml={3}
              onClick={doClearWeek}>
              Radera
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
