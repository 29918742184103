import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Wrap, Tag, Text } from '@chakra-ui/react'

const TagRender = ({ tags = [], size = 'md' }) => {
  if (!tags) return null
  return (
    <Wrap spacing="4px">
      {tags.map(({ name, value, color }) => (
        <Tag key={`${name}-${value}`} size={size} color={`${color}.200`}>
          <Text>
            {name ? (
              <>
                <b>{name}: </b>
                {value}
              </>
            ) : (
              value
            )}
          </Text>
        </Tag>
      ))}
    </Wrap>
  )
}

export default TagRender
