import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'
import TextInput from './TextInput'

const RadioInput = ({ id, label, required, options, ...other }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => (
        <InputSpacer>
          <FormControl id={id} isRequired={required}>
            <FormLabel fontSize="sm" fontWeight="bold" mb="0.5">
              {label}
            </FormLabel>
            <Flex flexWrap="wrap">
              {options.map(({ value, label, icon, icon2, color }) => (
                <Box
                  key={label}
                  bg={
                    value === field.value
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(255, 255, 255, 0.04)'
                  }
                  border="1px"
                  borderColor={
                    value === field.value
                      ? 'rgba(255, 255, 255, 0.8)'
                      : 'transparent'
                  }
                  opacity={value === field.value ? 1 : 0.8}
                  px="3"
                  py="2"
                  rounded="md"
                  mr="1"
                  mb="1"
                  cursor="pointer"
                  color={color}
                  _hover={{ bg: 'rgba(255, 255, 255, 0.15)', opacity: 1 }}
                  onClick={() => field.onChange(value)}>
                  <Flex>
                    {icon && (
                      <Box mr="2">
                        <FontAwesomeIcon icon={['fal', icon]} />
                      </Box>
                    )}
                    {icon2 && (
                      <Box mr="2">
                        <FontAwesomeIcon icon={['fal', icon2]} />
                      </Box>
                    )}
                    <Text fontSize="sm">{label}</Text>
                  </Flex>
                </Box>
              ))}
            </Flex>
          </FormControl>
        </InputSpacer>
      )}
    />
  )
}

export default RadioInput
