import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { register_url } from 'constants/app'
import { openRegister } from 'actions/register'

const AVAILABLE_POS_REGISTERS = gql`
  query availableRegisters {
    availableRegisters {
      id
    }
  }
`

const REQUEST_KEY = gql`
  query requestKey {
    requestKey {
      granted
      key
      reason
    }
  }
`

const CashRegisterStarter = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [getAvailableRegisters, registersQuery] = useLazyQuery(
    AVAILABLE_POS_REGISTERS
  )

  const [requestKey, requestKeyQuery] = useLazyQuery(REQUEST_KEY, {
    fetchPolicy: 'network-only',
    pollInterval: 2000
  })

  useEffect(() => {
    if (isOpen) {
      getAvailableRegisters()
    }
    // else if (called) {
    //   stopPolling()
    // }
  }, [isOpen])

  useEffect(() => {
    console.log(registersQuery.data)
  }, [registersQuery.data])

  useEffect(() => {
    if (isOpen && !registersQuery.loading) {
      //const url = new URL(register_url)
      //url.searchParams.append('key', data?.requestKey.key)
      //window.open(url, '_blank')

      setTimeout(() => {
        window.open('/register?key=' + 'key', '_blank')
        onClose()
      }, 1000)
    }
  }, [registersQuery])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>Startar kassan...</Center>
        </ModalHeader>
        <Center m="5">
          <FontAwesomeIcon size="4x" icon={['fal', 'cash-register']} />
        </Center>
        <Center m="5">
          <Spinner size="lg" />
        </Center>

        {/* <Center>
          {!loading && data?.requestKey.granted === false && (
            <Badge colorScheme="red">{data?.requestKey.reason}</Badge>
          )}
        </Center> */}

        <ModalBody>
          Novasecur letar efter en ledig kassa att tilldela dig. Om alla kassor
          inom din organisation är upptagna kan du behöva vänta tills andra
          kassaanvändare stänger sina aktiva kvitton.
        </ModalBody>

        <ModalFooter>
          <Button w="100%" variant="ghost" colorScheme="red" onClick={onClose}>
            Avbryt
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CashRegisterStarter
