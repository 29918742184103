import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { getUnixTime } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  Divider,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Skeleton,
  Progress,
  SkeletonText,
  Checkbox
} from '@chakra-ui/react'

import {
  member,
  membership,
  invoice as invoiceEntity,
  receipt as receiptEntity,
  externalPayment as externalPaymentEntity
} from 'constants/entities'
import { ListItem as CardListItem } from 'components/cards/ListCard'

import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'

import TimeTraveler from 'components/TimeTraveler'

import { useToaster } from 'utils/toaster'
import TagRender from 'components/TagRender'
import ProductInput from 'components/form/ProductInput'
import NumberInput from 'components/form/NumberInput'
import SubtleCard from 'components/cards/SubtleCard'
import DefaultErrorHandler from 'utils/DefaultErrorHandler'
import PaySelector from 'containers/services/PaySelector'
import DateInput from 'components/form/DateInput'

const BEGIN_CANCEL_MEMBERSHIP = gql`
  query beginCancelMembership($id: String!, $paymentPeriod: String) {
    beginCancelMembership(id: $id, paymentPeriod: $paymentPeriod) {
      cancellationDate
      lastPaymentPeriodDate
      lastAdmissionDate
    }
  }
`

const DO_CANCEL_MEMBERSHIP = gql`
  mutation doCancelMembership(
    $id: String!
    $cancellationDate: String
    $lastPaymentPeriodDate: String
    $lastAdmissionDate: String
  ) {
    doCancelMembership(
      id: $id
      cancellationDate: $cancellationDate
      lastPaymentPeriodDate: $lastPaymentPeriodDate
      lastAdmissionDate: $lastAdmissionDate
    ) {
      success
      message
    }
  }
`

const MembershipCancel = ({ isOpen, onClose, id, paymentPeriodId }) => {
  const toast = useToaster()
  const form = useForm()

  const [doBeginCancel, { loading, error, data }] = useLazyQuery(
    BEGIN_CANCEL_MEMBERSHIP,
    {
      variables: { id, paymentPeriod: paymentPeriodId },
      fetchPolicy: 'network-only',
      onCompleted: ({ beginCancelMembership }) =>
        form.reset(beginCancelMembership),
      onError: DefaultErrorHandler
    }
  )

  useEffect(() => {
    if (isOpen) doBeginCancel()
  }, [isOpen])

  const [doCancelMembership] = useMutation(DO_CANCEL_MEMBERSHIP, {
    onCompleted: ({ doCancelMembership }) => {
      toast({
        title: `Abonnemang uppsagat`,
        description: doCancelMembership.message,
        status: 'warning',
        position: 'top-right',
        isClosable: false
      })
      onClose()
    },
    onError: DefaultErrorHandler
  })

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {loading ? (
          <Center h="160px">
            <Spinner size="lg" />
          </Center>
        ) : (
          <>
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) =>
                  doCancelMembership({ variables: { id, ...data } })
                )}>
                <ModalHeader>Säg upp abonnemang</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <DateInput
                    id="cancellationDate"
                    label="Datum för uppsägning"
                  />
                  <DateInput
                    id="lastPaymentPeriodDate"
                    label="Sista betalperiod"
                  />
                  <DateInput id="lastAdmissionDate" label="Sista insläpp" />
                </ModalBody>

                <ModalFooter>
                  <Button size="sm" variant="ghost" mr="2" onClick={onClose}>
                    avbryt
                  </Button>
                  <Button type="submit" size="sm" colorScheme="red">
                    Säg upp abonnemang
                  </Button>
                </ModalFooter>
              </form>
            </FormProvider>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default MembershipCancel
