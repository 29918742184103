import React, { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  format,
  addDays,
  isValid,
  formatDistance,
  formatRelative
} from 'date-fns'
import { sv } from 'date-fns/locale'
import {
  Box,
  Flex,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Heading,
  Text,
  Tag
} from '@chakra-ui/react'

import InputSpacer from 'components/form/InputSpacer'
import TextInput from './TextInput'

const DateRangeInput = ({ id, ...other }) => {
  const { control } = useFormContext()
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ])

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => (
        <>
          <Popover placement="bottom" gutter="-10" onClose={field.onBlur}>
            <PopoverTrigger>
              <Box>
                <InputSpacer>
                  <TextInput
                    type="text"
                    value={field.value}
                    readOnly
                    noSpacer={true}
                    leftAddon={
                      <FontAwesomeIcon
                        size="lg"
                        icon={['fad', 'calendar-week']}
                      />
                    }
                    {...other}
                  />

                  <Box
                    px="2"
                    pb="1.5"
                    pt="0"
                    mx="2"
                    roundedBottom="lg"
                    border="1px"
                    bg="whiteAlpha.100"
                    border="0">
                    <Flex wrap="wrap" justify="center">
                      {isValid(state[0].startDate) &&
                        isValid(state[0].endDate) && (
                          <Flex w="100%" flexDir="column" align="flex-start">
                            <Wrap spacing="1" mt="1">
                              <Tag size="sm">
                                <Text as="b" mr="1">
                                  Från:
                                </Text>
                                {formatDistance(
                                  state[0].startDate,
                                  new Date(),
                                  {
                                    addSuffix: true,
                                    locale: sv
                                  }
                                )}
                              </Tag>
                              <Tag size="sm">
                                <Text as="b" mr="1">
                                  Till:
                                </Text>
                                {formatDistance(state[0].endDate, new Date(), {
                                  addSuffix: true,
                                  locale: sv
                                })}
                              </Tag>
                              <Tag size="sm" mt="1">
                                <Text as="b" mr="1">
                                  Längd:
                                </Text>
                                {formatDistance(
                                  state[0].startDate,
                                  state[0].endDate,
                                  {
                                    addSuffix: false,
                                    locale: sv
                                  }
                                )}
                              </Tag>
                            </Wrap>
                          </Flex>
                        )}
                    </Flex>
                  </Box>
                </InputSpacer>
              </Box>
            </PopoverTrigger>
            <PopoverContent w="600px" bg="none" border="0">
              <Box w="555px" rounded="lg" overflow="hidden" color="#333">
                <DateRangePicker
                  onChange={(item) => {
                    setState([item.selection])
                    field.onChange(
                      `${format(item.selection.startDate, 'dd/MM/y')}, ${format(
                        item.selection.endDate,
                        'dd/MM/y'
                      )}`
                    )
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  direction="horizontal"
                />
              </Box>
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  )
}

export default DateRangeInput
