import React, { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Switch } from '@chakra-ui/react'
import { Center } from '@chakra-ui/react'

import BaseCard from './BaseCard'

const SwitchDisclosureCard = ({ id, ...other }) => {
  const { control } = useFormContext()
  const [isActive, setIsActive] = useState(false)

  const watcher = useWatch({
    control,
    name: id
  })

  useEffect(() => {
    !!watcher ? setIsActive(true) : setIsActive(false)
  })

  return (
    <BaseCard
      {...other}
      withDisclosure
      useControlledDisclosure={true}
      controlledDisclosureToggle={isActive}
      HeaderRight={
        <Controller
          control={control}
          name={id}
          render={({ field }) => (
            <Center>
              <Switch
                name={id}
                size="md"
                colorScheme="green"
                bg={other.color}
                isChecked={field.value}
                onChange={(e) => {
                  field.onChange(!!e.target.checked)
                }}
              />
            </Center>
          )}
        />
      }></BaseCard>
  )
}

export default SwitchDisclosureCard
