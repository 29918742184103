import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import BookingCalendar from 'containers/BookingCalendar'
import ErrorBoundary from 'components/ErrorBoundary'

const NotFound = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>404 | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <Boxed>
            <PageTitle
              icon="question"
              title="404"
              subTitle="Vi kunde inte hitta sidan du letade efter."
            />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default NotFound
