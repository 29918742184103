import React from 'react'
import { Tag, TagLabel, Avatar, TagCloseButton } from '@chakra-ui/react'

import { member } from 'constants/entities'
import { pushPanel } from 'containers/PanelNavigation'

const MemberTagRender = ({
  id,
  memberNumber,
  fullname,
  clickAwayLock,
  onRemoveAction
}) => {
  return (
    <Tag
      variant="subtle"
      borderRadius="full"
      size="md"
      fontSize="xs"
      mb="1"
      mr="auto"
      transition={'0.1s'}
      color={member.color}
      border="1px solid"
      borderColor="whiteAlpha.400"
      _hover={!clickAwayLock && { borderColor: 'whiteAlpha.600' }}
      onClick={(e) => {
        e.stopPropagation()
        !clickAwayLock &&
          pushPanel('member', {
            id: memberNumber
          })
      }}>
      <Avatar size="xs" name={fullname} ml={-2} mr={2} />
      <TagLabel>
        #{memberNumber} {fullname}
      </TagLabel>
      {/* <TagCloseButton
        color="white"
        onClick={(e) => {
          e.stopPropagation()
          onRemoveAction(id)
        }}
      /> */}
    </Tag>
  )
}

export default MemberTagRender
