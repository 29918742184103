import * as yup from 'yup'

export default yup.object().shape({
  // vendingCredit: yup
  //   .number()
  //   .moreThan(-1, 'Måste vara ett posivit värde')
  //   .integer()
  //   .typeError('Måste vara ett nummer'),
  physicalAdmissionDenied: yup.boolean().nullable(),
  onlineAdmissionDenied: yup.boolean().nullable(),
  consentNewsletter: yup.boolean().nullable(),
  useWeightsensor: yup.boolean().nullable()
})
