import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Container, Flex, Text, Tooltip } from '@chakra-ui/react'
import { Page, Content, EntryBackground, EntryLiner } from 'style/wrappers'

import LoginForm from 'components/authentication/LoginForm'
import UserSetupForm from 'components/authentication/UserSetupForm'
import ErrorBoundary from 'components/ErrorBoundary'

const Copyright = styled.small`
  opacity: 0.6;
  font-size: 12px;
  font-weight: lighter;
  margin: auto;
  padding: 5px 25px;
`

const Login = () => {
  return (
    <ErrorBoundary>
      <Page h="100vh">
        <Helmet>
          <title>Användarstart | Novasecur</title>
        </Helmet>
        <EntryBackground>
          <Flex
            h="100vh"
            w="100vw"
            justify="center"
            align="center"
            flexDir="column"
            bg="blackAlpha.600"
            backdropFilter="blur(3px)">
            <Flex mt="20vh" mb="auto">
              <Container maxW="container.md" px="5%">
                <UserSetupForm />
              </Container>
            </Flex>
          </Flex>
        </EntryBackground>
      </Page>
    </ErrorBoundary>
  )
}

export default Login
