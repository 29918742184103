import { Children } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Stack,
  Heading,
  Button,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Text,
  Grid,
  GridItem,
  Tag,
  TagLeftIcon,
  TagLabel,
  Divider,
  RadioGroup,
  Radio,
  ButtonGroup
} from '@chakra-ui/react'

const InvoiceRow = ({ id, children, index, move, product }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      move(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'row',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <>
      <Flex
        align="center"
        cursor="move"
        bg="rgba(255, 255, 255, 0.06)"
        boxShadow="md"
        rounded="lg"
        py="2"
        px="4"
        mb="2"
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}>
        <Center flex="0.2">
          <FontAwesomeIcon
            size="lg"
            opacity={0.6}
            icon={['fal', 'grip-vertical']}
          />
        </Center>
        <Text flex="4" ml="4" mb="0.5" fontWeight="bold" fontSize="sm">
          {product}
        </Text>
        <Text flex="2" ml="4" mb="0.5" fontSize="sm">
          1
        </Text>
        <Text flex="2" ml="4" mb="0.5" fontSize="sm">
          234 kr
        </Text>

        <ButtonGroup flex="2" display="flex" justifyContent="flex-end">
          <Button size="sm" variant="ghost">
            Ändra
          </Button>
          <Button size="sm" variant="ghost" color="red.300">
            Radera
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  )
}

export default InvoiceRow
