import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from 'style/GlobalStyles'
import { setScreenSize } from 'actions/app'

import themeGlobals from 'style/theme'
import themeDark from 'style/theme_dark'
import themeLight from 'style/theme_light'

const ThemeManager = ({ children }) => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.user.theme)
  const size = useWindowSize()

  dispatch(setScreenSize(size))

  return (
    <ThemeProvider
      theme={{
        ...themeGlobals,
        ...(theme === 'light' ? themeLight : themeDark)
      }}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export default ThemeManager
