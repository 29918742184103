import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten, darken, radialGradient } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge,
  useDisclosure,
  Flex,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  InputLeftAddon,
  Kbd,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Divider
} from '@chakra-ui/react'

import { RegisterButton } from './RegisterButtonSet'

const RegisterPay = ({ closePayPanel }) => {
  return (
    <Flex
      pos="absolute"
      top="-5px"
      right="0"
      bottom="0"
      h="calc(100% + 5px)"
      w="100%"
      bg="gray.600"
      borderLeft="1px"
      borderTop="1px"
      borderColor="whiteAlpha.300"
      roundedTopLeft="lg"
      boxShadow="2xl">
      {/* <Flex w="100%" justify="space-between" align="center" p="2" pl="4">
        <Heading size="md">Produkter: Kläder</Heading>
        <Button variant="outline" colorScheme="red" size="lg">
          Återgå till Kassan
        </Button>
      </Flex> */}

      <Button
        h="100%"
        colorScheme="red"
        roundedBottom="none"
        roundedTopRight="none"
        iconSpacing={0}
        w="65px"
        onClick={closePayPanel}
        leftIcon={
          <FontAwesomeIcon size="lg" icon={['fal', 'rectangle-xmark']} />
        }></Button>

      <Flex w="100%" flexDir="column" p="8">
        <Heading fontSize="sm" mb="2" color="whiteAlpha.900">
          Ange belopp att betala
        </Heading>
        <Flex mb="6">
          <Button h="125px" w="180px" variant="solid" mr="2">
            <Flex flexDir="column">
              <Text fontSize="lg" mb="1">
                Hela beloppet
              </Text>
              <Text fontSize="xs" color="whiteAlpha.500">
                200kr
              </Text>
            </Flex>
          </Button>
          <Button h="125px" w="180px" variant="outline" mr="2">
            <Flex flexDir="column">
              <Text fontSize="lg" mb="1">
                1/2
              </Text>
              <Text fontSize="xs" color="whiteAlpha.500">
                100kr
              </Text>
            </Flex>
          </Button>
          <Button h="125px" w="180px" variant="outline" mr="2">
            <Flex flexDir="column">
              <Text fontSize="lg" mb="1">
                1/4
              </Text>
              <Text fontSize="xs" color="whiteAlpha.500">
                50kr
              </Text>
            </Flex>
          </Button>
          <Button h="125px" w="220px" variant="outline">
            <Flex flexDir="column">
              <Text fontSize="lg" mb="1">
                Egen belopp
              </Text>
              <Text fontSize="xs" color="whiteAlpha.500">
                Klicka för att ange
              </Text>
            </Flex>
          </Button>
        </Flex>

        <Heading fontSize="sm" mb="2" color="whiteAlpha.900">
          Ange betalsätt
        </Heading>

        <Flex flexDir="column" w="100%">
          <Grid
            w="100%"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap="2">
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">Kontant</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">Kort</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">Presentkort</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">Swish</Text>
              </Flex>
            </Button>
          </Grid>
          <Divider my="3" />
          <Grid
            w="100%"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(3, 1fr)"
            gap="2">
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">ACTIWAY</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">EPASSI</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">Friskvård</Text>
              </Flex>
            </Button>
            <Button h="120px" variant="outline">
              <Flex flexDir="column">
                <Text fontSize="lg">WELLNET</Text>
              </Flex>
            </Button>
          </Grid>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const Product = ({ name, image }) => (
  <Flex
    flexDir="column"
    p="3"
    border="1px solid rgba(255, 255, 255, 0.2)"
    rounded="lg"
    cursor="pointer"
    _hover={{ bg: 'rgba(255, 255, 255, 0.1)', boxShadow: 'xl' }}
    mb="auto">
    {image && <Image src={image} rounded="lg" mb="1" />}
    <Heading size="sm">{name}</Heading>
    <Text fontSize="sm" mt="1">
      100 kr
    </Text>
  </Flex>
)

export default RegisterPay
