import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Wrap,
  VStack,
  Center,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  Divider,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Skeleton,
  Progress,
  SkeletonText
} from '@chakra-ui/react'

const SkeletonPanel = ({
  loading,
  error,
  color,
  closeAction,
  icon,
  ...rest
}) => {
  return (
    <Box
      h="100vh"
      w="1400px"
      p="5%"
      rounded="25px"
      bg="gray.800"
      mr="-25px"
      zIndex="1400"
      bg={color}>
      {error ? (
        <Center h="100%">
          <VStack mb="4">
            <FontAwesomeIcon size="8x" icon={['fal', 'exclamation-triangle']} />
            <Heading pt="2" size="lg">
              Något gick fel i panelen.
            </Heading>
            <Text>Problemet har rapporterats</Text>
            {typeof error === 'object' && error !== null
              ? error.graphQLErrors.map(({ message }, i) => (
                  <Text pb="4" key={i}>
                    {message}
                  </Text>
                ))
              : error}
            <Box pt="2">
              <Button size="sm" onClick={closeAction}>
                Stäng panel
              </Button>
            </Box>
          </VStack>
        </Center>
      ) : (
        <Center h="100%">
          <Flex flexDir="column">
            {/* <Flex align="center" mb="12">
            <Skeleton h="220px" w="220px" rounded="full" mr="5" />
            <Flex flexDir="column" w="450px">
              <Skeleton h="25px" w="100%" />
              <SkeletonText w="100%" mt="4" noOfLines={4} spacing="4" />
            </Flex>
          </Flex>
          <Flex>
            <Skeleton h="400px" w="250px" rounded="lg" mr="5" />
            <Skeleton h="400px" w="250px" rounded="lg" mr="5" />
            <Skeleton h="400px" w="250px" rounded="lg" mr="5" />
            <Skeleton h="400px" w="250px" rounded="lg" />
          </Flex> */}

            <Flex justify="center" mb="4">
              <FontAwesomeIcon size="6x" icon={['fad', icon]} />
            </Flex>

            <Text fontSize="xl" textAlign="center" fontWeight="bold" mb="4">
              Laddar panel
            </Text>

            <Progress
              w="280px"
              size="sm"
              color="whiteAlpha.400"
              rounded="lg"
              boxShadow="lg"
              border="2px"
              borderColor="whiteAlpha.400"
              hasStripe
              isIndeterminate
              speed="0.2s"
            />

            <Center>
              <Button
                size="xs"
                variant="ghost"
                opacity={0.4}
                _hover={{ opacity: 1 }}
                mt="3"
                onClick={closeAction}>
                Avbryt
              </Button>
            </Center>
          </Flex>
        </Center>
      )}
    </Box>
  )
}

export default SkeletonPanel
