import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { gql, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  LightMode,
  Box,
  Flex,
  Center,
  Button,
  Heading,
  Text,
  useDisclosure
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { setIsAuthenticated, setShouldReauthenticate } from 'actions/user'

import bankidSchema from 'validationSchemas/bankidSchema'

import WithLoader from 'components/utils/WithLoader'
import bankIDLogo from 'style/images/BankID_logo_white.svg'
import TextInput from 'components/form/TextInput'
import BankIDPoll from 'containers/BankIDPoll'

const DO_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password, appType: "web") {
      success
      token
      refreshToken
      userSetupRequired
    }
  }
`

const DO_LOGIN_BANKID = gql`
  mutation loginBankID($nationalId: String!) {
    loginBankID(nationalId: $nationalId, appType: "web") {
      token
    }
  }
`

const LoginForm = () => {
  const toast = useToaster()
  const dispatch = useDispatch()
  const history = useHistory()

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)

  const [useBankID, setuseBankID] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    isOpen: isOpenBankID,
    onOpen: onOpenBankID,
    onClose: onCloseBankID
  } = useDisclosure()

  const form = useForm({
    mode: 'onChange'
  })

  const bankIDForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(bankidSchema)
  })

  const [doLogin, { loading: mutationLoading, data, error: mutationError }] =
    useMutation(DO_LOGIN, {
      onCompleted: (data) => {
        if (data.login.success) {
          toast({
            title: `Inloggning lyckad`,
            description: 'Du är nu inloggad i Novasecur',
            status: 'success',
            position: 'top-right',
            isClosable: true
          })

          localStorage.setItem('auth-token', data.login.token)
          localStorage.setItem('refresh-token', data.login.refreshToken)
          dispatch(setIsAuthenticated(true))

          if (data.login.userSetupRequired) {
            history.push('/login/setup')
          } else {
            window.location.href = '/'
          }
        }
      },
      onError: (error) => {
        toast({
          title: `Inloggning misslyckad`,
          description: 'Försök igen',
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    })

  const [loginBankID, { loading: bankIDLoading }] = useMutation(
    DO_LOGIN_BANKID,
    {
      onCompleted: () => {
        onOpenBankID()
      },
      onError: (error) => {
        toast({
          title: `Inloggning misslyckad`,
          description: 'Försök igen',
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    }
  )

  useEffect(() => {
    dispatch(setShouldReauthenticate(false))
  }, [])

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     history.push('/')
  //   }
  // }, [isAuthenticated])

  return (
    <WithLoader loading={isLoading}>
      <LightMode>
        <Box w="420px">
          {!useBankID ? (
            <Flex flexDir="column">
              <Flex justify="center">
                <Box
                  h="65px"
                  w="65px"
                  rounded="full"
                  bg="rgba(255, 255, 255, 0.2)">
                  <Flex h="100%" justify="center" align="center">
                    <FontAwesomeIcon size="2x" icon={['fad', 'asterisk']} />
                  </Flex>
                </Box>
              </Flex>

              <Flex flexDir="column" align="center" mb="8">
                <Heading color="#fff">Logga in</Heading>
                <Text color="#fff" fontWeight="bold">
                  Med användarnamn och lösenord
                </Text>
              </Flex>

              <Box
                bg="#fff"
                color="grey"
                p="6"
                py="6"
                rounded="lg"
                boxShadow="xl">
                <FormProvider {...form}>
                  <form
                    onSubmit={form.handleSubmit((input) =>
                      doLogin({ variables: input })
                    )}>
                    <TextInput
                      id="username"
                      label="Användarnamn"
                      helper="Ange ditt användarnamn"
                      large
                      bg="#eee"
                      color="#333"
                    />
                    <TextInput
                      id="password"
                      type="password"
                      label="Lösenord"
                      helper="Ange ditt lösenord"
                      large
                      bg="#eee"
                      color="#333"
                    />
                    <Flex justify="space-between" align="center" pt="2">
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={() =>
                          history.push('/login/forgotten-password')
                        }>
                        Glömt ditt lösenord?
                      </Button>

                      <Button
                        type="submit"
                        colorScheme="teal"
                        isLoading={mutationLoading}>
                        Logga in
                      </Button>
                    </Flex>
                  </form>
                </FormProvider>
              </Box>

              <Button
                size="lg"
                onClick={() => setuseBankID(true)}
                bg="#469CBE"
                leftIcon={<img style={{ height: 48 }} src={bankIDLogo} />}
                mx="4"
                roundedTop="0"
                fontSize="15px"
                fontWeight="bold">
                Logga in med BankID istället
              </Button>
            </Flex>
          ) : (
            <Flex flexDir="column">
              <Flex justify="center">
                <Box
                  h="65px"
                  w="65px"
                  rounded="full"
                  bg="rgba(255, 255, 255, 0.2)">
                  <Flex h="100%" justify="center" align="center">
                    <img style={{ height: 58 }} src={bankIDLogo} />
                  </Flex>
                </Box>
              </Flex>

              <Flex flexDir="column" align="center" mb="8">
                <Heading color="#fff">Logga in</Heading>
                <Text color="#fff" fontWeight="bold">
                  Med BankID
                </Text>
              </Flex>

              <Box
                bg="#fff"
                color="grey"
                p="6"
                py="8"
                rounded="lg"
                boxShadow="xl">
                <FormProvider {...bankIDForm}>
                  <form
                    onSubmit={bankIDForm.handleSubmit((vals) =>
                      loginBankID({ variables: vals })
                    )}>
                    <TextInput
                      id="nationalId"
                      label="Personnummer"
                      helper="Ange ditt personnummer"
                      large
                      bg="#eee"
                      color="#333"
                    />

                    <Flex justify="right">
                      <Button
                        type="submit"
                        colorScheme="blue"
                        color="#fff"
                        bg="#469CBE"
                        isDisabled={!bankIDForm.formState.isValid}
                        isLoading={bankIDLoading}>
                        Legitimera med BankID
                      </Button>
                    </Flex>
                  </form>
                </FormProvider>
              </Box>

              <Button
                size="lg"
                bg="rgba(0, 0, 0, 0.2)"
                color="#fff"
                onClick={() => setuseBankID(false)}
                mx="4"
                roundedTop="0"
                fontSize="15px"
                fontWeight="bold">
                Logga in med lösenord istället
              </Button>
            </Flex>
          )}
        </Box>
      </LightMode>

      <BankIDPoll isOpen={isOpenBankID} onClose={onCloseBankID} />
    </WithLoader>
  )
}

export default LoginForm
