import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { Page, EntryBackground } from 'style/wrappers'

import LoginForm from 'components/authentication/LoginForm'
import ErrorBoundary from 'components/ErrorBoundary'

const Login = () => {
  return (
    <ErrorBoundary>
      <Page h="100vh">
        <Helmet>
          <title>Login | Novasecur</title>
        </Helmet>
        <EntryBackground>
          <Flex
            h="100vh"
            w="100vw"
            justify="center"
            align="center"
            flexDir="column"
            bg="blackAlpha.600"
            backdropFilter="blur(3px)">
            <Flex mt="20vh" mb="auto">
              <LoginForm />
            </Flex>

            <Tooltip
              label={`
            REACT.${process.env.REACT_APP_ENVIRONMENT.substring(
              0,
              3
            ).toLocaleUpperCase()}
            NODE.${process.env.NODE_ENV.substring(0, 3).toLocaleUpperCase()} 
            HASH.${process.env.REACT_APP_COMMIT_HASH || 'NONE'}
          `}>
              <Text fontSize="sm" color="whiteAlpha.700" mb="1">
                {new Date().getFullYear()} © Novasecur. Version 0.0.1 ·
                Utvecklat med kärlek i Trosa.
              </Text>
            </Tooltip>
          </Flex>
        </EntryBackground>
      </Page>
    </ErrorBoundary>
  )
}

export default Login
