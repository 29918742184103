import * as yup from 'yup'
import personnummer from 'personnummer'
import organisationsnummer from 'organisationsnummer'

export default yup.object().shape({
  nationalId: yup
    .string()
    .test('personnr-valid', 'Inte ett giltigt personnummer', (value) => {
      if (value) {
        return personnummer.valid(value) || organisationsnummer.valid(value)
      } else {
        return true
      }
    })
    .nullable(),

  firstname: yup.string().required(),
  lastname: yup.string().required(),

  co: yup.string().nullable(),
  street: yup.string().required(),
  street2: yup.string().nullable(),
  postcode: yup.string().required(),
  city: yup.string().required(),

  email: yup.string().email().required(),
  mobileNumber: yup.string().nullable()
})
