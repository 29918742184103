import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { transparentize } from 'polished'
import { Badge } from '@chakra-ui/react'

import WithHelpi from 'components/utils/WithHelpi'

const criticalShake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 0;

  ${({ priority, checked }) =>
    !checked &&
    priority === 'CRITICAL' &&
    css`
      animation: ${criticalShake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97)
        infinite;
    `}
  animation-delay: 2s;

  margin: 0 0 4px 0;
  background: ${({ color, checked, theme }) =>
    checked ? 'grey' : theme.gradients.vivid(color)};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
  ${({ checked }) => checked && 'opacity: 0.6;'}

  &:hover {
    //transform: scale(1.01);
    opacity: 1;
    animation: none;
  }
`

const Circle = styled.div`
  height: 40px;
  width: 40px;
  margin: 0 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 5px 0px 14px 5px rgba(0, 0, 0, 0.15);

  &:hover {
    ${({ checkable }) => checkable && 'background: rgba(0, 0, 0, 0.3);'}
  }
`

const Icon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 22px;
`

const LeftWrap = styled.div`
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RightWrap = styled.div`
  min-height: 60px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-radius: 6px;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 5px 0px 14px 5px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 16px;
  font-weight: bolder;
  line-height: 1;
  margin: 0 0 4px 0;
  ${({ checked }) => checked && 'text-decoration: line-through;'}
`

const Description = styled.p`
  opacity: 0.85;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  margin: 0;
  ${({ checked }) => checked && 'text-decoration: line-through;'}
`

const Tags = styled.div``

const CheckboxOption = ({
  title,
  description,
  color,
  checked,
  onCheck,
  checkable = true,
  isAutomatic,
  priority
}) => {
  return (
    <Wrapper priority={priority} checked={checked} color={color}>
      <LeftWrap>
        {!isAutomatic && (
          <Circle
            checkable={checkable}
            onClick={() => checkable && onCheck(!checked)}>
            {checked && <Icon icon={['fad', 'check']} />}
          </Circle>
        )}
        {isAutomatic && (
          <WithHelpi
            title="Automatiskt avchecking"
            description="Raden checkas av automatiskt när du har gjort klart uppgiften.">
            <FontAwesomeIcon
              size="2x"
              color="rgba(0, 0, 0, 0.65)"
              icon={['fad', 'user-robot']}
            />
          </WithHelpi>
        )}
      </LeftWrap>
      <RightWrap>
        <Title checked={checked}>{title}</Title>
        <Description checked={checked}>{description}</Description>
        <Tags>
          {isAutomatic && (
            <Badge colorScheme="purple" variant="solid">
              Automatiskt
            </Badge>
          )}
          {priority === 'CRITICAL' && (
            <Badge colorScheme="pink" variant="solid">
              Kritisk
            </Badge>
          )}
          {priority === 'MISSED' && (
            <Badge colorScheme="pink" variant="solid">
              Missad!
            </Badge>
          )}
        </Tags>
      </RightWrap>
    </Wrapper>
  )
}

export default CheckboxOption
