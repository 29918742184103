import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTransition, config } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { popPanel } from 'containers/PanelNavigation'
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent
} from '@chakra-ui/react'

import { HelpContext } from 'containers/HelpOverlay'

const SlideOutManager = () => {
  const panels = useSelector((state) => state.panels.panels)
  const titles = useSelector((state) => state.panels.titles)
  const helpMode = useSelector((state) => state.app.helpMode)

  const Content = panels[panels.length - 1]?.content
    ? panels[panels.length - 1]['content']
    : null
  const data = panels[panels.length - 1]?.data
    ? panels[panels.length - 1]['data']
    : null

  let index = 0
  return (
    <Modal isOpen={panels.length !== 0} size="full" motionPreset="none">
      <ModalOverlay css={false && { backdropFilter: 'blur(10px)' }} />
      <ModalContent bg="none" overflowX="hidden">
        {panels.length !== 0 && (
          <Box pos="fixed" top="0" right="0" bottom="0" left="0" display="flex">
            <Flex ml="auto">
              {panels.slice(0, -1).map(({ title, color, icon, key }) => {
                index += 1
                return (
                  <Box key={index} zIndex="1300">
                    <Flex
                      flexDir="column"
                      transition="0.3s"
                      pl="3"
                      pr="8"
                      py="6"
                      mr="-24px"
                      h="100%"
                      boxShadow="2xl"
                      roundedLeft="xl"
                      color="#fff"
                      bg={color}>
                      <FontAwesomeIcon size="2x" icon={['fad', icon]} />
                      <Heading
                        size="md"
                        mt="15"
                        mr="2px"
                        mb="0"
                        style={{ writingMode: ' vertical-rl' }}>
                        {(titles && titles[key]) || title}
                      </Heading>
                    </Flex>
                  </Box>
                )
              })}
            </Flex>

            <HelpContext.Provider value={helpMode}>
              <Content
                index={panels.length}
                stackHeight={panels.length}
                closeAction={popPanel}
                data={data}
                panelKey={panels[panels.length - 1].key}
                {...panels[panels.length - 1]}
              />
            </HelpContext.Provider>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}

export default SlideOutManager
