import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { lighten, darken, radialGradient } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Button,
  Badge,
  useDisclosure,
  Flex,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  InputLeftAddon,
  Kbd,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton
} from '@chakra-ui/react'

import { closeRegister } from 'actions/register'
import RegisterCart from './RegisterCart'
import RegisterButtonSet from './RegisterButtonSet'
import RegisterProductSet from './RegisterProductSet'
import RegisterPay from './RegisterPay'
import wooshSound from 'style/sounds/ogg/Woosh/woosh_10.ogg'

const Register = () => {
  const dispatch = useDispatch()
  const fullscreen = useFullScreenHandle()
  const registerOpen = useSelector((state) => state.register.registerOpen)
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const infoDisclosure = useDisclosure()
  const [productEditing, setProductEditing] = useState(null)

  const productPanelDisclosure = useDisclosure({
    onOpen: () => {
      const audio = new Audio(wooshSound)
      audio.volume = 0.3
      audio.play()
    }
  })
  const payPanelDisclosure = useDisclosure()

  const productRef = useRef()
  const onScreenKeypadWrite = (char) => {
    productRef.current.value = productRef.current.value + char
  }

  useEffect(() => {
    if (window.fcWidget) {
      console.log('openin')
      if (!registerOpen) {
        window.fcWidget.show()
      } else {
        window.fcWidget.hide()
      }
    }
  }, [registerOpen])

  return (
    <>
      <Modal isOpen={registerOpen} onClose={onClose} size="full">
        <ModalContent rounded="none">
          <ModalBody p="0">
            <FullScreen handle={fullscreen}>
              <Flex h="100vh" w="100%" bg="rgba(255, 255, 255, 0.2)">
                <Flex flexDir="column" flex="2">
                  <Flex flexDir="column" maxH="100vh" flex="1" px="4">
                    <Flex my="2">
                      <Button
                        size="sm"
                        px="10"
                        mr="1"
                        variant="outline"
                        colorScheme="red"
                        leftIcon={
                          <FontAwesomeIcon
                            transform={{ rotate: 180 }}
                            icon={['fal', 'sign-out']}
                          />
                        }
                        onClick={() => {
                          dispatch(closeRegister())
                          fullscreen.exit()
                        }}>
                        Lämna
                      </Button>
                      <Button
                        size="sm"
                        mr="1"
                        px="10"
                        leftIcon={
                          <FontAwesomeIcon icon={['fal', 'receipt']} />
                        }>
                        Insamlingsläge
                      </Button>{' '}
                      <Button
                        size="sm"
                        mr="1"
                        px="5"
                        onClick={infoDisclosure.onOpen}>
                        Info
                      </Button>
                      <Alert
                        rounded="md"
                        h="32px"
                        px="2"
                        status="success"
                        mr="1">
                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                        <AlertTitle ml="2" fontSize="xs">
                          Kassan är aktiv
                        </AlertTitle>
                        <AlertDescription ml="-1" fontSize="xs" opacity="0.8">
                          Kvittoskrivare ansluten
                        </AlertDescription>
                      </Alert>
                      <Button
                        size="sm"
                        px="2"
                        iconSpacing="0"
                        leftIcon={
                          <FontAwesomeIcon
                            icon={[
                              'fal',
                              fullscreen.active
                                ? 'compress-arrows-alt'
                                : 'expand-arrows-alt'
                            ]}
                          />
                        }
                        onClick={() =>
                          fullscreen.active
                            ? fullscreen.exit()
                            : fullscreen.enter()
                        }></Button>
                    </Flex>

                    <Box
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px'
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '4px',
                          backgroundColor: 'rgba(255, 255, 255, 0.05)'
                        }
                      }}
                      overflowY="scroll">
                      <Flex
                        p="2"
                        px="4"
                        mb="1"
                        border="1px"
                        borderStyle="dashed"
                        borderColor="whiteAlpha.300"
                        rounded="lg"
                        align="center"
                        justify="space-between">
                        <Text fontWeight="bold">Ingen medlem vald</Text>
                        <Button size="sm">Välj medlem</Button>
                      </Flex>

                      <RegisterCart />
                    </Box>
                    <Flex py="2" px="2" opacity={productEditing && 0.2}>
                      <Flex flexDir="column" align="flex-start" w="60%">
                        <Heading fontSize="sm" mb="1">
                          Betalningar
                        </Heading>
                        <Tag size="lg" variant="subtle" colorScheme="green">
                          <TagLabel>
                            Swish betalning - <b>102kr</b>
                          </TagLabel>
                          <TagCloseButton />
                        </Tag>
                      </Flex>

                      <Flex w="40%" flexDir="column">
                        <Flex
                          w="100%"
                          justify="space-between"
                          ml="auto"
                          opacity="0.4">
                          <Text fontSize="sm" fontWeight="bold">
                            Antal produkter
                          </Text>
                          <Spacer />
                          <Text fontSize="sm">3 st</Text>
                        </Flex>

                        <Flex w="100%" justify="space-between" ml="auto">
                          <Text fontSize="xl" fontWeight="bold">
                            Summa
                          </Text>
                          <Spacer />
                          <Text fontSize="xl">234.00 kr</Text>
                        </Flex>

                        <Flex
                          w="100%"
                          justify="space-between"
                          ml="auto"
                          opacity="0.6">
                          <Text fontSize="lg" fontWeight="bold">
                            Saldo
                          </Text>
                          <Spacer />
                          <Text fontSize="lg">234.00 kr</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  flexDir="column"
                  flex="3"
                  h="100%"
                  w="100%"
                  alignItems="flex-end">
                  <Flex flex="1" w="100%" align="center">
                    <Flex
                      w="100%"
                      border="1px"
                      borderColor="rgba(255, 255, 255, 0.1)"
                      p="3"
                      pt="2"
                      px="3"
                      rounded="md"
                      m="2"
                      ml="0">
                      <FormControl flex="4" mr="1">
                        <FormLabel fontSize="sm" m="0">
                          Produktnummer
                        </FormLabel>
                        <Input
                          ref={productRef}
                          mt="-1"
                          variant="flushed"
                          placeholder="Produktnummer"
                          size="lg"
                        />
                      </FormControl>

                      <FormControl flex="1" mr="1">
                        <FormLabel fontSize="sm" m="0">
                          Belopp
                        </FormLabel>
                        <InputGroup>
                          <NumberInput
                            mt="-1"
                            size="lg"
                            variant="flushed"
                            placeholder="Antal">
                            <NumberInputField />
                          </NumberInput>
                          <InputRightElement
                            children={<Text opacity="0.2">Kr</Text>}
                          />
                        </InputGroup>
                      </FormControl>

                      <FormControl flex="1">
                        <FormLabel fontSize="sm" m="0">
                          Antal
                        </FormLabel>
                        <NumberInput
                          mt="-1"
                          size="lg"
                          variant="flushed"
                          placeholder="Antal"
                          defaultValue={1}
                          min={1}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper border="none" />
                            <NumberDecrementStepper border="none" />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" pos="relative" h="100%" w="100%">
                    <RegisterButtonSet
                      openProductPanel={productPanelDisclosure.onOpen}
                      openPayPanel={payPanelDisclosure.onOpen}
                      onScreenKeypadWrite={(c) => onScreenKeypadWrite(c)}
                    />

                    {productPanelDisclosure.isOpen && (
                      <RegisterProductSet
                        closeProductPanel={productPanelDisclosure.onClose}
                      />
                    )}

                    {payPanelDisclosure.isOpen && (
                      <RegisterPay closePayPanel={payPanelDisclosure.onClose} />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </FullScreen>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog {...infoDisclosure}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Kassa information
            </AlertDialogHeader>

            <AlertDialogBody></AlertDialogBody>

            <AlertDialogFooter>
              <Button w="100%" size="sm" onClick={infoDisclosure.onClose}>
                Stäng
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Register
