import React from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'

import {
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react'

import RadioInput from 'components/form/RadioInput'
import YesNoInput from 'components/form/YesNoInput'
import NumberInput from 'components/form/NumberInput'
import DateInput from 'components/form/DateInput'
import SelectInput from 'components/form/SelectInput'
import { useToaster } from 'utils/toaster'

const COPY_WEEK = gql`
  mutation copyWeek {
    copyWeek {
      success
      message
    }
  }
`

const CalendarCopy = ({ isOpen, onClose }) => {
  const toast = useToaster()
  const [doCopyWeek, { loading, error, data }] = useMutation(COPY_WEEK, {
    onError: (e) => {
      toast({
        title: `Kunde inte kopiera`,
        description: e.message,
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
      onClose()
    },
    onCompleted: (data) => {
      toast({
        title: `Vecka rensad.`,
        description: data.copyWeek.message,
        status: 'warning',
        position: 'top-right',
        isClosable: true
      })
      onClose()
    }
  })

  const form = useForm({
    defaultValues: {
      stepNumber: '1',
      stepType: 'week',
      copyRedDays: true,
      copyHoliday: true,
      copyInstructors: true
    }
  })

  const watchStepNumber = form.watch('stepNumber', '1')
  const watchStepType = form.watch('stepType', 'week')

  const watchLimit = form.watch('limit', 'byNumber')
  const watchLimitAmount = form.watch('limitAmount', null)
  const watchLimitDate = form.watch('limitDate', null)

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((vals) =>
              doCopyWeek({ variables: vals })
            )}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader pb="0">Kopiera vecka</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex w="100%">
                  <Box w="40%">
                    <NumberInput
                      id="stepNumber"
                      label="Var :de"
                      min="1"
                      max="200"
                    />
                  </Box>
                  <Box w="60%" ml="2">
                    <SelectInput
                      id="stepType"
                      label={
                        watchStepNumber === '1'
                          ? 'vecka / månad?'
                          : 'veckor / månader?'
                      }
                      options={[
                        { label: 'Vecka', value: 'week' },
                        { label: 'Månad', value: 'month' }
                      ]}
                    />
                  </Box>
                </Flex>

                <Flex flexDir="column" pt="3" pb="2">
                  <RadioInput
                    id="limit"
                    label="Antal veckor eller till datum?"
                    options={[
                      { label: 'Antal veckor', value: 'byNumber' },
                      { label: 'Till och med datum', value: 'toDate' }
                    ]}
                  />
                  {watchLimit === 'byNumber' && (
                    <NumberInput
                      id="limitAmount"
                      label="Antal veckor att kopiera"
                    />
                  )}
                  {watchLimit === 'toDate' && (
                    <DateInput
                      id="limitDate"
                      label="Datum då kopiering ska sluta"
                    />
                  )}
                </Flex>

                <YesNoInput id="copyParticipants" label="Kopiera deltagare?" />
                <YesNoInput
                  id="sendConfirmation"
                  label=" Skicka bokningsberäftelse vid kopiera deltagare?"
                />
                <YesNoInput id="copyTags" label="Kopiera Tags?" />
                <YesNoInput
                  id="copyRedDays"
                  label=" Kopiera till röda dagar?"
                />
                <YesNoInput id="copyHoliday" label="Kopiera till helgdagar?" />
                <YesNoInput
                  id="copyInstructors"
                  label="Kopiera instruktörer?"
                />
                <YesNoInput id="copyTitle" label="Kopiera rubrik?" />
                <YesNoInput
                  id="copyShowInSchedule"
                  label="Kopiera visa i schema?"
                />
                <YesNoInput
                  id="copyMaxNumberOfParticipants"
                  label=" Kopiera max antal deltagare?"
                />
              </ModalBody>

              <ModalFooter>
                <Flex w="100%" flexDir="column">
                  <Alert size="sm" rounded="lg" mb="2">
                    <AlertIcon />
                    <AlertDescription fontSize="sm">
                      Veckan kopieras till{' '}
                      <b>
                        {watchStepNumber === '1'
                          ? 'varje'
                          : watchStepNumber === '2'
                          ? 'varannan'
                          : `var ${watchStepNumber}:de`}{' '}
                      </b>
                      {watchStepType === 'week' ? 'vecka' : 'månad'}
                      <b>
                        {!watchLimit
                          ? ' ...'
                          : watchLimit === 'byNumber'
                          ? ` i ${watchLimitAmount || '-'} veckor`
                          : ` till och med ${watchLimitDate || '...'}`}
                      </b>
                    </AlertDescription>
                  </Alert>

                  <Flex>
                    <Button
                      size="sm"
                      mr="2"
                      color="red.200"
                      px="8"
                      onClick={() => {
                        onClose()
                        form.reset()
                      }}>
                      Avbryt
                    </Button>
                    <Button
                      type="submit"
                      w="100%"
                      size="sm"
                      isLoading={loading}>
                      Kopiera
                    </Button>
                  </Flex>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default CalendarCopy
