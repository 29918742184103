import { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

const useFormAutoSubmit = ({ form, onSubmit, debounceTime = 400 }) => {
  const valueWatcher = form.watch()
  const [prevValues, setPrevValues] = useState(null)

  // store pending autosave timers as an array to prevent decoupling
  const [pendingAutosaves, setPendingAutosaves] = useState([])

  useEffect(() => {
    // Make sure we have a reason to resubmit
    if (
      Object.keys(valueWatcher).length &&
      Object.keys(form.formState.touchedFields).length &&
      !form.formState.isSubmitting
    ) {
      // Make sure we haven't submitted these values before
      if (!isEqual(valueWatcher, prevValues)) {
        setPrevValues(valueWatcher)

        // cancel the old auto-saves
        pendingAutosaves.forEach((item) => clearTimeout(item))

        // and add this one
        setPendingAutosaves([
          ...pendingAutosaves,
          setTimeout(() => onSubmit(form.getValues()), debounceTime)
        ])
      }
    }
  }, [
    valueWatcher,
    prevValues,
    pendingAutosaves,
    form.formState.touchedFields,
    form.formState.isSubmitting
  ])

  return true
}

export default useFormAutoSubmit
