import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistance, fromUnixTime, isValid } from 'date-fns'
import { sv } from 'date-fns/locale'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Portal,
  Box,
  Flex,
  Center,
  Heading,
  Text,
  Spinner,
  Avatar,
  AvatarBadge,
  Button,
  Input,
  Tooltip
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { isArray } from 'lodash'

const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      id
      profilePicture {
        url
      }
      name
      status
    }
  }
`

const GET_CONTACT = gql`
  query getContact($id: ID) {
    getContact(id: $id) {
      id
      profilePicture {
        url
      }
      name
      status
      messages {
        id
        timestamp
        message
        author {
          id
        }
      }
    }
  }
`

const ON_MESSAGE = gql`
  subscription onMessage($chatId: ID) {
    onMessage(chatId: $chatId) {
      id
      timestamp
      message
      author {
        id
      }
    }
  }
`

const SEND_MESSAGE = gql`
  mutation sendMessage($chatId: ID, $message: String) {
    sendMessage(chatId: $chatId, message: $message) {
      success
    }
  }
`

const NovaChat = () => {
  const toast = useToaster()

  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedContact, setSelectedContact] = useState(null)
  const [messageValue, setMessageValue] = useState(null)

  const shouldReauthenticate = useSelector(
    (state) => state.user.shouldReauthenticate
  )
  const user = useSelector((state) => state.user.user)

  const {
    loading: contactsLoading,
    error,
    data: contactsData
  } = useQuery(GET_CONTACTS)

  const {
    loading: selectedContactLoading,
    data: selectedContactData,
    subscribeToMore
  } = useQuery(GET_CONTACT, {
    variables: { id: selectedContact },
    skip: !selectedContact
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ON_MESSAGE,
      variables: { chatId: 0 },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev

        return {
          ...prev,
          getContact: {
            ...prev.getContact,
            messages: [
              ...prev.getContact.messages,
              subscriptionData.data.onMessage
            ]
          }
        }
      }
    })

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [subscribeToMore, selectedContact])

  const [sendMessage, { loading: sendMessageLoading }] =
    useMutation(SEND_MESSAGE)

  return (
    <>
      <Portal>
        {!shouldReauthenticate && (
          <Center
            pos="fixed"
            top="-20px"
            right="15px"
            h="85px"
            w="65px"
            pt="20px"
            border="2px"
            borderTop="0"
            borderColor="rgba(255, 255, 255, 1)"
            borderBottomRadius="2xl"
            bg="blue.400"
            cursor="pointer"
            boxShadow="5xl"
            _hover={{ transform: 'scale(1.1) translateY(10px)' }}
            transition={'0.3s'}
            zIndex="popover"
            onClick={onOpen}>
            <FontAwesomeIcon size="lg" icon={['fad', 'comments-alt']} />
          </Center>
        )}
      </Portal>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
        <ModalContent>
          <ModalBody p="0">
            <Flex w="100%" h="75vh">
              <Flex
                flex="3"
                flexDir="column"
                borderRight="1px"
                borderColor="rgba(255, 255, 255, 0.1)"
                overflow="hidden">
                <Flex justify="space-between" align="center" mx="2" my="3">
                  <Heading fontWeight="normal" size="1x" ml="1">
                    Nova<b>Chat</b>
                  </Heading>
                  <Button
                    size="xs"
                    rounded="full"
                    iconSpacing="0"
                    // rightIcon={
                    //   <FontAwesomeIcon
                    //     size="1x"
                    //     icon={['fal', 'plus-circle']}
                    //   />
                    // }
                    // iconSpacing="1"
                    onClick={() => setSelectedContact(null)}>
                    Ny chatt
                  </Button>
                </Flex>

                {contactsLoading ? (
                  <Center w="100%">
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  isArray(contactsData.getContacts) &&
                  contactsData.getContacts.map(
                    ({ id, name, status, profilePicture }) => (
                      <Flex
                        align="center"
                        key={id}
                        px="2"
                        py="2"
                        mx="1"
                        mb="1"
                        borderColor="gray.800"
                        cursor="pointer"
                        rounded="md"
                        bg={
                          id === selectedContact && 'rgba(255, 255, 255, 0.1)'
                        }
                        _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
                        onClick={() => setSelectedContact(id)}>
                        <Avatar size="sm" name={name} src={profilePicture?.url}>
                          <AvatarBadge
                            boxSize="1em"
                            bg={
                              status === 'ONLINE'
                                ? 'green.500'
                                : status === 'BUSY'
                                ? 'red.500'
                                : 'gray.500'
                            }
                          />
                        </Avatar>
                        <Flex ml="3" flexDir="column" justify="center">
                          <Text fontSize="sm" fontWeight="bold">
                            {name}
                          </Text>
                          <Text fontSize="xs" mt="-2px">
                            {status === 'ONLINE'
                              ? 'Online'
                              : status === 'BUSY'
                              ? 'Upptagen'
                              : 'Offline'}
                          </Text>
                        </Flex>
                      </Flex>
                    )
                  )
                )}

                <Flex w="100%" mt="auto">
                  <Button h="40px" w="100%" size="sm" m="3" mx="2">
                    Nytt supportärende
                  </Button>
                </Flex>
              </Flex>

              <Flex flex="7">
                {selectedContact ? (
                  selectedContactLoading || !selectedContactData ? (
                    <Center w="100%">
                      <Spinner size="xl" />
                    </Center>
                  ) : (
                    <Flex w="100%" flexDir="column">
                      <Flex
                        py="2"
                        px="4"
                        align="center"
                        borderBottom="1px"
                        borderColor="rgba(255, 255, 255, 0.1)">
                        <Avatar
                          size="md"
                          name={selectedContactData.getContact.name}
                          src={
                            selectedContactData.getContact.profilePicture.url
                          }
                        />
                        <Flex ml="3" flexDir="column" justify="center">
                          <Text fontSize="md" fontWeight="bold">
                            {selectedContactData.getContact.name}
                          </Text>
                          <Text fontSize="xs" mt="-2px">
                            {selectedContactData.getContact.status === 'ONLINE'
                              ? 'Online'
                              : selectedContactData.getContact.status === 'BUSY'
                              ? 'Upptagen'
                              : 'Offline'}
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex
                        flexGrow="1"
                        w="100%"
                        flexDirection="column"
                        justify="flex-end"
                        align="center"
                        px="4"
                        py="2">
                        {selectedContactData.getContact.messages.map(
                          ({ id, timestamp, message, author }) => (
                            <Message
                              key={id}
                              timestamp={timestamp}
                              isOwnMessage={author?.id === user?.id}
                              message={message}
                            />
                          )
                        )}
                      </Flex>

                      <Flex justify="space-around" w="100%" p="2" pb="3">
                        <Tooltip label="Skicka nuvarande plats i Novasecur">
                          <Button
                            mr="2"
                            rounded="full"
                            iconSpacing="0"
                            px="2"
                            leftIcon={
                              <FontAwesomeIcon
                                size="1x"
                                icon={['fad', 'anchor']}
                              />
                            }></Button>
                        </Tooltip>

                        <Box w="100%">
                          <Input
                            id="message"
                            placeholder="skriv ditt meddelande"
                            rounded="full"
                            flexGrow="1"
                            isLoading={sendMessageLoading}
                            value={messageValue}
                            onChange={(e) => setMessageValue(e.target.value)}
                          />
                        </Box>

                        <Tooltip label="Skicka Meddelande">
                          <Button
                            ml="2"
                            type="submit"
                            colorScheme="blue"
                            rounded="full"
                            iconSpacing="0"
                            px="6"
                            leftIcon={
                              <FontAwesomeIcon
                                size="1x"
                                icon={['fad', 'paper-plane']}
                              />
                            }
                            isDisabled={messageValue === ''}
                            isLoading={sendMessageLoading}
                            onClick={() => {
                              setMessageValue('')
                              sendMessage({
                                variables: {
                                  message: messageValue,
                                  chatId: selectedContactData.getContact.id
                                }
                              })
                            }}
                          />
                        </Tooltip>
                      </Flex>
                    </Flex>
                  )
                ) : (
                  <Flex
                    w="100%"
                    flexDir="column"
                    justify="center"
                    align="center">
                    <FontAwesomeIcon size="4x" icon={['fad', 'comments-alt']} />
                    <Heading fontWeight="normal" size="lg" mt="3" ml="1">
                      Nova<b>Chat</b>
                    </Heading>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NovaChat

const Message = ({ message, timestamp, isOwnMessage }) => (
  <Flex
    flexDir="column"
    align={isOwnMessage ? 'flex-end' : 'flex-start'}
    ml={isOwnMessage && 'auto'}
    mr={!isOwnMessage && 'auto'}
    my="1">
    <Box
      maxW="350px"
      rounded="2xl"
      boxShadow="md"
      py="1"
      px="3"
      bg={isOwnMessage ? 'gray.600' : 'blue.600'}>
      <Text fontSize="sm">{message}</Text>
    </Box>
    <Text
      as="small"
      fontSize="10px"
      opacity="0.5"
      mt="1px"
      mx="1"
      ml={isOwnMessage && 'auto'}
      mr={!isOwnMessage && 'auto'}>
      {timestamp !== null &&
        formatDistance(fromUnixTime(timestamp / 1000), new Date(), {
          addSuffix: true,
          locale: sv
        })}
    </Text>
  </Flex>
)
