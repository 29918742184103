import React from 'react'
import { gql, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Page, Content, EntryBackground, EntryLiner } from 'style/wrappers'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Container,
  Flex,
  Button,
  Spacer,
  Heading,
  Text,
  LightMode
} from '@chakra-ui/react'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import { useToaster } from 'utils/toaster'
import ErrorBoundary from 'components/ErrorBoundary'

const FORGOTTEN_PASSWORD = gql`
  mutation forgottenPassword($emailOrUsername: String) {
    forgottenPassword(emailOrUsername: $emailOrUsername) {
      success
    }
  }
`

const validationSchema = yup.object().shape({
  emailOrUsername: yup.string().required()
})

const ForgottenPassword = () => {
  const toast = useToaster()
  const history = useHistory()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const [forgottenPassword, { loading: mutationLoading, data }] = useMutation(
    FORGOTTEN_PASSWORD,
    {
      onCompleted: (data) => {
        toast({
          title: `Återställning lyckad`,
          description: data.forgottenPassword.message,
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
      },
      onError: (e) => {
        toast({
          title: `Kunde inte återställa lösenord`,
          description: e.message,
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    }
  )

  return (
    <ErrorBoundary>
      <Page h="100vh">
        <Helmet>
          <title>Återställ lösenord | Novasecur</title>
        </Helmet>
        <EntryBackground>
          <Flex
            h="100vh"
            w="100vw"
            justify="center"
            align="center"
            flexDir="column"
            bg="blackAlpha.600"
            backdropFilter="blur(3px)">
            <Flex mt="20vh" mb="auto">
              <Container maxW="container.lg">
                <Box mb="6">
                  <Heading textAlign="center">återställ lösenord</Heading>
                  <Text textAlign="center" px="10%">
                    Glömt ditt lösenord? Ingen fara!
                  </Text>
                </Box>
                <LightMode>
                  <FormProvider {...form}>
                    <form
                      onSubmit={form.handleSubmit((input) =>
                        forgottenPassword({ variables: input })
                      )}>
                      <Flex
                        w="420px"
                        flexDir="column"
                        bg="#fff"
                        color="grey"
                        px="4"
                        py="5"
                        mx="auto"
                        rounded="lg"
                        boxShadow="xl">
                        <TextInput
                          id="emailOrUsername"
                          label="E-postadress eller användarnamn"
                          helper="Ange E-postadress eller användarnamn kopplat till ditt konto"
                          bg="#eee"
                          color="#333"
                        />
                      </Flex>

                      <Flex justify="center" mt="6">
                        <Button
                          variant="ghost"
                          onClick={() => history.push('/login')}>
                          Tillbaka
                        </Button>

                        <Spacer />

                        <Button
                          type="submit"
                          colorScheme="teal"
                          isLoading={mutationLoading}
                          isDisabled={!form.formState.isValid}>
                          Återställ lösenord
                        </Button>
                      </Flex>
                    </form>
                  </FormProvider>
                </LightMode>
              </Container>
            </Flex>
          </Flex>
        </EntryBackground>
      </Page>
    </ErrorBoundary>
  )
}

export default ForgottenPassword
