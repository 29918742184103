import React, { useState, useCallback, useEffect } from 'react'
import * as yup from 'yup'
import { Wizard, useWizard } from 'react-use-wizard'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { gql, useQuery, useMutation } from '@apollo/client'
import omitDeep from 'omit-deep'
import {
  Box,
  ButtonGroup,
  Button,
  Textarea,
  Spinner,
  Center,
  Flex,
  Text,
  Heading,
  Stack,
  VStack,
  Divider,
  useDisclosure
} from '@chakra-ui/react'

import { member, membership } from 'constants/entities'
import bankidSchema from 'validationSchemas/bankidSchema'
import AddressSchema from 'validationSchemas/AddressSchema'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import AddressForm from 'containers/forms/AddressForm'
import { pushPanel, popPanel } from 'containers/PanelNavigation'

import { ListItem as CardListItem } from 'components/cards/ListCard'
import PageTitle from 'components/PageTitle'
import TextInput from 'components/form/TextInput'
import YesNoInput from 'components/form/YesNoInput'
import SelectorInput from 'components/form/SelectorInput'
import PinInput from 'components/form/PinInput'
import { number } from 'yup/lib/locale'
import LabelRow from 'components/LabelRow'
import WizardStep from 'components/WizardStep'
import ListOption from 'components/ListOption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubtleCard from 'components/cards/SubtleCard'
import { useDispatch } from 'react-redux'
import { openCardCollector } from 'actions/services'
import CardCollector from 'containers/services/CardCollector'
import { useToaster } from 'utils/toaster'
import eventInterchange from 'utils/eventInterchange'

const CREATE_MEMBER = gql`
  mutation createMember($input: MemberInput!) {
    createMember(input: $input) {
      id
      memberNumber
    }
  }
`

const GET_ADDRESS_FROM_NATIONALID = gql`
  query addressFromNationalId($nationalId: String!) {
    addressFromNationalId(nationalId: $nationalId) {
      nationalId
      email
      mobileNumber

      fullname
      firstname
      middlename
      lastname

      co
      street
      street2
      postcode
      city
      country
    }
  }
`

const CreateMemberPanel = ({
  data: { openedFromRegister, source, limitedMode },
  ...rest
}) => {
  const [data, setData] = useState({ primaryAddress: {}, mediaId: [] })
  const doSetData = (val) => setData({ ...data, ...val })

  return (
    <SlideOutPanel {...rest}>
      <Wizard>
        <NationalId setData={doSetData} />
        <Lookup data={data} setData={doSetData} />
        <Information data={data} setData={doSetData} />
        <Facilities data={data} setData={doSetData} />
        <MediaID data={data} setData={doSetData} />
        <Final
          data={data}
          setData={doSetData}
          openedFromRegister={openedFromRegister}
          source={source}
          limitedMode={limitedMode}
        />
      </Wizard>
    </SlideOutPanel>
  )
}

const NationalId = ({ setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(bankidSchema)
  })

  return (
    <>
      <Progress step={activeStep} />
      <Box py="2" px="30%">
        <PageTitle
          icon="user-edit"
          title="Skapa medlem"
          subTitle="Ange kundens personnummer för att automatiskt hämta uppgifter från folkbokföringen, eller klicka på 'Inget personnummer' om kunden saknar personnummer."
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((value) => {
              setData({ primaryAddress: value })
              nextStep()
            })}>
            <TextInput
              id="nationalId"
              label="Personnummer"
              helper="Accepterar endast svenska personnummer"
            />

            <ButtonGroup float="right" mt="5">
              <Button
                type="button"
                variant="ghost"
                color="#8395a7"
                onClick={() => nextStep(2)}>
                Inget personnummer
              </Button>
              <Button
                type="submit"
                isDisabled={!form.formState.isValid}
                color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const Lookup = ({ data, setData }) => {
  const toast = useToaster()
  const { handleStep, previousStep, nextStep } = useWizard()

  const { loading } = useQuery(GET_ADDRESS_FROM_NATIONALID, {
    variables: { nationalId: data.primaryAddress.nationalId },
    onCompleted: ({ addressFromNationalId }) => {
      toast({
        title: 'Information från folkbokföringen hämtad!',
        description: 'Dubbelkolla så allt ser korrekt ut',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
      const { __typename, ...cleanInfoFromNationalId } = addressFromNationalId
      setData({
        primaryAddress: { ...cleanInfoFromNationalId, ...data.primaryAddress }
      })
      nextStep()
    },
    onError: (error) => {
      toast({
        title: `Kunde inte hämta uppgifter`,
        description: 'Försök igen eller fyll i manuellt',
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
      previousStep()
    }
  })

  return (
    <Box py="5" px="30%">
      <PageTitle
        icon=""
        title="Hämtar folkbokföringsuppgifter"
        subTitle="Vänta medans vi automatiskt hämtar uppgifter för kunden"
        center
      />

      <Box py="8">
        <Center>
          <Spinner size="xl" />
        </Center>
      </Box>

      <Center>
        <ButtonGroup mt="5">
          <Button
            variant="ghost"
            color="#8395a7"
            onClick={() => previousStep()}>
            Föregående
          </Button>
        </ButtonGroup>
      </Center>
    </Box>
  )
}

const Information = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddressSchema)
  })

  useEffect(() => form.reset(data.primaryAddress), [data])

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%" mb="10%">
        <PageTitle
          title="Fyll i uppgifter"
          subTitle="vi behöver namn, en adress och telefon/mail för att skapa en medlem"
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((values) => {
              setData({ primaryAddress: { ...data.primaryAddress, ...values } })
              nextStep()
            })}>
            <AddressForm />

            <ButtonGroup float="right" mt="5">
              <Button
                variant="ghost"
                color="#8395a7"
                onClick={() => previousStep(0)}>
                Föregående
              </Button>
              <Button
                type="submit"
                isDisabled={!form.formState.isValid}
                color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const Facilities = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()

  const schema = yup.object().shape({
    pin: yup.string().length(4)
  })

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(schema)
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          title="Anläggningar"
          subTitle="Vilka Anläggningar ska kunden ha tillgång till? Här anges även pinkod som används för passersystemet"
          center
        />

        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((val) => {
              setData(val)
              nextStep()
            })}>
            <SelectorInput
              id="facilities"
              label="Vilka anläggningar ska ha tillgång till denna medlem?"
              multi
              options={[
                { value: 'Göteborg', label: 'Göteborg' },
                { value: 'Malmö', label: 'Malmö' },
                { value: 'Stockholm', label: 'Stockholm' }
              ]}
            />

            <Box my="5" mb="8">
              <PinInput id="pin" label="Medlemmens pinkod" />
            </Box>

            <ButtonGroup float="right">
              <Button
                variant="ghost"
                color="#8395a7"
                onClick={() => previousStep()}>
                Föregående
              </Button>
              <Button type="submit" color="#54a0ff">
                Nästa
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

const MediaID = ({ data, setData }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()
  const dispatch = useDispatch()
  const cardCollectorDisclosure = useDisclosure()

  const form = useForm()

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          icon="id-card-clip"
          title="Kort & Tagg"
          subTitle="Lägg till kort & taggar på medlemmen"
          center
        />

        <Flex flexDir="column" align="flex-start" w="100%" mb="4">
          {data.mediaId.map((value) => (
            <SubtleCard w="100%" mb="1">
              <Center w="100%" justifyContent="space-between">
                <Heading fontSize="sm">Kort / tagg - {value}</Heading>

                <Center
                  cursor="pointer"
                  ml="8"
                  mr="3"
                  opacity="0.6"
                  color="red.400"
                  _hover={{ opacity: '1' }}
                  onClick={() =>
                    setData({
                      mediaId: data.mediaId.filter((id) => id !== value)
                    })
                  }>
                  <FontAwesomeIcon size="lg" icon={['fad', 'times-circle']} />
                </Center>
              </Center>
            </SubtleCard>
          ))}

          {data.mediaId.length === 0 && (
            <Center
              w="100%"
              h="100px"
              border="2px dashed"
              borderColor="whiteAlpha.400"
              rounded="lg"
              fontWeight="bold"
              cursor="pointer"
              _hover={{ bg: 'whiteAlpha.100' }}
              onClick={cardCollectorDisclosure.onOpen}>
              Lägg till kort / tagg
            </Center>
          )}

          {data.mediaId.length > 0 && (
            <Button
              onClick={cardCollectorDisclosure.onOpen}
              size="sm"
              w="100%"
              mt="2">
              Lägg till flera kort / taggar
            </Button>
          )}
        </Flex>

        <ButtonGroup float="right" mt="4">
          <Button
            variant="ghost"
            color="#8395a7"
            onClick={() => previousStep()}>
            Föregående
          </Button>
          <Button onClick={() => nextStep()} color="#54a0ff">
            Nästa
          </Button>
        </ButtonGroup>
      </Box>

      <CardCollector
        {...cardCollectorDisclosure}
        onSave={(tagId) => setData({ mediaId: [...data.mediaId, tagId] })}
      />
    </>
  )
}

const Final = ({ data, openedFromRegister, source, limitedMode }) => {
  const { handleStep, previousStep, nextStep, activeStep } = useWizard()
  const toast = useToaster()

  const [shouldCreateSubscription, setShouldCreateSubscription] =
    useState(false)
  const form = useForm()

  const [createMember] = useMutation(CREATE_MEMBER, {
    onCompleted: ({ createMember }) => {
      toast({
        title: 'Medlem skapad!',
        description: 'Medlemmen skapades utan problem',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })

      eventInterchange.emit(source || 'newMemberCreated', createMember)

      popPanel()
      if (shouldCreateSubscription) {
        pushPanel('create-membership', {
          memberID: createMember.memberNumber,
          ...(openedFromRegister && { openedFromRegister: openedFromRegister })
        })
      }
    },
    onError: (e) => {
      toast({
        title: `Kunde inte skapa medlem`,
        description: e.message,
        status: 'error',
        position: 'top-right',
        isClosable: true
      })
    }
  })

  return (
    <>
      <Progress step={activeStep} />

      <Box py="5" px="30%">
        <PageTitle
          title="Nästan klara!"
          subTitle="är du redo att skapa medlemmen?"
          center
        />

        <Flex flexDir="column" w="100%" mb="2">
          <Flex
            flexDir="column"
            flex="1"
            p="2"
            mb="1"
            bg="rgba(255, 255, 255, 0.05)"
            rounded="lg">
            <LabelRow
              label="Förnamn"
              value={data.primaryAddress.firstname}
              color="green"
            />
            <LabelRow
              label="Efternamn"
              value={data.primaryAddress.lastname}
              color="green"
            />
            {data.primaryAddress.nationalId && (
              <LabelRow
                label="Personnr"
                value={data.primaryAddress.nationalId}
                color="green"
              />
            )}

            <Box mb="2"></Box>

            <LabelRow
              label="Adress"
              value={data.primaryAddress.street}
              color="cyan"
            />
            {data.primaryAddress.street2 && (
              <LabelRow
                label="Adress 2"
                value={data.primaryAddress.street2}
                color="cyan"
              />
            )}
            <LabelRow
              label="Postnummer"
              value={data.primaryAddress.postcode}
              color="cyan"
            />
            <LabelRow
              label="Ort"
              value={data.primaryAddress.city}
              color="cyan"
            />

            <Box mb="2"></Box>

            <LabelRow
              label="Mobil"
              value={data.primaryAddress.mobileNumber}
              icon="home"
              color="red"
            />
            <LabelRow
              label="Mail"
              value={data.primaryAddress.email}
              icon="city"
              color="red"
            />
          </Flex>

          <Flex
            flexDir="column"
            flex="1"
            p="2"
            bg="rgba(255, 255, 255, 0.05)"
            rounded="lg">
            <LabelRow label="Pin" value={data.pin} icon="home" color="yellow" />
            <LabelRow
              label="Anläggningar"
              value={data.facilities}
              color="yellow"
            />
          </Flex>
        </Flex>

        <Divider mt="4" />

        <FormProvider {...form}>
          <form>
            <Box>
              <Box mt="4" mb="5">
                <Heading size="xs" textAlign="center" mb="2">
                  Något att tillägga?
                </Heading>
                <Textarea
                  {...form.register('note')}
                  placeholder="Skriv en anteckning"></Textarea>
              </Box>

              <YesNoInput
                id="consentNewsletter"
                label="Samtycke till nyhetsbrev"
              />

              <Divider my="4" />

              <VStack mt="5">
                {limitedMode ? (
                  <ListOption
                    icon={member.icon}
                    color={member.color}
                    title="Skapa medlemmen och gå tillbaka"
                    description="Skapa medlemmen och fortsätt där du var."
                    onClick={form.handleSubmit((values) => {
                      createMember({
                        variables: { input: { ...data, ...values } }
                      })
                    })}
                  />
                ) : (
                  <>
                    <ListOption
                      icon={membership.icon}
                      color={membership.color}
                      title="Lägg till abonnemang på medlemmen"
                      description="Lägg till ett abonnemang direkt"
                      onClick={form.handleSubmit((values) => {
                        setShouldCreateSubscription(true)
                        createMember({
                          variables: { input: { ...data, ...values } }
                        })
                      })}
                    />
                    <ListOption
                      icon={member.icon}
                      color={member.color}
                      title="Skapa bara medlemmen"
                      description="Skapa medlemmen utan ett abonnemang"
                      onClick={form.handleSubmit((values) => {
                        createMember({
                          variables: { input: { ...data, ...values } }
                        })
                      })}
                    />
                  </>
                )}
              </VStack>

              <Center w="100%" mx="auto" pt="20%">
                <VStack>
                  <Button
                    variant="ghost"
                    color="#8395a7"
                    onClick={() => previousStep()}>
                    Föregående
                  </Button>
                </VStack>
              </Center>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}

export default CreateMemberPanel

const Progress = ({ step }) => {
  return (
    <Stack
      mt="3"
      mb="12"
      spacing="0"
      direction={{
        base: 'column',
        md: 'row'
      }}>
      <WizardStep
        title="Personnummer"
        description=""
        isActive={step === 0}
        isCompleted={step > 0}
        isFirstStep
      />
      <WizardStep
        title="Information"
        description=""
        isActive={step === 2}
        isCompleted={step > 2}
      />
      <WizardStep
        title="Pinkod"
        description=""
        isActive={step === 3}
        isCompleted={step > 3}
      />
      <WizardStep
        title="Kort & tagg"
        description=""
        isActive={step === 4}
        isCompleted={step > 4}
      />
      <WizardStep
        title="Slutför"
        description=""
        isActive={step === 5}
        isCompleted={step > 5}
        isLastStep
      />
    </Stack>
  )
}
