const init = {
  shouldReauthenticate: false,
  isAuthenticated: false,
  CookieConsent: false,
  user: {
    id: null,
    username: '',
    firstname: '',
    lastname: '',
    permissions: {},
    toDoLists: [],
    preferences: {},
    selectedMainFacility: { name: '', doors: [] },
    selectedFacilities: []
  },
  theme: 'dark'
}

const userReducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_IS_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload }

    case 'SET_SHOULD_REAUTHENTICATE':
      return { ...state, shouldReauthenticate: action.payload }

    case 'SET_USER':
      return { ...state, user: action.payload }

    case 'SET_MAIN_FACILITY':
      return {
        ...state,
        user: { ...state.user, selectedMainFacility: action.payload }
      }

    case 'SET_SELECTED_FACILITIES':
      return {
        ...state,
        user: { ...state.user, selectedFacilities: action.payload }
      }

    case 'SET_THEME':
      return { ...state, theme: action.payload }

    case 'LOGOUT':
      return { ...state, user: init.user }

    case 'HAS_COOKIE_CONSENTED':
      return { ...state, CookieConsent: true }

    default:
      return state
  }
}

export default userReducer
