import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { Page, Content, EntryBackground, EntryLiner } from 'style/wrappers'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Box,
  Container,
  Flex,
  Button,
  Spacer,
  Heading,
  Text,
  LightMode
} from '@chakra-ui/react'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import { useToaster } from 'utils/toaster'
import ErrorBoundary from 'components/ErrorBoundary'

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String, $newPassword: String) {
    resetPassword(token: $token, newPassword: $newPassword) {
      success
    }
  }
`

const validationSchema = yup.object().shape({
  newPassword: yup.string().required(),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Lösenordet måste matcha!')
})

const ResetPassword = () => {
  const toast = useToaster()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const [doResetPassword, { loading: mutationLoading }] = useMutation(
    RESET_PASSWORD,
    {
      variables: {
        token: searchParams.get('token')
      },
      onCompleted: (data) => {
        toast({
          title: `Lösenord ändrat`,
          description: data.resetPassword.message,
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
      },
      onError: (e) => {
        toast({
          title: `Kunde inte återställa lösenord`,
          description: e.message,
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    }
  )

  return (
    <ErrorBoundary>
      <Page h="100vh">
        <Helmet>
          <title>Byt lösenord | Novasecur</title>
        </Helmet>
        <EntryBackground>
          <Flex
            h="100vh"
            w="100vw"
            justify="center"
            align="center"
            flexDir="column"
            bg="blackAlpha.600"
            backdropFilter="blur(3px)">
            <Flex mt="20vh" mb="auto">
              <Container maxW="container.lg">
                <Box mb="6">
                  <Heading textAlign="center">Återställ lösenord</Heading>
                  <Text textAlign="center" px="10%"></Text>
                </Box>
                <LightMode>
                  <FormProvider {...form}>
                    <form
                      onSubmit={form.handleSubmit(({ newPassword }) =>
                        doResetPassword({ variables: { newPassword } })
                      )}>
                      <Flex
                        w="420px"
                        flexDir="column"
                        bg="#fff"
                        color="grey"
                        px="4"
                        py="5"
                        mx="auto"
                        rounded="lg"
                        boxShadow="xl">
                        <TextInput
                          type="password"
                          id="newPassword"
                          label="Nytt Lösenord"
                          helper=""
                          bg="#eee"
                          color="#333"
                        />
                        <TextInput
                          type="password"
                          id="newPasswordConfirm"
                          label="Repetera nytt lösenord"
                          helper=""
                          bg="#eee"
                          color="#333"
                        />
                      </Flex>

                      <Flex justify="center" mt="6">
                        <Button
                          variant="ghost"
                          onClick={() => history.push('/login')}>
                          Tillbaka
                        </Button>

                        <Spacer />

                        <Button
                          type="submit"
                          colorScheme="teal"
                          isLoading={mutationLoading}
                          isDisabled={!form.formState.isValid}>
                          Återställ lösenord
                        </Button>
                      </Flex>
                    </form>
                  </FormProvider>
                </LightMode>
              </Container>
            </Flex>
          </Flex>
        </EntryBackground>
      </Page>
    </ErrorBoundary>
  )
}

export default ResetPassword
