import React, { useEffect, createContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react'

import { setHelpMode } from 'actions/app'

export const HelpContext = createContext(false)

const HelpOverlay = ({ children }) => {
  const dispatch = useDispatch()
  const helpMode = useSelector((state) => state.app.helpMode)
  const panels = useSelector((state) => state.panels.panels)

  return (
    <HelpContext.Provider value={panels.length === 0 && helpMode}>
      {children}

      {helpMode && (
        <Box
          pos="fixed"
          bottom="5px"
          left="80px"
          width="400px"
          zIndex="popover">
          <Box
            py="4"
            px="3"
            background="gray.700"
            borderRadius="md"
            boxShadow="xl"
            borderWidth="2px"
            borderColor="rgba(255, 255, 255, 0.2)">
            <Flex flexDir="column" justify="center" align="center" my="4">
              <FontAwesomeIcon size="4x" icon={['fad', 'life-ring']} />
              <Heading size="md" mt="2" mb="4">
                Hjälpläge
              </Heading>
              <Text mt="1" mx="2" textAlign="center">
                Använd <FontAwesomeIcon icon={['fal', 'info-circle']} />{' '}
                ikonerna placerade på sidan för att lära dig mer om Novasecurs
                olika funktioner. Något du behöver vår hjälp med?
              </Text>
              <Button mt="4">Skapa supportärende</Button>
            </Flex>

            {/* <LinkBox
              as="article"
              maxW="sm"
              p="2"
              mb="1"
              borderWidth="1px"
              rounded="md">
              <Heading size="sm" my="1">
                <LinkOverlay href="#">Navigera i Novasecur v2</LinkOverlay>
              </Heading>
              <Text fontSize="xs">
                Lär dig hur du på bästa sätt rör dig runt nya Novasecur med det
                nya navigerings funktionerna.
              </Text>
            </LinkBox>

            <LinkBox
              as="article"
              maxW="sm"
              p="2"
              mb="1"
              borderWidth="1px"
              rounded="md">
              <Heading size="sm" my="1">
                <LinkOverlay href="#">
                  Användningsguide för tabellen
                </LinkOverlay>
              </Heading>
              <Text fontSize="xs">
                Tabeller är basen av Novasecur och en mycket kraftfullt verktyg
                för att hantera din organisation.
              </Text>
            </LinkBox>

            <LinkBox
              as="article"
              maxW="sm"
              p="2"
              borderWidth="1px"
              rounded="md">
              <Heading size="sm" my="1">
                <LinkOverlay href="#">
                  Snabbstartguide för nya användare
                </LinkOverlay>
              </Heading>
              <Text fontSize="xs">
                Lär dig allt du behöver veta för att snabbt och effektivt
                använda Novasecur. Gjord speciellt för dig som precis har börjat
                använda Nova.
              </Text>
            </LinkBox> */}

            <Button
              onClick={() => dispatch(setHelpMode(false))}
              variant="ghost"
              size="sm"
              w="100%"
              ml="auto"
              mt="3">
              Stäng
            </Button>
          </Box>
        </Box>
      )}
    </HelpContext.Provider>
  )
}

export default HelpOverlay
