import React, { useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery } from '@apollo/client'
import { ButtonGroup, Button as Button } from '@chakra-ui/react'

import FreshTable from 'containers/FreshTable'
import WithHelpi from 'components/utils/WithHelpi'

import { pushPanel } from 'containers/PanelNavigation'

const GET_MEMBERS = gql`
  query members($pageIndex: Int!, $pageSize: Int!, $search: String!) {
    members(
      table: { pageIndex: $pageIndex, pageSize: $pageSize, search: $search }
    ) {
      pageCount
      filteredCount
      totalCount
      members {
        id
        memberNumber
        primaryAddress {
          email
          fullname
          firstname
          lastname
          nationalId
        }
      }
    }
  }
`

const EventTable = () => {
  const [getMembers, { loading, error, data }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: 'network-only'
  })

  const onChange = useCallback((val) => {
    getMembers({ variables: val })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'M.nr',
        accessor: 'memberNumber',
        maxWidth: 50
      },
      {
        Header: 'Namn',
        accessor: 'fullname'
      },
      {
        Header: 'Personnr',
        accessor: 'nationalId'
      },
      {
        Header: 'Epost',
        accessor: 'primaryAddress.email'
      }
    ],
    []
  )

  return (
    <>
      <ButtonGroup mb="2" ml="2">
        <Button
          onClick={() => pushPanel('create-member')}
          leftIcon={<FontAwesomeIcon icon={['fad', 'flag-checkered']} />}
          color={'#70a1ff'}
          helpi="Skapa ett nytt arrangemang">
          Skapa ett nytt arrangemang
        </Button>
      </ButtonGroup>

      <FreshTable
        title="Alla Arrangemang"
        color={'#70a1ff'}
        icon={'flag-checkered'}
        loading={loading}
        columns={columns}
        data={data?.members ? data.members.members : []}
        pageCount={data?.members.pageCount}
        filteredCount={data?.members.filteredCount}
        totalCount={data?.members.totalCount}
        onChange={onChange}
        onRowClick={(values) =>
          pushPanel('member-menu', {
            name: values.fullname,
            id: values.memberNumber
          })
        }
      />
    </>
  )
}

export default EventTable
