import { Box, useColorModeValue } from '@chakra-ui/react'

const PageHeader = ({ image }) => {
  const bg = useColorModeValue('white', 'gray.800')

  return (
    <Box
      mb="-34vh"
      h="40vh"
      w="100%"
      bg={`url(${image})`}
      bgSize="100%"
      bgRepeat="no-repeat"
      boxShadow="inner"
      bgAttachment="fixed">
      <Box
        h="100%"
        w="100%"
        bgGradient={`linear(to-b, transparent, ${bg} 90%)`}
      />
    </Box>
  )
}

export default PageHeader
