const init = {
  active: false,
  type: null,
  nationalId: null
}

const bankidReducer = (state = init, action) => {
  switch (action.type) {
    case 'START_BANKID':
      return {
        ...state,
        active: true,
        nationalId: action.payload.nationalId,
        type: action.payload.type
      }

    case 'END_BANKID':
      return { ...state, active: false, nationalId: null }

    default:
      return state
  }
}

export default bankidReducer
