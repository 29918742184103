const themeDark = {
  type: 'dark',
  primary_body: '#343a40',
  secondary_body: '#2e3439',
  divider: '#71757a',
  primary_text: '#fff',
  secondary_text: '#f5f5f6',
  background: '#484d53'
}

export default themeDark
