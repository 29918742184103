import React from 'react'
import { Helmet } from 'react-helmet'
import { Page, Content, Boxed } from 'style/wrappers'

import PrimaryNavigation from 'containers/PrimaryNavigation'
import PageTitle from 'components/PageTitle'
import BookingCalendar from 'containers/BookingCalendar'
import PageHeader from 'components/PageHeader'
import ErrorBoundary from 'components/ErrorBoundary'

const Booking = () => {
  return (
    <ErrorBoundary>
      <Page>
        <Helmet>
          <title>Bokningar | Novasecur</title>
        </Helmet>

        <PrimaryNavigation />

        <Content>
          <PageHeader image="https://images.pexels.com/photos/2526878/pexels-photo-2526878.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          <Boxed>
            <PageTitle
              icon="ticket"
              title="Bokningar"
              subTitle="Hantera pass och tjänsbokningar inom din organization."
            />

            <BookingCalendar />
          </Boxed>
        </Content>
      </Page>
    </ErrorBoundary>
  )
}

export default Booking
