import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Flex, Heading, Button } from '@chakra-ui/react'

import { member, membership, invoice, booking } from 'constants/entities'
import { logout } from 'actions/user'
import { openAllDoors } from 'actions/services'

import { pushPanel } from 'containers/PanelNavigation'

import ListOption from 'components/ListOption'
import InlineTitle from 'components/InlineTitle'
import DoorController from 'components/DoorController'

// TODO move to containers
const ControlPanel = ({ doClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const doors =
    useSelector((state) => state.user.user.selectedMainFacility.doors) || []

  return (
    <Flex direction="column" px="3" py="4" mb="2">
      <ListOption
        title="Ny medlem"
        description="Skapa en ny medlem"
        color={member.color}
        icon={member.icon}
        onClick={() => {
          pushPanel('create-member')
          doClose()
        }}
      />
      <ListOption
        title="Nytt abonnemang"
        description="Skapa ett nytt abonnemang"
        color={membership.color}
        icon={membership.icon}
        onClick={() => {
          pushPanel('create-membership')
          doClose()
        }}
      />
      <ListOption
        title="Ny faktura"
        description="Skapa en ny faktura"
        color={invoice.color}
        icon={invoice.icon}
        onClick={() => {
          pushPanel('create-invoice')
          doClose()
        }}
      />
      <ListOption
        title="Nytt pass"
        description="Skapa en nytt pass"
        color={booking.color}
        icon={booking.icon}
      />

      {doors.length > 0 && (
        <>
          <InlineTitle>Dörrar</InlineTitle>
          {doors
            .filter(({ isPrimary }) => isPrimary === true)
            .map((values) => (
              <DoorController key={values.id} {...values} />
            ))}
          {doors.find(({ isPrimary }) => isPrimary === false) !== null && (
            <Button
              size="sm"
              onClick={() => {
                dispatch(openAllDoors())
                doClose()
              }}>
              Alla dörrar
            </Button>
          )}
        </>
      )}

      <InlineTitle>Användare</InlineTitle>

      <Flex mt="1">
        <FontAwesomeIcon size="3x" icon={['fad', 'id-badge']} />
        <Flex ml="2" direction="column">
          <Flex>
            <Heading size="sm" mr="2">
              {user.firstname} {user.lastname}
            </Heading>
            <Heading color="gray.500" size="sm">
              {user.username}
            </Heading>
          </Flex>
          <Button
            size="xs"
            style={{ marginTop: '4px' }}
            mr="auto"
            onClick={() => {
              dispatch(logout())
              history.push('/login')
            }}>
            Logga ut
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ControlPanel
