import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text, Tag, TagLeftIcon, TagLabel, Center } from '@chakra-ui/react'

const TimeRangeDisplay = ({ start, end, ...rest }) => {
  return (
    <Tag align="center" {...rest} rounded="lg" colorScheme="blue">
      <TagLeftIcon
        as={() => (
          <FontAwesomeIcon size="lg" icon={['fad', 'calendar-range']} />
        )}
      />
      <TagLabel>
        <Center ml="2">
          <Text fontSize="sm" fontWeight="bold" mr="2" mt="2px">
            {new Date(start).toLocaleDateString()}
          </Text>
          <FontAwesomeIcon size="lg" icon={['fal', 'long-arrow-right']} />
          <Text fontSize="sm" fontWeight="bold" ml="2" mt="2px">
            {new Date(end).toLocaleDateString()}
          </Text>
        </Center>
      </TagLabel>
    </Tag>
  )
}

export default TimeRangeDisplay
