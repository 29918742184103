import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Flex,
  Input,
  Spinner,
  Image
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { closeCardCollector } from 'actions/services'

import TextInput from 'components/form/TextInput'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'

const LOOKUP_AUTOGIRO = gql`
  query lookupAutogiro(
    $nationalId: String
    $clearing: String
    $account: String
  ) {
    lookupAutogiro(
      nationalId: $nationalId
      clearing: $clearing
      account: $account
    ) {
      valid
      clearingValid
      accountValid

      bank
    }
  }
`

const REGISTER_AUTOGIRO = gql`
  mutation registerAutogiro(
    $nationalId: String
    $clearing: String
    $account: String
  ) {
    registerAutogiro(
      nationalId: $nationalId
      clearing: $clearing
      account: $account
    ) {
      success
      message
    }
  }
`

const AutogiroConsent = ({ isOpen, onClose, onSave }) => {
  const toast = useToaster()
  const inputRef = useRef()

  const form = useForm()

  const [doLookupAutogiro, { data }] = useLazyQuery(LOOKUP_AUTOGIRO, {
    onCompleted: ({ lookupAutogiro: { clearingValid, accountValid } }) => {
      form.clearErrors()
      if (!clearingValid)
        form.setError('clearing', {
          type: 'manual',
          message: 'Clearingnummer är felaktigt ifyllt.'
        })
      if (!accountValid)
        form.setError('clearing', {
          type: 'manual',
          message: 'Kontonummer är felaktigt ifyllt.'
        })
    }
  })

  const [doRegisterAutogiro] = useMutation(REGISTER_AUTOGIRO, {
    onCompleted: (data) => {
      if (data.registerAutogiro.success) {
        toast({
          title: 'Medgivande har sparats!',
          status: 'success',
          position: 'top-right',
          isClosable: true
        })
        onClose()
      } else {
        toast({
          title: data.registerAutogiro.message,
          status: 'error',
          position: 'top-right',
          isClosable: true
        })
      }
    }
  })

  useFormAutoSubmit({
    form,
    onSubmit: (vals) => {
      doLookupAutogiro({ variables: vals })
    }
  })

  return (
    <>
      <Modal
        size="xl"
        initialFocusRef={inputRef}
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit((vals) => {
              doRegisterAutogiro({ variables: vals })
            })}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Autogiro medgivande</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mt="-4" mb="4">
                  Upprätta ett autogiro medgivande för att kunna debitera
                  kunden. Du kan inte spara ett inkomplett medgivande, knappen
                  spara medgivande blir aktiv först när alla fält har fyllts i
                  korrekt.
                </Text>

                <TextInput
                  id="nationalId"
                  label="Personnummer"
                  placeholder="ÅÅMMDD-XXXX"
                  helper="Ägare av bankkonto"
                />

                <Flex
                  flexDir="column"
                  p="2"
                  mt="4"
                  border="1px"
                  borderColor="whiteAlpha.300"
                  rounded="md">
                  <TextInput
                    id="clearing"
                    label="Clearingnummer"
                    placeholder="XXXX"
                    helper="4 siffror"
                    error="test"
                  />

                  <TextInput
                    id="account"
                    label="Kontonummer"
                    placeholder=""
                    helper="Kontonummer, eller personnumer för Nordea kunder"
                  />

                  <Flex align="center" h="65px" px="2">
                    <ErrorBoundary fallback={<Box />} resetKeys={[data]}>
                      {data && (
                        <Image
                          w="135px"
                          src={`${process.env.PUBLIC_URL}/banks/${
                            data.lookupAutogiro.bank || ''
                          }.png`}
                        />
                      )}
                    </ErrorBoundary>
                  </Flex>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  size="sm"
                  w="100%"
                  mr="2"
                  onClick={() => {
                    onClose()
                  }}>
                  Avbryt
                </Button>
                <Button
                  size="sm"
                  colorScheme="blue"
                  w="100%"
                  type="submit"
                  disabled={!data || !data?.lookupAutogiro?.valid}>
                  Spara medgivande
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default AutogiroConsent
