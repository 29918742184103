import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery, useMutation } from '@apollo/client'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { Wizard, useWizard } from 'react-use-wizard'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  ButtonGroup,
  Button,
  Heading,
  Text,
  Divider,
  Tag,
  Spacer,
  Spinner,
  Center
} from '@chakra-ui/react'

import { invoice } from 'constants/entities'

import { useToaster } from 'utils/toaster'
import BigOption from 'components/BigOption'
import TextInput from 'components/form/TextInput'
import NumberInput from 'components/form/NumberInput'
import LabelRow from 'components/LabelRow'

const GET_PRICE = gql`
  query paymentPrice($payment: PaymentInput) {
    paymentPrice(payment: $payment) {
      price
    }
  }
`

const PaySelector = ({ isOpen, onClose, payment }) => {
  const toast = useToaster()
  const [paymentState, setPaymentState] = useState({})

  useEffect(() => {
    if (isOpen) {
      console.log('payment: ', payment)
      setPaymentState(payment)
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
      <ModalContent>
        <ModalHeader pb="0">
          <FontAwesomeIcon
            size="lg"
            style={{ marginRight: '15px' }}
            icon={['fad', 'money-bill-wave']}
          />
          Hantera betalning
        </ModalHeader>

        <ModalCloseButton size="lg" />
        <ModalBody my="4">
          <Wizard>
            <Amount payment={paymentState} setPayment={setPaymentState} />
            <Options payment={paymentState} />
          </Wizard>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PaySelector

const Amount = ({ payment, setPayment }) => {
  const { handleStep, previousStep, nextStep } = useWizard()
  const [paymentOverUnder, setPaymentOverUnder] = useState(0)

  const form = useForm()
  const selectedAmountWatch = form.watch('selectedAmount')

  const { loading, error, data } = useQuery(GET_PRICE, {
    variables: { payment },
    fetchPolicy: 'network-only',
    onCompleted: ({ paymentPrice }) =>
      form.setValue('selectedAmount', paymentPrice.price)
  })

  if (loading)
    return (
      <Center py="4">
        <Spinner size="lg" />
      </Center>
    )

  const {
    paymentPrice: { price }
  } = data
  const selectedAmountUnderpaid = selectedAmountWatch < price
  const selectedAmountOverpaid = selectedAmountWatch > price

  return (
    <Box pt="0">
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(({ selectedAmount }) => {
            setPayment({ ...payment, price: selectedAmount })
            nextStep()
          })}>
          <Flex flexDir="column">
            <Text mt="-4">
              Du kan välja att betala hela summan eller delbetala
            </Text>

            <Flex flexDir="column" my="4">
              <NumberInput
                id="selectedAmount"
                label="Ange belopp att betala"
                min={0}
              />
              <Flex mt="-1" mb="4">
                {selectedAmountUnderpaid && (
                  <Tag colorScheme="red" size="sm">
                    <Text as="b" mr="1">
                      Kvar att betala:
                    </Text>
                    {price - selectedAmountWatch}
                    kr
                  </Tag>
                )}
                {selectedAmountOverpaid && (
                  <Tag colorScheme="green" size="sm">
                    <Text as="b" mr="1">
                      över summa:
                    </Text>
                    {selectedAmountWatch - price}
                    kr
                  </Tag>
                )}
                <Spacer />
                {selectedAmountWatch != price && (
                  <Button
                    size="xs"
                    onClick={() => form.setValue('selectedAmount', price)}>
                    Hela summan
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>

          <ButtonGroup w="100%">
            <Button
              type="submit"
              isDisabled={selectedAmountWatch < 1}
              w="100%"
              ml="auto">
              {selectedAmountUnderpaid
                ? 'Delbetala'
                : selectedAmountOverpaid
                ? `Betala hela summan + ${selectedAmountWatch - price}kr`
                : 'Betala hela summan'}
            </Button>
          </ButtonGroup>
        </form>
      </FormProvider>
    </Box>
  )
}

const Options = ({ payment }) => {
  const { handleStep, previousStep, nextStep } = useWizard()
  const [isValid, setIsValid] = useState(false)

  return (
    <Box pt="0">
      <Flex flexDir="column" w="100%" mb="4">
        <Heading size="sm" mb="2">
          Betalning
        </Heading>
        <LabelRow
          label="Du betalar nu"
          value={`${payment.price}kr`}
          color="green"
        />
      </Flex>

      <ListOption
        icon="cash-register"
        title="Betala i kassan"
        description="Kassan kommer öppnas som ett fönster"
      />

      <ListOption
        icon={invoice.icon}
        title="Betala via faktura"
        description="Guide för att skapa en ny faktura öppnas"
      />

      <Divider w="60%" my="4" mx="auto" />

      <ListOption
        icon="exclamation-triangle"
        title="Makera som externt betald"
        description="Du intygar att betalning har utförts utanför Novasecur"
        color="#e17055"
      />

      <ButtonGroup w="100%" mt="2" size="sm">
        <Button onClick={() => previousStep()} w="100%" ml="auto">
          Tillbaka
        </Button>
      </ButtonGroup>
    </Box>
  )
}

const ListOption = ({ icon, title, description, color, onClick }) => (
  <Flex
    justify="center"
    align="center"
    bg="rgba(255, 255, 255, 0.05)"
    p="4"
    mb="2"
    rounded="lg"
    boxShadow="md"
    cursor="pointer"
    transition="0.3s"
    border="1px"
    borderColor="rgba(255, 255, 255, 0.1)"
    _hover={{ transform: 'scale(1.01)' }}
    onClick={onClick}>
    <FontAwesomeIcon color={color} size="2x" icon={['fal', icon]} />
    <Flex flexDir="column" ml="4" mr="auto">
      <Heading color={color} size="sm">
        {title}
      </Heading>
      <Text color={color} fontSize="sm">
        {description}
      </Text>
    </Flex>
  </Flex>
)
