import React from 'react'
import { Helmet } from 'react-helmet'
import { isIE, isLegacyEdge } from 'react-device-detect'
import { useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text
} from '@chakra-ui/react'

import ThemeManager from 'containers/ThemeManager'
import NetworkManager from 'containers/NetworkManager'
import SlideOutManager from 'containers/SlideOutManager'
import SearchModal from 'containers/SearchModal'
import LoginOverlay from 'containers/LoginOverlay'
import HelpOverlay from 'containers/HelpOverlay'
import PanelRouter from 'PanelRouter'
import Register from 'containers/register/Register'
import NovaChat from 'containers/NovaChat'

import Preflight from 'containers/Preflight'
import BankIDFlow from 'containers/BankIDFlow'
import MessageSender from 'containers/services/MessageSender'
import CardCollector from 'containers/services/CardCollector'
import EntryRegistration from 'containers/services/EntryRegistration'
import AllDoors from 'containers/services/AllDoors'
import MemberPicker from 'containers/services/MemberPicker'
import PaySelector from 'containers/services/PaySelector'

import CookieConsent from 'components/CookieConsent'
import ErrorBoundary from 'components/ErrorBoundary'

const PageRoot = ({ children }) => {
  const appReady = useSelector((state) => state.app.appReady)

  return (
    <ThemeManager>
      <Preflight>
        <Helmet>
          <title>Novasecur</title>
        </Helmet>

        <HelpOverlay>{children}</HelpOverlay>

        <ErrorBoundary>
          <SlideOutManager />
        </ErrorBoundary>

        <CookieConsent />
        <NetworkManager />
        {appReady && (
          <>
            {/* <NovaChat /> */}
            <Register />
            <LoginOverlay />
            <PanelRouter />
            <BankIDFlow />
            <SearchModal />
            <CardCollector />
            <EntryRegistration />
            <AllDoors />
          </>
        )}
      </Preflight>

      {
        <Modal isOpen={isIE || isLegacyEdge} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Du använder en inkompatibel webbläsare</ModalHeader>
            <ModalBody>
              <Text>
                Webbläsaren du använder är inte kompatibel med Novasecur. <br />
                Vänligen uppgradera din webbläsare eller byt till senaste
                versionen av Chrome, Firefox, Opera eller Edge.
              </Text>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      }
    </ThemeManager>
  )
}

export default PageRoot
