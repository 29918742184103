const themeLight = {
  type: 'light',
  primary_body: '#fff',
  secondary_body: '#f5f5f5',
  divider: 'hsl(0,0%,80%)',
  primary_text: '#474747',
  secondary_text: '#6c757d',
  background: '#f5f5f6'
}

export default themeLight
