import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistance, fromUnixTime } from 'date-fns'
import { sv } from 'date-fns/locale'
import {
  Box,
  Flex,
  Fade,
  Center,
  SimpleGrid,
  Heading,
  Text,
  Container,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import { invoice } from 'constants/entities'

import Checklist from './Checklist'

// const BoxList = styled.div`
//   display: flex;
//   flex-direction: column;
// `

// const BoxListTitle = styled.span`
//   font-size: 20px;
//   font-weight: bolder;
//   text-transform: uppercase;
//   text-align: center;
// `

// const BoxOuter = styled.div`
//   width: 100%;

//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   background: ${({ color, gradientColor, theme }) =>
//     gradientColor ? theme.gradients.vivid(gradientColor) : color};

//   border-radius: 12px;
//   padding: 6px 4px;
//   margin: 3px 0;
//   cursor: pointer;
//   transition: 0.3s;
// `

// const BoxHeader = styled.div`
//   width: 100%;

//   display: flex;
//   align-items: center;

//   padding-right: 15px;
//   margin-left: 15px;

//   span {
//     font-size: 10px;
//     font-weight: bolder;
//     text-transform: uppercase;
//     opacity: 0.85;
//   }

//   p {
//     font-size: 20px;
//     font-weight: bolder;
//     margin: 0;
//     margin-top: -7px;
//   }

//   svg:first-child {
//     font-size: 30px;
//     margin-right: 10px;
//   }

//   svg {
//     font-size: 35px;
//   }
// `

// const BoxHeaderLeft = styled.div`
//   margin-right: auto;
//   margin-top: -2px;
// `

// const BoxInner = styled.div`
//   width: 100%;
//   height: 400px;
//   background-color: ${({ color, theme }) => color || theme.primary_body};
//   border-radius: 8px;
//   padding: 6px;
//   margin-top: 8px;
// `

// const BoxItem = styled.div`
//   width: 100%;
//   min-height: 30px;

//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   background-color: rgba(255, 255, 255, 0.15);
//   padding: 12px 15px;
//   margin-bottom: 6px;
//   border-radius: 6px;

//   small {
//     opacity: 0.85;
//     font-size: 13px;
//     font-weight: bolder;
//   }

//   span {
//     font-size: 18px;
//     font-weight: bolder;
//     margin-top: -4px;
//   }

//   p {
//     font-size: 13px;
//     margin: 0;
//   }
// `

const GET_DASHBOARD = gql`
  query dashboard {
    dashboard {
      greeting
      stats {
        content
        type
      }
      headerImage

      banners {
        title
        content
        type
      }
      tips {
        content
      }
      news {
        id
        timestamp
        title
        content
      }
    }
  }
`

const DashboardContent = () => {
  const dispatch = useDispatch()
  const bg = useColorModeValue('white', 'gray.800')
  const tipBg = useColorModeValue('gray.100', 'gray.600')

  const toDoLists = useSelector((state) => state.user.user.toDoLists)
  const { loading, error, data } = useQuery(GET_DASHBOARD)

  if (loading) return null
  if (error) return null

  return (
    <>
      <Box
        h="50vh"
        w="100%"
        bg={`url(${data.dashboard.headerImage})`}
        bgSize="100%"
        bgRepeat="no-repeat"
        boxShadow="inner"
        bgAttachment="fixed">
        <Box
          h="100%"
          w="100%"
          bgGradient={`linear(to-b, transparent, ${bg} 90%)`}
        />
      </Box>

      <Container maxW="container.xl" mt="-40vh">
        <Fade in={true} unmountOnExit>
          <Flex direction="column">
            {data.dashboard.banners.map(({ title, content, type }) => (
              <Alert
                key={title}
                status={type.toLowerCase()}
                rounded="lg"
                mb="2">
                <AlertIcon />
                <AlertTitle mr={2}>{title}</AlertTitle>
                <AlertDescription>{content}</AlertDescription>
              </Alert>
            ))}

            <Flex h="380px" mb="2">
              <Box flex="2" py="8" px="10" rounded="xl" boxShadow="2xl" bg={bg}>
                <Heading size="3xl" mb="8" w="80%">
                  {data.dashboard.greeting}
                </Heading>

                <Flex flexDir="column" align="flex-start">
                  {data.dashboard.stats.map(({ content, type }) => {
                    const color = '#FAF089'
                    const icon = 'lightbulb'

                    if (type === 'MONEY') {
                      color = '#16a085'
                      icon = 'coins'
                    } else if (type === 'INVOICE') {
                      color = invoice.color
                      icon = invoice.icon
                    } else if (type === 'STAT') {
                      color = '#e67e22'
                      icon = 'chart-pie'
                    }

                    return (
                      <Flex
                        h="50px"
                        mt="2"
                        align="center"
                        bg="whiteAlpha.50"
                        py="1"
                        pr="3"
                        rounded="md">
                        <Center w="65px">
                          <FontAwesomeIcon
                            color={color}
                            size="lg"
                            icon={['fad', icon]}
                          />
                        </Center>
                        <Heading size="sm" ml="1">
                          {content}
                        </Heading>
                      </Flex>
                    )
                  })}
                </Flex>
              </Box>

              {data.dashboard.tips && (
                <Box
                  flex="1"
                  ml="5"
                  py="8"
                  px="10"
                  rounded="xl"
                  boxShadow="2xl"
                  bg={tipBg}>
                  <Flex>
                    <Center w="45px">
                      <FontAwesomeIcon
                        color="#ECC94B"
                        size="2x"
                        icon={['fad', 'bolt']}
                      />
                    </Center>
                    <Heading size="xl">Nova tips</Heading>
                  </Flex>

                  <Text mt="4" fontSize="xl" fontWeight="bold">
                    {data.dashboard.tips[0].content}
                  </Text>
                </Box>
              )}
            </Flex>
          </Flex>

          <SimpleGrid columns={[1, 2, 3]} spacing={4}>
            {toDoLists?.map(({ id }) => (
              <Checklist key={id} id={id} />
            ))}

            <Flex flexDir="column" mt="6px">
              {data.dashboard.news.map(({ timestamp, title, content }) => (
                <Flex flexDir="column" bg="whiteAlpha.100" p="2" rounded="md">
                  <Text color="whiteAlpha.400" fontSize="xs">
                    {formatDistance(fromUnixTime(timestamp), new Date(), {
                      addSuffix: true,
                      locale: sv
                    })}
                  </Text>
                  <Heading size="sm">{title}</Heading>
                  <Text fontSize="sm">{content}</Text>
                </Flex>
              ))}
            </Flex>

            {/* <BoxList>
              <Heading size="lg" mt="2" mb="4" textAlign="center">
                Kom igång!
              </Heading>

              <BoxOuter gradientColor="#1abc9c">
                <BoxHeader>
                  <FontAwesomeIcon icon={['fad', 'user']} />
                  <BoxHeaderLeft>
                    <span>Skapa en ny</span>
                    <p>Medlem</p>
                  </BoxHeaderLeft>
                  <FontAwesomeIcon icon={['fad', 'long-arrow-right']} />
                </BoxHeader>
              </BoxOuter>

              <BoxOuter gradientColor="#ffbd59">
                <BoxHeader>
                  <FontAwesomeIcon icon={['fad', 'repeat-alt']} />
                  <BoxHeaderLeft>
                    <span>Skapa ett nytt</span>
                    <p>Abonnemang</p>
                  </BoxHeaderLeft>
                  <FontAwesomeIcon icon={['fad', 'long-arrow-right']} />
                </BoxHeader>
              </BoxOuter>

              <BoxOuter gradientColor="#fea47f">
                <BoxHeader>
                  <FontAwesomeIcon icon={['fad', 'ticket-alt']} />
                  <BoxHeaderLeft>
                    <span>Skapa en ny</span>
                    <p>Bokning</p>
                  </BoxHeaderLeft>
                  <FontAwesomeIcon icon={['fad', 'long-arrow-right']} />
                </BoxHeader>
              </BoxOuter>

              <BoxOuter gradientColor="#fd7272">
                <BoxHeader>
                  <FontAwesomeIcon icon={['fad', 'file-invoice']} />
                  <BoxHeaderLeft>
                    <span>Skapa en ny</span>
                    <p>Faktura</p>
                  </BoxHeaderLeft>
                  <FontAwesomeIcon icon={['fad', 'long-arrow-right']} />
                </BoxHeader>
              </BoxOuter>
            </BoxList> */}
          </SimpleGrid>
        </Fade>
      </Container>
    </>
  )
}

export default DashboardContent
