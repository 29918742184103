import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { formatDistance, fromUnixTime } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Flex, ButtonGroup, Button, Heading, Text } from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import SubtleCard from 'components/cards/SubtleCard'

const GET_INTERNAL_LOG = gql`
  query internalLog($page: Int) {
    internalLog(page: $page) {
      entries {
        id
        timestamp
        title
      }
      hasNewer
      hasOlder
    }
  }
`

const InternalLog = () => {
  const toast = useToaster()
  const [page, setPage] = useState(0)

  const { loading, error, data } = useQuery(GET_INTERNAL_LOG, {
    variables: { page: page },
    fetchPolicy: 'network-only'
  })

  if (error) return error
  return (
    <>
      {!loading && (
        <Flex w="100%" flexDir="column">
          {data.internalLog.entries.map(({ id, timestamp, title }) => (
            <SubtleCard key={id} mb="1">
              <Flex>
                <Text as="small" fontSize="xs">
                  {formatDistance(fromUnixTime(timestamp), new Date(), {
                    addSuffix: true,
                    locale: sv
                  })}
                  · #{id}
                </Text>
              </Flex>
              <Heading size="xs">{title}</Heading>
            </SubtleCard>
          ))}

          <ButtonGroup mt="2" ml="auto">
            <Button
              size="sm"
              disabled={!data.internalLog.hasNewer}
              onClick={() => setPage(page - 1)}>
              Visa nyare
            </Button>
            <Button
              size="sm"
              disabled={!data.internalLog.hasOlder}
              onClick={() => setPage(page + 1)}>
              Visa äldre
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </>
  )
}

export default InternalLog
