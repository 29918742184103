import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Masonry from 'react-masonry-css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Flex,
  Center,
  SimpleGrid,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Input
} from '@chakra-ui/react'

import { booking } from 'constants/entities'
import BaseCard from 'components/cards/BaseCard'
import ListOption from 'components/ListOption'

const SettingsBoxes = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const user = useSelector((state) => state.user.user)
  const selectedFacilities = useSelector(
    (state) => state.user.user.selectedFacilities
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [searchString, setSearchString] = useState('')

  const boxes = [
    {
      title: 'Personal & arbetstider',
      color: '#8395a7',
      icon: 'shield-heart',
      column: 0,
      items: [
        {
          title: 'Personal',
          subtitle: 'Hantera personal i din organization',
          icon: 'users',
          tags: ['']
        },
        {
          title: 'Arbetsscheman',
          subtitle: 'Hantera arbetsschemat för personalen',
          icon: 'calendar-range',
          tags: ['Semester']
        },
        {
          title: 'Arbetstider',
          subtitle: 'Hantera arbetstider för personalen',
          icon: 'clock',
          tags: ['']
        }
      ]
    },
    {
      title: 'Företag',
      color: '#9b59b6',
      icon: 'industry-windows',
      column: 0,
      items: [
        {
          title: 'Gymmet i stan AB',
          subtitle: 'Hantera företaget Gymmet i stan AB',
          icon: 'industry',
          tags: ['']
        }
      ]
    },
    {
      title: 'Anläggningar',
      color: '#16a085',
      icon: 'buildings',
      column: 0,
      items: [
        {
          title: 'Göteborg',
          subtitle: 'Hantera anläggningen Göteborg',
          icon: 'building',
          tags: ['']
        }
      ]
    },
    {
      title: 'Bokning',
      color: booking.color,
      icon: booking.icon,
      column: 0,
      items: [
        {
          title: 'Passbokning',
          subtitle: 'Allmänna inställningar för passbokning',
          icon: 'ballot-check',
          tags: ['']
        },
        {
          title: 'Passunderlag',
          subtitle: 'Skapa och hantera passunderlag',
          icon: 'book-blank',
          tags: ['']
        },
        {
          title: 'Regler',
          subtitle: 'Hantera bokningsregler',
          icon: 'scale-balanced',
          tags: ['']
        },
        {
          title: 'Resurser',
          subtitle: 'Hantera salar, rum och bassänger',
          icon: 'people-roof',
          tags: ['']
        },
        {
          title: 'Kategorier',
          subtitle: 'Hantera bokningskategorier',
          icon: 'puzzle-piece-simple',
          tags: ['']
        },
        {
          title: 'Hantera scheman',
          subtitle: 'Hantera interna schemavyer',
          icon: 'calendars',
          tags: ['']
        }
      ]
    },
    {
      title: 'Försäljning',
      color: '#fd7272',
      icon: 'coin',
      column: 1,
      items: [
        {
          title: 'Faktura & fakturering',
          subtitle: 'Allmänna inställningar för fakturering',
          icon: 'file-invoice',
          tags: ['']
        },
        {
          title: 'Nummerserier',
          subtitle: 'Hantera nummerserier för försäljning',
          icon: 'sort-numeric-up-alt',
          tags: ['']
        },
        {
          title: 'Inkassokonto',
          subtitle: 'Hantera din organizations inkassokonto',
          icon: 'piggy-bank',
          tags: ['']
        },
        {
          title: 'Kontoplan',
          subtitle: 'Visa kontoplan',
          icon: 'glasses-alt',
          tags: ['']
        },
        {
          title: 'Kostnadsställen',
          subtitle: 'Hantera kostnadsställen',
          icon: 'university',
          tags: ['']
        },
        {
          title: 'Lagerplatser',
          subtitle: 'Hantera lagerplatser',
          icon: 'forklift',
          tags: ['']
        },
        {
          title: 'Terminaler',
          subtitle: 'Hantera betalterminaler',
          icon: 'credit-card',
          tags: ['']
        },
        {
          title: 'Swedbank Pay',
          subtitle: 'Hantera integration mot Swedbank pay',
          icon: 'money-bill-wave',
          tags: ['']
        },
        {
          title: 'Fortnox',
          subtitle: 'Hantera integration mot Fortnox',
          icon: 'wallet',
          tags: ['']
        }
      ]
    },
    {
      title: 'Produktregister',
      color: '#e74c3c',
      icon: 'box-open-full',
      column: 1,
      items: [
        {
          title: 'Produkter',
          subtitle: 'Allmänt produktregister',
          icon: 'gift',
          tags: ['']
        },
        {
          title: 'Abonnemangsmallar',
          subtitle: 'Skapa och hantera abonnemangsmallar',
          icon: 'arrows-repeat',
          tags: ['']
        },
        {
          title: 'Arrangemang',
          subtitle: 'Skapa och hantera arrangemang',
          icon: 'flag-checkered',
          tags: ['']
        },
        {
          title: 'Tjänster',
          subtitle: 'Skapa och hantera tjänster',
          icon: 'hand-holding-dollar',
          tags: ['']
        },
        {
          title: 'Kategorier',
          subtitle: 'Skapa och hantera produktkategorier',
          icon: 'puzzle',
          tags: ['']
        },
        {
          title: 'Leverantörer',
          subtitle: 'Skapa och hantera leverantörer',
          icon: 'people-carry-box',
          tags: ['']
        },
        {
          title: 'Momsgrupper',
          subtitle: 'Skapa och hantera momsgrupper',
          icon: 'percent',
          tags: ['']
        },
        {
          title: 'Varugrupper',
          subtitle: 'Skapa och hantera varugrupper',
          icon: 'ball-pile',
          tags: ['']
        },
        {
          title: 'Rabattkoder',
          subtitle: 'Skapa och hantera rabattkoder',
          icon: 'badge-percent',
          tags: ['']
        }
      ]
    },
    {
      title: 'Lager',
      color: '#2980b9',
      icon: 'warehouse',
      column: 1,
      items: [
        {
          title: 'Lagersaldo',
          subtitle: 'Hantera lagersaldon',
          icon: 'pallet-boxes',
          tags: ['']
        },
        {
          title: 'Lagerställen',
          subtitle: 'Hantera lagerställen',
          icon: 'warehouse-full',
          tags: ['']
        }
      ]
    },
    {
      title: 'Inpassering',
      color: '#01a3a4',
      icon: 'user-lock',
      column: 2,
      items: [
        {
          title: 'Dörrar',
          subtitle: 'Kontrollera och hantera dörrar',
          icon: 'door-open',
          tags: ['']
        },
        {
          title: 'Zoner',
          subtitle: 'Zoner för inpasseringssystem',
          icon: 'drafting-compass',
          tags: ['']
        },
        {
          title: 'Schema',
          subtitle: 'Schema för inpasseringszoner',
          icon: 'calendar-days',
          tags: ['']
        },
        {
          title: 'Kort och taggar',
          subtitle: 'Inställningar för fysiska kort och taggar',
          icon: 'user-tag',
          tags: ['']
        },
        {
          title: 'Passerkoder',
          subtitle: 'Skapa och hantera koder till inpasseringssystemet',
          icon: 'asterisk',
          tags: ['']
        }
      ]
    },
    {
      title: 'Enheter',
      color: '#10ac84',
      icon: 'phone-laptop',
      column: 2,
      items: [
        {
          title: 'Kopplade fjärrenheter',
          subtitle: 'Telefoner, surfplattor och system kopplade till Novasecur',
          icon: 'mobile-signal-out',
          tags: ['']
        },
        {
          title: 'Mobilapp',
          subtitle: 'Anpassa och hantera mobilappen',
          icon: 'mobile-screen-button',
          tags: ['']
        },
        {
          title: 'Personalapp',
          subtitle: 'Hantera Personalappen',
          icon: 'rocket-launch',
          tags: ['']
        },
        {
          title: 'Webb Portal',
          subtitle: 'Anpassa och hantera webb portaler',
          icon: 'browser',
          tags: ['']
        },
        {
          title: 'API nycklar',
          subtitle: 'Lägg till och ta bort API nycklar',
          icon: 'binary',
          tags: ['']
        }
      ]
    },
    {
      title: 'Rapporter',
      color: '#f39c12',
      icon: 'chart-pie',
      column: 2,
      items: [
        {
          title: 'Alternativ för rapportgenerator',
          subtitle: 'Alternativ för rapporter genererade av rapportgeneratorn',
          icon: 'rectangle-list',
          tags: ['']
        }
      ]
    }
  ]

  return (
    <Box mt="12">
      <Flex mb="4" mx="4">
        <Box onClick={onOpen}>
          <FontAwesomeIcon size="5x" icon={['fad', 'id-badge']} />
        </Box>
        <Flex justify="space-between" w="100%" ml="5">
          <Box>
            <Heading size="md" opacity="0.6">
              Administratör
            </Heading>
            <Heading>
              {user.firstname} {user.lastname}
            </Heading>
          </Box>

          <Flex flexDir="column" align="flex-end">
            <Button mb="1">Byt lösenord</Button>
            <Button>Logga ut</Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex w="100%" px="2" mb="2" mt="6">
        <Input
          size="lg"
          placeholder="Sök i inställningar"
          variant="filled"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </Flex>

      <SimpleGrid columns={3} spacing={3}>
        <Flex flexDir="column">
          {boxes
            .filter(({ column }) => column === 0)
            .map((group) => (
              <SettingsGroupCard {...group} searchString={searchString} />
            ))}

          {/*

          <BaseCard title="Användare" icon="user" color="#6c5ce7">
            <ListOption
              title={colorMode === 'light' ? 'Ljust tema' : 'Mörkt tema'}
              description="Ändra temat för Novasecur"
              icon="brush"
              onClick={toggleColorMode}
              color="#6c5ce7"
            />
          </BaseCard>

          <BaseCard title="Bokning" icon={booking.icon} color={booking.color}>
            <ListOption
              title="Arbetsschema"
              description="Hantera alla uppkopplade dörrar"
              icon="drafting-compass"
              color={booking.color}
            />
            <ListOption
              title="Arbetsschema"
              description="Hantera alla uppkopplade dörrar"
              icon="drafting-compass"
              color={booking.color}
            />
            <ListOption
              title="Arbetsschema"
              description="Hantera alla uppkopplade dörrar"
              icon="drafting-compass"
              color={booking.color}
            />
            <ListOption
              title="Arbetsschema"
              description="Hantera alla uppkopplade dörrar"
              icon="drafting-compass"
              color={booking.color}
            />
          </BaseCard>

          <BaseCard title="Kalender" icon="calendar-day" color="#fdcb6e">
            <ListOption
              title="Ändra kategorier"
              description="Lägg till och hantera kategorier för kalendern"
              icon="ball-pile"
              color="#fdcb6e"
            />
          </BaseCard> */}
        </Flex>

        <Flex flexDir="column">
          {boxes
            .filter(({ column }) => column === 1)
            .map((group) => (
              <SettingsGroupCard {...group} searchString={searchString} />
            ))}
        </Flex>

        <Flex flexDir="column">
          {boxes
            .filter(({ column }) => column === 2)
            .map((group) => (
              <SettingsGroupCard {...group} searchString={searchString} />
            ))}
        </Flex>
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Felsökningshjälp</ModalHeader>

          <ModalBody>
            <Text fontSize="xs">
              <pre>{JSON.stringify(user, null, 2)}</pre>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default SettingsBoxes

const SettingsGroupCard = ({ title, color, icon, items, searchString }) => {
  const filteredItems = items.filter(
    ({ title, subtitle, tags }) =>
      title.toLowerCase().includes(searchString.toLowerCase()) ||
      subtitle.toLowerCase().includes(searchString.toLowerCase()) ||
      tags.filter((tag) =>
        tag.toLowerCase().includes(searchString.toLowerCase())
      ).length > 0
  )

  return filteredItems.length === 0 ? null : (
    <BaseCard title={title} icon={icon} color={color}>
      {filteredItems.map(({ title, subtitle, icon, color }) => (
        <ListOption
          title={title}
          description={subtitle}
          icon={icon}
          color={color}
        />
      ))}
    </BaseCard>
  )
}
