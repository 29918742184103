import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const entry = keyframes`
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  min-width: 55px;
  font-size: 23px;
  background-color: ${({ active, color }) => (active ? color : '#8395a7')};
  border-radius: 50px;
  color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: 0.3s ease;
  margin: 0 6px;
  animation: ${entry} 1.2s;
  ${({ active }) =>
    active &&
    css`
      animation: ${pulse} 2s infinate;
    `};

  ${({ primary }) => primary && ' padding: 0 15px 0 20px;'}
  ${({ primary }) => primary && 'margin-left: 15px;'}


  &:hover {
    transform: translateY(2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

const Title = styled.p`
  font-size: 14px;
  padding: 1px 5px 0 10px;
  margin: 0;
  text-transform: uppercase;
`

const FloatingButton = ({
  label,
  title,
  icon,
  color,
  primary,
  active = true
}) => (
  <Outer color={color} title={title} primary={primary} active={active}>
    <FontAwesomeIcon icon={['fal', icon]} />
    {title && <Title>{title}</Title>}
  </Outer>
)

export default FloatingButton
