import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import {
  isValid,
  format,
  fromUnixTime,
  differenceInCalendarMonths
} from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import {
  Box,
  Flex,
  Wrap,
  Center,
  Stack,
  Heading,
  Button,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Text,
  Grid,
  GridItem,
  Tag,
  TagLeftIcon,
  TagLabel,
  Divider,
  RadioGroup,
  Radio,
  ButtonGroup
} from '@chakra-ui/react'

import { member, membership, invoice } from 'constants/entities'

import SlideOutPanel from 'components/slideouts/SlideOutPanel'
import SlideOutSection from 'components/slideouts/SlideOutSection'
import SlideOutColumns, { Column } from 'components/slideouts/SlideOutColumns'
import SectionTitle from 'components/slideouts/SectionTitle'
import BaseCard from 'components/cards/BaseCard'
import SwitchDisclosureCard from 'components/cards/SwitchDisclosureCard'
import EntityCard from 'components/cards/EntityCard'
import LabelRow from 'components/LabelRow'
import MemberPicker from 'containers/MemberPicker'

import Timeline, { TimelineEntry, TimelineConnector } from 'components/Timeline'
import TimeTraveler from 'components/TimeTraveler'

import TextInput from 'components/form/TextInput'
import SwitchInput from 'components/form/SwitchInput'
import SelectorInput from 'components/form/SelectorInput'
import DateRangeInput from 'components/form/DateRangeInput'
import DateInput from 'components/form/DateInput'
import RadioInput from 'components/form/RadioInput'
import NumberInput from 'components/form/NumberInput'
import YesNoInput from 'components/form/YesNoInput'

import SwitchInputWithDisclosure from 'components/form/SwitchInputWithDisclosure'
import InvoiceRow from 'components/InvoiceRow'
import ShowFile from 'components/ShowFile'
import SkeletonPanel from 'components/slideouts/SkeletonPanel'

const segments = [
  {
    startDate: 1501538400000,
    endDate: 1509490800000,
    type: 'freeze'
  },
  {
    startDate: 1509490800000,
    endDate: 1527804000000,
    type: 'normal'
  },
  {
    startDate: 1527804000000,
    endDate: 1604185200000,
    type: 'discounted'
  },
  {
    startDate: 1604185200000,
    endDate: 1651356000000,
    type: 'normal'
  }
]

const GET_INVOICE = gql`
  query getInvoice {
    facilities {
      id
      shortName
      name
    }
  }
`

const actions = [
  {
    title: 'Skicka betallänk',
    color: '#00cec9',
    icon: 'link',
    description: 'Skicka en betallänk till mottagaren för onlinebetalning'
  },
  {
    title: 'Skapa påminnelse',
    color: '#f1c40f',
    icon: 'siren-on',
    description: 'Skapa och skicka en påminnelse'
  },
  {
    title: 'Betala i kassan',
    color: '#00b894',
    icon: 'money-bill-wave',
    description: 'Öppna kassan och lägg till på kvittot'
  },
  {
    title: 'Betalmarkera',
    color: '#8e44ad',
    icon: 'check',
    description: 'Betalmarkera fakturan'
  },
  {
    title: 'Kopiera faktura',
    color: '#bdc3c7',
    icon: 'copy',
    description: 'Kopiera fakturan till en ny'
  },
  {
    title: 'Lås fakturan',
    color: '#2980b9',
    icon: 'lock',
    description: 'Skydda fakturan från ändringar'
  },
  {
    title: 'Makulera',
    color: '#d35400',
    icon: 'broom',
    description: 'Makulera fakturan'
  },
  {
    title: 'Radera fakturan',
    color: '#e17055',
    icon: 'ban',
    description: 'Radera fakturan förevigt'
  }
]

const navigation = [
  { to: 'inv-home', icon: 'home', color: '#00a8ff' },
  { to: 'inv-rows', icon: 'abacus', color: '#00b894' }
]

// const PreviewWindow = () => (
//   <Box
//     position="absolute"
//     top="0"
//     bottom="0"
//     right="0"
//     roundedLeft="xl"
//     h="80vh"
//     w="50vw"
//     my="auto"
//     p="5"
//     bg="gray.600"
//     zIndex="2"
//     transform="translateX(100%)"
//     border="1px"
//     borderColor="white"
//     borderRight="0"
//     transition="0.3s"
//     _hover={{ transform: 'translateX(0%)' }}>
//     <Box
//       position="absolute"
//       top="20px"
//       left="-53px"
//       p="3"
//       px="6"
//       roundedLeft="lg"
//       boxShadow="lg"
//       border="1px"
//       borderColor="white"
//       borderRight="0"
//       bg="#fd7272"
//       fontSize="lg"
//       fontWeight="bold"
//       style={{ writingMode: ' vertical-rl' }}>
//       Förhandsvisning
//     </Box>

//     <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//       <Viewer fileUrl={process.env.PUBLIC_URL + '/pdf-open-parameters.pdf'} />
//     </Worker>
//   </Box>
// )

const InvoicePanel = ({ uid, data: { id }, ...rest }) => {
  const [rows, setRows] = useState([
    { id: 41378764, product: 'Produkt ett' },
    ,
    { id: 4134234, product: 'Produkt två' },
    { id: 423424, product: 'Produkt tre' }
  ])
  const {
    isOpen: isOpenPriAd,
    onOpen: onOpenPriAd,
    onClose: onClosePriAd
  } = useDisclosure()

  const previewDisclosure = useDisclosure()

  const form = useForm()
  const rowForm = useForm()

  const { loading, error, refetch, data } = useQuery(GET_INVOICE, {
    skip: !id,
    variables: {},
    fetchPolicy: 'network-only'
  })

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = rows[dragIndex]
      setRows(
        update(rows, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [rows]
  )

  if (loading) return <SkeletonPanel loading={true} {...rest} />
  if (error) return <SkeletonPanel error={error} {...rest} />

  return (
    <>
      <SlideOutPanel
        actions={actions}
        navigation={navigation}
        {...rest}
        title={'test'}
        error={null}
        loading={false}
        actionLeftAddon={
          <Button
            onClick={previewDisclosure.onOpen}
            h="55px"
            rounded="full"
            mr="2">
            Förhandsvisning
          </Button>
        }
        actionRightAddon={
          <Button
            px="5"
            colorScheme="blue"
            leftIcon={<FontAwesomeIcon size="lg" icon={['fal', 'save']} />}
            onClick={previewDisclosure.onOpen}
            h="55px"
            rounded="full"
            ml="2">
            Spara
          </Button>
        }>
        <SlideOutSection name="inv-home">
          <FormProvider {...form}>
            <form style={{ width: '100%' }}>
              <Flex px="6" py="2" mb="4" rounded="lg">
                <Center pos="relative" h="100px" w="100px">
                  <FontAwesomeIcon
                    color={invoice.color}
                    size="5x"
                    icon={['fad', invoice.icon]}
                  />
                  <Center
                    pos="absolute"
                    bottom="0px"
                    right="5px"
                    h="40px"
                    w="40px"
                    rounded="full"
                    bg="#00b894">
                    <FontAwesomeIcon size="lg" icon={['fad', 'check']} />
                  </Center>
                </Center>

                <Flex height="100%" direction="column" justify="end" ml="6">
                  <Heading size="xl">Faktura</Heading>

                  <Box mt="1">
                    <Tag mr="1">
                      <TagLabel ml="1">Kreditfaktura</TagLabel>
                    </Tag>
                    <Tag mr="1">
                      <FontAwesomeIcon icon={['fad', invoice.icon]} />
                      <TagLabel ml="1">NR: 123243</TagLabel>
                    </Tag>
                  </Box>

                  <Wrap mt="5"></Wrap>
                </Flex>
              </Flex>

              <SlideOutColumns columns={3}>
                <Column>
                  <BaseCard icon="coins" color={invoice.color} title="Allmänt">
                    <SelectorInput
                      id="anlaggningar"
                      label="Utfärdad på anläggning"
                      multi
                      options={data?.facilities?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />

                    <MemberPicker label="Avtal på medlem" />

                    <MemberPicker label="Medlem för fakturan" />

                    <Divider
                      orientation="horizontal"
                      w="60%"
                      mx="auto"
                      mt="2"
                      mb="4"
                    />

                    <YesNoInput id="autopay" label="Autobetala om möjligt" />
                  </BaseCard>
                </Column>

                <Column>
                  <BaseCard
                    icon="hand-holding-box"
                    color="#27ae60"
                    title="Referens">
                    <TextInput id="ourReference" label="Vår referens" />

                    <TextInput id="yourReference" label="Er referens" />

                    <TextInput id="orgnr" label="Personnr/Org.nr" />
                  </BaseCard>

                  <BaseCard icon="alarm-clock" color="#f39c12" title="Datum">
                    <DateInput id="invoiceDate" label="Fakturadatum" />

                    <DateInput id="dueDate" label="Förfallodatum" />
                  </BaseCard>
                </Column>

                <Column>
                  <BaseCard
                    withDisclosure
                    disclosureDefaultOpen
                    px="2"
                    py="3"
                    title="Adressuppgifter"
                    icon="id-card"
                    color="#2e86de">
                    <LabelRow
                      label="Namn"
                      value="Stefan Testsson"
                      color="green"
                    />
                    <LabelRow label="C/O" value="Stor Stefan" color="green" />

                    <Box mb="2"></Box>

                    <LabelRow label="Adress" value="Testvägen 2" color="cyan" />
                    <LabelRow label="Adress 2" value="lgh 1101" color="cyan" />
                    <LabelRow label="Postnr" value="452 23" color="cyan" />
                    <LabelRow label="Ort" value="Testköping" color="cyan" />

                    <Box mb="2"></Box>

                    <LabelRow label="Mobil" value="3453646" color="red" />
                    <LabelRow
                      label="Mail"
                      value="test@example.com"
                      color="red"
                    />

                    <Button onClick={() => null} size="sm" mt="2" mb="-1">
                      Redigera Adressuppgifter
                    </Button>
                  </BaseCard>
                </Column>
              </SlideOutColumns>
            </form>
          </FormProvider>
        </SlideOutSection>

        <SlideOutSection name="inv-rows">
          <SectionTitle color="#00b894">Rader</SectionTitle>

          <Flex flexDir="column" w="100%" p="4">
            <Box mb="4">
              <Box
                bg="rgba(255, 255, 255, 0.06)"
                boxShadow="md"
                rounded="lg"
                py="3"
                pt="2"
                mb="2">
                <FormProvider {...form}>
                  <form>
                    <Flex py="1" px="4">
                      <Center flex="0.2"></Center>

                      <Box flex="4" w="100%">
                        <SelectorInput
                          style={{ width: '100%' }}
                          id="product"
                          label="Artikel"
                          multi
                          options={data?.facilities?.map(({ id, name }) => {
                            return {
                              value: id,
                              label: name
                            }
                          })}
                        />
                      </Box>

                      <Center flex="2" px="2">
                        <NumberInput
                          id="quantity"
                          label="Antal"
                          placeholder="antal"
                        />
                      </Center>

                      <Center flex="2" px="2">
                        <TextInput
                          id="vendingCredit"
                          label="Belopp (inkl. moms)"
                          placeholder="Belopp"
                          rightAddon={'SEK'}
                        />
                      </Center>

                      <Center flex="2" px="2" mt="auto">
                        <Button ml="auto" mb="1px">
                          Skapa ny rad
                        </Button>
                      </Center>
                    </Flex>
                  </form>
                </FormProvider>
              </Box>
            </Box>

            <Flex py="1" px="4">
              <Center flex="0.2"></Center>
              <Text flex="4" ml="4" mb="0.5" fontWeight="bold" fontSize="xs">
                Artikel
              </Text>
              <Text flex="2" ml="4" mb="0.5" fontWeight="bold" fontSize="xs">
                antal
              </Text>
              <Text flex="2" ml="4" mb="0.5" fontWeight="bold" fontSize="xs">
                Belopp inkl. moms
              </Text>

              <Box flex="2"></Box>
            </Flex>

            <DndProvider backend={HTML5Backend}>
              {rows.map(({ id, product }, index) => (
                <InvoiceRow
                  key={id}
                  index={index}
                  id={id}
                  move={moveRow}
                  product={product}></InvoiceRow>
              ))}
            </DndProvider>
          </Flex>
        </SlideOutSection>
      </SlideOutPanel>

      <ShowFile {...previewDisclosure} />
    </>
  )
}

export default InvoicePanel
