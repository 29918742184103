import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Flex,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text
} from '@chakra-ui/react'

import SubtleCard from 'components/cards/SubtleCard'
import SelectInput from 'components/form/SelectInput'
import SelectorInput from 'components/form/SelectorInput'
import DateInput from 'components/form/DateInput'
import DateRangeInput from 'components/form/DateRangeInput'
import TextInput from 'components/form/TextInput'
import NumberRangeInput from 'components/form/NumberRangeInput'
import useFormAutoSubmit from 'utils/useFormAutoSubmit'

const FreshTableFilter = ({
  availableFilters = [],
  activeFilters: remoteActiveFilters = [],
  isOpen,
  onClose,
  onFilterChange
}) => {
  const [activeFilters, setActiveFilters] = useState([])

  useEffect(() => {
    if (isOpen) {
      setActiveFilters(remoteActiveFilters)
    }
  }, [isOpen])

  const form = useForm()

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>Tabell filter</Center>
        </ModalHeader>

        <ModalBody>
          <FormProvider {...form}>
            <form>
              <SelectorInput
                id="filterSearch"
                label="Välj ett nytt filter"
                noOptionsMessage="Inga fler filter"
                placeholder="Välj eller sök..."
                options={availableFilters
                  .filter(
                    ({ id: availableFilterId }) =>
                      !activeFilters.find(({ id }) => id === availableFilterId)
                  )
                  .map(({ id, label }) => ({
                    label: label,
                    value: id
                  }))}
                onChange={({ value }) => {
                  if (!activeFilters.find(({ id }) => id === value)) {
                    setActiveFilters([
                      ...activeFilters,
                      availableFilters.find(({ id }) => id === value)
                    ])
                  }
                }}
              />
            </form>
          </FormProvider>

          <Box my="4">
            <Heading size="xs">Aktiva filter</Heading>
            <Flex flexDir="column" mt="1">
              {activeFilters.map((filter) => (
                <FilterRowRender
                  {...filter}
                  setActiveFilters={setActiveFilters}
                  activeFilters={activeFilters}
                />
              ))}

              {activeFilters.length === 0 && (
                <Center>
                  <Heading my="4" opacity="0.4" size="xs" textAlign="center">
                    Inga aktiva filter
                  </Heading>
                </Center>
              )}
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            w="100%"
            mr="2"
            onClick={() => {
              onClose()
              setActiveFilters([])
            }}>
            Avbryt
          </Button>
          <Button
            size="sm"
            colorScheme="blue"
            w="100%"
            onClick={() => {
              onFilterChange(
                activeFilters.map(({ id, value }) => ({ id, value }))
              )
              onClose()
              setActiveFilters([])
            }}>
            Använd filter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FreshTableFilter

const FilterRowRender = ({
  id,
  label,
  type,
  options,
  activeFilters,
  setActiveFilters
}) => {
  const form = useForm()

  useFormAutoSubmit({
    form,
    onSubmit: form.handleSubmit(({ value }) => {
      setActiveFilters(
        activeFilters.map((filter) => {
          if (filter.id === id) {
            return { ...filter, value: value.split(',') }
          } else {
            return filter
          }
        })
      )
    })
  })

  return (
    <SubtleCard mb="1" p="4" pb="3">
      <Text fontSize="sm" fontWeight="bold" mb="1">
        {label}
      </Text>

      <FormProvider {...form}>
        <form>
          {type === 'STRING' ? (
            <TextInput id="value" />
          ) : type === 'STRINGWITHTYPE' ? (
            <TextInput id="value" rightAddon={options[0]} />
          ) : type === 'SELECT' ? (
            <SelectInput
              id="value"
              placeholder="Välj"
              options={options.map((val) => ({
                label: val,
                value: val
              }))}
            />
          ) : type === 'MULTISELECT' ? (
            <SelectorInput
              id="value"
              placeholder="Välj"
              multi
              options={options.map((val) => ({
                label: val,
                value: val
              }))}
            />
          ) : type === 'DATE' ? (
            <DateInput id="value" />
          ) : type === 'DATERANGE' ? (
            <DateRangeInput id="value" />
          ) : type === 'RANGE' ? (
            <NumberRangeInput
              bothMin={options[0]}
              bothMax={options[1]}
              id="value"
            />
          ) : null}
        </form>
      </FormProvider>

      <Flex mt="-1" justifyContent="flex-end">
        <Button
          size="xs"
          colorScheme="red"
          variant="ghost"
          onClick={() =>
            setActiveFilters(
              activeFilters.filter(({ id: filterId }) => filterId !== id)
            )
          }>
          Radera filter
        </Button>
      </Flex>
    </SubtleCard>
  )
}
