import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid
} from '@chakra-ui/react'

import { useToaster } from 'utils/toaster'
import { closeAllDoors } from 'actions/services'

import DoorController from 'components/DoorController'
import TextInput from 'components/form/TextInput'

const START_MEDIAID_POLL = gql`
  mutation startMediaIdPoll {
    startMediaIdPoll {
      success
    }
  }
`

const POLL_MEDIAID = gql`
  query pollMediaId {
    pollMediaId {
      hasResult
      result
    }
  }
`

const AllDoors = () => {
  const toast = useToaster()
  const dispatch = useDispatch()
  const allDoorsOpen = useSelector((state) => state.services.allDoors.open)

  const selectedMainFacility = useSelector(
    (state) => state.user.user.selectedMainFacility
  )

  const doors =
    useSelector((state) => state.user.user.selectedMainFacility.doors) || []

  return (
    <Modal
      isOpen={allDoorsOpen}
      onClose={() => {
        dispatch(closeAllDoors())
      }}
      size="lg">
      <ModalOverlay css={{ backdropFilter: 'blur(10px)' }} />
      <ModalContent>
        <ModalHeader pb="0">
          <FontAwesomeIcon
            size="lg"
            style={{ marginRight: '15px' }}
            icon={['fad', 'door-open']}
          />
          Alla dörrar: {selectedMainFacility?.name}
        </ModalHeader>

        <ModalCloseButton size="lg" />
        <ModalBody my="4">
          <Grid templateColumns="repeat(1, 1fr)" gap={1}>
            {[...doors]
              .sort((a, b) => {
                return Number(a.isPrimary) - Number(b.isPrimary)
              })
              .reverse()
              .map((values) => (
                <DoorController key={values.id} {...values} />
              ))}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AllDoors
